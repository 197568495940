import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import {
    Button,
    FormControl,
    FormLabel,
    Stack,
    Text,
    HStack,
    Spacer,
    Heading,
    SimpleGrid,
    TabPanels,
    Tabs,
    TabList,
    Tab,
    TabPanel,
} from '@chakra-ui/react';
import { ObjectId, to_oid } from "../../utils/ObjectId";

import {FiArchive, FiCopy, FiPlus} from "react-icons/fi";
import { CourseUpdate } from "../../models/course";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { CourseAPI } from "../../api/CourseAPI";
import { AttendanceAPI } from "../../api/AttendanceAPI";
import DatePicker from "../DatePicker";
import { CheckIcon } from "@chakra-ui/icons";
import { UserRole } from "../../api/UserApi";
import LocalStorageService from "../../api/LocalStorageService";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { updateCourseSchema } from "../../validations/course";
import FormInput from "../forms/FormInput";
import ExternalLinks from "./ExternalLinks";
import {SemesterAPI} from "../../api/SemesterAPI";
import FormSelectOptions from "../forms/FormSelectObjects";
import { TableTeacher, Teacher } from "../../models/teacher";
import { Lookup } from "../../models/lookup";
import { TeacherAPI } from "../../api/TeacherAPI";
import { LightButton } from "../layout/LightButton";
import SimpleTeacherList from "../teachers/SimpleTeacherList";
import AddStudentsDialog from "./AddStudentsDialog";
import AddTeachersDialog from "./AddTeachersDialog";
import ReportLayoutForm from "./cards/ReportLayoutForm";
import {CreateCourseDialog} from "./CreateCourseDialog";
import GClassItemViewer from "../gclass/GClassItemViewer";
import { StudentAPI } from "../../api/StudentAPI";
import { Student } from "../../models/student";
import SimpleStudentsList from "../students/SimpleStudentsList";
import { BlCard } from "../layout/Card";
import { proto_utils } from "../../utils/proto_utils";
import { ReportLayout } from "protobuffer-ts/dist/class_service/report_layout";
import { useToaster } from "../../hooks/useToaster";
import { BlHeading } from "../layout/Heading";
import { BlBreadcrumbs, useUpdateBreadcrumbs } from "../layout/Breadcrumbs";

export const CourseDashboard = React.memo(() =>  {
    let { course_id } = useParams();

    useUpdateBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Courses", path: "/class/courses/" },
        { label: "Course Details" }
    ])

    const { showErrorToast, showSuccessToast } = useToaster()
    const [course, set_course] = useState<Course>()
    const [semesters, set_semesters] = useState<Lookup[]>([]);
    const [students, set_students] = useState<Student[]>();
    const [teachers, set_teachers] = useState<Teacher[]>([]);
    const [table_teachers, set_table_teachers] = useState<TableTeacher[]>();
    const [teachers_lookup, set_teachers_lookup] = useState<Lookup[]>([]);
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm<CourseUpdate>({
        resolver: yupResolver(updateCourseSchema),
    });

    const [reportLayout, setReportLayout] = useState<ReportLayout>();

    const [loading, set_loading] = useState<boolean>(false);
    const [archive_loading, set_archive_loading] = useState<boolean>(false);

    const [isOpen_add_teachers, setIsOpen_add_teachers] = useState(false)

    const [isOpen_add_students, setIsOpen_add_students] = useState(false)

    const [attendance_start_date, set_attendance_start_date] = useState<Date>(new Date(Date.now()))
    const [attendance_end_date, set_attendance_end_date] = useState<Date>(new Date(Date.now()))

    const user = LocalStorageService.getInstance().getUser();
    const canModifyCourse = user?.has_role(UserRole.Can_Modify_Course);

    const [isOpen_course, setIsOpen_course] = useState(false)
    const onClose_course = () => setIsOpen_course(false)

    const fetchCourseAndReportLayout = () => {
        CourseAPI.get_course(to_oid(course_id!)).then((res) => {

            set_course(res)

            let course_update: CourseUpdate = {
                _id: proto_utils.from_proto_object_id(res.id!),
                name: res.name,
                semester_id: proto_utils.from_proto_object_id(res.semester!.id!),
                course_code: res.courseCode
            }
            reset(course_update)

            if (res?.teacherIds?.length) {
                TeacherAPI.teachers_by_ids(proto_utils.from_proto_object_id_arr(res.teacherIds)).then(res => {
                    set_teachers(res)
                })
            } else set_teachers([])

            if (res?.studentIds?.length) {
                StudentAPI.students_by_ids(proto_utils.from_proto_object_id_arr(res.studentIds)).then(res => {
                    set_students(res)
                })
            } else set_students([])

        })

        CourseAPI.get_report_layout(to_oid(course_id!)).then((res) => {
            console.log('res: ', res);
            setReportLayout(res)
        })

    }

    const onClose_add_teachers = () => {
        setIsOpen_add_teachers(false)

        fetchCourseAndReportLayout()
    }
    const onClose_add_students = () => {
        setIsOpen_add_students(false)

        fetchCourseAndReportLayout()
    }

    useEffect(() => {
        fetchCourseAndReportLayout()
    }, []);

    useEffect(()=>{
        SemesterAPI.semester_list(null, undefined, "", null,null, false)
            .then((res) => {
                let data = res!.semesters.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.name
                    }
                })
                set_semesters(data)
            })

        TeacherAPI.teachers_list(null, null, null)
            .then((res) => {
                let data = res!.teachers.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.first_name + " " + e.last_name
                    }
                })
                set_teachers_lookup(data)
            })
    }, [])

    useEffect(() => {
        set_table_teachers(teachers.map((teacher) => {
            return {
                _id: teacher._id, 
                profile: {
                    first_name: teacher.first_name,
                    last_name: teacher.last_name, 
                    email: teacher.email,
                }
            } as TableTeacher;
        }));
    }, [teachers]);

    const download_attendance_csv = () => {
        AttendanceAPI.course_attendance_csv_data(course!, attendance_start_date, attendance_end_date)
            .catch((err) => {
    			showErrorToast('Failed to download attendance csv')
            })
    }

    const update_course = async (data: CourseUpdate) => {
        set_loading(true)
        try {
            const updatedCourse = await CourseAPI.update(
                data._id,
                data.semester_id,
                data.name,
                data.course_code
            );

            set_course(updatedCourse);

            showSuccessToast('Course updated successfully');
        } catch (error) {
            showErrorToast('Failed to download attendance csv')
        } finally {
            set_loading(false)
        }
    }

    const archive = () => {
        set_archive_loading(true)
        CourseAPI.archive_course(proto_utils.from_proto_object_id(course!.id!))
        .then(() => {
            showSuccessToast('Course Archived');
        })
        .catch(() => {
            showErrorToast('Failed to Archive Course')
        })
    }

    const removeTeacher = async (teacherId: ObjectId) => {
        try {
            await CourseAPI.remove_teachers(to_oid(course_id!), [teacherId]);
        
            showSuccessToast('Teacher removed successfully');

            fetchCourseAndReportLayout()
        } catch (error) {
            showErrorToast('Failed to remove Teacher')
        }
    };

    const removeStudent = async (studentId: ObjectId) => {
        try {
            await CourseAPI.remove_students(to_oid(course_id!), [studentId]);
        
            showSuccessToast('Student removed successfully');

            fetchCourseAndReportLayout()
        } catch (error) {
            showErrorToast('Failed to remove Student')
        }
    };

    return (
        <>
        <HStack align={"center"} mb={8}>
            <BlHeading mb="0"> {course?.name} Course </BlHeading>

            <Spacer/>

            <ExternalLinks type="course" id={course_id!} />
        </HStack>

        <BlBreadcrumbs />

        <Tabs variant='line'>
            <TabList>
                <Tab>General</Tab>

                <Tab>Academic Tracker</Tab>
            </TabList>

            <TabPanels>
                <TabPanel paddingX={0}>

                    <Stack gap="4">
                        <BlCard
                            as="form"
                            onSubmit={handleSubmit(update_course)}
                        >
                                <HStack>
                                    <FormLabel fontSize={"lg"} fontWeight="bold">
                                        Course Information
                                    </FormLabel>

                                    <Spacer />

                                    { canModifyCourse && (
                                            <Button
                                                isLoading={loading}
                                                colorScheme='green'
                                                leftIcon={<CheckIcon/>}
                                                type="submit"
                                            >
                                                Update
                                            </Button>
                                        )
                                    }
                                </HStack>

                                <SimpleGrid columns={{sm: 1, md: 4}} spacing={10}>
                                    <FormInput
                                        name="name"
                                        label="Name"
                                        register={register('name', { required: true })}
                                        error={errors.name?.message}
                                        isDisabled={!canModifyCourse}
                                        isRequired
                                    />
                                    <FormSelectOptions
                                        name="semester_id"
                                        label="Semester"
                                        options={semesters}
                                        control={control}
                                        error={errors.semester_id?.message}
                                        isDisabled={!canModifyCourse}
                                        isRequired
                                    />
                                    <FormInput
                                        name="course_code"
                                        label="Course Code"
                                        register={register('course_code', { required: true })}
                                        error={errors.course_code?.message}
                                        isDisabled={!canModifyCourse}
                                        isRequired
                                    />
                                </SimpleGrid>
                        </BlCard>

                        <BlCard>
                            <HStack>
                                <FormLabel fontSize={"lg"} fontWeight="bold">
                                    Teachers
                                </FormLabel>

                                <Spacer/>

                                <LightButton
                                    color="green"
                                    leftIcon={<FiPlus />} 
                                    onClick={() => setIsOpen_add_teachers(true)}
                                >
                                    Add Teacher
                                </LightButton>

                                { course && 
                                    <AddTeachersDialog
                                        course={proto_utils.from_proto_object_id(course.id!)}
                                        isOpen={isOpen_add_teachers} 
                                        onClose={onClose_add_teachers}
                                    />
                                }
                            </HStack>

                            <SimpleTeacherList teachers={table_teachers} onDelete={removeTeacher} />
                        </BlCard>

                        <BlCard>
                            <HStack>
                                <FormLabel fontSize={"lg"} fontWeight="bold">
                                    Students
                                </FormLabel>

                                <Spacer/>

                                <LightButton
                                    color="green"
                                    leftIcon={<FiPlus />} 
                                    onClick={() => setIsOpen_add_students(true)}
                                >
                                    Add Students
                                </LightButton>

                                { course && 
                                    <AddStudentsDialog
                                        course={proto_utils.from_proto_object_id(course.id!)}
                                        isOpen={isOpen_add_students} 
                                        onClose={onClose_add_students}
                                    />
                                }
                            </HStack>

                            <SimpleStudentsList students={students} onDelete={removeStudent} />
                        </BlCard>

                      <BlCard>
                            <HStack>
                                <Text fontSize={"lg"} fontWeight="bold">
                                    Attendance Export
                                </Text>

                                <Spacer/>

                                <LightButton
                                    color="blue"
                                    onClick={download_attendance_csv}
                                >
                                    Export CSV
                                </LightButton>
                            </HStack>

                            <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                                <FormControl id="start_date" isRequired>
                                    <FormLabel>Start Date</FormLabel>
                                    <DatePicker
                                        selectedDate={attendance_start_date}
                                        onChange={(value) => {
                                            set_attendance_start_date(value as Date)
                                        }}
                                    />
                                </FormControl>
                                <FormControl id="end_date" isRequired>
                                    <FormLabel>End Date</FormLabel>
                                    <DatePicker
                                        selectedDate={attendance_end_date}
                                        onChange={(value) => {
                                            set_attendance_end_date(value as Date)
                                        }}
                                    />
                                </FormControl>
                            </Stack>
                        </BlCard>

                        <ReportLayoutForm
                            reportLayout={reportLayout}
                            semesterReportLayout={course?.semester?.reportLayout}
                            onSubmitSuccess={(updatedLayout) =>setReportLayout(updatedLayout)}
                        />

                        <HStack>
                            <Spacer />
                            {course?.homeroom === null && (
                                <Button
                                    bgColor={"blue.100"}
                                    textColor="blue.700"
                                    leftIcon={<FiCopy />}
                                    onClick={() => setIsOpen_course(true)}
                                >
                                    Clone Course
                                </Button>
                            )}
                            {course && (
                                <CreateCourseDialog
                                    isOpen={isOpen_course}
                                    onClose={onClose_course}
                                    teachers={teachers_lookup}
                                    semesters={semesters}
                                />
                            )}

                            <Button
                                bgColor={"orange.100"}
                                textColor="orange.700"
                                colorScheme='yellow'
                                leftIcon={<FiArchive/>}
                                onClick={archive}
                                isDisabled={course?.archived || archive_loading}
                            >
                                Archive
                            </Button>
                        </HStack>
                    </Stack>

                </TabPanel>

                <TabPanel paddingX={0}>
                    <BlCard>
                        { course && <GClassItemViewer type="course" item={course!} /> }
                    </BlCard>
                </TabPanel>
            </TabPanels>
        </Tabs>
        </>
    );
})
