import { useState, useEffect } from "react";

const useVersionChecker = () => {
    const [currentVersion, setCurrentVersion] = useState<string | null>(null);
    const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

    useEffect(() => {
        const checkVersion = async () => {
            try {
                const response = await fetch("/version.json", { cache: "no-cache" });
                if (!response.ok) return;

                const data = await response.json();
                const latestVersion = data.version;

                if (currentVersion && currentVersion !== latestVersion) {
                    setIsNewVersionAvailable(true);
                } else {
                    setCurrentVersion(latestVersion);
                }
            } catch (error) {
                console.error("Error fetching version.json", error);
            }
        };

        // Initial check
        checkVersion();

        // Set up interval to check every 60 seconds
        const intervalId = setInterval(checkVersion, 60000);

        return () => clearInterval(intervalId);
    }, [currentVersion]);

    return { isNewVersionAvailable };
};

export default useVersionChecker;