import React, { useState } from "react";
import {
    Badge,
    Box,
    Flex,
    List,
    ListItem,
    Text,
    useColorMode,
} from "@chakra-ui/react";
import { ProfileSection } from "protobuffer-ts/dist/organization_service/organization_profile_settings";
import { ProfileSectionCustomFieldsGroups } from "../custom_fields/ProfileSectionCustomFieldsGroups";
import { UserType } from "../../api/UserApi";
import { Student } from "../../models/student";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { ApprovalStatus, ApprovalStatusColors, getApprovalStatusIcon, GroupStatus } from "../../models/custom_fields_group";
import { ObjectId } from "../../utils/ObjectId";
import { toCapitalized } from "../../utils/helpers";
import { ResponsiveSidebarWrapper } from "./ResponsiveSidebarWrapper";

interface CustomFieldsSectionProps {
    student: Student;
    profileSections: ProfileSection[];
}

export const StudentCustomFieldsSection: React.FC<CustomFieldsSectionProps> = ({ student, profileSections }) => {
    const [selectedSection, setSelectedSection] = useState<ProfileSection>(profileSections[0]);
    const [sectionGroupStatuses, setSectionGroupStatuses] = useState<Map<ProfileSection, GroupStatus[]>>(new Map());
    const { colorMode } = useColorMode();
    const isLight = colorMode === "light";

    const activeBg = isLight ? "blue.50" : "blue.900";
    const activeColor = isLight ? "blue.600" : "blue.300";
    const hoverBg = isLight ? "blue.50" : "blue.700";
    const hoverColor = isLight ? "blue.600" : "blue.300";

    const handleGroupStatusUpdate = (section: ProfileSection, groupId: ObjectId, status: ApprovalStatus) => {
        setSectionGroupStatuses(prev => {
            const newStatuses = new Map(prev);
            const sectionStatuses = newStatuses.get(section) || [];
            const groupIndex = sectionStatuses.findIndex(s => s.groupId.$oid === groupId.$oid);

            if (groupIndex >= 0) {
                sectionStatuses[groupIndex] = { groupId, status };
            } else {
                sectionStatuses.push({ groupId, status });
            }

            newStatuses.set(section, sectionStatuses);
            return newStatuses;
        });
    };

    const renderStatusIndicators = (section: ProfileSection) => {
        const statuses = sectionGroupStatuses.get(section) || [];
        return (
            <Flex ml={2} gap={1}>
                {statuses.map(({ status }, index) => (
                    <Badge
                        key={index}
                        colorScheme={ApprovalStatusColors[status] || 'gray'}
                        variant="subtle"
                        borderRadius="full"
                        w="4"
                        h="4"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontSize="xs"
                    >
                        {getApprovalStatusIcon(status)}
                    </Badge>
                ))}
            </Flex>
        );
    };

    const sidebarList = (onClose: () => void) => (
        <List spacing={2}>
            {profileSections.map((section) => (
                <ListItem
                    key={section}
                    cursor="pointer"
                    p={2}
                    borderRadius="md"
                    fontWeight="semibold"
                    bg={selectedSection === section ? activeBg : "transparent"}
                    color={selectedSection === section ? activeColor : "inherit"}
                    _hover={{ bg: hoverBg, color: hoverColor }}
                    onClick={() =>  {
                        setSelectedSection(section)

                        onClose()
                    }}
                >
                    <Flex align="center" justify="space-between">
                        <Flex align="center">
                            <Text as="span">{toCapitalized(section.toString())}</Text>
                            {renderStatusIndicators(section)}
                        </Flex>
                        <ChevronRightIcon />
                    </Flex>
                </ListItem>
            ))}
        </List>
    );

    const allGroups = (
        <>
            {profileSections.map((section) => (
                <Box key={section} display={selectedSection === section ? "block" : "none"}>
                    <ProfileSectionCustomFieldsGroups
                        userId={student._id}
                        userType={UserType.Student}
                        profileSection={section}
                        onGroupStatusUpdate={(groupId, status) => handleGroupStatusUpdate(section, groupId, status)}
                    />
                </Box>
            ))}
        </>
    );

    return (
        <ResponsiveSidebarWrapper sidebar={sidebarList}>
            {allGroups}
        </ResponsiveSidebarWrapper>
    );
};

export default StudentCustomFieldsSection;
