import { BlCard } from "../layout/Card";
import { Heading, Stack, FormControl, FormLabel, Button } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FormSwitch from "../forms/FormSwitch";
import { OnboardingSettingsAPI } from "../../api/OnboardingSettingsAPI";
import { useToaster } from "../../hooks/useToaster";
import { EnrollmentConfigData } from "../../models/onboarding_settings";


interface EnrollmentConfigSectionProps {
    initialData: EnrollmentConfigData;
}
export const EnrollmentConfigSection = ({ initialData }: EnrollmentConfigSectionProps) => {
    const { showErrorToast, showSuccessToast } = useToaster();
    const { control, handleSubmit } = useForm({
        defaultValues: initialData,
    });

    const onSubmit = async (data: EnrollmentConfigData) => {
        try {
            await OnboardingSettingsAPI.update_enrollment_configuration(data);
            showSuccessToast("Enrollment settings updated");
        } catch (e: any){
            showErrorToast(e.response.data, "Failed to Update.")
        }
    };

    return (
        <BlCard>
            <Heading size="md" mb={6}>
                Enrollment Settings
            </Heading>
            <Stack spacing={4}>
                <FormControl display="flex" alignItems="center">
                    <FormLabel mb="0" flex="1" fontSize="sm" fontWeight="semibold">
                        Require Interview
                    </FormLabel>
                    <FormSwitch
                        name="has_interview_for_newcomers"
                        control={control}
                    />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                    <FormLabel mb="0" flex="1" fontSize="sm" fontWeight="semibold">
                        Group Approval System
                    </FormLabel>
                    <FormSwitch
                        name="enable_group_approval_system"
                        control={control}
                    />
                </FormControl>
                <Button onClick={handleSubmit(onSubmit)} colorScheme="blue" alignSelf="flex-end">
                    Save Settings
                </Button>
            </Stack>
        </BlCard>
    );
};