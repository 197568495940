import { ObjectID } from "bson";
import { ObjectId as ProtoObjectId } from "protobuffer-ts/dist/utils/object_id";

export interface ObjectId {
    $oid: string;
}

export const to_oid = (id: string | ProtoObjectId): ObjectId => {
    if (typeof id === "string") {
        return { $oid: id };
    }

    return { $oid: id.id };
};

export const to_proto_oid = (id: string | ObjectId): ProtoObjectId => {
    if (typeof id === "string") {
        return {
            id: id
        }
    }

    return { id: id.$oid };
};

export const new_oid = (): ObjectId => {
    let id  = new ObjectID();
    return {
        $oid: id.toString()
    }
}

export const new_oid_proto = (): ProtoObjectId => {
    let id  = new ObjectID();
    return {
        id: id.toString()
    }
}

