import React from "react"
import { useState } from "react";
import {
    Box,
    Stack,
    Skeleton,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    Center,
    Textarea,
    VStack,
    Checkbox,
    CheckboxGroup,
    Menu,
    MenuButton,
    Portal,
    MenuList,
    MenuItem,
    HStack,
    Text,
    MenuDivider,
} from '@chakra-ui/react';
import FocusLock from 'react-focus-lock';
import { FiPlus, FiCheck, FiSlash } from "react-icons/fi";
import { TableContent } from "../table/TableContent";
import 'react-datepicker/dist/react-datepicker.css';
import { Student } from "../../models/student";
import { Violation, ViolationReason, ViolationStatus } from "../../models/violation";
import { ViolationsAPI } from "../../api/ViolationsAPI";
import {BrowserView, MobileView} from 'react-device-detect';
import { useToaster } from "../../hooks/useToaster";

interface TableData {
    student: Student,
    violation_entry: Violation,
    name: string,
    first_name: string,
    last_name: string
}

interface ViolationsProps {
    entry_data: Array<TableData> | undefined,
}

export const ViolationsClass = React.memo(({entry_data}: ViolationsProps) =>  {
    return (
        <Box>
            {entry_data ?
                    <TableContent data={entry_data} columns={ViolationColumns}/>
                :
                    <Stack>
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                    </Stack>
            }
        </Box>
    );
} );

const ViolationColumns = [
    {
        Header: "Name",
        accessor: "name",
        Cell: function StatusCell(name: string) {
            return(
                <>
                    <BrowserView>
                        <Text>{name}</Text>
                    </BrowserView>
                    <MobileView>
                        <Text fontSize='xs'>{name}</Text>
                    </MobileView>
                    
                </>
            )
        }

    },
    {
        Header: "Violation",
        accessor: "violation_entry",
        Cell: function StatusCell(violation: Violation, table_data: TableData) {
            const [status, set_status] = useState<Array<ViolationStatus>>(violation.status);
            const [reasons, set_reasons] = useState<ViolationReason>(violation.reasons);
            const { showErrorToast } = useToaster()
            const set_entry_status = (data: Array<ViolationStatus>, reason: ViolationReason) => {
                let intersection = data.filter(x => !status.includes(x))
                let element = intersection[0]
                if (element == ViolationStatus.Detention){
                    if(!data.includes(ViolationStatus.Warning)){
                        showErrorToast("Failed you select warning before")
                        return
                    }
                }
                else if (element == ViolationStatus.LetterHome){
                    if(!data.includes(ViolationStatus.Detention)){
                        showErrorToast("Failed you select detention before")
                        return
                    }
                }
                
                ViolationsAPI.update_entry_status(violation._id, data, reason).then((res) => {
                    set_status(data)
                    set_reasons(reason)
                    console.log(status)
                }).catch((e) => {
                    console.log("ERROR")
                    console.log(e)
                    set_status(status)
                    set_reasons(reasons)
                    showErrorToast("Failed to update " + table_data.first_name + "'s violation")
                })
            }
            const violation_reasons = [
                "Not Following Instructions",
                "Speak Out of Turn",
                "Classroom Disrespect",
                "Disturbed Class",
                "Teacher Disrespect",
                "Student Disrespect",
                "Invade Others' Space",
                "Damaged Property",
                "Other"
            ]

            const violation_check = (violation_status: ViolationStatus, str: string, color: string) => {
                const on_reason_click = (res: any) => {
                    let reason_str = res as any
                    let reason = {...reasons}
                    reason[violation_status] = reason_str.target.value
                    let violations = status.filter(item => item !== violation_status)
                    violations.push(violation_status)

                    set_entry_status(violations, reason)
                }

                const reasons_selected = (reason: string, r_stat: ViolationReason): boolean => {
                    let e = r_stat[violation_status]
                    return e == reason
                }
        
                return (
                    <HStack spacing={2}>
                        
                        <Menu>
                            <MenuButton>
                                <Checkbox colorScheme={color} value={violation_status}>{str}</Checkbox>
                            </MenuButton>
                            <Portal>
                                <MenuList>
                                    {
                                        violation_reasons.map((e) => {
                                            return(
                                                <>
                                                { reasons_selected(e, reasons) ?
                                                        <MenuItem 
                                                        value={e}
                                                        onClick={on_reason_click}
                                                        icon={<FiCheck/>}
                                                        >
                                                            {e}
                                                        </MenuItem>
                                                    :
                                                        <MenuItem 
                                                        value={e}
                                                        onClick={on_reason_click}
                                                        >
                                                            {e}
                                                        </MenuItem>
                                                }
                                                </>
                                                
                                            )
                                        })
                                    }
                                    <MenuDivider />
                                    <MenuItem
                                        icon={<FiSlash/>}
                                        onClick={(res) => {
                                            let violations = status.filter(item => item !== violation_status)
                                            let reason = {...reasons}
                                            reason[violation_status] = ""
                                            set_entry_status(violations, reason)
                                        }}
                                    >
                                        Remove
                                    </MenuItem>
                                </MenuList>
                            </Portal>
                        </Menu>
                    </HStack>
                )
            }

            return (
                <Stack spacing={5} direction='row'>
                    <CheckboxGroup colorScheme='green' value={status} onChange={(res) => set_entry_status(res as any, reasons)}>
                        <Stack spacing={[1, 5]} direction={['column', 'row']}>
                            {violation_check(ViolationStatus.Warning, "Warning", 'purple')}
                            {violation_check(ViolationStatus.Detention, "Detention", 'yellow')}
                            {violation_check(ViolationStatus.LetterHome, "Letter Home", 'orange')}
                            <Checkbox colorScheme='red' value={ViolationStatus.EscalatedToOffice}>Escalated to Office</Checkbox>
                        </Stack>
                    </CheckboxGroup>
                </Stack>
              
            )
        },
    
    },
    {
        Header: "Notes",
        accessor: "notes",   
        Cell: function ReasonsCell(_: any, table_data: TableData) {
            const { showErrorToast } = useToaster()
            let [notes, set_notes] = useState<string>(table_data.violation_entry.notes)
            
            const notes_saved = () => {
                ViolationsAPI.update_notes(table_data.violation_entry._id, notes)
                .then((res) => {})
                .catch((e) => {
                    showErrorToast("Failed to update " + table_data.first_name + "'s notes")
                })
            }
            return (
                <Box w='75%'>
                    <Popover placement='right' closeOnBlur={true}>
                        {({ isOpen, onClose }) => (
                            <>
                                <PopoverTrigger>
                                    <Button leftIcon={<FiPlus />} colorScheme='teal' variant='outline'>
                                        Notes
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent p={5}>
                                <FocusLock returnFocus persistentFocus={false}>
                                    <Center>
                                        <PopoverArrow />
                                        <PopoverCloseButton />
                                        <VStack spacing={4}>
                                            <Textarea placeholder='Reason' value={notes} onChange={(text) => set_notes(text.target.value)}/>
                                            <Button leftIcon={<FiCheck />} colorScheme='green' variant='solid' onClick={() => {notes_saved(); onClose()}}>Save</Button>
                                        </VStack>
                                    </Center>
                                </FocusLock>
                                </PopoverContent>
                            </>
                        )}
                    </Popover>
                </Box>
            )
        },     
    },
]
