import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, usePrevious } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useLocation, Link } from "react-router-dom";
import { createContext, useContext, useEffect, useRef, useState } from "react";

// Breadcrumb Context
const BreadcrumbContext = createContext({
	breadcrumbs: [] as { label: string; path?: string }[],
	setBreadcrumbs: (_: { label: string; path?: string }[]) => { },
});

export const BreadcrumbProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [breadcrumbs, setBreadcrumbs] = useState<{ label: string; path?: string }[]>([]);
	return (
		<BreadcrumbContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
			{children}
		</BreadcrumbContext.Provider>
	);
};

export const useBreadcrumbs = () => useContext(BreadcrumbContext);

export const BlBreadcrumbs: React.FC = () => {
	const { breadcrumbs } = useBreadcrumbs();
	return (
		<Breadcrumb separator={<ChevronRightIcon color="gray.500" />} mb="4">
			{breadcrumbs.map(({ label, path }, index) => (
				<BreadcrumbItem key={label} isCurrentPage={index === breadcrumbs.length - 1}>
					<BreadcrumbLink
						as={Link}
						to={path || "#"}
						fontWeight="semibold"
						color={index === breadcrumbs.length - 1 ? "blue.600" : "gray.600"}
						textTransform="capitalize"
						_hover={index === breadcrumbs.length - 1 ? {} : { color: "blue.600", textDecoration: "none" }}
					>
						{label}
					</BreadcrumbLink>
				</BreadcrumbItem>
			))}
		</Breadcrumb>
	);
};

export const useUpdateBreadcrumbs = (customBreadcrumbs?: { label: string; path?: string }[]) => {
	const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();
	const location = useLocation();

	const prevBreadcrumbs = usePrevious(customBreadcrumbs);

	useEffect(() => {
		const pathnames = location.pathname.split("/").filter((x) => x);
		const breadcrumbs = pathnames.map((segment, index) => {
			const path = `/${pathnames.slice(0, index + 1).join("/")}`;
			return { label: segment.replace(/-/g, " "), path };
		});
		setBreadcrumbs([{ label: "Home", path: "/" }, ...breadcrumbs]);
	}, [location.pathname]);

	useEffect(() => {
		//! Somehow, useEffect is triggering changes between old custom breadcrumbs and new ones, even though there's no change
		const prev = prevBreadcrumbs && JSON.stringify(breadcrumbs)
		const current = customBreadcrumbs && JSON.stringify(customBreadcrumbs)

		if (prev === current) return

		if (customBreadcrumbs) {
			setBreadcrumbs(customBreadcrumbs);
		}
	}, [customBreadcrumbs]);

};