import {
    Button,
    Stack,
    AlertDialogFooter,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialog,
    FormControl,
    FormLabel,
    Input,
} from '@chakra-ui/react';
import { memo, useRef, useState } from "react";
import { ObjectId } from "../../utils/ObjectId";
import { ReportAPI } from '../../api/ReportAPI';
import { ReportEntry } from '../../models/report_entry';
import { useToaster } from "../../hooks/useToaster";

interface UnpublishDialogProps {
    isOpen: boolean
    onClose: (data? : ReportEntry) => void
    report_id: ObjectId
}

export const UnpublishDialog = memo(({isOpen, onClose, report_id}: UnpublishDialogProps) => {
    const { showErrorToast } = useToaster()
    const [loading, set_loading] = useState<boolean>(false);

    const [requested_changes, set_requested_changes] = useState<string>();
    const cancelRef = useRef<HTMLButtonElement>(null);

    const create = async () => {
        // check if there is homeroom, assign its semester_id
        if(requested_changes) {
            set_loading(true)

            const data = { report_id, requested_changes }

            ReportAPI.unpublish(data).then((res) => {
                set_loading(false)
                onClose(res)
            })
        }else{
			showErrorToast("Please fill in all the required information")
        }
    }

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Unpublish
                    </AlertDialogHeader>

                    <AlertDialogBody>
						<Stack gap="4">
							<FormControl id="name" isRequired>
								<FormLabel>Requested Changes</FormLabel>

								<Input 
									isRequired
									value={requested_changes}
									onChange={(e) => set_requested_changes(e.target.value)}
								/>
							</FormControl>
						</Stack>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={() => onClose()}>
                            Cancel
                        </Button>

                        <Button colorScheme='green' ml={3} isLoading={loading} onClick={create}>
                            Save Changes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
})