import {
    ReportDates,
    ReportType,
    reportTypeFromJSON,
    reportTypeToNumber,
    Semester,
    SemesterReportLayout
} from "protobuffer-ts/dist/class_service/semester";
import { ReportLayoutSection } from "protobuffer-ts/dist/class_service/report_layout";
import { ObjectId } from "../utils/ObjectId"

// Remove strings and unrecognized value
export const reportTypeValues = Object.values(ReportType)
    .map((e) => reportTypeToNumber(e))
  .filter((type) => typeof type === 'number' && type !== -1);

export const reportTypeOptions = (semester: Semester | undefined) => {
    if (semester?.reportLayout?.includeProgressReportCards) {
        return reportTypeValues.map((type) => ({
            value: type,
            label: reportTypeFromJSON(type),
        }));    
    } else {
        return reportTypeValues.map((type) => ({
            value: type,
            label: reportTypeFromJSON(type),
        })).filter(e => e.label !== ReportType.Progress)
    }
}

export enum ReviewStatus {
    NotFilled = "NotFilled",
    Filled = "Filled",
    ChangesRequested = "ChangesRequested",
    Approved = "Approved",
    Published = "Published"
}

export const reviewStatusOptions = Object.values(ReviewStatus).map((status) => ({
    value: status,
    label: status,
}));

export interface CheckBox {
    check_box_id: ObjectId,
    state: boolean,
}

interface Section {
    layout_id: ObjectId,
    mark: string,
    median_mark?: string
}

interface LeaningSkill { 
    learning_skill_id: ObjectId,
    mark: string,
}

export class ReportEntry {
    _id: ObjectId
    organization: ObjectId
    student: ObjectId
    course?: ObjectId | null
    homeroom?: ObjectId | null

    report_type: ReportType
    review_status: ReviewStatus
    comment?: string | null
    requested_changes: string
    
    checkboxes: CheckBox[]
    sections: Section[]
    learning_skills: LeaningSkill[]

    class_name: string
    semester: Semester
    semester_report_layout: SemesterReportLayout

    credit_earned: boolean
    signed_by_guardian_name?: string | null

    constructor(data : any) {
        this._id = data._id
        this.organization = data.organization
        this.student = data.student
        this.course = data.course
        this.homeroom = data.homeroom
    
        this.report_type = reportTypeFromJSON(data.report_type)
        this.review_status = data.review_status
        this.comment = data.comment
        this.requested_changes = data.requested_changes
        
        this.checkboxes = data.checkboxes
        this.sections = data.sections
        this.learning_skills = data.learning_skills

        this.class_name = data.class_name
        this.semester = data.semester
        this.semester_report_layout = SemesterReportLayout.fromJSON(data.semester_report_layout)

        this.credit_earned = data.credit_earned || false
        this.signed_by_guardian_name = data.signed_by_guardian_name
    }
}

export interface SectionMedian {
    section_layout_id: ObjectId;
    median_mark: string | null;
}

export class ReportMedian {
    report_entry: ObjectId;
    section_medians: SectionMedian[];

    constructor(data: any) {
        this.report_entry = data.report_entry;
        this.section_medians = data.section_medians.map(
            (median: any) => ({
                section_layout_id: median.section_layout_id,
                median_mark: median.median_mark ?? null,
            })
        );
    }
}

export type ReportEntryUpdate = Omit<ReportEntry, 'class_name' | 'semester' | 'semester_report_layout'>;

export interface RequestChangesReportEntry {
    report_id: ObjectId,
    requested_changes: String,
}

export interface UnpublishReportEntry {
    report_id: ObjectId,
    requested_changes: String,
}

export const ReportTypeColor: Record<ReportType, string> = {
    [ReportType.Progress]: 'blue',
    [ReportType.Midterm]: 'green',
    [ReportType.Final]: 'purple',
    [ReportType.UNRECOGNIZED]: 'gray'
};


export const ReviewStatusColor: Record<ReviewStatus, string> = {
    [ReviewStatus.NotFilled]: 'gray',
    [ReviewStatus.Filled]: 'blue',
    [ReviewStatus.ChangesRequested]: 'orange',
    [ReviewStatus.Approved]: 'green',
    [ReviewStatus.Published]: 'purple'
};


export const ElementaryMarksOptions = [
    "Progressing with Difficulty",
    "Progressing Well",
    "Progressing Very Well",
    "N/A",
]


export const getNextReportType = (reportDates: ReportDates[]): ReportType => {
    const currentDate = new Date();
    const sortedDates = reportDates
        .filter(date => date.distributionDate)
        .sort((a, b) => (a.distributionDate! > b.distributionDate! ? 1 : -1));

    for (const date of sortedDates) {
        if (date.distributionDate && date.distributionDate >= currentDate) {
            return date.reportType;
        }
    }

    return ReportType.Final;
};

// get grade for a specific report type and skill
export const getGradeForReport = (
    skillTitle: string,
    report?: ReportEntry,
    learningSkillsMap: { [key: string]: string } = {}
) => {
    if (!report || !Array.isArray(report.learning_skills)) {
        return ' ';
    }

    const skillEntry = report.learning_skills.find(
        (skill) => learningSkillsMap[skill.learning_skill_id?.$oid] === skillTitle
    );

    return skillEntry?.mark?.[0] || ' ';
};

export const getReportsByType = (reports : ReportEntry[]) => reports.reduce((acc, report) => {
    acc[report.report_type] = report;
    return acc;
}, {} as Record<ReportType, ReportEntry>);

export const getReportMark = (report: ReportEntry, section?: ReportLayoutSection) => {
    if (!section) return " ";

    if (!report || !Array.isArray(report.sections)) return " ";

    const sectionEntry = report.sections.find(
        (entry) => entry.layout_id?.$oid === section.id?.id
    );

    return sectionEntry?.mark || " ";
};

export const getReportMedianMark = (report: ReportEntry, section?: ReportLayoutSection) => {
    if (!section) return " ";

    if (!report || !Array.isArray(report.sections)) return " ";

    const sectionEntry = report.sections.find(
        (entry) => entry.layout_id?.$oid === section.id?.id
    );

    return sectionEntry?.median_mark || " ";
};