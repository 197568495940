import React, { useEffect, useMemo, useState } from "react";
import {
	Stack,
	Box,
	FormLabel,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Alert,
	AlertIcon,
	Text,
} from "@chakra-ui/react";
import { ReportEntry, reportTypeValues } from "../../models/report_entry";
import { ReportAPI } from "../../api/ReportAPI";
import { StudentReportEntryView } from "../reports/StudentReportEntryView";
import { Student } from "../../models/student";
import { useToaster } from "../../hooks/useToaster";
import { Select } from "chakra-react-select";
import EmptyPlaceholder from "../layout/EmptyPlaceholder";
import {
	ReportType,
	reportTypeFromJSON,
	Semester,
} from "protobuffer-ts/dist/class_service/semester";
import SkeletonTable from "../table/TableSkeleton";
import { FlexWrap } from "../layout/FlexWrap";
import { StudentAPI } from "../../api/StudentAPI";

interface Props {
	student: Student;
}

export const StudentPublishedReports = React.memo(({ student }: Props) => {
	const { showErrorToast } = useToaster();

	const [semesters, set_semesters] = useState<Semester[]>([]);
	const [report_entries, set_report_entries] = useState<ReportEntry[]>([]);
	const [loading, set_loading] = useState<boolean>(true);

	const [selectedReportType, setSelectedReportType] = useState<ReportType>();
	const [selectedSemester, setSelectedSemester] = useState<Semester>();

	// States for modal and signing behavior
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [ignoredModal, setIgnoredModal] = useState<boolean>(false);
	const [isSigning, setIsSigning] = useState<boolean>(false);

	const reportTypes = reportTypeValues.map((type) => ({
		value: type,
		label: reportTypeFromJSON(type),
	}));

	const availableReportTypes = useMemo(() => {
		const semesterEntries = selectedSemester
			? report_entries.filter(
				(entry) => entry.semester.id?.id === selectedSemester.id?.id
			)
			: report_entries;

		let _available = reportTypes.filter((option) =>
			semesterEntries.some((entry) => entry.report_type === option.label)
		);

		if (_available.length) {
			setSelectedReportType(_available[_available.length - 1].label);
		}

		return _available;
	}, [selectedSemester, report_entries]);

	const filteredEntries = useMemo(() => {
		let entries = report_entries;
		if (selectedSemester) {
			entries = entries.filter(
				(entry) => entry.semester.id?.id === selectedSemester.id?.id
			);
		}
		if (selectedReportType) {
			entries = entries.filter(
				(entry) => entry.report_type === selectedReportType
			);
		}
		return entries;
	}, [selectedSemester, selectedReportType, report_entries]);

	const unsignedEntries = useMemo(
		() => report_entries.filter((entry) => !entry.signed_by_guardian_name),
		[report_entries]
	);

	const fetchAllData = async () => {
		set_loading(true);

		try {
			const _semesters = await StudentAPI.get_semesters(student._id);
			set_semesters(_semesters);

			const res: ReportEntry[] =
				await ReportAPI.get_student_published_report_entries(student._id.$oid);
			const homeroom_entries = res.filter((e) => e.homeroom != null);
			const course_entries = res.filter((e) => e.course != null);
			const all_reports = [...homeroom_entries, ...course_entries];
			set_report_entries(all_reports);
		} catch (err) {
			showErrorToast("Failed to Retrieve Entries.");
		} finally {
			set_loading(false);
		}
	};

	useEffect(() => {
		if (
			report_entries.length > 0 &&
			report_entries.some((entry) => !entry.signed_by_guardian_name) &&
			!ignoredModal
		) {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [report_entries, ignoredModal]);

	useEffect(() => {
		fetchAllData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSignAll = async () => {
		setIsSigning(true);
		try {
			await Promise.all(
				unsignedEntries.map((entry) =>
					ReportAPI.guardian_sign_report_card({ report_id: entry._id })
				)
			);
			await fetchAllData();
			// After signing, clear the ignored state so all (now signed) cards are shown.
			setIgnoredModal(false);
			setIsModalOpen(false);
		} catch (error) {
			showErrorToast("Failed to sign report cards.");
		} finally {
			setIsSigning(false);
		}
	};

	const entriesToDisplay = useMemo(() => {
		if (ignoredModal) {
			return filteredEntries.filter(
				(entry) => entry.signed_by_guardian_name
			);
		}
		return filteredEntries;
	}, [filteredEntries, ignoredModal]);

	return (
		<>
			<Modal
				isOpen={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
					setIgnoredModal(true);
				}}
				isCentered
				closeOnOverlayClick={false}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader bg="purple.500" color="white">
						Sign Report Cards
					</ModalHeader>
					<ModalCloseButton color="white" />
					<ModalBody>
						<Text mb={4}>
							You have {unsignedEntries.length} new report card
							{unsignedEntries.length > 1 ? "s" : ""} pending your acknowledgment.
						</Text>
						<Text>
							By clicking <strong>Sign Reports</strong>, you confirm that you have
							received these report cards and will be granted full access to view
							all report cards.
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="purple"
							onClick={handleSignAll}
							isLoading={isSigning}
						>
							Sign Reports
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* Header banner when there are unsigned report cards and the user has ignored the modal */}
			{ignoredModal && unsignedEntries.length > 0 && (
				<Alert
					status="warning"
					colorScheme="purple"
					cursor="pointer"
					onClick={() => {
						setIgnoredModal(false);
						setIsModalOpen(true);
					}}
				>
					<AlertIcon />
					You have {unsignedEntries.length} new report card
					{unsignedEntries.length > 1 ? "s" : ""}. Click here to sign them.
				</Alert>
			)}

			{!isModalOpen && (
				loading ? (
					<SkeletonTable />
				) : report_entries.length === 0 ? (
					<EmptyPlaceholder
						title="no report cards"
						message="There are no report cards available."
					/>
				) : (
					<>
						<Stack gap={4} mt={4}>
							<FlexWrap>
								<Box minW={{ md: 300 }}>
									<FormLabel>Semester</FormLabel>
									<Select
										isClearable
										options={semesters}
										placeholder="Select Semester"
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.name}
										onChange={(s) => setSelectedSemester(s || undefined)}
									/>
								</Box>

								<Box minW={{ md: 300 }}>
									<FormLabel>Type</FormLabel>
									<Select
										options={availableReportTypes}
										value={availableReportTypes.find(
											(option) => option.label === selectedReportType
										)}
										onChange={(e) => setSelectedReportType(e!.label)}
										placeholder="Select report type"
									/>
								</Box>
							</FlexWrap>

							<Stack spacing={4}>
								{entriesToDisplay.map((entry) => (
									<StudentReportEntryView
										key={entry._id.$oid}
										report_entry={entry}
									/>
								))}
							</Stack>
						</Stack>
					</>
				)
			)}
		</>
	);
});