import { CreateCustomField, CustomField } from "../models/custom_field";
import { CustomFieldEntry } from "protobuffer-ts/dist/user_service/custom_field_entry";
import { CreateCustomFieldEntry, CreateCustomFieldsGroup, CustomFieldsGroup, GroupApprovalStatus } from "../models/custom_fields_group";
import {ProfileSection} from "protobuffer-ts/dist/organization_service/organization_profile_settings"
import { ObjectId } from "../utils/ObjectId";
import HTTPApi from "./ApiCommon";
import { UserType } from "./UserApi";

export namespace CustomFieldsGroupAPI {

    export const get_custom_fields_groups_for_user_type = async (user_type: UserType): Promise<Array<CustomFieldsGroup>> => {
        const res = await HTTPApi.get("/custom_fields_group/get_custom_fields_groups_for_user_type/" + user_type);
        return res.data;
    }

    export const get_groups_for_user_type_and_profile_section = async (user_type: UserType, profile_section: ProfileSection): Promise<Array<CustomFieldsGroup>> => {
        const res = await HTTPApi.get("/custom_fields_group/get_groups_for_user_type_and_profile_section/" + user_type + "/" + profile_section); 
        return res.data; 
    }

    export const get_custom_fields_for_user_type = async (user_type: UserType) => {
        return HTTPApi.get("/custom_field/get_custom_fields_for_user_type/" + user_type)
            .then((res) => {
                return res.data as Array<CustomField>
            })
    }

    export const get_group_custom_fields = async (group_id: ObjectId) => {
        return HTTPApi.get("/custom_fields_group/get_group_custom_fields/" + group_id.$oid)
            .then((res) => {
                return res.data as Array<CustomField>
            })
    }

    export const get_group_active_custom_fields = async (group_id: ObjectId) => {
        return HTTPApi.get("/custom_fields_group/get_group_active_custom_fields/" + group_id.$oid)
            .then((res) => {
                return res.data as Array<CustomField>
            })
    }

    export const get_group_approval_status = async (group_id: ObjectId, user_id: ObjectId) => {
        return HTTPApi.get("/group_approval_status/group_status/" + group_id.$oid + "/" + user_id.$oid)
            .then((res) => {
                return res.data as GroupApprovalStatus
            })
    }

    export const approve_group = async (group_id: ObjectId, user_id: ObjectId) => {
        return HTTPApi.post("/group_approval_status/approve_group/" + group_id.$oid + "/" + user_id.$oid)
            .then((res) => {
                return res.data as GroupApprovalStatus
            })
    }

    export const reject_group = async (group_id: ObjectId, user_id: ObjectId, rejection_message: string) => {
        return HTTPApi.post("/group_approval_status/reject_group/" + group_id.$oid + "/" + user_id.$oid, {
            rejection_message,
        }).then((res) => {
            return res.data as GroupApprovalStatus
        })
    }

    export const add_custom_field_to_group = async (group_id: ObjectId, body: CreateCustomField) => {
        return HTTPApi.post(
            "/custom_fields_group/add_new_custom_field_to_group/" + group_id.$oid, body
        ).then((res) => {
            return res.data as CustomField
        })
    }

    export const create_new_custom_fields_group = async (body: CreateCustomFieldsGroup) => {
        return HTTPApi.post(
            "/custom_fields_group/create_custom_fields_group", body
        ).then((res) => {
            return res.data as CustomFieldsGroup
        })
    }


    export const assign_entries_to_custom_fields_group = async (userId: ObjectId, groupId: ObjectId, body: CreateCustomFieldEntry[]) => {
        return HTTPApi.post(
            "/custom_fields_entries/update_custom_fields/" + userId.$oid + "/" + groupId.$oid, body
        ).then((res) => {
            return res.data as Array<CustomFieldEntry>
        })
    }
    
    export const update_custom_field = async (fieldId: ObjectId, body: CreateCustomField) => {
        return HTTPApi.post(
            "/custom_fields_group/update_custom_field/" + fieldId.$oid, body
        ).then((res) => {
            return res.data as CustomField
        })
    }
}