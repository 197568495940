import {
	Document,
	Image,
	Page,
	View,
	Text as PdfText,
	Font,
} from "@react-pdf/renderer";
import { ReportLayout } from "protobuffer-ts/dist/class_service/report_layout";
import {
	ReportType,
	Semester,
	reportTypeToNumber,
} from "protobuffer-ts/dist/class_service/semester";
import { CourseAPI } from "../../api/CourseAPI";
import { PdfAttendanceCount } from "../../models/attendance";
import { CustomField } from "../../models/custom_field";
import { CustomFieldEntry } from "protobuffer-ts/dist/user_service/custom_field_entry";
import { ReportEntry } from "../../models/report_entry";
import { Student } from "../../models/student";
import { Teacher } from "../../models/teacher";
import { utils } from "../../utils/array_utils";
import { LegendPage } from "../homeroom_pdf/LegendPage";
import { CourseRowPdf } from "./CourseRowPdf";
import { FooterSection } from "./FooterSection";
import PdfStylesheet from "./PdfStylesheet";
import { StudentInfoSection } from "./StudentInfoSection";
import { SignatureProps, SignatureSection } from "./SignatureSection";

// Define styles for the PDF document
const styles = PdfStylesheet;

Font.registerHyphenationCallback((text) => [text]);

Font.registerEmojiSource({
	format: "png",
	url: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/",
});

// Register Roboto fonts
Font.register({
	family: "Roboto",
	fonts: [
		{ src: "/fonts/Roboto-regular.woff", fontWeight: "normal" },
		{ src: "/fonts/Roboto-700.woff", fontWeight: "bold" },
	],
});

interface ReportCardProps {
	student: Student;
	semester: Semester;
	reportType: ReportType;
	semesterDate?: Date;
	courses: CourseAPI.ListCourse[];
	teachers?: Teacher[];
	reportEntries: ReportEntry[];
	reportLayouts: ReportLayout[];
	attendanceCourses: Record<string, PdfAttendanceCount>;
	primary_id_custom_field?: CustomField;
	primary_id_entry?: CustomFieldEntry;
	principalSignature?: SignatureProps;
}

export const ReportCardPdf: React.FC<ReportCardProps> = ({
															 student,
															 semester,
															 reportType,
															 semesterDate,
															 courses,
															 teachers,
															 reportEntries,
															 reportLayouts,
															 attendanceCourses,
															 primary_id_custom_field,
															 primary_id_entry,
															 principalSignature,
														 }) => {
	// Convert the subjects to an array of 4 items for each, each 4 will be rendered in a separated page
	const coursesChunks = utils.chunkArray(
		courses,
		4
	) as CourseAPI.ListCourse[][];
	const distributionDate = semester.reportLayout?.reportDates.find(
		(date) => date.reportType == reportType
	)?.["distributionDate"];

	return (
		<Document>
			{coursesChunks.map((chunk, index) => (
				<Page style={styles.page} orientation="landscape" key={`page-${index}`}>
					<View style={styles.headerContainer}>
						{/* Logo Column */}
						<View style={styles.headerColumn}>
							<Image style={styles.logo} src="/high-school-logo.jpeg" />

							<PdfText>
								{distributionDate?.toLocaleDateString("en-US", {
									month: "short",
									day: "2-digit",
									year: "numeric",
								})}
							</PdfText>
						</View>

						<View style={styles.headerColumn}>
							<PdfText style={styles.header}>
								Provincial Report Card, Grades 9 – 12
							</PdfText>
						</View>

						<View style={styles.headerColumn}>
							<View style={styles.headerSemester}>
								<View style={[styles.cell, styles.border]}>
									<PdfText style={styles.label}>Term: </PdfText>
									{/* -1 to skip the progress "we don't calculate progress in high school report types" */}
									<PdfText style={styles.value}>
										{reportTypeToNumber(reportType) - 1}
									</PdfText>
								</View>

								<View style={[styles.cell, styles.border]}>
									<PdfText style={styles.label}>Date: </PdfText>
									<PdfText>
										{semesterDate?.toLocaleDateString("en-US", {
											month: "short",
											day: "2-digit",
											year: "numeric",
										})}
									</PdfText>
								</View>
							</View>
						</View>
					</View>

					<StudentInfoSection
						student={student}
						semester={semester}
						principal_name={principalSignature?.name!}
						primary_id_entry={primary_id_entry}
						primary_id_custom_field={primary_id_custom_field}
					/>

					{/* Grid Header */}
					<View style={[styles.gridContainer, { backgroundColor: "#dbdbf2" }]}>
						<View
							style={[styles.gridCell, styles.subjectCol, styles.flexCenter]}
						>
							<PdfText style={styles.value}>Courses</PdfText>
						</View>
						<View style={[styles.gridCell, styles.smallCol, { height: 81 }]}>
							<PdfText style={styles.verticalText}>Reporting-Period</PdfText>
						</View>
						<View style={[styles.gridCell, styles.smallCol]}>
							<PdfText style={styles.verticalText}>Percentage-Mark</PdfText>
						</View>
						<View style={[styles.gridCell, styles.smallCol]}>
							<PdfText style={styles.verticalText}>Course-Median</PdfText>
						</View>
						<View style={[styles.gridCell, styles.smallCol]}>
							<PdfText style={styles.verticalText}>Credits-Earned</PdfText>
						</View>

						<View style={[{ width: "15%", padding: 0 }]}>
							<PdfText style={[styles.gridCell, styles.learningSkillsLabel]}>
								Learning Skills and Work Habits
							</PdfText>

							<View
								style={[
									styles.gridContainer,
									{ backgroundColor: "#dbdbf2", width: "100%", borderLeft: 0 },
								]}
							>
								<View style={[styles.gridCell, styles.SemesterCol]}>
									<PdfText style={styles.verticalText}>Responsibility</PdfText>
								</View>

								<View style={[styles.gridCell, styles.SemesterCol]}>
									<PdfText style={styles.verticalText}>Organization</PdfText>
								</View>

								<View style={[styles.gridCell, styles.SemesterCol]}>
									<PdfText style={styles.verticalText}>
										Independent-Work
									</PdfText>
								</View>

								<View style={[styles.gridCell, styles.SemesterCol]}>
									<PdfText style={styles.verticalText}>Collaboration</PdfText>
								</View>

								<View style={[styles.gridCell, styles.SemesterCol]}>
									<PdfText style={styles.verticalText}>Initiative</PdfText>
								</View>

								<View style={[styles.gridCell, styles.SemesterCol]}>
									<PdfText style={styles.verticalText}>Self-Regulation</PdfText>
								</View>
							</View>
						</View>

						<View style={[styles.gridCell, styles.largeCol, styles.flexCenter]}>
							<PdfText style={styles.value}>
								Strengths/Next Steps for Improvement
							</PdfText>
						</View>

						<View style={[{ width: "7.5%", padding: 0 }]}>
							<PdfText style={[styles.gridCell, styles.learningSkillsLabel]}>
								Attendance
							</PdfText>

							<View
								style={[
									styles.gridContainer,
									{ backgroundColor: "#dbdbf2", width: "100%", borderLeft: 0 },
								]}
							>
								<View style={[styles.gridCell, styles.AttendanceCol]}>
									<PdfText style={styles.verticalText}>Classes-Missed</PdfText>
								</View>

								<View style={[styles.gridCell, styles.AttendanceCol]}>
									<PdfText style={styles.verticalText}>Times-Late</PdfText>
								</View>

								<View style={[styles.gridCell, styles.AttendanceCol]}>
									<PdfText style={styles.verticalText}>Total-Classes</PdfText>
								</View>
							</View>
						</View>
					</View>

					{/* Course Rows */}
					{chunk.map((course) => {
						const teacherId = course.teachers?.[0]; // Get the first teacher
						const teacher = teachers?.find(
							(t) => t._id.$oid === teacherId.$oid
						);
						const teacherName = teacher
							? `${teacher.first_name} ${teacher.last_name}`
							: undefined;

						return (
							<CourseRowPdf
								key={course._id.$oid}
								semester={semester}
								course={course}
								teacher={teacherName}
								reportType={reportType}
								reports={reportEntries.filter(
									(r) => r.course?.$oid === course._id.$oid
								)}
								reportLayout={
									reportLayouts.find((r) => r.courseId?.id === course._id.$oid)!
								}
								attendanceCount={attendanceCourses[course._id.$oid]}
							/>
						);
					})}
					{principalSignature && index === coursesChunks.length - 1 && (
						<SignatureSection signatureProps={principalSignature} />
					)}
					<FooterSection
						page={index + 1}
						totalPages={coursesChunks.length + 1}
					/>
				</Page>
			))}

			<LegendPage isNumeric={true}>
				<StudentInfoSection
					student={student}
					semester={semester}
					principal_name={principalSignature?.name!}
					primary_id_entry={primary_id_entry}
					primary_id_custom_field={primary_id_custom_field}
				/>

				<View style={styles.sectionSpacing} />

				<FooterSection
					page={coursesChunks.length + 1}
					totalPages={coursesChunks.length + 1}
				/>
			</LegendPage>
		</Document>
	);
};