import { Box } from "@chakra-ui/react";
import { InvoiceAPI } from "../../api/InvoiceAPI";
import { CreateInvoice } from "../../models/invoice";
import { useNavigate } from "react-router-dom";
import { InvoiceForm } from "./InvoiceForm";
import { useState } from "react";
import { useToaster } from '../../hooks/useToaster';
import { BlHeading } from "../layout/Heading";
import { BlBreadcrumbs } from "../layout/Breadcrumbs";

export const CreateInvoiceView = () => {
  const { showSuccessToast } = useToaster()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>();

  const onSubmit = async (data: CreateInvoice) => {
    setLoading(true);
    // check if data has defined course or homeroom defined
    const hasClass = data.course || data.homeroom

    // define the function 
    const submitFunction = hasClass ? InvoiceAPI.create_for_class : InvoiceAPI.create

    try {
      await submitFunction(data);

      showSuccessToast('Invoice Created Successfully');

      navigate('/invoices')

    } catch (error) {
      console.error("Error creating invoice:", error);
    }

    setLoading(false);
  };
  return (
    <Box>
      <BlHeading> Create Invoice </BlHeading>

      <BlBreadcrumbs />

      <InvoiceForm onSubmit={onSubmit} loading={loading} />
    </Box>
  );
};
