import * as React from "react"
import { Heading, GridItem, VStack, Text, SimpleGrid, Container } from '@chakra-ui/react';
import { Property } from "./3rd_party/Property";
import { LabeledProperty } from "./3rd_party/LabeledProperty";
import LocalStorageService from "../api/LocalStorageService";
import { BlCard } from "./layout/Card";


export const Home: React.FC = (props) =>  {

    const important_links = (
        <BlCard>
            <Text fontSize="xl" fontWeight="bold">
                Important Links
            </Text>

            <LabeledProperty label="Telegram Support Channel" link='https://t.me/+Q_6kbDsSAUkwOTQx' />

            <LabeledProperty label="Software Usage Wiki" link='https://ahmadb.notion.site/Public-Wiki-9b44fc076c964fa4acdab9c550df5fb8'/>
        </BlCard>
    )

    const announcements = (
        <BlCard>
            <Text fontSize="xl" fontWeight="bold">
            Announcements
            </Text>

            <Property date="Aug 23, 2024" value="Welcome to the new 2024-2025 school year!" />
        </BlCard>
    )

    return (
        <>
        <VStack mb={4}>
            <Heading size="sm" fontWeight="bold">
                Hello {LocalStorageService.getInstance().getUser()?.full_name}!
            </Heading>

            <Text fontSize="xl">Date: {new Date().toDateString()}</Text>
        </VStack>

        <Container>
            <SimpleGrid columns={{ lg: 2 }} gap={6}>
                <GridItem> {important_links} </GridItem>

                <GridItem> {announcements} </GridItem>
            </SimpleGrid>
        </Container>
        </>
    );
}
