// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentAPI } from "../../api/PaymentAPI";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  Spinner,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { STRIPE } from "../../api/ApiCommon";
import { InvoiceResponse } from "../../models/invoice";
import { PaymentType } from "../../models/transaction";

interface CheckoutFormCard {
  price: number
  redirect_url?: string
}

const CheckoutForm = ({ price, redirect_url }: CheckoutFormCard) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  setTimeout(function() { setIsDisabled(false); }, 1000);

  const payment_element = <PaymentElement id="payment-element" />;

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: redirect_url
          ? `${window.location.origin}/payment_confirmed?redirect_url=${encodeURIComponent(redirect_url)}`
          : `${window.location.origin}/payment_confirmed`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <Stack>
      {message && (
        <Alert status="error">
          <AlertIcon />

          <AlertTitle mr={2}>A payment error occurred!</AlertTitle>

          <AlertDescription>{message}</AlertDescription>
        </Alert>
      )}

      {payment_element && stripe && (
        <Stack gap="4">
          {payment_element}

          { isLoading ? <Center><Spinner /></Center> : (
            <HStack>
              <Spacer />

              <Button
                disabled={isLoading || !stripe || !elements || isDisabled}
                colorScheme="green"
                onClick={handleSubmit}
              >
                Pay Now - {price}$
              </Button>
            </HStack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const stripePromise = loadStripe(STRIPE);

interface Card {
  invoiceRes: InvoiceResponse;
  redirect_url?: string,
}

export const Payments = React.memo(({ invoiceRes, redirect_url }: Card) => {
  const invoice = invoiceRes.invoice
  const grandTotal = invoiceRes.total_amount
  const totalPaid = invoiceRes.total_amount_paid
  const toPay = grandTotal - totalPaid

  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure();

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const handlePayNowClick = () => {
    setLoading(true)
    PaymentAPI.new_payment(invoice._id, PaymentType.Stripe, grandTotal)
      .then((res) => {
        setClientSecret(res.data);

        onOpen();
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button colorScheme="green" isLoading={loading} onClick={handlePayNowClick}>
        Pay Now - {toPay}$
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />

        <ModalContent >
          <ModalHeader>Payment</ModalHeader>

          <ModalCloseButton />

          <ModalBody mb={4}>
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm price={toPay} redirect_url={redirect_url}/>
            </Elements>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});
