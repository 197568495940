import {
	Badge, Divider, Flex, Icon, SimpleGrid, Spacer, Stack, Text
} from '@chakra-ui/react';
import { ProfileSection } from 'protobuffer-ts/dist/organization_service/organization_profile_settings';
import { useEffect, useState } from 'react';
import { FiGlobe, FiHome, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import { OrganizationProfileSettingsAPI } from '../../../api/OrganizationProfileSettingsAPI';
import { ParentAPI } from '../../../api/ParentAPI';
import { Family, livingWithValue } from '../../../models/family';
import { Parent } from '../../../models/parent';
import { BlCard } from '../../layout/Card';
import { CopyIcon } from '../../layout/CopyIcon';
import SkeletonTable from '../../table/TableSkeleton';

interface Props {
	family: Family;
}

export const FamilyOverviewCard = ({ family }: Props) => {
	const [loading, setLoading] = useState<boolean>(false)
	const [parents, setParents] = useState<Parent[]>([])

	const [parentProfileSections, setParentProfileSections] = useState<ProfileSection[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)

			let parentsPromise = Promise.all(family.guardians.map((e) => ParentAPI.get_parent(e)))
			let _parents = await parentsPromise
			setParents(_parents)

			let _settings = await OrganizationProfileSettingsAPI.get_organization_profile_settings()
			setParentProfileSections(_settings.parentProfileSections)

			setLoading(false)
		}

		fetchData()
	}, [])

	if (loading) return <SkeletonTable />

	if (!family) return <>No Family Found</>

	const preferredContactId = family.information.preferred_contact?.$oid;

	const preferredContactParent = parents.find(
		(parent) => parent._id.$oid === preferredContactId
	);

	const preferredContactName = preferredContactParent?.name || parents?.[0]?.name || 'Unknown';

	const FamilyContact = family.information.emergency_contact

	return (
		<SimpleGrid columns={{ md: 2, xl: 3 }} gap="4">
			<Stack>
				<BlCard>
					<Stack spacing={6}>
						{/* Family Name */}
						<Text fontSize="xl" fontWeight="bold">
							{family.name}
						</Text>

						{/* Basic Family Information */}
						<Flex gap="8" wrap="wrap">
							<Flex gap={2} align="center">
								<Icon as={FiHome} boxSize={6} color="blue.500" />

								<Text color="gray.600">
									Living With: <strong>{livingWithValue[family.information.student_living]}</strong>
								</Text>
							</Flex>

							<Flex gap={2} align="center">
								<Icon as={FiUser} boxSize={6} color="green.500" />

								<Text color="gray.600">
									Preferred Contact: <strong>{preferredContactName}</strong>
								</Text>
							</Flex>

							<Flex gap={2} align="center">
								<Icon as={FiGlobe} boxSize={6} color="orange.500" />
								<Text color="gray.600">
									Language Spoken at Home: <strong>{family.information.language_spoken}</strong>
								</Text>
							</Flex>
						</Flex>
					</Stack>
				</BlCard>

				<BlCard gap={6}>
					<Text fontWeight="bold" fontSize="lg">
						Emergency Contact
					</Text>

					<Flex gap="8" wrap="wrap" width="full">
						{/* Full Name */}
						<Flex gap={2} align="center">
							<Icon as={FiUser} boxSize={6} color="green.500" />

							<Text color="gray.600">
								Name: <strong>{FamilyContact.name}</strong>
							</Text>

							<CopyIcon text={FamilyContact.name} />
						</Flex>

						{/* Email */}
						<Flex gap={2} align="center">
							<Icon as={FiMail} boxSize={6} color="blue.500" />

							<Text color="gray.600" isTruncated maxW="100%">
								Email: <strong>{FamilyContact.email}</strong>
							</Text>

							<CopyIcon text={FamilyContact.email} />
						</Flex>

						{/* Phone */}
						<Flex gap={2} align="center">
							<Icon as={FiPhone} boxSize={6} color="orange.500" />

							<Text color="gray.600">
								Phone:{' '}
								{FamilyContact.phone ? (
									<strong>{`+1 ${FamilyContact.phone}`}</strong>
								) : (
									<Badge colorScheme="red">Not available</Badge>
								)}
							</Text>

							<Spacer />

							{FamilyContact.phone && (
								<CopyIcon text={FamilyContact.phone} />
							)}
						</Flex>
					</Flex>
				</BlCard>
			</Stack>
			{/* Guardians Section */}
			<BlCard gap={6} gridColumn={{ xl: 'span 2' }}>
				<Text fontWeight="bold" fontSize="lg">
					Guardians
				</Text>

				<Stack spacing={4}>
					{parents.length > 0 ? (
						parents.map(guardian => (
							<>
								<BlCard boxShadow="none" bg="gray.50">
									<Flex gap="8" wrap="wrap">
										<Flex gap={2} align="center">
											<Icon as={FiUser} boxSize={6} color="green.500" />

											<Text color="gray.600">
												Name: <strong>{guardian.name}</strong>
											</Text>
										</Flex>

										<Flex gap={2} align="center">
											<Icon as={FiMail} boxSize={6} color="blue.500" />

											<Text color="gray.600" isTruncated maxW={{ base: '200px', md: 'full' }}>
												Email: <strong>{guardian.email}</strong>
											</Text>

											<CopyIcon text={guardian.email} />
										</Flex>

										<Flex gap={2} align="center">
											<Icon as={FiPhone} boxSize={6} color="orange.500" />

											<Text color="gray.600">
												Phone: {' '}
												{guardian.phone ?
													<strong>{guardian.phone}</strong> :

													<Badge size="sm">Not available</Badge>
												}
											</Text>

											{ guardian.phone && <CopyIcon text={guardian.phone} /> }
										</Flex>
									</Flex>
								</BlCard>

								<Divider />
							</>
						))
					) : (
						<Text>No guardians available.</Text>
					)}
				</Stack>
			</BlCard>
		</SimpleGrid>

	);
};
