import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay, Box, Button,
    Center,
    Checkbox,
    FormControl,
    FormLabel, HStack,
    Input,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger, Stack, useDisclosure
} from "@chakra-ui/react";
import React, { RefObject, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TeacherAPI } from "../../api/TeacherAPI";
import { Teacher } from "../../models/teacher";
import { ObjectId, to_oid } from "../../utils/ObjectId";
import { TeacherProfile } from "./TeacherProfile";
import { FiAlertTriangle, FiArrowDown } from "react-icons/fi";
import { FocusableElement } from "@chakra-ui/utils";
import { useToaster } from "../../hooks/useToaster";
import { BlHeading } from "../layout/Heading";
import SkeletonTable from "../table/TableSkeleton";
import { LightButton } from "../layout/LightButton";

export const TeacherProfileRoute = () => {
    let { teacher_id } = useParams();

    const [teacher, set_teacher] = useState<Teacher>();
    const [loading, set_loading] = useState<boolean>(false);

    useEffect(() => {
        set_loading(true)

        TeacherAPI.get_teacher(to_oid(teacher_id!))
            .then((res) => {
                set_teacher(res)
            }).finally(() => {
                set_loading(false)
            })
    }, []);


    if (loading) return <SkeletonTable />

    if (!teacher) return <></>

    return (
        <Box>
            <BlHeading> {teacher.first_name + " " + teacher.last_name} </BlHeading>

            <TeacherProfile teacher={teacher} set_teacher={set_teacher} />

            <HStack justify='end'>
                <ChangePasswordPopover teacher_id={teacher!._id} />

                <WithdrawPopOver teacher={teacher} />
            </HStack>
        </Box>
    )

}

interface ChangePasswordPopoverCard {
    teacher_id: ObjectId
}
const ChangePasswordPopover = ({ teacher_id }: ChangePasswordPopoverCard) => {
    const { showErrorToast, showSuccessToast } = useToaster()

    const { isOpen, onToggle, onClose } = useDisclosure()

    const [password, set_password] = React.useState<string>("");
    const [change_password_at_next_login, set_change_password_at_next_login] = React.useState<boolean>(true);

    const change_password = () => {
        TeacherAPI.change_password(teacher_id, password, change_password_at_next_login)
            .then((res) => {
                onToggle()
                set_password("")
                showSuccessToast('Password Change');
            })
            .catch((err) => {
                set_password("")

                showErrorToast("Failed to change password")
            })
    }


    return (

        <Popover
            isOpen={isOpen}
            onClose={onClose}
        >
            <PopoverTrigger >
                <LightButton color="orange" rightIcon={<FiArrowDown />} onClick={onToggle}>Change Password</LightButton>            
            </PopoverTrigger>

            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Change Password</PopoverHeader>
                <PopoverBody>
                    <>
                        <FormControl isRequired>
                            <FormLabel>Password</FormLabel>
                            <Input placeholder='Password' type="password" value={password} onChange={(value) => { set_password(value.target.value) }} />
                        </FormControl>
                        <br />
                        <FormControl isRequired>
                            <Checkbox
                                colorScheme="green"
                                isChecked={change_password_at_next_login}
                                onChange={(e) => set_change_password_at_next_login(e.target.checked)}
                            >Change Password at Next Login</Checkbox>
                        </FormControl>
                        <br />
                        <Center>
                            <Button colorScheme="green" onClick={change_password}>Submit</Button>
                        </Center>
                    </>
                </PopoverBody>
            </PopoverContent>
        </Popover>

    )
}


interface WithdrawPopoverCard {
    teacher: Teacher
}
const WithdrawPopOver = ({ teacher }: WithdrawPopoverCard) => {
    const { showErrorToast, showSuccessToast } = useToaster()


    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef() as RefObject<FocusableElement>

    const [loading, set_loading] = React.useState<boolean>(false)


    const withdraw = () => {
        set_loading(true)
        TeacherAPI.withdraw(teacher._id)
            .then((res) => {
                onClose()
                showSuccessToast('Teacher Withdrawn');
                window.location.reload();
            })
            .catch((err) => {
                set_loading(false)

                showErrorToast("Failed to withdraw teacher")
            })
    }


    return (
        <>
            <LightButton color='red' rightIcon={<FiAlertTriangle />} onClick={onOpen}>
                Withdraw
            </LightButton>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Withdraw Teacher?
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef as React.LegacyRef<HTMLButtonElement>} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={withdraw} ml={3} isLoading={loading}>
                                Withdraw
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}