import { useEffect, useState } from "react";
import { BassemLabsStaff, StaffStatus } from "../../models/bassem_labs_staff";
import { BassemLabsStaffAPI } from "../../api/BassemLabsStaffAPI";
import {
    Box,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Badge,
    Spinner,
    Center,
    Button,
    Input,
    HStack,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Select,
    useDisclosure,
} from "@chakra-ui/react";
import { DateTime } from "../../utils/DateTime";
import {useNavigate} from "react-router-dom";

const BassemLabsStaffList = () => {
    const [staffList, setStaffList] = useState<BassemLabsStaff[]>([]);
    const [filteredStaff, setFilteredStaff] = useState<BassemLabsStaff[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        username: "",
        personal_email: "",
        phone_number: "",
        gender: "Male",
        date_of_birth: DateTime.from_date(new Date()),
    });

    useEffect(() => {
        const fetchStaff = async () => {
            try {
                const data = await BassemLabsStaffAPI.get_users();
                setStaffList(data);
                setFilteredStaff(data);
            } catch (error) {
                console.error("Error fetching staff:", error);
                toast({
                    title: "Error",
                    description: "Failed to fetch staff members.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        };

        fetchStaff();
    }, [toast]);

    const handleSearch = (query: string) => {
        setSearchQuery(query);
        const filtered = staffList.filter((staff) =>
            `${staff.firstName} ${staff.lastName}`.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredStaff(filtered);
    };

    const handleRowClick = (staffId: string) => {
       navigate(`/staff/users/${staffId}`);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        if (name === "date_of_birth") {
            const selectedDate = new Date(value);
            if (!isNaN(selectedDate.getTime())) {
                const dateTime = DateTime.from_date(selectedDate);
                setFormData({ ...formData, [name]: dateTime });
            } else {
                setFormData({ ...formData, [name]: DateTime.from_date(new Date()) });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleCreateStaff = async () => {
        if (!formData.first_name || !formData.last_name || !formData.username) {
            toast({
                title: "Error",
                description: "First Name, Last Name, and Email are required",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const newStaff = await BassemLabsStaffAPI.create_user(
                {
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    username: formData.username,
                    personal_email: formData.personal_email,
                    phone_number: formData.phone_number,
                    gender: formData.gender,
                    date_of_birth: formData.date_of_birth
                }
            );

            setStaffList([newStaff, ...staffList]);
            setFilteredStaff([newStaff, ...filteredStaff]);
            toast({
                title: "Staff Created",
                description: "New staff member has been added successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

            onClose();
            setFormData({
                first_name: "",
                last_name: "",
                username: "",
                personal_email: "",
                phone_number: "",
                gender: "Male",
                date_of_birth: DateTime.from_date(new Date()),
            });
        } catch (error) {
            console.error("Error creating staff:", error);
            toast({
                title: "Error",
                description: "Failed to create staff member",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Box p={6} maxW="6xl" mx="auto">
            <Heading mb={6} textAlign="center" size={"xl"}>
                Staff Dashboard
            </Heading>

            <HStack spacing={4} mb={4} justify="space-between">
                <Input
                    placeholder="Search staff..."
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                    width="300px"
                />
                <Button colorScheme="blue" onClick={onOpen}>
                    + Create Staff
                </Button>
            </HStack>

            {loading ? (
                <Center>
                    <Spinner size="xl" />
                </Center>
            ) : (
                <Table variant="simple" size="md">
                    <Thead bg="gray.100">
                        <Tr>
                            <Th>Name</Th>
                            <Th>Email</Th>
                            <Th>Status</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredStaff.map((staff) => (
                            <Tr
                                key={staff.id.id}
                                onClick={() => handleRowClick(staff.id.id)}
                                cursor="pointer"
                                _hover={{ bg: "gray.50" }}
                            >
                                <Td fontWeight="medium">{`${staff.firstName} ${staff.lastName}`}</Td>
                                <Td fontWeight="medium">{`${staff.email}`}</Td>
                                <Td>
                                    <Badge colorScheme={staff.status === StaffStatus.ACTIVE ? "green" : "red"}>
                                        {staff.status}
                                    </Badge>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create Staff Member</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb={3} isRequired>
                            <FormLabel>First Name</FormLabel>
                            <Input name="first_name" value={formData.first_name} onChange={handleChange} />
                        </FormControl>
                        <FormControl mb={3} isRequired>
                            <FormLabel>Last Name</FormLabel>
                            <Input name="last_name" value={formData.last_name} onChange={handleChange} />
                        </FormControl>
                        <FormControl mb={3} isRequired>
                            <FormLabel>Bassem Labs Username</FormLabel>
                            <Input name="username" type="text" value={formData.username} onChange={handleChange} />
                        </FormControl>
                        <FormControl mb={3}>
                            <FormLabel>Personal Email</FormLabel>
                            <Input name="personal_email" type="email" value={formData.personal_email} onChange={handleChange} />
                        </FormControl>
                        <FormControl mb={3}>
                            <FormLabel>Phone Number</FormLabel>
                            <Input name="phone_number" type="tel" value={formData.phone_number} onChange={handleChange} />
                        </FormControl>
                        <FormControl mb={3}>
                            <FormLabel>Gender</FormLabel>
                            <Select name="gender" value={formData.gender} onChange={handleChange}>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </Select>
                        </FormControl>
                        <FormControl mb={3}>
                            <FormLabel>Date of Birth</FormLabel>
                            <Input name="date_of_birth" type="date" value={formData.date_of_birth.to_date().toISOString().split("T")[0]} onChange={handleChange} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleCreateStaff}>
                            Create
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default BassemLabsStaffList;