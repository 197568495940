import React, { useEffect } from "react";
import {
  Box,
  Center, Button,
  VStack,
  Stack,
  HStack,
  Spacer,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  Divider,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  Skeleton,
  FormControl,
  FormLabel,
  Input,
  Select,
  AlertDialogFooter
} from '@chakra-ui/react';
import { Link, useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Family } from "../../models/family";
import { FamilyAPI } from "../../api/FamilyAPI";
import { FamilyStudentList } from "./FamilyStudentList";
import { LightButton } from "../layout/LightButton";
import { FiChevronDown, FiEdit2, FiPlus } from "react-icons/fi";
import { BlCard } from "../layout/Card";
import { StudentAPI } from "../../api/StudentAPI";
import { Grades } from "../../models/student";
import { FlexWrap } from "../layout/FlexWrap";
import { DateTime } from "../../utils/DateTime";
import DatePicker from "../DatePicker";
import { useToaster } from "../../hooks/useToaster";
import SkeletonTable from "../table/TableSkeleton";
import { ObjectId } from "../../utils/ObjectId";
import { BlHeading } from "../layout/Heading";


export const FamilyList: React.FC = () => {

    const navigate = useNavigate()
    const [families, set_families] = React.useState<Family[]>([])
    const [loading, setLoading] = React.useState(true)

    const [isOpen, setIsOpen] = React.useState(false)

    const onClose = () => {
      setIsOpen(false)

      get_family()
    }

    const get_family = async () => {
      setLoading(true)
      
      const res = await FamilyAPI.get_parent_families()

      set_families(res)

      setLoading(false)
    }

    useEffect(() => {
      get_family()
    }, []);

    return (
            <Box>
                <Box overflowX="auto" overflowY="hidden">
                  <BlHeading> Parent's Dashboard </BlHeading>

                    <Stack>
                        <HStack mb={4}>
                            <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
                                <BreadcrumbItem>
                                    <BreadcrumbLink href='/parent/home'>Home</BreadcrumbLink>
                                </BreadcrumbItem>

                                <BreadcrumbItem isCurrentPage>
                                    <BreadcrumbLink href='#'>Family</BreadcrumbLink>
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <Spacer/>
                            <Link to="/parent/family/create">
                              <Button colorScheme={"green"}>Create New Family</Button>
                            </Link>
                        </HStack>

                        { loading ? <SkeletonTable /> :
                          families.length > 0 ?
                          <>
                          { families.map((family) => (
                              <>
                              <BlCard>
                                <FlexWrap>
                                  <Text fontSize={"lg"} fontWeight="bold">
                                      {family.name} Family
                                  </Text>

                                  <Spacer />

                                  <LightButton 
                                    leftIcon={<FiEdit2 />}
                                    color="blue" 
                                    onClick={() => navigate("/parent/family/update/" + family._id.$oid)}
                                  >
                                    Edit Family
                                  </LightButton>

                                  <LightButton 
                                      color='green'
                                      leftIcon={<FiPlus/>}
                                      onClick={() => navigate(`/parent/family/${family._id.$oid}/add_student`)}
                                  >
                                      Add Student
                                  </LightButton>
                                </FlexWrap>

                                <FamilyStudentList family={family}/>
                              </BlCard>


                              <Divider />
                              </>
                          ))}
                          </>
                          :
                          <>
                          <Center>
                                <VStack>
                                    <Text>No Family...</Text>
                                    <Text>Add a family using the above button to the right</Text>
                                </VStack>   
                            </Center>
                          </>

                        }

                    </Stack>
                </Box>
            </Box>
    );
}
