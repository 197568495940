import { Button, Divider, Flex, SimpleGrid } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { StudentAPI } from '../../../api/StudentAPI';
import { useToaster } from '../../../hooks/useToaster';
import { GradesArray, Student, StudentProfileData } from '../../../models/student';
import { updateStudentProfileSchema } from '../../../validations/student';
import FormDatePicker from '../../forms/FormDatePicker';
import FormInput from '../../forms/FormInput';
import FormSelect from '../../forms/FormSelect';
import { BlCard } from '../../layout/Card';

interface Card {
    student: Student
    set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
}

export const ProfileCard = React.memo(({student, set_student}: Card) => {
    const { showErrorToast, showSuccessToast } = useToaster()

    const { register, handleSubmit, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(updateStudentProfileSchema),
        defaultValues: {
            ...student,
            date_of_birth: student.date_of_birth.to_date()
        },
    })

    React.useEffect(() => {
        reset({
            ...student,
            date_of_birth: student.date_of_birth.to_date()
        })
    }, [student])

    const onSubmit = async (data: StudentProfileData) => {
        try {
            await StudentAPI.update_profile(student._id, data)

            const res = await StudentAPI.get_student(student._id.$oid)

            set_student(res!)

            showSuccessToast('Saved Profile');
        } catch (err : any) {
            showErrorToast(err.response.data)
        }
    }

    return (
        <BlCard as="form" onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid columns={{ lg: 2 }} gap={4}>
                <FormInput
                    name="first_name"
                    label="First Name"
                    register={register('first_name')}
                    error={errors.first_name?.message}
                    placeholder="Enter First Name"
                    isRequired
                />

                <FormInput
                    name="last_name"
                    label="Last Name"
                    register={register('last_name')}
                    error={errors.last_name?.message}
                    placeholder="Enter Last Name"
                    isRequired
                />
            </SimpleGrid>

            <SimpleGrid columns={{ lg: 3 }} gap={4}>
                <FormSelect
                    name="gender"
                    label="Gender"
                    register={register('gender')}
                    error={errors.gender?.message}
                    isRequired
                    options={['M', 'F']}
                />
                <FormSelect
                    name="grade"
                    label="Grade"
                    options={GradesArray}
                    register={register('grade')}
                    error={errors.grade?.message}
                    isRequired
                />

                <FormDatePicker
                    name="date_of_birth"
                    label="Date of Birth"
                    control={control}
                    error={errors.date_of_birth?.message}
                    isDisabled={false}
                    isRequired
                />
            </SimpleGrid>

            <Divider />

            <Flex justify={"flex-end"}>
                <Button type="submit">
                    Save
                </Button>
            </Flex>
        </BlCard>
    )
})
