import { UserType } from "../api/UserApi";
import { ObjectId } from "../utils/ObjectId";
import { ProfileSection } from "protobuffer-ts/dist/organization_service/organization_profile_settings";

export interface CustomFieldsGroup {
    _id: ObjectId, 
    organization: ObjectId, 
    group_name: string, 
    user_type: UserType,
    profile_section: ProfileSection,
    hints?: string[],
    group_access_settings: ObjectId;
    entries_access_settings: ObjectId;
}

export interface CreateCustomFieldsGroup {
    group_name: string, 
    user_type: UserType, 
    profile_section: ProfileSection,
    group_access_settings: ObjectId;
    entries_access_settings: ObjectId;
}


export interface CreateCustomFieldEntry {
    custom_field_id: ObjectId,
    value: string,
}

export interface GroupApprovalStatus {
    status: ApprovalStatus,
    rejection_message?: string,
}

export enum ApprovalStatus {
    RequiredToFill = "RequiredToFill",
    PendingReview = "PendingReview",
    Approved = "Approved",
    Rejected = "Rejected",
}

export type GroupStatus = {
    groupId: ObjectId;
    status: ApprovalStatus;
};


export const ApprovalStatusColors: Record<string, string> = {
    [ApprovalStatus.Approved]: 'green',
    [ApprovalStatus.Rejected]: 'red',
    [ApprovalStatus.PendingReview]: 'orange',
    [ApprovalStatus.RequiredToFill]: 'blue'
};

export const getApprovalStatusIcon = (status: ApprovalStatus): string => {
    switch (status) {
        case ApprovalStatus.Approved:
            return '✓';
        case ApprovalStatus.Rejected:
            return '✗';
        case ApprovalStatus.PendingReview:
            return '…';
        case ApprovalStatus.RequiredToFill:
            return '!';
        default:
            return '';
    }
};
