import { Box, HStack, Stack, Text, Heading } from "@chakra-ui/react";
import { AttachmentIcon } from "@chakra-ui/icons";
import { AWSFile } from "protobuffer-ts/dist/utils/aws_file";
import { BlCard } from "../../layout/Card";


interface DocumentsDisplayProps {
    title?: string;
    files: AWSFile[];
}

export const DocumentsDisplay = ({ title, files }: DocumentsDisplayProps) => {
    return (
        <BlCard>
            <Heading size="sm">
                {title}
            </Heading>

            <Stack spacing={4}>
                <Box>
                    <Stack spacing={2}>
                        {files.map((file, index) => (
                            <Box
                                key={`${file.preSignedUrl}-${index}`}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={3}
                                borderRadius="md"
                                bg="blue.100"
                                cursor="pointer"
                                border="2px dashed blue.400"
                                _hover={{ borderColor: "white.600", bg: "blue.200" }}
                                onClick={() => window.open(file.preSignedUrl, '_blank')}
                            >
                                <HStack>
                                    <AttachmentIcon color="blue.600" />
                                    <Text fontWeight="medium" color="blue.800">
                                        {file.fileName}
                                    </Text>
                                </HStack>
                            </Box>
                        ))}
                    </Stack>
                </Box>
            </Stack>
        </BlCard>
    );
};
