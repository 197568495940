import { BlCard } from "../layout/Card";
import { Heading, Stack, Button } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FormInput from "../forms/FormInput";
import { OnboardingSettingsAPI } from "../../api/OnboardingSettingsAPI";
import { useToaster } from "../../hooks/useToaster";
import { WaitlistFeeData } from "../../models/onboarding_settings";


interface WaitlistFeeSectionProps {
  initialData: WaitlistFeeData;
}

export const WaitlistFeeSection = ({ initialData }: WaitlistFeeSectionProps) => {
  const { showErrorToast, showSuccessToast } = useToaster();
  const { register, handleSubmit } = useForm({
    defaultValues: initialData,
  });

  const onSubmit = async (data: WaitlistFeeData) => {
    try {
      await OnboardingSettingsAPI.update_waitlist_fee(data);
      showSuccessToast("Waitlist fee updated");
    } catch {
      showErrorToast("Update failed");
    }
  };

  return (
    <BlCard>
      <Heading size="md" mb={6}>
        Waitlist Fee
      </Heading>
      <Stack spacing={4}>
        <FormInput
          name="waitlistFee"
          label="Fee Amount"
          placeholder="Enter fee amount"
          register={register("waitlist_fee", { valueAsNumber: true })}
        />
        <Button onClick={handleSubmit(onSubmit)} colorScheme="blue" alignSelf="flex-end">
          Save Fee
        </Button>
      </Stack>
    </BlCard>
  );
};
