import { Badge, Box, Flex, Stack, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react"
import { AccessPermissionType, accessRuleToString, ResourceAccessSettings } from "../../models/resource_access_management"
import { BlCard } from "../layout/Card";

export interface ResourceAccessSettingsCardProps {
    resourceAccessSettings: ResourceAccessSettings;
}

export const ResourceAccessSettingsCard = ({ resourceAccessSettings }: ResourceAccessSettingsCardProps) => {
    const allowedReadRules = resourceAccessSettings.access_rules.filter(
        (rule) => rule.permission_type === AccessPermissionType.AllowRead
    );
    const deniedReadRules = resourceAccessSettings.access_rules.filter(
        (rule) => rule.permission_type === AccessPermissionType.DenyRead
    );
    const allowedWriteRules = resourceAccessSettings.access_rules.filter(
        (rule) => rule.permission_type === AccessPermissionType.AllowWrite
    );
    const deniedWriteRules = resourceAccessSettings.access_rules.filter(
        (rule) => rule.permission_type === AccessPermissionType.DenyWrite
    );

    return (
        <BlCard>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
                {resourceAccessSettings.name}
            </Text>
            <Flex align="stretch">
                {/* Left side: Who can read? */}
                <Box flex="1" pr={4}>
                    <Text fontSize="md" fontWeight="semibold" mb={2}>
                        Who can read?
                    </Text>
                    <Wrap spacing="2">
                        {allowedReadRules.map((rule, index) => (
                            <WrapItem key={`allow-read-${index}`}>
                                <Badge colorScheme="green" px="2" py="1">
                                    {accessRuleToString(rule)}
                                </Badge>
                            </WrapItem>
                        ))}
                        {deniedReadRules.length > 0 && (
                            <Stack>
                                <WrapItem>
                                    <Text mt={5} fontSize="sm" color="gray.600">
                                        Except for
                                    </Text>
                                </WrapItem>
                                {deniedReadRules.map((rule, index) => (
                                    <WrapItem key={`deny-read-${index}`}>
                                        <Badge colorScheme="red" px="2" py="1">
                                            {accessRuleToString(rule)}
                                        </Badge>
                                    </WrapItem>
                                ))}
                            </Stack>
                        )}
                    </Wrap>
                </Box>

                {/* Vertical Divider */}
                <Box borderLeft="1px" borderColor="gray.300" mx={2} minHeight="100%" />

                {/* Right side: Who can update? */}
                <Box flex="1" pl={4}>
                    <Text fontSize="md" fontWeight="semibold" mb={2}>
                        Who can update?
                    </Text>
                    <Wrap spacing="2">
                        {allowedWriteRules.map((rule, index) => (
                            <WrapItem key={`allow-write-${index}`}>
                                <Badge colorScheme="green" px="2" py="1">
                                    {accessRuleToString(rule)}
                                </Badge>
                            </WrapItem>
                        ))}
                        {deniedWriteRules.length > 0 && (
                            <Stack>
                                <WrapItem>
                                    <Text mt={5} fontSize="sm" color="gray.600">
                                        Except for
                                    </Text>
                                </WrapItem>
                                {deniedWriteRules.map((rule, index) => (
                                    <WrapItem key={`deny-write-${index}`}>
                                        <Badge colorScheme="red" px="2" py="1">
                                            {accessRuleToString(rule)}
                                        </Badge>
                                    </WrapItem>
                                ))}
                            </Stack>
                        )}
                    </Wrap>
                </Box>
            </Flex>
        </BlCard>
    );
};
