import { Box, Center, Heading, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react";
import { OnboardingSettings } from "protobuffer-ts/dist/organization_service/onboarding_settings";
import { useEffect, useState } from "react";
import { OnboardingSettingsAPI } from "../../../api/OnboardingSettingsAPI";
import { StudentAPI } from "../../../api/StudentAPI";
import { useToaster } from "../../../hooks/useToaster";
import { StudentCounts } from "../../../models/onboarding";
import { StudentStatus } from "../../../models/student";
import { StudentsList } from "../StudentsList";
import { ReregistrationStudents } from "./ReregistrationStudents";


export const ReregistrationPage = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [onboardingSettings, setOnboardingSettings] = useState<OnboardingSettings>();
    const [studentCounts, setStudentCounts] = useState<StudentCounts>();
    const { showErrorToast } = useToaster();

    const fetchOnboardingSettings = () => {
        setLoading(true);
        OnboardingSettingsAPI.get_onboarding_settings()
            .then((data) => {
                setOnboardingSettings(data);
                setLoading(false);
            })
            .catch(() => {
                showErrorToast("Failed to load settings");
                setLoading(false);
            });
    }

    const fetchStudentCounts = async (): Promise<void> => {
        try {
            const [reregistration, withdrawn] = await Promise.all([
                StudentAPI.count_students({ "statuses": [StudentStatus.ReRegistration] }),
                StudentAPI.count_students({ "statuses": [StudentStatus.Withdrawn] }),
            ]);

            setStudentCounts({
                reregistration,
                withdrawn,
            });
        } catch (error) {
            showErrorToast("Failed to load student counts");
        }
    };

    useEffect(() => {
        fetchOnboardingSettings();
        fetchStudentCounts();
    }, []);

    const handleStudentCountUpdate = () => {
        fetchStudentCounts();
    };

    if (loading) {
        return (
            <Box maxW="800px" mx="auto" mt="10" p="5">
                <VStack spacing="5" align="stretch">
                    <Skeleton height="40px" />
                    <Skeleton height="25px" />
                    <Skeleton height="25px" />
                    <Skeleton height="70px" />
                    <Skeleton height="25px" />
                </VStack>
            </Box>
        );
    }

    if (!onboardingSettings) {
        return null;
    }

    return (
        <Box>
            <Center>
                <Heading size="sm" fontWeight={"bold"} mb="6">
                    Reregistration
                </Heading>
            </Center>

            <Tabs isLazy lazyBehavior="unmount">
                <TabList>
                    <Tab>Reregistration ({studentCounts?.reregistration})</Tab>
                    <Tab>Withdrawn ({studentCounts?.withdrawn})</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <ReregistrationStudents onStudentCountUpdate={handleStudentCountUpdate} />
                    </TabPanel>
                    <TabPanel>
                        <StudentsList status={StudentStatus.Withdrawn} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    )
};
