import React, { useEffect, useState } from "react";
import { Box } from '@chakra-ui/react';
import { Lookup } from "../../../models/lookup";
import { SemesterAPI } from "../../../api/SemesterAPI";
import { TabRoutes } from "../../layout/TabRoutes";
import { useTeachersLookup } from "../../../hooks/useTeachersLookup";
import { HomeroomAPI } from "../../../api/HomeroomAPI";
import SkeletonTable from "../../table/TableSkeleton";
import { HomeroomList } from "../../classes/HomeroomList";
import { CourseList } from "../../classes/CourseList";
import { BlHeading } from "../../layout/Heading";
import { BlBreadcrumbs } from "../../layout/Breadcrumbs";

export const Markbook = React.memo(() => {
    const [semesters, set_semesters] = useState<Lookup[]>([]);
    const [defaultTabIndex, setDefaultTabIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const { teachers } = useTeachersLookup();

    const tabs = [
        {
            label: "Homerooms",
            path: "/markbook/homerooms",
            element: (
                <HomeroomList
                    teachers={teachers}
                    semesters={semesters}
                    base_url={"markbook"}
                />
            ),
        },
        {
            label: "Courses",
            path: "/markbook/courses",
            element: (
                <CourseList
                    teachers={teachers}
                    semesters={semesters}
                    base_url={"markbook"}
                />
            ),
        },
    ];

    useEffect(() => {
        SemesterAPI.semester_list()
            .then((res) => {
                let data = res!.semesters.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.name
                    }
                })
                set_semesters(data)
            })

        HomeroomAPI.homeroom_list(null, null, undefined, false)
            .then((res) => {
                // check if homeroom is empty => redirect to courses page
                if (!res.homerooms_count) setDefaultTabIndex(1) // courses page is in the tab 1
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <Box>
            <BlHeading> MarkBook </BlHeading>

            <BlBreadcrumbs />

            {
                loading ?
                    <SkeletonTable></SkeletonTable>
                    :
                    <TabRoutes tabs={tabs} defaultTabIndex={defaultTabIndex} />
            }
        </Box>
    );
});
