import { SemesterReportLayout } from "protobuffer-ts/dist/class_service/semester";
import { ObjectId as ProtoObjectId } from "protobuffer-ts/dist/utils/object_id"
import { ObjectId } from "../utils/ObjectId";

// Interface for the ReportLayout
export interface updateReportLayout {
    _id: ObjectId
    comment_char_limit: number
    sections: { id: ProtoObjectId, name: string }[]
    check_boxes: { id: ProtoObjectId, name: string }[]
    credit_weight: number
}


export const getLearningSkillsMap = (reportLayout? : SemesterReportLayout) => {
	const learningSkillsMap: { [key: string]: string } = {};

    reportLayout?.learningSkills.forEach((skill) => {
        if (skill.id) {
            learningSkillsMap[skill.id.id] = skill.title;
        }
    });

    return learningSkillsMap
}