import {ObjectId} from "../utils/ObjectId";
import {DateTime} from "../utils/DateTime";
export class Student {
    _id: ObjectId;
    id: string;
    status: StudentStatus;
    username: string;
    family_id: ObjectId;

    first_name: string;
    last_name: string;
    full_name: string; 
    gender: string;
    grade: string;
    date_of_birth: DateTime;

    registration_date: DateTime | null;
    admission_date: DateTime | null;

    interview_date: DateTime | null;

    constructor(data: any){
        console.log(data);
        
        this._id = data._id
        this.id = data.id
        this.status = data.status
        this.username = data.username
        this.family_id = data.family_id

        this.first_name = data.first_name
        this.last_name = data.last_name
        this.full_name = `${data.first_name} ${data.last_name}`;
        this.gender = data.gender
        this.grade = data.grade
        this.date_of_birth  = new DateTime(data.date_of_birth)

        this.registration_date = data.registration_date?  new DateTime(data.registration_date) : null
        this.admission_date = data.admission_date?  new DateTime(data.admission_date) : null

        this.interview_date = data.interview_date?  new DateTime(data.interview_date) : null
    }

    static getName(student?: Student) {
        return student?.first_name + " " + student?.last_name;
    }
}


export interface StudentProfileData {
    first_name: string;
    last_name: string;
    gender: string;
    grade: string;
    date_of_birth: Date;
}


export enum StudentStatus {
    Applicant = "Applicant",
    Processed = "Processed",
    Waitlist = "Waitlist",
    Interview = "Interview",
    Rejected = "Rejected",
    Enrolled = "Enrolled",
    ReRegistration = "ReRegistration",
    Withdrawn = "Withdrawn",
}


export const Grades = new Map<string, string>([
    ["Pre K", "Pre K"],
    ["Junior Kindergarten", "Junior Kindergarten"],
    ["Senior Kindergarten", "Senior Kindergarten"],
    ["Grade 1", "Grade 1"],
    ["Grade 2", "Grade 2"],
    ["Grade 3", "Grade 3"],
    ["Grade 4", "Grade 4"],
    ["Grade 5", "Grade 5"],
    ["Grade 6", "Grade 6"],
    ["Grade 7", "Grade 7"],
    ["Grade 8", "Grade 8"],
    ["Grade 9", "Grade 9"],
    ["Grade 10", "Grade 10"],
    ["Grade 11", "Grade 11"],
    ["Grade 12", "Grade 12"]
]);

export const validGrades = Array.from(Grades.entries()).filter(([_, gradeName]) => gradeName !== "");

export const getGradeKey = (grade : string) => Grades.has(grade)
? grade
: getGradeKeyFromValue(grade) || "";

export const getNextGrade = (grade : string) => {
    const gradeKeys = Array.from(Grades.keys());
    const currentIndex = gradeKeys.indexOf(grade);

    if (currentIndex === -1 || currentIndex === gradeKeys.length - 1) return ""; 

    return Grades.get(gradeKeys[currentIndex + 1]);
}

export const GradesArray = Array.from(Grades.values());

export const getGradeKeyFromValue = (searchValue: string): string | undefined => {
	for (let [key, value] of Grades.entries()) {
		if (value === searchValue) {
			return key
		}
	}
	return undefined // Return undefined if the value is not found
}


export const getIsHighSchool = (grade : string) => {
    const gradeKey = getGradeKey(grade) 

	return ["grade9", "grade10", "grade11", "grade12"].includes(gradeKey)

}

export enum ActionType {
    PayInvoice = "PayInvoice",
    FillFields = "FillFields",
    CorrectFields = "CorrectFields",
}
export interface ActionRequiredByParents {
    action_type: ActionType;
    title: string;
    invoice_id?: ObjectId;
    group_id?: ObjectId;
}