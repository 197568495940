import { ObjectId } from "../utils/ObjectId";
import { DateTime } from "../utils/DateTime";
import {UserType} from "../api/UserApi";
import { Student } from "./student";

export interface Attendance {
    _id: ObjectId,
    student: ObjectId,
    course: ObjectId | null,
    homeroom: ObjectId | null,
    date: DateTime,
    reason: string,
    sign_in_time: DateTime | null,
    sign_out_time: DateTime | null,
    status: Status,
    late_dismissal_date?: DateTime,
    student_excused_by?: ObjectId,
    student_excused_by_user_type?: UserType
    period_number: number
}

export interface AttendanceCount {
    course_id?: ObjectId, 
    homeroom_id?: ObjectId, 
    student_id: ObjectId, 
    start_date: string, 
    end_date: string
}

export enum Status {
  Present = "Present",
  Late = "Late",
  Absent = "Absent"
}

export const AttendanceColors: Record<string, string> = {
    'Present': 'green',
    'Late': 'orange',
    'Absent': 'red'
};


export interface PdfAttendanceCount {
    days_count:  { [key: string]: number }
    total_days_count:  { [key: string]: number }
}

export interface AttendanceTableData {
    student: Student,
    date: string,
    attendance_entry: Attendance,
    first_name: string,
    last_name: string,
    name: string
}

export interface AttendanceDateMap {
  [date: string]: "full" | "partial" | "empty";
}
  
export type AttendanceDateMapVec = AttendanceDateMap[];

export function mapAttendanceEntries(data: Attendance[],student: Student): AttendanceTableData[] {
    return data.map((entry) => ({
      date: entry.date
        ? new DateTime(entry.date as any).to_utc_string_date()
        : "No Date",
      student,
      attendance_entry: entry,
      first_name: student.first_name,
      last_name: student.last_name,
      name: `${student.first_name} ${student.last_name}`,
    }));
  }

export interface StudentOverview {
  student: Student,
  overview: Record<Status, number>;
};

export type AttendnaceOverview = StudentOverview[]
