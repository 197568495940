import {
    Box,
    Flex,
    SimpleGrid,
    Stack,
    VStack,
    Skeleton,
    Text,
    Icon,
    HStack,
    Spacer,
    Switch
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { OnboardingSettings } from "protobuffer-ts/dist/organization_service/onboarding_settings";
import { ProfileSection } from "protobuffer-ts/dist/organization_service/organization_profile_settings";
import { OnboardingSettingsAPI } from "../../../api/OnboardingSettingsAPI";
import { OrganizationProfileSettingsAPI } from "../../../api/OrganizationProfileSettingsAPI";
import { StudentAPI } from "../../../api/StudentAPI";
import { useToaster } from "../../../hooks/useToaster";
import { Student, StudentStatus } from "../../../models/student";
import { ObjectId } from "../../../utils/ObjectId";
import { BlCard } from "../../layout/Card";
import { TablePagination } from "../../table/TablePagination";
import { HiOutlineClock, HiOutlineCurrencyDollar, HiOutlineExclamationCircle } from "react-icons/hi";
import { ApprovalStatus } from "../../../models/custom_fields_group";
import { ApplicantStudentCard } from "../onboarding/ApplicantStudentCard";
import EmptyPlaceholder from "../../layout/EmptyPlaceholder";

interface ReregistrationStudentsProps {
    onStudentCountUpdate: () => void;
}

export const ReregistrationStudents = ({ onStudentCountUpdate }: ReregistrationStudentsProps) => {
    const { showErrorToast } = useToaster();
    const PER_PAGE = 5;

    const [students, setStudents] = useState<Student[]>([]);
    const [page, setPage] = useState(1);
    const [totalStudents, setTotalStudents] = useState(0);
    const [profileSections, setProfileSections] = useState<ProfileSection[]>([]);
    const [onboardingSettings, setOnboardingSettings] = useState<OnboardingSettings>();

    const [loadingStudents, setLoadingStudents] = useState(true);
    const [loading, setLoading] = useState(true);

    const [showOnlyComplete, setShowOnlyComplete] = useState(true);
    const [showOnlyPaid, setShowOnlyPaid] = useState(false);

    const [incompleteCount, setIncompleteCount] = useState(0);
    const [notPaidCount, setnotPaidCount] = useState(0);
    const [completeAndPaidCount, setCompleteAndPaidCount] = useState(0);

    const handleStudentAction = (studentId: ObjectId) => {
        setStudents(prevStudents => prevStudents.filter(student => student._id.$oid !== studentId.$oid));
        setTotalStudents(prevTotal => prevTotal - 1);
        fetchOrganizationData();
        onStudentCountUpdate();
    };

    const [filter, setFilter] = useState<StudentAPI.StudentsFilterParams>({
        per_page: PER_PAGE,
        page: 1,
        statuses: [StudentStatus.ReRegistration],
        filled_profile_sections: undefined,
        no_nonpaid_registration: false,
        no_nonpaid_reregistration: true,
        sort_enrolled_family_first: true,
    });

    useEffect(() => {
        setFilter(prev => ({
            ...prev,
            page: page,
        }));
    }, [page]);

    useEffect(() => {
        setFilter(prev => ({
            ...prev,
            filled_profile_sections: showOnlyComplete ? profileSections : undefined,
        }));
    }, [showOnlyComplete, profileSections]);

    useEffect(() => {
        setFilter(prev => ({
            ...prev,
            no_nonpaid_reregistration: showOnlyPaid,
        }));
    }, [showOnlyPaid]);

    const fetchOrganizationData = async () => {
        setLoading(true);
        try {
            const [profileSettings, onboardingSettings] = await Promise.all([
                OrganizationProfileSettingsAPI.get_organization_profile_settings(),
                OnboardingSettingsAPI.get_onboarding_settings()
            ]);

            setProfileSections(profileSettings.studentProfileSections);
            setOnboardingSettings(onboardingSettings);

            const [completedFields, paidCount, completeAndPaid, totalReRegistration] = await Promise.all([
                StudentAPI.count_students({
                    statuses: [StudentStatus.ReRegistration],
                    all_groups_status: [ApprovalStatus.Approved, ApprovalStatus.PendingReview],
                }),

                StudentAPI.count_students({
                    statuses: [StudentStatus.ReRegistration],
                    "exclude_not_all_reregistraion_fees_paid": true,
                }),

                StudentAPI.count_students({
                    statuses: [StudentStatus.ReRegistration],
                    all_groups_status: [ApprovalStatus.Approved, ApprovalStatus.PendingReview],
                    "exclude_not_all_reregistraion_fees_paid": true,
                }),
                StudentAPI.count_students({
                    statuses: [StudentStatus.ReRegistration],
                }),
            ]);

            setIncompleteCount(totalReRegistration - completedFields);
            setnotPaidCount(totalReRegistration - paidCount);
            setCompleteAndPaidCount(completeAndPaid);
        } catch (error: any) {
            showErrorToast(error.message || "Failed to load data");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoadingStudents(true);
        StudentAPI.filtered_students_list(filter)
            .then((res) => {
                if (res) {
                    setStudents(res.students);
                    setTotalStudents(res.students_count);
                }
                setLoadingStudents(false);
            })
            .catch((e) => {
                setLoadingStudents(false); 
                console.error(e);
            });
    }, [filter]);

    useEffect(() => {
        fetchOrganizationData();
    }, []);

    if (loading) {
        return (
            <BlCard>
                <VStack spacing={4} align="stretch">
                    {[...Array(3)].map((_, i) => (
                        <Skeleton key={i} height="100px" borderRadius="md" />
                    ))}
                </VStack>
            </BlCard>
        );
    }

    if (!onboardingSettings) {
        return null;
    }

    return (
        <BlCard>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap={4} mb={6}>
                <Box as={HStack} bgColor="yellow.50" border="2px" borderColor="yellow.500" borderStyle="dashed" rounded="lg" px={8} py={4}>
                    <div>
                        <Text fontWeight="black" fontSize="3xl" color="yellow.500">{completeAndPaidCount}</Text>
                        <Text fontWeight="medium" textColor="gray.600">Waiting Admin Review</Text>
                    </div>
                    <Spacer />
                    <Icon as={HiOutlineClock} boxSize={12} color="yellow.500" />
                </Box>

                <Box as={HStack} bgColor="red.50" border="2px" borderColor="red.500" borderStyle="dashed" rounded="lg" px={8} py={4}>
                    <div>
                        <Text fontWeight="black" fontSize="3xl" color="red.500">{incompleteCount}</Text>
                        <Text fontWeight="medium" textColor="gray.600">Not Completed By Parents</Text>
                    </div>
                    <Spacer />
                    <Icon as={HiOutlineExclamationCircle} boxSize={12} color="red.500" />
                </Box>

                <Box as={HStack} bgColor="green.50" border="2px" borderColor="green.500" borderStyle="dashed" rounded="lg" px={8} py={4}>
                    <div>
                        <Text fontWeight="black" fontSize="3xl" color="green.500">{notPaidCount}</Text>
                        <Text fontWeight="medium" textColor="gray.600">Fees Not Paid</Text>
                    </div>
                    <Spacer />
                    <Icon as={HiOutlineCurrencyDollar} boxSize={12} color="green.500" />
                </Box>
            </SimpleGrid>

            <Flex direction={{ base: 'column', md: 'row' }} gap="2">
                <Switch isChecked={showOnlyComplete} onChange={(e) => setShowOnlyComplete(e.target.checked)}>
                    Show only completed reregistrations
                </Switch>
                <Switch isChecked={showOnlyPaid} onChange={(e) => setShowOnlyPaid(e.target.checked)}>
                    Show only paid reregistrations
                </Switch>
            </Flex>

            <Spacer />

            <SimpleGrid columns={12} spacing={6}>
                <Box gridColumn="span 12">
                    <Stack spacing={4}>
                        {loadingStudents ? (
                            <VStack spacing={4} align="stretch">
                                {[...Array(PER_PAGE)].map((_, i) => (
                                    <Skeleton key={i} height="100px" borderRadius="md" />
                                ))}
                            </VStack>
                        ) : students.length === 0 ? (
                            <EmptyPlaceholder message="No items to show" />
                        ) : (
                            <>
                                <VStack spacing={4} align="stretch">
                                    {students.map((student) => (
                                        <ApplicantStudentCard
                                            onboardingSettings={onboardingSettings}
                                            onStudentAction={handleStudentAction}
                                            key={student._id.$oid}
                                            student={student}
                                            profileSections={profileSections}
                                        />
                                    ))}
                                </VStack>

                                <Box mt={6}>
                                    <TablePagination
                                        perPage={PER_PAGE}
                                        page={page}
                                        total={totalStudents}
                                        onPageChange={setPage}
                                    />
                                </Box>
                            </>
                        )}
                    </Stack>
                </Box>
            </SimpleGrid>
        </BlCard>
    );
};
