import React from 'react';
import { FormControl, FormLabel, Select, FormErrorMessage } from '@chakra-ui/react';
import { UseFormRegisterReturn } from 'react-hook-form';
import './styles/form-input.css';

interface FormSelectProps {
    name: string;
    label: string;
    options: string[];  // Array of options for the Select
    register: UseFormRegisterReturn;
    error?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
}

const FormSelect: React.FC<FormSelectProps> = ({ name, label, options, register, error, isDisabled = false, isRequired= false }) => {
    return (
        <FormControl isInvalid={!!error}>
            <FormLabel htmlFor={name} className={isRequired ? 'required' : ''}>{label}</FormLabel>

            <Select
                bgColor="white"
                id={name}
                {...register}  // Spread the register object directly into the Select
                isDisabled={isDisabled}
            >
                {options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </Select>
            <FormErrorMessage>
                {error}
            </FormErrorMessage>
        </FormControl>
    );
};

export default FormSelect;
