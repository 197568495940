import {
    Box,
    Button,
    HStack,
    Stack,
    Text,
    Input,
    IconButton,
    Heading
} from "@chakra-ui/react";
import { AttachmentIcon, AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { AWSFile } from "protobuffer-ts/dist/utils/aws_file";
import { BlCard } from "../layout/Card";
import { useToaster } from "../../hooks/useToaster";

interface DocumentUploadSectionProps {
    title: string;
    initialFiles: AWSFile[];
    onUpload: (file: File, file_name: string) => Promise<AWSFile[]>;
    onDelete: (file_index: number) => Promise<AWSFile[]>;
}

export const DocumentUploadSection = ({ title, initialFiles, onUpload, onDelete }: DocumentUploadSectionProps) => {
    const { showErrorToast, showSuccessToast } = useToaster();
    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState<AWSFile[]>(initialFiles);

    const handleFileUpload = async (file: File) => {
        try {
            const updatedFiles = await onUpload(file, file.name);
            setFiles(updatedFiles);
            showSuccessToast("File Uploaded Successfully!");
        } catch (error: any) {
            showErrorToast(`Upload Failed: ${error?.message || "Unknown error"}`);
        }
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
        if (selectedFiles.length === 0) return;

        setUploading(true);
        try {
            for (const file of selectedFiles) {
                await handleFileUpload(file);
            }
        } finally {
            setUploading(false);
        }
    };

    const handleRemoveFile = async (index: number) => {
        try {
            const updatedFiles = await onDelete(index);
            setFiles(updatedFiles);
            showSuccessToast("File Removed Successfully!");
        } catch (error) {
            console.error("Removal error:", error);
            showErrorToast("Removal Failed. Please try again.");
        }
    };

    return (
        <BlCard>

            <Heading size="md" mb={6}>
                {title}
            </Heading>

            <Stack spacing={4}>
                <Box>
                    <Stack spacing={2}>
                        {files.map((file, index) => (
                            <Box
                                key={`${file.preSignedUrl}-${index}`}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={3}
                                borderRadius="md"
                                bg="blue.100"
                                cursor="pointer"
                                border="2px dashed blue.400"
                                _hover={{ borderColor: "white.600", bg: "blue.200" }}
                                onClick={() => window.open(file.preSignedUrl, '_blank')}
                            >
                                <HStack>
                                    <AttachmentIcon color="blue.600" />
                                    <Text fontWeight="medium" color="blue.800">
                                        {file.fileName}
                                    </Text>
                                </HStack>
                                <HStack spacing={2}>
                                    <IconButton
                                        aria-label="Remove document"
                                        icon={<CloseIcon />}
                                        size="xs"
                                        variant="ghost"
                                        colorScheme="red"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRemoveFile(index);
                                        }}
                                    />
                                </HStack>
                            </Box>
                        ))}
                    </Stack>
                </Box>

                <Box position="relative" display="inline-block" width="full">
                    <Button
                        as="label"
                        leftIcon={<AddIcon />}
                        colorScheme="blue"
                        variant="solid"
                        isLoading={uploading}
                        loadingText="Uploading..."
                        cursor={uploading ? 'wait' : 'pointer'}
                    >
                        Upload Files
                        <Input
                            type="file"
                            multiple
                            onChange={handleFileChange}
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            opacity="0"
                            cursor="pointer"
                            pointerEvents={uploading ? 'none' : 'auto'}
                            disabled={uploading}
                        />
                    </Button>
                </Box>
            </Stack>
        </BlCard>
    );
};
