import { useMemo } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { getInvoiceUserInfo, Invoice, InvoiceResponse, InvoiceStatus, InvoiceStatusColor, InvoiceUserCategoryColor } from '../../models/invoice';
import { TableContent } from '../table/TableContent';
import { useChipCell } from '../../hooks/useChipCell';
import { convertCaseToNormal } from '../../utils/helpers';

interface InvoicesTableProps {
	loading?: boolean
	invoices?: Array<InvoiceResponse>
	showBillToColunm?: boolean
}

export const InvoicesTable = ({ invoices = [], loading, showBillToColunm = false }: InvoicesTableProps) => {
	const columns = useMemo(() => {
		let _columns = [{
			Header: 'Number',
			accessor: 'invoice',
			Cell: (invoice: Invoice) => invoice.invoice_number,
		},
		{
			Header: 'Title',
			accessor: 'invoice',
			Cell: (invoice: Invoice) => invoice.title,
		},
		...(showBillToColunm && [{
			Header: 'Bill to',
			accessor: 'invoice',
			Cell: (invoice: Invoice, row: InvoiceResponse) => {
			  const { userName, userCategory } = getInvoiceUserInfo(invoice, row.student, row.family);
			  return (
				<HStack>
				  <Text>{userName}</Text>

				  {useChipCell(userCategory, InvoiceUserCategoryColor[userCategory])}
				</HStack>
			  );
			},
		  }] || []),
		{
			Header: 'Status',
			accessor: 'status',
			Cell: (value: InvoiceStatus) => useChipCell(convertCaseToNormal(value), InvoiceStatusColor[value]),
		},
		{
			Header: 'Total Amount',
			accessor: 'total_amount',
			Cell: (value: number) => (
				<Text fontWeight="bold">
					{`$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
				</Text>
			)
		},
		{
			Header: 'Total Paid',
			accessor: 'total_amount_paid',
			Cell: (value: number) => (
				<Text fontWeight="bold" color="green">
					{`$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
				</Text>
			)
		}]

		return _columns
	}, [])



	return (
		<>
			<TableContent data={invoices} columns={columns} loading={loading} />
		</>
	);
};
