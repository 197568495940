import * as React from "react";
import { CardWizard } from "./CardWizard";
import { WizardCard } from "./WizardCard";
import { Center, Skeleton, Spinner, Stack } from '@chakra-ui/react';
import { useNavigate, useParams } from "react-router-dom";
import { to_oid } from "../../../utils/ObjectId";
import { ActionRequiredByParents, ActionType } from "../../../models/student";
import { useEffect, useState } from "react";
import { CustomFieldsGroupAPI } from "../../../api/CustomFieldsGroupAPI";
import { UserType } from "../../../api/UserApi";
import { ApprovalStatus, CustomFieldsGroup } from "../../../models/custom_fields_group";
import { CustomFieldsGroupDisplay } from "../../custom_fields/CustomFieldsGroupDisplay";
import { useToaster } from "../../../hooks/useToaster";
import { InvoiceDetailsParentView } from "../../invoice/InvoiceDetailsParentView";
import { StudentAPI } from "../../../api/StudentAPI";


export const ParentCompleteActionsCards: React.FC = () => {
    const { student_id } = useParams<{ student_id: string }>();
    const { showErrorToast } = useToaster()
    const navigate = useNavigate()

    const [requiredAction, setRequiredAction] = useState<ActionRequiredByParents>();
    const [remainingActions, setRemainingActions] = useState<number>();
    const [groupCompletionStatus, setGroupCompletionStatus] = useState(false);
    const [groups, setGroups] = useState<CustomFieldsGroup[]>();
    const [loading, setLoading] = useState(false);

    const handleGroupStatusUpdate = (_groupId: any, status: ApprovalStatus) => {
        setGroupCompletionStatus(status !== ApprovalStatus.Rejected && status !== ApprovalStatus.RequiredToFill)
    };

    const fetchActionData = async () => {
        try {
            setLoading(true);
            let actions = await StudentAPI.actions_required_by_parents(student_id!);
            
            if (!actions.length) {
                navigate(`/parent/student/${student_id!}`); 
                return;
            }
            setRequiredAction(actions[0]);
            setRemainingActions(actions.length);
            setGroupCompletionStatus(false);

            if (!groups) {
                CustomFieldsGroupAPI.get_custom_fields_groups_for_user_type(UserType.Student).then(
                    groups => setGroups(groups)
                )
            }
        } catch (err: any) {
            showErrorToast(err);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchActionData()
    }, []);

    if (loading || !requiredAction || !groups || !remainingActions) {
        return <Center>
            <Spinner />
        </Center>
    }

    return (
        <CardWizard
            onCompleteStep={fetchActionData}
            remainingSteps={remainingActions}
            step={requiredAction.action_type === ActionType.PayInvoice
                ? {
                    id: requiredAction.invoice_id!.$oid,
                    title: requiredAction.title,
                    component: (
                        <WizardCard>
                            <InvoiceDetailsParentView
                                invoice_id={requiredAction.invoice_id!.$oid}
                                wrapInCard={true}
                                redirect_url={`/parent/student/${student_id}/complete_required_actions`}
                            />
                        </WizardCard>
                    ),
                    enableNext: true,
                }
                : {
                    id: requiredAction.group_id!.$oid,
                    title: requiredAction.title,
                    component: (
                        <WizardCard>
                            <CustomFieldsGroupDisplay
                                group={groups.find((group) => group._id.$oid === requiredAction.group_id?.$oid)!}
                                userId={to_oid(student_id!)}
                                onGroupStatusUpdate={handleGroupStatusUpdate}
                            />
                        </WizardCard>
                    ),
                    enableNext: groupCompletionStatus,
                }
            }
        />
    );

}
