import HTTPApi from "./ApiCommon";
import {
    Student,
    StudentStatus,
    StudentProfileData,
    ActionRequiredByParents,
} from "../models/student";
import { ObjectId } from "../utils/ObjectId";
import { DateTime } from "../utils/DateTime";
import { StudentIncident } from "../models/student_incident";
import { GClassStudentSubmission } from "protobuffer-ts/dist/class_service/gclass";
import { Homeroom } from "protobuffer-ts/dist/class_service/homeroom";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { ApprovalStatus } from "../models/custom_fields_group";
import { InvoiceStatus } from "../models/invoice";
import { Semester } from "protobuffer-ts/dist/class_service/semester";
import { ProfileSection } from "protobuffer-ts/dist/organization_service/organization_profile_settings";

export namespace StudentAPI {
    export interface StudentList {
        students: Array<Student>,
        students_count: number
    }

    export interface CountStudentsRequest {
        statuses: StudentStatus[];
        all_groups_status?: ApprovalStatus[], 
        no_group_status?: ApprovalStatus[],
        exclude_not_all_registraion_fees_paid?: boolean, 
        exclude_not_all_reregistraion_fees_paid?: boolean,
    }

    export interface StudentsFilterParams {
        per_page: number,
        page: number,
        grade?: string,
        gender?: string,
        statuses: StudentStatus[],
        no_nonpaid_registration: boolean,
        no_nonpaid_reregistration: boolean,
        sort_enrolled_family_first: boolean,
        
        // Exclude the student if has a GroupApprovalStatus status equal to RequiredToFill or Rejected for any CustomFieldsGroup that belong to one of these profile sections.
        filled_profile_sections?: ProfileSection[],
    }

    export const students_list = async (
            per_page: number | undefined = undefined, 
            page: number | undefined = undefined, 
            name_search: string | null = null, 
            status: StudentStatus[] | undefined = undefined,
            course_id: ObjectId | undefined = undefined,
            homeroom_id: ObjectId | undefined = undefined
        ): Promise<StudentList | void> => {

        return HTTPApi.post(
                "students/students_list", {
                    per_page: per_page,
                    page: page,
                    name_search: name_search,
                    status: status,
                    homeroom_id: homeroom_id,
                    course_id: course_id
                })
                .then((res) => {
                    return res.data as StudentList
                })
                .catch((e) => {
                    console.log(e);
                })
    }


    export const filtered_students_list = async (
        params: StudentsFilterParams
    ): Promise<StudentList> => {
        const res = await HTTPApi.post(
            "students/filtered_students_list",
            params
        ); 

        return res.data;
    }

    export const students_by_ids = async (students : ObjectId[]): Promise<Student[]> => {
        return HTTPApi.post(
                "students/students_by_ids", { students })
                .then((res) => {
                    return res.data.map((studentData: any) => new Student(studentData));
                })
    }

    export const get_student = async (student_id: string): Promise<Student> => {
        const res = await HTTPApi.get("/students/" + student_id); 
        return new Student(res.data);
    }

    export const get_students = async (students: Array<ObjectId>) => {
        return HTTPApi.post(
            "students/get_students", {
                students: students,
            }
        )
        .then((res) => {
            let data = res!.data
            let students: Array<Student> = data.map((e: any) => new Student(e))
            return students
        })
    }


    export const update_profile = async (student_id: ObjectId, update_profile: StudentProfileData) => {
        return HTTPApi.post(
            "students/update_profile", {
                _id: student_id,
                ...update_profile
            }
        )
    }

    export const get_semesters = async (student_id: ObjectId, include_archived?: boolean) => {
        const res = await HTTPApi.post(
            "students/semesters", {
                student: student_id,
                include_archived: include_archived
            })
              
        return res.data as Semester[]
    }

    export const get_courses = async (student_id: ObjectId, include_archived?: boolean) => {
        return HTTPApi.post(
            "students/courses", {
                student: student_id,
                include_archived: include_archived
            }).then((res) => {
                return res.data as Course[]
            })
    }

    export const get_homerooms = async (student_id: ObjectId, include_archived?: boolean) => {
        return HTTPApi.post(
            "students/homerooms", {
                student: student_id,
                include_archived: include_archived
            }).then((res) => {
                return res.data as Homeroom[]
            })
    }

    export const get_gclass_course_submissions = async (student: ObjectId, course: ObjectId): Promise<GClassStudentSubmission[]> => {
        return HTTPApi.post(
            "students/get_gclass_course_submissions", {
                student, 
                course
            }).then((res) => {
                return res.data.map((e: any) => GClassStudentSubmission.fromJSON(e));
            })
    }
    
    export const get_gclass_homeroom_submissions = async (student: ObjectId, homeroom: ObjectId) : Promise<GClassStudentSubmission[]> => {
        return HTTPApi.post(
            "students/get_gclass_homeroom_submissions", {
                student, 
                homeroom
            }).then((res) => {
                return res.data.map((e: any) => GClassStudentSubmission.fromJSON(e));
            })
    }


    export const re_register = async (student_id: ObjectId) => {
        return HTTPApi.post(
            "students/re_register", {
                _id: student_id
            }
        )
    }

    export const change_password = async (student_id: ObjectId, password: string, change_password_at_next_login: boolean) => {
        return HTTPApi.post(
            "students/change_password", {
                _id: student_id,
                password: password,
                change_password_at_next_login: change_password_at_next_login
            }
        )
    }


    export const withdraw = async (student_id: ObjectId, withdraw_message: string): Promise<Student>  => {
        return HTTPApi.post(
            "students/withdraw", { student_id, withdraw_message }
        ).then(res => {
            return res.data as Student;
        })
    }

    export const reject = async (student_id: ObjectId, rejection_message: string): Promise<Student>  => {
        return HTTPApi.post(
            "students/reject", { student_id, rejection_message }
        ).then(res => {
            return res.data as Student;
        })
    }

    export const to_interview = async (student_id: ObjectId, interview_message: string, interview_date: Date): Promise<Student> => {
        return HTTPApi.post(
            "students/to_interview", { student_id, interview_message, interview_date }
        ).then(res => {
            return res.data as Student;
        })
    }
    
    export const approve = async (student_id: ObjectId, approval_message: string): Promise<Student>  => {
        return HTTPApi.post(
            "students/approve", { student_id, approval_message }
        ).then(res => {
            return res.data as Student;
        })
    }

    export const enroll = async (student_id: ObjectId, enrollment_message: string): Promise<Student>  => {
        return HTTPApi.post(
            "students/enroll", { student_id, enrollment_message }
        ).then(res => {
            return res.data as Student;
        })
    }

    export const requireFeildsChangesFromParents = async (student_id: ObjectId, message: string): Promise<Student>  => {
        return HTTPApi.post(
            "students/require_fields_changes_from_parents", { student_id, message }
        ).then(res => {
            return res.data as Student;
        })
    }

    export interface CreateStudentParams {
        first_name: string, 
        last_name: string,
        grade: string,
        gender: string, 
        family_id: ObjectId, 
        date_of_birth: DateTime,
    }
    
    export const new_student = async (params: CreateStudentParams): Promise<Student> => {
        const res = await HTTPApi.post(
            "students/new", params
        )

        return res.data;
    }

    export const create_student_incidents = async (student_id: ObjectId, title: string, date: DateTime) => {
        return HTTPApi.post(
            "student_incident/create_student_incidents", {
                student_id: student_id,
                title: title,
                date: date.to_date()
            }
        ).then((res) => {
            return new StudentIncident(res.data)
        })
    }

    export const update_student_incidents = async (incident: StudentIncident) => {
        return HTTPApi.post(
            "student_incident/update_student_incidents", {
                incident: incident
            }
        ).then((res) => {
            return new StudentIncident(res.data)
        })
    }

    export const get_student_incidents = async (student_id: ObjectId) => {
        return HTTPApi.post(
            "student_incident/get_student_incidents", {
                _id: student_id
            }
        ).then((res) => {
            return (res.data as Array<StudentIncident>).map((e) => new StudentIncident(e))
        })
    }
    
    export const count_students = async (params: CountStudentsRequest): Promise<number> => {
        return HTTPApi.post("students/count_students", params)
            .then((res) => res.data as number);
    };

    export const actions_required_by_parents = async (student_id: string): Promise<ActionRequiredByParents[]> => {
        const res = await HTTPApi.get("/students/required_actions_by_parents/" + student_id);
        return res.data;
    }
}