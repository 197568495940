import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Stack,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { ObjectId } from '../../utils/ObjectId';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PaymentAPI } from '../../api/PaymentAPI';
import { createManualTransactionSchema } from '../../validations/payment';
import { ManualTransaction, ManualPaymentTypeValues, Transaction } from '../../models/transaction';
import FormInput from '../forms/FormInput';
import FormSelect from '../forms/FormSelect';
import { useToaster } from '../../hooks/useToaster';

interface AddTransactionDialogProps {
	invoice_id: ObjectId
	isOpen: boolean
	onClose: (Transaction?: Transaction) => void
}

export default React.memo(({ invoice_id, isOpen, onClose }: AddTransactionDialogProps) => {
	const { showErrorToast } = useToaster()

	const [loading, setLoading] = React.useState(false)
	const cancelRef = useRef<HTMLButtonElement>(null)

	const { register, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(createManualTransactionSchema),
		defaultValues: { invoice_id }
	});

	const onSubmit = (payload: ManualTransaction) => {
		setLoading(true)

		PaymentAPI.create_manual_transaction(payload)
			.then((transaction) => {
				setLoading(false)
				onClose(transaction)
			})
			.catch(() => {
				setLoading(false)

				showErrorToast('An error occurred while creating Transaction')
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent as="form" onSubmit={handleSubmit(onSubmit)} minW={{ lg: 450 }}>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Add Transaction
					</AlertDialogHeader>

					<AlertDialogBody gap={4}>
						<Stack gap="4">
							<FormSelect
								name="payment_type"
								label="Payment Type"
								options={ManualPaymentTypeValues}
								register={register('payment_type')}
								error={errors.payment_type?.message}
								isRequired
							/>

							<FormInput
								name="amount"
								label="Amount"
								register={register('amount')}
								error={errors.amount?.message}
								placeholder='Enter Amount'
								isRequired
							/>
						</Stack>
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={() => onClose()}>
							Cancel
						</Button>

						<Button colorScheme='green' ml={3} isLoading={loading} type="submit">
							Add
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
})
