import { DateTime } from "../utils/DateTime"
import { ObjectId } from "../utils/ObjectId"

export interface Item {
    name: string,
    price: number
}

export interface Transaction {
    items: Array<Item>
    status: PaymentStatus
    date: DateTime
    description: string
}

export enum PaymentStatus {
    Created = "Created",
    Declined = "Declined",
    Paid = "Paid"
}

export enum PaymentType {
    Stripe = "Stripe",
    Cash = "Cash",
    Cheque = "Cheque",
    Card = "Card",
    Other = "Other"
}

export const PaymentTypeValues = Object.values(PaymentType)

export const ManualPaymentTypeValues = PaymentTypeValues.filter(type => type !== PaymentType.Stripe)

export const PaymentTypeColors: Record<PaymentType, string> = {
    [PaymentType.Stripe]: "blue",
    [PaymentType.Cash]: "green",
    [PaymentType.Cheque]: "red",
    [PaymentType.Card]: "purple",
    [PaymentType.Other]: "orange"
}


export const PaymentStatusColor: Record<PaymentStatus, string> = {
    [PaymentStatus.Created]: "blue",
    [PaymentStatus.Declined]: "red",
    [PaymentStatus.Paid]: "green"
}

export class Transaction {
    currency: string
    status: PaymentStatus
    payment_type: PaymentType
    date: DateTime
    amount: number
    constructor(data: any){
        this.currency = data.currency || "CAD"
        this.payment_type = data.payment_type
        this.status = data.status
        this.amount = data.amount
        this.date = new DateTime(data.date)
    }
}

export interface ManualTransaction {
    payment_type: PaymentType, 
    invoice_id: ObjectId,
    amount: number,
}