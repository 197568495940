import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Stack } from "@chakra-ui/react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { UserRole } from "../../api/UserApi";
import { AccessPermissionType, AccessRule, AccessRuleFormValues, formSelectionOptions, principalType, WildcardAccess } from "../../models/resource_access_management";
import FormSelect from "../forms/FormSelect";




interface AddAccessRuleDialogProps {
    isOpen: boolean;
    onClose: (rule?: AccessRule) => void;
    permissionType: AccessPermissionType;
}

export const AddAccessRuleDialog: React.FC<AddAccessRuleDialogProps> = ({ isOpen, onClose, permissionType }) => {
    const cancelRef = useRef<HTMLButtonElement>(null);

    const { register, handleSubmit, watch } = useForm<AccessRuleFormValues>({
        defaultValues: { selection: 'user' },
    });


    const selection = watch('selection');

    const onSubmit = (data: AccessRuleFormValues) => {
        let rule: AccessRule;
        if (data.selection === "user") {
          rule = {
            permission_type: permissionType,
            principal: principalType.User,
          };
        } else if (data.selection === "family") {
          rule = {
            permission_type: permissionType,
            principal: principalType.Family,
          };
        } else if (data.selection === "role") {
          rule = {
            permission_type: permissionType,
            wildcard: { Role: data.roleValue as UserRole },
          };
        } else if (
          data.selection === "AllPrincipals" ||
          data.selection === "AllStudentPrincipals" ||
          data.selection === "AllTeacherPrincipals" ||
          data.selection === "AllParentPrincipals"
        ) {
          rule = {
            permission_type: permissionType,
            wildcard: data.selection as WildcardAccess,
          };
        } else {
          rule = { permission_type: permissionType };
        }
        onClose(rule);
    };

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={() => onClose()}>
            <AlertDialogOverlay>
                <AlertDialogContent as="form" onSubmit={handleSubmit(onSubmit)} minW="450px">
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Add {AccessPermissionType[permissionType]} Rule
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Stack spacing="4">
                            <FormSelect
                                name="field_type"
                                label="Add Rule For"
                                options={formSelectionOptions}
                                register={register('selection')}
                            />

                            {selection === 'role' && <FormSelect
                                name="role_value"
                                label="Role"
                                options={Object.values(UserRole)}
                                register={register('roleValue')}
                            />}
                        </Stack>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={() => onClose()}>
                            Cancel
                        </Button>
                        <Button colorScheme="green" ml={3} type="submit">
                            Add
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
