import { useState, useEffect } from 'react';
import { getInvoiceUserInfo, Invoice, InvoiceResponse, InvoiceStatus, InvoiceStatusColor, InvoiceUserCategory, InvoiceUserCategoryColor } from '../../models/invoice';
import { InvoiceAPI } from '../../api/InvoiceAPI';
import { Heading, HStack, Text } from '@chakra-ui/react';
import { ParentAPI } from '../../api/ParentAPI';
import { useChipCell } from '../../hooks/useChipCell';
import { convertCaseToNormal } from '../../utils/helpers';
import { TableContent } from '../table/TableContent';
import { Student } from '../../models/student';
import { FamilyAPI } from '../../api/FamilyAPI';
import { Family } from '../../models/family';

export const InvoiceListParentView = () => {
	const [invoices, setInvoices] = useState<InvoiceResponse[]>([]);
	const [students, setStudents] = useState<Student[]>([]);
	const [families, setFamilies] = useState<Family[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		const fetchInvoices = async () => {
			setLoading(true);

			try {
				let [invoicesRes, studentsRes, familiesRes] = await Promise.all([
					InvoiceAPI.list_for_parent(),
					ParentAPI.all_students(),
					FamilyAPI.get_parent_families()
				])

				setStudents(studentsRes)
				setFamilies(familiesRes);

				invoicesRes = invoicesRes.map(i => ({ ...i, link: `/parent/invoices/${i.invoice._id.$oid}` }))

				setInvoices(invoicesRes);
			} catch (err) {
				console.error('Error fetching invoices:', err);
				setInvoices([]);
			} finally {
				setLoading(false);
			}
		};

		fetchInvoices();
	}, []);

	const columns = [
		{
			Header: 'Number',
			accessor: 'invoice',
			Cell: (invoice: Invoice) => invoice.invoice_number,
		},
		{
			Header: 'Bill to',
			accessor: 'invoice',
			Cell: (invoice: Invoice) => {
				const student = students.find(s => s._id.$oid === invoice.user?.$oid);
				const family = families.find(fm => fm._id.$oid === invoice.family?.$oid);
			  
				let { userName, userCategory } = getInvoiceUserInfo(invoice, student, family);
			  
				return (
					<HStack>
						<Text>{userName}</Text>

						{useChipCell(userCategory, InvoiceUserCategoryColor[userCategory])}
					</HStack>
				)
			}			  
		},
		{
			Header: 'Title',
			accessor: 'invoice',
			Cell: (invoice: Invoice) => invoice.title,

		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: (value: InvoiceStatus) => useChipCell(convertCaseToNormal(value), InvoiceStatusColor[value]),
		},
		{
			Header: 'Total Amount',
			accessor: 'total_amount',
			Cell: (value: number) => (
				<Text fontWeight="bold">
					{`$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
				</Text>
			)
		},
		{
			Header: 'Total Paid',
			accessor: 'total_amount_paid',
			Cell: (value: number) => (
				<Text fontWeight="bold" color="green">
					{`$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
				</Text>
			)
		},
	]

	return (
		<>
			<Heading size="md" mb="6" textAlign="center">
				Invoices
			</Heading>

			<TableContent data={invoices} columns={columns} loading={loading} />
		</>
	);
};
