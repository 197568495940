import React, { useState, useEffect } from "react";
import { Stack, HStack, Badge } from "@chakra-ui/react";
import { StudentAPI } from "../../api/StudentAPI";
import { ObjectId } from "../../utils/ObjectId";
import { StudentStatus } from "../../models/student";
import { TablePagination } from "../table/TablePagination";
import { TableContent } from "../table/TableContent";
import SearchInput from "../forms/SearchInput";
import { BlBreadcrumbs } from "../layout/Breadcrumbs";

interface Card {
    status: StudentStatus;
    course_id?: ObjectId;
    homeroom_id?: ObjectId;
    setHeading?: (heading: string) => void;
}

export const StudentsList = React.memo(
    ({
         status,
         course_id,
         homeroom_id,
         setHeading
     }: Card) => {

        const PER_PAGE = 15;

        const [students_data, set_students_data] = useState<Array<StudentsObj>>([]);

        const [page, set_page] = useState<number>(1);
        const [loading, setLoading] = useState<boolean>(true);
        const [total_students, set_total_students] = useState<number>();
        const [name_search, set_name_search] = useState<string>("");

        // refresh title after returning from Student Profile
        setHeading && setHeading("Students")

        let StudentColumns = [
                {
                    Header: "Name",
                    accessor: "name",
                },
                {
                    Header: "Status",
                    accessor: "status",
                    Cell: function StatusCell(data: any) {
                        return (
                        <Badge fontSize="xs" colorScheme={badgeEnum[data]}>
                            {data}
                        </Badge>
                        )
                    },
                }
        ]

        const set_student_list = (_page: number, name_search: string | null) => {
            setLoading(true)
            const fetchStudents = course_id
                ? StudentAPI.students_list(PER_PAGE, _page, name_search, status ? [status] : [], course_id)
                : homeroom_id
                    ? StudentAPI.students_list(
                        PER_PAGE,
                        _page,
                        name_search,
                        status ? [status] : [],
                        undefined,
                        homeroom_id
                    )
                    : StudentAPI.students_list(PER_PAGE, _page, name_search, status ? [status] : []);

            fetchStudents.then((res) => {
                const data = res!;
                const students = data.students.map((student) => ({
                    _id: student._id.$oid,
                    name: student.first_name + " " + student.last_name,
                    status: student.status,
                    link: (status === StudentStatus.Interview)
                        ? "/onboarding/interview/" + student._id.$oid
                        : "/students/" + student._id.$oid
                }));
                setLoading(false)
                set_students_data(students);
                set_total_students(data.students_count);
            });
        };

        useEffect(() => {
            
            set_student_list(page, name_search);
        }, [page]);

        useEffect(() => {
            set_page(1);

            set_student_list(1, name_search);
        }, [name_search]);

        return (
            <Stack>
                <BlBreadcrumbs />

                <Stack
                    spacing="4"
                    direction={{ base: "column", md: "row" }}
                    justify="space-between"
                >
                    <HStack>
                        <SearchInput onChange={set_name_search} />
                    </HStack>
                </Stack>
                <TableContent loading={loading} data={students_data} columns={StudentColumns} />

                <TablePagination
                    page={page}
                    onPageChange={set_page}
                    total={total_students!}
                />
            </Stack>
        );
    }
);

interface StudentsObj {
    _id: string;
    name: string;
    status: string;
    link?: string;
}

const badgeEnum: Record<string, string> = {
    Enrolled: "green",
    Withdrawn: "red",
};