import { Box, FlexProps, HStack, VStack } from '@chakra-ui/react';

interface Props extends FlexProps {
  date: string
  value: string
}

export const Property = ({ date, value, ...flexProps }: Props) => {
  return (
    <Box
      borderWidth="1px"
      borderStyle="dashed"
      borderRadius="md"
      background="gray.50"
      p={3}
      {...flexProps}
    >
        <Box flex="1" fontWeight="semibold">{value}</Box>


        <HStack justify="end">
          <Box>{date}</Box>
        </HStack>
    </Box>
  )
}
