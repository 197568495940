import {
    Box, FormLabel, Stack
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FamilyAPI } from "../../api/FamilyAPI";
import { Family } from "../../models/family";
import { to_oid } from "../../utils/ObjectId";
import { BlBreadcrumbs, useUpdateBreadcrumbs } from "../layout/Breadcrumbs";
import { BlCard } from "../layout/Card";
import { BlHeading } from "../layout/Heading";
import { FamilyStudentList } from "../parents/FamilyStudentList";
import { FamilyOverviewCard } from "../students/Cards/FamilyOverviewCard";
import SkeletonTable from "../table/TableSkeleton";

export const FamilyRoute: React.FC = () => {

    useUpdateBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Family", path: "/family/" },
        { label: "Family Details" }
    ])


    let { family_id } = useParams();
    const [loading, set_loading] = React.useState<boolean>(false);
    const [family, set_family] = React.useState<Family>();

    useEffect(() => {
        set_loading(true)
        FamilyAPI.get_family(to_oid(family_id!))
            .then((res) => {
                set_family(res)
            }).finally(() => {
                set_loading(false)
            })
    }, [family_id])

    if (loading) return <SkeletonTable />

    if (!family) return <></>

    return (
        <>
            <Box>
                <BlHeading> {family.name} </BlHeading>

                <BlBreadcrumbs />

                <Stack gap={4}>
                    <FamilyOverviewCard family={family} />

                    <BlCard>
                        <FormLabel fontSize={"lg"} fontWeight="bold">
                            Students
                        </FormLabel>

                        <FamilyStudentList family={family} read_only={true} redirect_to_profile={true} />
                    </BlCard>

                </Stack>
            </Box>
        </>
    )
}