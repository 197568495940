import { useEffect, useState } from "react";
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Text, useDisclosure, Alert, AlertIcon, Box } from "@chakra-ui/react";
import useVersionChecker from "../hooks/useVersionChecker";

const VersionCheckerComponent = () => {
    const { isNewVersionAvailable } = useVersionChecker();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showModal, setShowModal] = useState(false);
    const [cancelWarning, setCancelWarning] = useState(false);

    useEffect(() => {
        if (isNewVersionAvailable) {
            setShowModal(true);
            onOpen();
        }
    }, [isNewVersionAvailable, onOpen]);

    const handleCancel = () => {
        setCancelWarning(true);
        setShowModal(false);
        onClose();
        document.body.style.pointerEvents = "none";
    };

    return (
        <>
            <Modal isOpen={showModal} onClose={handleCancel} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>New Update Available</ModalHeader>
                    <ModalBody>
                        <Text>A new version of the app is available. Please refresh to get the latest features and improvements.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={() => window.location.reload()}>
                            Refresh
                        </Button>
                        <Button variant="ghost" onClick={handleCancel}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {cancelWarning && (
                <Box position="fixed" top={0} left={0} width="100%" bg="yellow.100" p={4} boxShadow="md" textAlign="center" zIndex={1000}>
                    <Alert status="warning">
                        <AlertIcon />
                        You can continue viewing this page, but you will not be able to modify anything until you refresh.
                    </Alert>
                </Box>
            )}
        </>
    );
};

export default VersionCheckerComponent;
