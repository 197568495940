import { Center, Spinner } from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { StudentAPI } from "../../api/StudentAPI";
import { ActionRequiredByParents, Student, StudentStatus } from "../../models/student";
import { BlHeading } from "../layout/Heading";
import { ParentsStudentProfile } from "./ParentStudentProfile";

export const ParentStudent: React.FC = () => {
    let { student_id } = useParams();

    const [student, set_student] = React.useState<Student>();
    const [actionsRequiredByParents, setActionsRequiredByParents] = useState<ActionRequiredByParents[]>([]);
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        setLoading(true);

        StudentAPI.get_student(student_id!).then((res) => {
            let s = res!
            set_student(s)
        })

        StudentAPI.actions_required_by_parents(student_id!).then((actions) => {
            setActionsRequiredByParents(actions);
        })

        setLoading(false);
    }, [student_id])


    if (loading || !student) {
        return (
            <Center minH="100vh">
                <Spinner size="xl" color="teal.500" />
            </Center>
        );
    }

    return <>
        <BlHeading> {student?.first_name} {student?.last_name} </BlHeading>
        <ParentsStudentProfile student={student} actionRequiredByParents={actionsRequiredByParents} />
    </>
}