import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {BassemLabsStaff, BassemLabsStaffUpdate, StaffStatus} from "../../models/bassem_labs_staff";
import { BassemLabsStaffAPI } from "../../api/BassemLabsStaffAPI";
import {
    Box,
    Heading,
    Text,
    Badge,
    Spinner,
    Center,
    Avatar,
    Stack,
    HStack,
    VStack,
    useToast,
    Button,
    IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    Select,
    useDisclosure,
    Spacer,
    Flex,
} from "@chakra-ui/react";
import { DateTime } from "../../utils/DateTime";
import { to_oid } from "../../utils/ObjectId";
import { EditIcon, ArrowBackIcon } from "@chakra-ui/icons";
import DatePicker from "../DatePicker";
import { useForm, Controller } from "react-hook-form";

interface Props {
    staff?: BassemLabsStaff;
}

interface FormValues {
    first_name: string;
    last_name: string;
    gender: string;
    personal_email: string;
    phone_number: string;
    date_of_birth: Date;
}

const StaffProfileCard: React.FC<Props> = ({ staff }) => {
    const navigate = useNavigate();
    const { staff_id } = useParams();
    const [user, setUser] = useState<BassemLabsStaff | null>(staff || null);
    const [loading, setLoading] = useState(!staff);
    const [error, setError] = useState<string | null>(null);
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    // Initialize react-hook-form with default values
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues: {
            first_name: "",
            last_name: "",
            gender: "",
            personal_email: "",
            phone_number: "",
            date_of_birth: new Date(),
        },
    });

    // When the user data loads, update the form defaults
    useEffect(() => {
        if (!user && staff_id) {
            const fetchUser = async () => {
                try {
                    const fetchedUser = await BassemLabsStaffAPI.get_user_by_id(
                        to_oid(staff_id!)
                    );
                    setUser(fetchedUser);
                    reset({
                        first_name: fetchedUser.firstName,
                        last_name: fetchedUser.lastName,
                        gender: fetchedUser.gender,
                        personal_email: fetchedUser.personalEmail || "",
                        phone_number: fetchedUser.phoneNumber || "",
                        // Convert the ISO string to a Date object for the DatePicker
                        date_of_birth: DateTime.from_iso_8601(fetchedUser.dateOfBirth).to_date(),
                    });
                } catch {
                    setError("Failed to fetch user");
                    toast({
                        title: "Error",
                        description: "Failed to fetch user information.",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } finally {
                    setLoading(false);
                }
            };
            fetchUser();
        } else if (user) {
            // If the user was passed as a prop, also set the default form values
            reset({
                first_name: user.firstName,
                last_name: user.lastName,
                gender: user.gender,
                personal_email: user.personalEmail || "",
                phone_number: user.phoneNumber || "",
                date_of_birth: DateTime.from_iso_8601(user.dateOfBirth).to_date(),
            });
        }
    }, [staff_id, user, reset, toast]);

    const handleBack = () => {
        navigate("/staff/users");
    };

    // onSubmit uses the same update API call signature
    const onSubmit = async (data: FormValues) => {
        if (!user) return;
        try {
            const updatedUser = await BassemLabsStaffAPI.update_profile(
                to_oid(user.id.id),
                {
                    first_name: data.first_name || null,
                    last_name: data.last_name || null,
                    gender: data.gender || null,
                    personal_email: data.personal_email || null,
                    phone_number: data.phone_number || null,
                    date_of_birth: data.date_of_birth ? DateTime.from_date(data.date_of_birth) : null
                } as BassemLabsStaffUpdate
            );
            setUser(updatedUser);
            toast({
                title: "Profile Updated",
                description: "Staff profile has been updated successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            onClose();
        } catch (error) {
            console.error("Error updating profile:", error);
            toast({
                title: "Error",
                description: "Failed to update profile.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleStatusChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (!user) return;
        const newStatus = e.target.value as StaffStatus;
        try {
            const updatedUser = await BassemLabsStaffAPI.update_status(
                to_oid(user.id.id),
                newStatus
            );
            setUser(updatedUser);
            toast({
                title: "Status Updated",
                description: `Staff status has been updated to ${updatedUser.status}.`,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error("Error updating status:", error);
            toast({
                title: "Error",
                description: "Failed to update status.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    if (loading) {
        return (
            <Center h="100vh">
                <Spinner size="xl" />
            </Center>
        );
    }

    if (error || !user) {
        return (
            <Center h="100vh">
                <Text>{error || "User not found"}</Text>
            </Center>
        );
    }

    return (
        <Flex
            p={6}
            bg="gray.50"
            minH="100vh"
            align="center"
            justify="center"
            position="relative"
        >
            {/* Back Button */}
            <IconButton
                icon={<ArrowBackIcon />}
                onClick={handleBack}
                aria-label="Back to Dashboard"
                variant="ghost"
                position="absolute"
                top={6}
                left={6}
            />

            {/* Profile Card */}
            <Box
                maxW={"600px"}
                w={"full"}
                bg={"white"}
                boxShadow={"2xl"}
                rounded={"md"}
                overflow={"hidden"}
            >
                <HStack bg={"gray.100"} px={6} py={4}>
                    <Avatar size={"2xl"} name={`${user.firstName} ${user.lastName}`} />
                    <VStack align="start" spacing={1}>
                        <Heading fontSize={"2xl"}>{`${user.firstName} ${user.lastName}`}</Heading>
                        <Badge colorScheme={user.status === StaffStatus.ACTIVE ? "green" : "red"}>
                            {user.status}
                        </Badge>
                    </VStack>
                    <Spacer />
                    <IconButton
                        icon={<EditIcon />}
                        onClick={onOpen}
                        aria-label="Edit Profile"
                        variant="outline"
                        colorScheme="blue"
                    />
                </HStack>
                <Box p={6}>
                    <Stack spacing={4}>
                        <HStack>
                            <Text fontWeight="bold" width="150px">
                                Email:
                            </Text>
                            <Text>{user.email}</Text>
                        </HStack>
                        <HStack>
                            <Text fontWeight="bold" width="150px">
                                Username:
                            </Text>
                            <Text>{user.username}</Text>
                        </HStack>
                        <HStack>
                            <Text fontWeight="bold" width="150px">
                                Phone:
                            </Text>
                            <Text>{user.phoneNumber || "N/A"}</Text>
                        </HStack>
                        <HStack>
                            <Text fontWeight="bold" width="150px">
                                Gender:
                            </Text>
                            <Text>{user.gender}</Text>
                        </HStack>
                        <HStack>
                            <Text fontWeight="bold" width="150px">
                                Date of Birth:
                            </Text>
                            <Text>
                                {DateTime.from_iso_8601(user.dateOfBirth).to_utc_string_date()}
                            </Text>
                        </HStack>
                        <HStack>
                            <Text fontWeight="bold" width="150px">
                                Status:
                            </Text>
                            <Select
                                value={user.status}
                                onChange={handleStatusChange}
                                maxW="200px"
                            >
                                <option value={StaffStatus.ACTIVE}>{StaffStatus.ACTIVE}</option>
                                <option value={StaffStatus.INACTIVE}>{StaffStatus.INACTIVE}</option>
                            </Select>
                        </HStack>
                    </Stack>
                </Box>
            </Box>

            {/* Edit Profile Modal with react-hook-form */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Profile</ModalHeader>
                    <ModalCloseButton />
                    {/* Wrap the modal body in a form */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody>
                            <Stack spacing={4}>
                                <FormControl isRequired>
                                    <FormLabel>First Name</FormLabel>
                                    <Input {...register("first_name", { required: true })} />
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>Last Name</FormLabel>
                                    <Input {...register("last_name", { required: true })} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Personal Email</FormLabel>
                                    <Input type="email" {...register("personal_email")} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Phone Number</FormLabel>
                                    <Input type="tel" {...register("phone_number")} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Gender</FormLabel>
                                    <Select {...register("gender")}>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </Select>
                                </FormControl>
                                <FormControl mb={3}>
                                    <FormLabel>Date of Birth</FormLabel>
                                    <Controller
                                        control={control}
                                        name="date_of_birth"
                                        render={({ field: { onChange, value } }) => (
                                            <DatePicker
                                                selectedDate={value || new Date()}
                                                onChange={(d) => onChange(d)}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Stack>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="blue" type="submit">
                                Save
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default StaffProfileCard;