import { View, Text as PdfText } from "@react-pdf/renderer";
import { CustomField } from "../../models/custom_field";
import { CustomFieldEntry } from "protobuffer-ts/dist/user_service/custom_field_entry";
import { Grades, Student } from "../../models/student"

import PdfStylesheet from "./PdfStylesheet";

const styles = PdfStylesheet

interface StudentInfoSectionProps {
	student: Student
	primary_id_custom_field?: CustomField
	primary_id_entry?: CustomFieldEntry
}

export const StudentInfoSection: React.FC<StudentInfoSectionProps> = ({ student, primary_id_custom_field, primary_id_entry }) => {
	return (
		<>
		<View style={styles.sectionTitleContainer}>
			<PdfText style={styles.sectionTitleText}>Student Information</PdfText>
		</View>

		{/* Student Information and School Information in a Specific Order */}
		<View style={styles.tableContainer}>
			{/* First Row */}
			<View style={[styles.cell, styles.cellThirdWidth]}>
				<PdfText>
					<PdfText style={styles.label}>Name: </PdfText>
					<PdfText style={styles.value}>{`${student.first_name} ${student.last_name}`}</PdfText>
				</PdfText>
			</View>

			<View style={[styles.cell, styles.cellThirdWidth]}>
				{ primary_id_custom_field && primary_id_entry &&
					<PdfText>
						<PdfText style={styles.label}>{primary_id_custom_field.name}:</PdfText>
						<PdfText style={styles.value}>{primary_id_entry.value}</PdfText>
					</PdfText>
				}	
			</View>

			<View style={[styles.cell, styles.cellThirdWidth]}>
				<PdfText>
					<PdfText style={styles.label}>Grade: </PdfText>
					<PdfText style={styles.value}>{Grades.get(student.grade)}</PdfText>
				</PdfText>
			</View>
		</View>
		</>
	)
}