import { CreateResourceAccessSettings, ResourceAccessSettings } from "../models/resource_access_management";
import HTTPApi from "./ApiCommon";
import { UserType } from "./UserApi";

export namespace ResourceAccessManagementAPI {

    export const get_resource_access_settings = async (): Promise<Array<ResourceAccessSettings>> => {
        const res = await HTTPApi.get("/resource_access_settings");
        return res.data;
    }

    export const create_resource_access_settings = async (data: CreateResourceAccessSettings) => {
      const res = await HTTPApi.post("/resource_access_settings/create", data);
      return res.data;
  } 
}