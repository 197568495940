import React from 'react';
import { FormControl, FormLabel, Input, FormErrorMessage, FormControlProps, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { UseFormRegisterReturn } from 'react-hook-form';
import './styles/form-input.css';

interface FormInputProps extends FormControlProps {
    name: string;
    label?: string;
    placeholder?: string;
    register: UseFormRegisterReturn;
    error?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    isHeader?: boolean;
    leftAddon?: string;
}

const FormInput: React.FC<FormInputProps> = ({ name, label, placeholder, register, error, isDisabled = false, isRequired = false, isHeader = false, leftAddon, ...props }) => {
    return (
        <FormControl isInvalid={!!error} {...props} >
            {label && (
                <FormLabel htmlFor={name} className={isRequired ? 'required' : ''}>
                    {label}
                </FormLabel>
            )}

            <InputGroup>
                {leftAddon && <InputLeftAddon children={leftAddon} />}

                <Input
                    {...register}  // Spread the register object directly into the Input
                    isDisabled={isDisabled}
                    fontWeight={isHeader ? 'bold' : 'normal'}
                    placeholder={placeholder}
                />
            </InputGroup>
            <FormErrorMessage>
                {error}
            </FormErrorMessage>
        </FormControl>
    );
};

export default FormInput;