import { useEffect, useState } from "react";
import {
    Box,
    FormControl,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    usePrevious,
} from "@chakra-ui/react";
import {useLocation} from "react-router-dom";
import {TableContent} from "../table/TableContent";
import {BsSearch} from "react-icons/bs";
import {TablePagination} from "../table/TablePagination";
import {NotificationAPI} from "../../api/NotificationAPI";

export const NotificationList = () => {
    const PER_PAGE = 100;
    const location = useLocation();
    const [notificationsData, setNotificationsData] = useState<Array<NotificationObj>>([]);
    const [notificationsPage, setNotificationsPage] = useState<number>(1);
    const [totalNotifications, setTotalNotifications] = useState<number>(0);
    const [notificationTitleSearch, setNotificationTitleSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    const previousSearch = usePrevious(notificationTitleSearch);

    const _setNotificationList = (res: NotificationAPI.NotificationList) => {
        setNotificationsData(
            res.notifications.map((notification) => ({
                _id: notification._id.$oid,
                title: notification.title,
                body: notification.body,
                created: notification.created,
                created_by: notification.created_by,
                created_by_id: notification.created_by_id.$oid,
                notification_type: notification.notification_type,
                notification_destination_user: notification.notification_destination_user,
                notification_destination_user_id: notification.notification_destination_user_id.$oid,
                date_to_send: notification.date_to_send.to_utc_string_date(),
                link: `${location.pathname}/notification/${notification._id.$oid}`,
            }))
        );

        setTotalNotifications(res.notifications_count);
    };

    const setNotificationList = async (_page?: number, title_search?: string) => {
        setLoading(true);

        const res = await NotificationAPI.notification_list(PER_PAGE, _page, title_search, false);
        if (res) {
            _setNotificationList(res);
        } else {
            console.warn("Received undefined response for notifications.");
            // Optionally, reset the notifications data and pagination
            setNotificationsData([]);
            setTotalNotifications(0);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (previousSearch !== notificationTitleSearch) {
            setNotificationsPage(1);
        }

        setNotificationList(notificationsPage, notificationTitleSearch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationsPage, notificationTitleSearch]);

    const getNotificationColumns = () => {
        const columns = [
            {
                Header: "Title",
                accessor: "title",
            },
            {
                Header: "Created",
                accessor: "created",
            },
            {
                Header: "Send Date",
                accessor: "date_to_send",
            },
        ];

        return columns;
    };

    return (
        <Box>
            <Stack>
                <Stack spacing="4" direction={{ base: "column", md: "row" }} justify="space-between">
                    <HStack>
                        <FormControl minW={{ md: "320px" }} id="search">
                            <InputGroup size="sm">
                                <InputLeftElement pointerEvents="none" color="gray.400">
                                    <BsSearch />
                                </InputLeftElement>
                                <Input
                                    rounded="base"
                                    type="search"
                                    placeholder="Search by title..."
                                    value={notificationTitleSearch}
                                    onChange={(e) => setNotificationTitleSearch(e.target.value)}
                                />
                            </InputGroup>
                        </FormControl>
                    </HStack>
                </Stack>

                <TableContent loading={loading} data={notificationsData} columns={getNotificationColumns()} />

                <TablePagination
                    perPage={PER_PAGE}
                    page={notificationsPage}
                    total={totalNotifications}
                    onPageChange={setNotificationsPage}
                />
            </Stack>
        </Box>
    );
};

export interface NotificationObj {
    _id: string;
    title: string;
    body: string;
    created: string;
    created_by: string;
    created_by_id: string;
    notification_type: string[];
    notification_destination_user: string;
    notification_destination_user_id: string;
    date_to_send: string;
    link?: string;
}