import { useEffect, useMemo, useState } from "react";
import {
	Box,
	Stack,
	Text,
	Spacer,
	Badge,
	Wrap,
	ButtonGroup,
	Button,
	FormControl,
	FormLabel,
	Menu,
	MenuButton,
	MenuList,
} from "@chakra-ui/react";
import { BlCard } from "../../layout/Card";
import { FlexWrap } from "../../layout/FlexWrap";
import { ParentAPI } from "../../../api/ParentAPI";
import SkeletonTable from "../../table/TableSkeleton";
import { AttendanceColors, AttendnaceOverview, Status, StudentOverview } from "../../../models/attendance";
import { DateFilterOption, dateFilterOptions, getAttendanceDateRange } from "../../../utils/date_utils";
import DatePicker from "../../DatePicker";
import { LightButton } from "../../layout/LightButton";
import { getIsHighSchool } from "../../../models/student";

export const AttendanceOverviewCard = () => {
	const [loading, setLoading] = useState(false);
	const [attendnaceOverview, setAttendnaceOverview] = useState<AttendnaceOverview>([]);
	const [dateFilter, setDateFilter] = useState<DateFilterOption>("today");
	const [customRange, setCustomRange] = useState<{ start?: Date; end?: Date }>({});

	const isOneDayFilter = useMemo(() => dateFilter === "today" || dateFilter === "yesterday", [dateFilter]);

	const fetchAttendanceOverview = async (start: Date, end: Date) => {
		setLoading(true);
		try {
			const data = await ParentAPI.attendance_overview(start, end);
			setAttendnaceOverview(data!);
		} catch (error) {
			console.error("Error fetching attendance overview", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (dateFilter !== "custom") {
			const { start, end } = getAttendanceDateRange(dateFilter, customRange);
			fetchAttendanceOverview(start, end);
		}
	}, [dateFilter]);

	const renderBadges = (overview: Record<Status, number>, isHighSchool: boolean) => {
		if (isHighSchool) {
			return Object.keys(Status).map((status) => (
				<Badge key={status} colorScheme={AttendanceColors[status]}>
					{/* @ts-ignore */}
					{overview[status] ?? 0} {status.toLowerCase()}
				</Badge>
			));
		}
		return Object.entries(overview).map(([status, value]) => (
			<Badge key={status} colorScheme={AttendanceColors[status]}>
				{/* ignore value if it's one and one day filter */}
				{isOneDayFilter && value === 1 ? null : value} {status}
			</Badge>
		));
	};

	const setStartDate = (date: Date) => {
		setCustomRange((prev) => ({ ...prev, start: date }));
	};

	const setEndDate = (date: Date) => {
		setCustomRange((prev) => ({ ...prev, end: date }));
	};

	const applyCustomFilter = (onclose: () => void) => {
		const { start, end } = getAttendanceDateRange("custom", customRange);

		fetchAttendanceOverview(start, end);

		onclose();
	}

	return (
		<BlCard>
			<FlexWrap>
				<Text fontSize="lg" fontWeight="bold">
					Attendance Overview
				</Text>
				<Spacer />
				<ButtonGroup size="sm" isAttached variant="outline">
					{dateFilterOptions.map((option) =>
						option.value === "custom" ? (
							<Menu key={option.value} closeOnSelect={false} placement="bottom-end">
								{({ onClose }) => (
									<>
										<MenuButton
											as={Button}
											key={"custom"}
											isActive={dateFilter === "custom"}
											_active={{ bgColor: "blue.50", textColor: "blue.500" }}
											bg={dateFilter === "custom" ? "blue.50" : undefined}
											color={dateFilter === "custom" ? "blue.500" : undefined}
											onClick={() => setDateFilter("custom")}
										>
											{option.label}
										</MenuButton>

										<MenuList>
											<Stack gap={4} p={4}>
												<FormControl>
													<FormLabel>Start Date:</FormLabel>

													<DatePicker
														placeholderText="select Date"
														selectedDate={customRange.start}
														onChange={setStartDate}
													/>
												</FormControl>
												<FormControl mt={2}>
													<FormLabel>End Date:</FormLabel>

													<DatePicker
														placeholderText="select Date"
														selectedDate={customRange.end}
														onChange={setEndDate}
													/>
												</FormControl>

												<LightButton color="blue" border="none" onClick={() => applyCustomFilter(onClose)}>
													Apply
												</LightButton>
											</Stack>
										</MenuList>
									</>
								)}
							</Menu>
						) : (
							<Button
								key={option.value}
								isActive={dateFilter === option.value}
								_active={{ bgColor: "blue.50", textColor: "blue.500" }}
								onClick={() => setDateFilter(option.value)}
							>
								{option.label}
							</Button>
						)
					)}
				</ButtonGroup>
			</FlexWrap>

			{loading ? (
				<SkeletonTable height="50px" />
			) : (
				<Stack gap={2}>
					{attendnaceOverview.map(({ student, overview }) => {
						const isHighSchool = getIsHighSchool(student.grade);
						return (
							<Box
								key={student._id.$oid}
								borderWidth="1px"
								borderStyle="dashed"
								borderRadius="md"
								background="gray.50"
								p={3}
							>
								<FlexWrap>
									<Text fontWeight="bold">
										{student.first_name} {student.last_name}
									</Text>
									<Spacer />
									<Wrap align="start" spacing={2}>
										{renderBadges(overview, isHighSchool)}
									</Wrap>
								</FlexWrap>
							</Box>
						);
					})}
				</Stack>
			)}
		</BlCard>
	);
};
