import * as React from "react";
import {
    Container,
    Center,
    Button,
    Heading,
    Image,
    Stack,
    Flex,
    Box, Spacer,
} from '@chakra-ui/react';
import { FaUserGraduate } from "react-icons/fa";
import { RiParentFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import 'firebase/compat/auth';
import logo from "../images/logo_transparent.png";
import { AuthLayout } from "./auth/authLayout";
import '../css/login.css';

export const LoginSelector: React.FC = () => {
    return (
        <AuthLayout>
            <Flex
                direction="column"
                justify="space-between"
                align="center"
                minHeight="100vh"
                px={4}
            >
                {/* Main Content */}
                <Spacer/>
                <Box textAlign="center" width="100%">
                    <Container maxW='container.sm' mb="12" maxWidth={"xl"}>
                        <Image src={logo} mx="auto" />
                    </Container>

                    <Center>
                        <Heading size='sm'>
                            Sign In
                        </Heading>
                    </Center>

                    <Stack align={"center"} maxW="500px" mx="auto" spacing={6} mt={6}>
                        <Button as={Link} to="/login/parent" minW="220" leftIcon={<RiParentFill />} colorScheme="primary" >
                            Login As Parent
                        </Button>

                        <Button as={Link} to="/login/teacher" minW="220"  leftIcon={<FaUserGraduate />} border="1px solid #8d53a2" color="#8d53a2" colorScheme="white">
                            Login As Teacher
                        </Button>
                    </Stack>
                </Box>
                <Spacer/>

                {/* Footer with Terms and Conditions */}
                <Box mb={6}>
                    <Button
                        as={Link}
                        to="/login/staff"
                        variant="link"
                        color="gray.500"
                        fontSize="sm"
                        _hover={{ textDecoration: 'underline' }}
                    >
                        Staff Login
                    </Button>
                </Box>
            </Flex>
        </AuthLayout>
    );
};