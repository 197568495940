import HTTPApi from "./ApiCommon";
import { CreateInvoice, Invoice, InvoiceFilter, InvoiceListResponse, InvoiceResponse, InvoiceStatus } from "../models/invoice";
import { ObjectId } from "../utils/ObjectId";
import { DateTime } from "../utils/DateTime";

export namespace InvoiceAPI {
    export const create = async (body : CreateInvoice) : Promise<Invoice> => {
        return HTTPApi.post("invoice/create", body)
    }

    export const create_for_class = async (body : CreateInvoice) : Promise<Invoice> => {
        return HTTPApi.post("invoice/create_for_class", body)
    }

    export const update = async (body : CreateInvoice) : Promise<Invoice> => {
        return HTTPApi.post("invoice/update", { 
            invoice: {
                ...body,
                due_date: body.due_date ? DateTime.from_date(body.due_date) : undefined
            }
          });
    }

    export const archive = async (id: ObjectId) => {
        return HTTPApi.get("/invoice/archive/" + id.$oid)
    }


    export const get = async (invoiceId : ObjectId) : Promise<InvoiceResponse> => {
        return HTTPApi.get(`invoice/get/${invoiceId.$oid}`).then(({ data }) => ({...data, invoice: new Invoice(data.invoice)}))
    }

    export const user_invoices = async (userId : ObjectId) : Promise<InvoiceResponse[]> => {
        const res = await HTTPApi.get(`invoice/get_user_invoices/${userId.$oid}`);
        return res.data;
    }

    export const family_invoices = async (familyId: ObjectId) : Promise<InvoiceResponse[]> => {
        return HTTPApi.get(`invoice/get_family_invoices/${familyId.$oid}`).then(res => res.data)
    }

    export const invoices_list = async (payload : InvoiceFilter) : Promise<InvoiceListResponse> => {
        return HTTPApi.post('invoice/list', payload).then(res => res.data)
    }

    export const list_for_parent = async () : Promise<InvoiceResponse[]> => {
        return HTTPApi.get('invoice/list_for_parent').then(res => res.data)
    }
}