import HTTPApi from "./ApiCommon";
import {ReportEntry, ReportEntryUpdate, ReportMedian, RequestChangesReportEntry} from "../models/report_entry";
import { ObjectId } from "../utils/ObjectId";


export namespace ReportAPI {
    export const get_course_report_entries = async (courseId: string) : Promise<ReportEntry[]> => {
        return HTTPApi.get(`/reports/get_course_report_entries/${courseId}`)
            .then((res) => {
                return res.data.map((report : ReportEntry) => new ReportEntry(report))
            })
    }

    export const get_student_report_entries_for_course = async (student_id : ObjectId, course_id: ObjectId) => {
        return HTTPApi.post(`/reports/get_student_report_entries_for_course`, {student_id, course_id})
            .then((res) => {
                return res.data.map((report : ReportEntry) => new ReportEntry(report))
            })
    }

    export const get_report_entry_median = async (report_entry_id: ObjectId) => {
        return HTTPApi.get(`/reports/get_report_entry_median/${report_entry_id.$oid}`)
            .then((res) => {
                return new ReportMedian(res.data);
            });
    }

    export const get_homeroom_report_entries = async (homeroomId: string) => {
        return HTTPApi.get(`/reports/get_homeroom_report_entries/${homeroomId}`)
            .then((res) => {
                return res.data.map((report : ReportEntry) => new ReportEntry(report))
            })
    }

    export const get_student_report_entries_for_homeroom = async (student_id : ObjectId, homeroom_id: ObjectId) => {
        return HTTPApi.post(`/reports/get_student_report_entries_for_homeroom`, {student_id, homeroom_id})
            .then((res) => {
                return res.data.map((report : ReportEntry) => new ReportEntry(report))
            })
    }

    export const get_student_published_report_entries = async (studentId : string) => {
        return HTTPApi.get(`/reports/get_student_published_report_entries/${studentId}`)
            .then((res) => {
                return res.data.map((report : ReportEntry) => new ReportEntry(report))
            })
    }

    export const get_report_entries_queue = async (teacher_id?: ObjectId, student_id?: ObjectId, semester_id?: ObjectId, ) => {
        return HTTPApi.post("/reports/get_report_entries_queue", { teacher_id, student_id, semester_id })
            .then((res) => {
                return res.data.map((report : ReportEntry) => new ReportEntry(report))
            })
    }

    export const get_report_entry = async (reportId: string) => {
        return HTTPApi.get(`/reports/get_report_entry/${reportId}`)
            .then((res) => {
                return new ReportEntry(res.data)
            })
    }

    export const generate_report_entry_smart_comment = async (reportId: string, optional_prompt: string | undefined) => {
        return HTTPApi.post(`/reports/generate_report_entry_smart_comment/${reportId}`, {
            optional_prompt
        })
            .then((res) => {
                return String(res.data)
            })
    }

    export const update_report_entry = async (reportEntry: ReportEntryUpdate) => {
        return HTTPApi.post("/reports/update", { report_entry: reportEntry })
            .then((res) => {
                return new ReportEntry(res.data)
            })
    }

    export const request_changes = async (data: RequestChangesReportEntry) => {
        return HTTPApi.post("/reports/request_changes", data)
            .then((res) => {
                return res.data as ReportEntry
            })
    }

    export const approve = async (data: { report_id : ObjectId}) => {
        return HTTPApi.post("/reports/approve", data)
            .then((res) => {
                return res.data as ReportEntry
            })
    }

    export const unpublish = async (data: RequestChangesReportEntry) => {
        return HTTPApi.post("/reports/unpublish", data)
            .then((res) => {
                return res.data as ReportEntry
            })
    }

    export const publish = async (data: { report_id : ObjectId}) => {
        let res = await HTTPApi.post("/reports/publish", data)
        return res.data as ReportEntry
    }

    export const guardian_sign_report_card = async (data: { report_id : ObjectId}) : Promise<ReportEntry> => {
        return HTTPApi.post("/reports/guardian_sign_report_card", data)
            .then((res) => {
                return res.data
            })
    }

    export const update_reports_with_medians = async (reports: ReportEntry[]) => {
        return Promise.all(
            reports.map(async (report) => {
                const reportMedian = await ReportAPI.get_report_entry_median(report._id);
        
                report.sections = report.sections.map((section) => {
                    const matchingMedian = reportMedian.section_medians.find(
                    (median) => median.section_layout_id.$oid === section.layout_id.$oid
                    );
        
                    return {
                    ...section,
                    median_mark: matchingMedian?.median_mark ?? undefined,
                    };
                });
        
                return report;
            })
        );
    }
}
