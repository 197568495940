export const LegendData = [
	{
		numeric: "80–100",
		letter: "A- to A+",
		description:
			"The student has demonstrated the required knowledge and skills with a high degree of effectiveness. Achievement surpasses the provincial standard. (Level 4)"
	},
	{
		numeric: "70–79",
		letter: "B- to B+",
		description:
			"The student has demonstrated the required knowledge and skills with considerable effectiveness. Achievement meets the provincial standard. (Level 3)"
	},
	{
		numeric: "60–69",
		letter: "C- to C+",
		description:
			"The student has demonstrated the required knowledge and skills with some effectiveness. Achievement approaches the provincial standard. (Level 2)"
	},
	{
		numeric: "50–59",
		letter: "D- to D+",
		description:
			"The student has demonstrated the required knowledge and skills with limited effectiveness. Achievement falls much below the provincial standard. (Level 1)"
	},
	{
		numeric: "Below 50",
		letter: "R",
		description: "The student did not meet the requirements."
	}
];

export const schoolInfo = {
	School: 'Alfajrul Bassem Academy',
	Address: '700 Kerr St Oakville, ON, L6K 3E1',
	Telephone: '+1 (905) 514-9995',
	Email: 'info@alfajrulbassem.com',
	BoysSupervisor: 'Abdulnafi Alrifaie',
	girlsSupervisor: 'Nivin Sarraj',
};