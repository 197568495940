import { Button, ButtonGroup, Flex, Text, useColorModeValue as mode } from '@chakra-ui/react'
import React from 'react'

interface TablePaginationProps {
    page: number
    onPageChange: (page: number) => void
    perPage?: number
    total: number
}

export const TablePagination: React.FC<TablePaginationProps> = ({
    page,
    onPageChange,
    perPage = 15,
    total,
}) => {
    const displayText = `Showing ${Math.min(page * perPage, total)} of ${total}`
    const maxPage = Math.ceil(total / perPage)

    if (!total) return null

    return (
        <Flex align="center" justify="space-between">
            <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
                {displayText}
            </Text>
            <ButtonGroup variant="outline" size="sm">
                <Button
                    as="a"
                    rel="prev"
                    onClick={() => page > 1 && onPageChange(page - 1)}
                    isDisabled={page <= 1}
                >
                    Previous
                </Button>
                <Button
                    as="a"
                    rel="next"
                    onClick={() => page < maxPage && onPageChange(page + 1)}
                    isDisabled={page >= maxPage}
                >
                    Next
                </Button>
            </ButtonGroup>
        </Flex>
    )
}
