import { useToaster } from "./useToaster";

export const useCopyToClipboard = () => {
  const { showSuccessToast, showErrorToast } = useToaster();

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showSuccessToast('Text copied to clipboard', 'Copied');
      })
      .catch(() => {
        showErrorToast('Failed to copy text');
      });
  };

  return copyToClipboard;
};
