// Dashboard.tsx
import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconType } from 'react-icons';
import {
    FiHome,
    FiCompass,
    FiStar,
    FiThumbsUp,
    FiBookOpen,
    FiUsers,
    FiUserCheck,
    FiBook,
    FiCode,
    FiActivity,
} from 'react-icons/fi';
import { BiReceipt } from "react-icons/bi";
import { BsFillPeopleFill } from "react-icons/bs";
import SimpleSidebar from "./3rd_party/SimpleSideBar";
import LocalStorageService from "../api/LocalStorageService";
import { UserRole, UserType } from "../api/UserApi";

// Interface for navigation links
interface NavLink {
    name: string;
    icon: IconType;
    route: string;
    roles?: UserRole[]; // Optional: If undefined, accessible to all roles
    userTypes?: UserType[]; // Optional: Restrict to specific user types
}

// Centralized navigation link definitions
const generalLinks: NavLink[] = [
    { name: 'Home', icon: FiHome, route: "/home" },
    { name: 'Classes', icon: FiBookOpen, route: "/class" },
    { name: 'Attendance', icon: FiCompass, route: "/attendance" },
    // { name: 'Violations', icon: FiAlertOctagon, route: "/violations" },
    { name: 'Report Card', icon: FiBook, route: "/reports" },
    { name: 'Mark Book', icon: FiBook, route: "/markbook" },
];

const parentLinks: NavLink[] = [
    { name: 'Home', icon: FiHome, route: "/parent/home" },
    { name: 'Family', icon: FiStar, route: "/parent/family" },
    { name: 'Invoices', icon: BiReceipt, route: "/parent/invoices" },
];

const staffLinks: NavLink[] = [
    { name: 'Dashboard', icon: BsFillPeopleFill, route: "/staff/home" },
    { name: 'Bassem Staff', icon: BsFillPeopleFill, route: "/staff/users" },
    { name: 'Organizations', icon: BsFillPeopleFill, route: "/staff/organizations" },
    { name: 'Developer Portal', icon: FiCode, route: "/staff/developer_portal" },
];

// Utility function to filter navigation links based on user roles and type
const filterLinks = (
    links: NavLink[],
    userRoles: UserRole[],
    userType: UserType
): NavLink[] => {
    return links.filter(link => {
        // Restrict by user type if specified
        if (link.userTypes && !link.userTypes.includes(userType)) {
            return false;
        }

        // Restrict by roles if specified
        if (link.roles && !link.roles.some(role => userRoles.includes(role))) {
            return false;
        }

        // Link is accessible
        return true;
    });
};

const Dashboard: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const user = LocalStorageService.getInstance().getUser();

    useEffect(() => {
        if (!user) {
            // Redirect to login if user is not authenticated
            navigate("/login");
            return;
        }

        if (location.pathname === "/") {
            switch (user.user_type) {
                case UserType.Teacher:
                    navigate("/home");
                    break;
                case UserType.BassemLabsStaff:
                    navigate("/staff/home");
                    break;
                case UserType.Parent:
                    navigate("/parent/home");
                    break;
                default:
                    navigate("/home");
            }
        }
    }, [location.pathname, navigate, user]);

    // Memoize link items to prevent unnecessary recalculations
    const linkItems = useMemo(() => {
        if (!user) return [];

        switch (user.user_type) {
            case UserType.Parent:
                return parentLinks;
            case UserType.BassemLabsStaff:
                return staffLinks;
            case UserType.Teacher:
                let items = [...generalLinks];

                if (user.has_role(UserRole.Can_Access_Family_Manager)) {
                    items.push({
                        name: 'Family',
                        icon: FiUsers,
                        route: "/family",
                        roles: [UserRole.Can_Access_Family_Manager, UserRole.Super_Admin],
                    });
                }

                if (user.has_role(UserRole.Can_Access_All_Student)) {
                    items.push({
                        name: 'Students',
                        icon: FiStar,
                        route: "/students",
                        roles: [UserRole.Can_Access_All_Student, UserRole.Super_Admin],
                    });
                }

                if (user.has_role(UserRole.Can_Access_All_Teacher)) {
                    items.push({
                        name: 'Teachers',
                        icon: FiStar,
                        route: "/teachers",
                        roles: [UserRole.Can_Access_All_Teacher, UserRole.Super_Admin],
                    });
                }

                if (user.has_role(UserRole.Can_Access_Invoice_Manager)) {
                    items.push({
                        name: 'Invoices',
                        icon: BiReceipt,
                        route: "/invoices",
                        roles: [UserRole.Can_Access_Invoice_Manager, UserRole.Super_Admin],
                    });
                }

                if (user.has_role(UserRole.Can_Enroll_Student)) {
                    items.push(
                        {
                            name: 'Onboarding',
                            icon: FiThumbsUp,
                            route: "/onboarding",
                            roles: [UserRole.Can_Enroll_Student, UserRole.Super_Admin],
                        },
                        {
                            name: 'Re-Registration',
                            icon: FiUserCheck,
                            route: "/reregistration",
                            roles: [UserRole.Can_Enroll_Student, UserRole.Super_Admin],
                        }
                    );
                }

                if (user.has_role(UserRole.Super_Admin)) {
                    items.push({
                        name: 'Admin Portal',
                        icon: FiCompass,
                        route: "/admin_portal",
                        roles: [UserRole.Super_Admin],
                    });
                }

                if (user.has_role(UserRole.Developer)) {
                    items.push({
                        name: 'Migrations',
                        icon: FiActivity,
                        route: "/migrations",
                        roles: [UserRole.Developer],
                    });
                }

                return filterLinks(items, user.user_roles, user.user_type);
            default:
                return [];
        }
    }, [user]);

    return (
        <>
            {user && <SimpleSidebar link_items={linkItems} />}
        </>
    );
};

export default Dashboard;