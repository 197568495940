import {
    Box,
    Button,
    Center,
    Container,
    Divider,
    Flex,
    Heading,
    Icon,
    Spinner,
    Text,
    Grid,
    VStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiInteractionRecordAPI } from "../../api/AiInteractionRecordAPI";
import { to_oid } from "../../utils/ObjectId";
import { AiInteractionRecord } from "../../models/ai_interaction_record";
import { FiUser, FiCalendar, FiBriefcase } from "react-icons/fi";
import { useToaster } from "../../hooks/useToaster";

export const AiInteractionRecordDetail = () => {
    const { ai_interaction_record_id } = useParams<{ ai_interaction_record_id: string }>();
    const navigate = useNavigate(); // For navigation (e.g., back button)
    const { showErrorToast } = useToaster(); // For displaying error messages

    const [ai_interaction_record, setAiInteractionRecord] = useState<AiInteractionRecord | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchAiInteractionRecord = async () => {
            setLoading(true);
            try {
                const ai_interaction_record = await AiInteractionRecordAPI.get_ai_interaction_record(to_oid(ai_interaction_record_id!));
                setAiInteractionRecord(ai_interaction_record);
            } catch (err: any) {
                console.error("Error fetching ai_interaction_record:", err);
                setError(err.message || "An unexpected error occurred.");

                showErrorToast(err.message || "Failed to load the ai_interaction_record.");
            } finally {
                setLoading(false);
            }
        };

        fetchAiInteractionRecord();
    }, [ai_interaction_record_id]);

    const handleBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    if (loading) {
        return (
            <Center minH="100vh">
                <Spinner size="xl" color="teal.500" />
            </Center>
        );
    }

    if (error) {
        return (
            <Center minH="100vh">
                <Box textAlign="center" p={6}>
                    <Heading size="lg" mb={4} color="red.500">
                        {error}
                    </Heading>
                    <Button
                        onClick={handleBack}
                        colorScheme="teal"
                        leftIcon={<ArrowBackIcon />}
                    >
                        Go Back
                    </Button>
                </Box>
            </Center>
        );
    }

    return (
        <Container maxW="container.lg" py={10}>
            <Button
                mb={6}
                onClick={handleBack}
                colorScheme="teal"
                variant="outline"
                leftIcon={<ArrowBackIcon />}
            >
                Back
            </Button>

            <Box
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                p={8}
                boxShadow="lg"
                bg="white"
            >
                <VStack align="start" spacing={6}>
                    <Heading as="h2" size="md">
                        AI Interaction Record
                    </Heading>

                    <Grid templateColumns={{ base: "1fr", md: "200px 1fr" }} gap={4} width="100%">
                        <Flex align="center">
                            <Icon as={FiUser} mr={2} color="teal.500" />
                            <Text fontWeight="bold">User:</Text>
                        </Flex>
                        <Text>{ai_interaction_record?.user_id.$oid}</Text>  {/* Displaying the user field */}

                        <Flex align="center">
                            <Icon as={FiBriefcase} mr={2} color="teal.500" />
                            <Text fontWeight="bold">Organization:</Text>
                        </Flex>
                        <Text>{ai_interaction_record?.organization.$oid}</Text>  {/* Displaying the organization field */}

                        <Flex align="center">
                            <Icon as={FiCalendar} mr={2} color="teal.500" />
                            <Text fontWeight="bold">Status:</Text>
                        </Flex>
                        <Text>{ai_interaction_record?.status}</Text>  {/* Displaying the status field */}
                    </Grid>

                    <Divider />

                    <Box width="100%">
                        <Text fontWeight="bold" fontSize="lg" mb={2} color="teal.500">
                            Prompt:
                        </Text>
                        <Box
                            p={4}
                            bg="gray.50"
                            borderRadius="md"
                            boxShadow="sm"
                            whiteSpace="pre-wrap"
                        >
                            <Text>{ai_interaction_record?.prompt}</Text>
                        </Box>
                    </Box>

                    <Divider/>

                    <Box width="100%">
                        <Text fontWeight="bold" fontSize="lg" mb={2} color="teal.500">
                            Response:
                        </Text>
                        <Box
                            p={4}
                            bg="gray.50"
                            borderRadius="md"
                            boxShadow="sm"
                            whiteSpace="pre-wrap"
                        >
                            <Text>{ai_interaction_record?.response}</Text>
                        </Box>
                    </Box>
                </VStack>
            </Box>
        </Container>
    );
};