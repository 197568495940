import { Skeleton, Stack, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CustomFieldsGroupAPI } from '../../api/CustomFieldsGroupAPI';
import { UserType } from '../../api/UserApi';
import { ApprovalStatus, CustomFieldsGroup } from '../../models/custom_fields_group';
import {ProfileSection} from "protobuffer-ts/dist/organization_service/organization_profile_settings"
import { ObjectId } from '../../utils/ObjectId';
import { CustomFieldsGroupDisplay } from './CustomFieldsGroupDisplay';
import { useToaster } from '../../hooks/useToaster';

interface ProfileSectionCustomFieldsGroupsProps {
    userType: UserType;
    profileSection: ProfileSection;
    userId: ObjectId;
    onGroupStatusUpdate: (groupId: ObjectId, status: ApprovalStatus) => void;
}

export const ProfileSectionCustomFieldsGroups: React.FC<ProfileSectionCustomFieldsGroupsProps> = ({ userType, profileSection, userId, onGroupStatusUpdate }) => {

    const [groups, setGroups] = useState<CustomFieldsGroup[]>();
    const [loadingGroups, setLoadingGroups] = useState<boolean>(false);

    const { showErrorToast } = useToaster();

    const fetchGroups = async () => {
        setLoadingGroups(true);

        try {
            const grps = await CustomFieldsGroupAPI.get_groups_for_user_type_and_profile_section(userType, profileSection)
            setGroups(grps);
        } catch (error: any) {
            showErrorToast('Failed to fetch fields data: ' + error.response.data)
        } finally {
            setLoadingGroups(false);
        }
    };


    useEffect(() => {
        fetchGroups();
    }, [profileSection]);

    return (
        <>
            {loadingGroups ? (
                <>
                    <Skeleton height="100px" />
                    <Skeleton height="100px" />
                    <Skeleton height="100px" />
                </>
            ) : (
                <Stack gap={4}>
                    {groups?.map((group) => (
                        <CustomFieldsGroupDisplay key={group._id.toString()} group={group} userId={userId} onGroupStatusUpdate={onGroupStatusUpdate} />
                    ))}
                </Stack>
            )}
        </>
    );
}