import {
    Button,
    Stack,
    AlertDialogFooter,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialog,
} from '@chakra-ui/react';
import { memo, useMemo } from "react";
import { useForm } from "react-hook-form";
import { CustomFieldsGroupAPI } from '../../api/CustomFieldsGroupAPI';
import { CreateCustomFieldsGroup, CustomFieldsGroup } from '../../models/custom_fields_group';
import { UserType } from '../../api/UserApi';
import { customFieldsUserTypes } from './CustomFields';
import { ProfileSection, profileSectionToJSON } from "protobuffer-ts/dist/organization_service/organization_profile_settings"
import FormInput from '../forms/FormInput';
import FormSelect from '../forms/FormSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import { createCustomFieldsGroupSchema } from '../../validations/custom_fields';
import { useToaster } from '../../hooks/useToaster';
import { OwnershipKind, ResourceAccessSettings } from '../../models/resource_access_management';
import FormSelectOptions from '../forms/FormSelectObjects';
import { Lookup } from '../../models/lookup';

interface CreateCustomFieldsGroupDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onGroupAdded: (group: CustomFieldsGroup) => void;
    initialUserType: UserType;
    userTypeProfileSections: Map<UserType, ProfileSection[]>;
    resourceAccessSettings: ResourceAccessSettings[]
}

export const CreateCustomFieldsGroupDialog = memo(
    ({ isOpen, onClose, onGroupAdded, initialUserType, userTypeProfileSections, resourceAccessSettings }: CreateCustomFieldsGroupDialogProps) => {

        const { showErrorToast, showSuccessToast } = useToaster();

        const { register, handleSubmit, reset, setValue, watch, formState: { errors, isSubmitting }, control } = useForm({
            defaultValues: {
                user_type: initialUserType,
                profile_section: userTypeProfileSections.get(initialUserType)![0],
            },
            resolver: yupResolver(createCustomFieldsGroupSchema)
        });

        const userType = watch("user_type");
        const profileSectionsList = userTypeProfileSections.get(userType)!;

        const groupAccessSettingsLookups = useMemo((): Lookup[] => {
            return resourceAccessSettings
                .filter(setting => setting.ownership_kind === OwnershipKind.Unowned)
                .map(setting => ({
                    value: setting._id,
                    label: setting.name,
                }));
        }, [resourceAccessSettings]);

        const entriesAccessSettingsLookups = useMemo((): Lookup[] => {
            return resourceAccessSettings
                .filter(setting => setting.ownership_kind === OwnershipKind.Owned)
                .map(setting => ({
                    value: setting._id,
                    label: setting.name,
                }));
        }, [resourceAccessSettings]);


        const handleUserTypeChange = (selectedValue: UserType) => {
            setValue("user_type", selectedValue);
            setValue("profile_section", userTypeProfileSections.get(selectedValue)![0]);
        };

        const handleProfileSectionChange = (selectedValue: ProfileSection) => {
            setValue("profile_section", selectedValue);
        };

        const submitNewGroup = async (data: CreateCustomFieldsGroup) => {
            try {
                const newGroup = await CustomFieldsGroupAPI.create_new_custom_fields_group(data);

                showSuccessToast('Group created successfully');

                reset();
                onGroupAdded(newGroup);
                onClose();
            } catch (error: any) {
                showErrorToast('Failed to create a field: ' + error.response.data)
            }
        };

        return (
            <AlertDialog
                isOpen={isOpen}
                onClose={onClose}
                leastDestructiveRef={undefined}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Create Custom Fields Group
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <form onSubmit={handleSubmit(submitNewGroup)}>
                                <Stack gap="4">

                                    <FormInput
                                        name='group_name'
                                        label='Group Name'
                                        register={register('group_name', { required: true })}
                                        placeholder="Enter group name"
                                        error={errors.group_name?.message}
                                        isRequired
                                    />

                                    <FormSelect
                                        name='user_type'
                                        label='User Type'
                                        options={customFieldsUserTypes}
                                        register={{
                                            ...register("user_type", {
                                                onChange: (event) => handleUserTypeChange(event.target.value as UserType),
                                            }),
                                        }}
                                        error={errors.user_type?.message}
                                        isRequired
                                    />

                                    <FormSelect
                                        name='profile_section'
                                        label='Profile Section'
                                        options={profileSectionsList}
                                        register={{
                                            ...register("profile_section", {
                                                onChange: (event) => handleProfileSectionChange(event.target.value as ProfileSection),
                                            }),
                                        }}
                                        error={errors.profile_section?.message}
                                        isRequired
                                    />

                                    <FormSelectOptions
                                        name="group_access_settings"
                                        label="Group Access Settings"
                                        options={groupAccessSettingsLookups}
                                        control={control}
                                        error={errors.group_access_settings?.message}
                                        isRequired
                                    />

                                    <FormSelectOptions
                                        name="entries_access_settings"
                                        label="Entries Access Settings"
                                        options={entriesAccessSettingsLookups}
                                        control={control}
                                        error={errors.entries_access_settings?.message}
                                        isRequired
                                    />

                                </Stack>
                            </form>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button onClick={onClose} isDisabled={isSubmitting}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="green"
                                onClick={handleSubmit(submitNewGroup)}
                                isLoading={isSubmitting}
                                ml={3}
                            >
                                Submit
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        );
    }
);