import React, { useEffect, useState } from "react";
import { TabRoutes } from "../layout/TabRoutes";
import { Box } from "@chakra-ui/react";
import { AttendanceHomeroomList } from "./AttendanceHomeroomList";
import { AttendanceCourseList } from "./AttendanceCourseList";
import { useTeachersLookup } from "../../hooks/useTeachersLookup";
import { HomeroomAPI } from "../../api/HomeroomAPI";
import SkeletonTable from "../table/TableSkeleton";
import { BlHeading } from "../layout/Heading";
import { BlBreadcrumbs } from "../layout/Breadcrumbs";

export const Attendance = React.memo(() => {
    const [defaultTabIndex, setDefaultTabIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const { teachers } = useTeachersLookup();

    const tabs = [
        {
            label: "Homerooms",
            path: "/attendance/homerooms",
            element: (
                <AttendanceHomeroomList
                    teachers={teachers}
                />
            ),
        },
        {
            label: "Courses",
            path: "/attendance/courses",
            element: (
                <AttendanceCourseList
                    teachers={teachers}
                />
            ),
        },
    ];

    useEffect(() => {
            HomeroomAPI.homeroom_list(null, null, undefined, false)
                .then((res) => {
                    // check if homeroom is empty => redirect to courses page
                    if (!res.homerooms_count) setDefaultTabIndex(1) // courses page is in the tab 1 
                })
                .finally(() => {
                    setLoading(false)
                })
    }, [])


    return (
        <Box>
            <BlHeading> Classes </BlHeading>

            <BlBreadcrumbs />

            {
                loading ? 
                    <SkeletonTable></SkeletonTable> 
                :  
                    <TabRoutes tabs={tabs} defaultTabIndex={defaultTabIndex} />
            }
        </Box>
    );
})


export const StatusRadioEnum: Record<string, string> = {
    None: '0',
    Present: '1',
    Late: '2',
    Absent: '3'
}

export const RStatusRadioEnum: Record<string, string> = {
    '0': 'None',
    '1': 'Present',
    '2': 'Late',
    '3': 'Absent'
}
