// Utility function to convert camelCase or PascalCase to normal text
export const convertCaseToNormal = (text: string): string => {
    return text
        .replace(/([a-z])([A-Z])/g, '$1 $2')  // Add space between camelCase words
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')  // Add space between PascalCase words
        .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
};

export const toCapitalized = (str: string) => {
    const lower = str.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
};
