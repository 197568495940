import React, { useEffect, useMemo, useState } from "react";
import {
    Text,
    Stack,
    HStack,
    FormLabel,
    SimpleGrid,
    Alert,
    Box,
    AlertTitle,
    AlertDescription,
    Badge,
    Spacer,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    IconButton,
    Flex,
    GridItem,
    Icon,
    ModalOverlay,
    ModalBody,
    Input,
    ModalHeader,
    ModalContent,
    ModalFooter,
    Modal,
    ModalCloseButton,
    Spinner,
    List,
    ListItem,
    ListIcon,
    FormControl,
    Grid,
    Tooltip,
} from '@chakra-ui/react';
import { ReportAPI } from "../../api/ReportAPI";
import { ElementaryMarksOptions, ReportEntry, ReportEntryUpdate, ReviewStatus, ReviewStatusColor } from "../../models/report_entry";
import { to_oid } from "../../utils/ObjectId";
import { Student } from "../../models/student";
import { StudentAPI } from "../../api/StudentAPI";
import { BlCard } from "../layout/Card";
import {FiAlertCircle, FiBook, FiFileText} from "react-icons/fi";
import { ReportLayout } from "protobuffer-ts/dist/class_service/report_layout";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { updateReportEntrySchema } from "../../validations/report_entry";
import FormSwitch from "../forms/FormSwitch";
import FormInput from "../forms/FormInput";
import FormTextarea from "../forms/FormTextarea";
import LocalStorageService from "../../api/LocalStorageService";
import { UserRole } from "../../api/UserApi";
import { FlexWrap } from "../layout/FlexWrap";
import { convertCaseToNormal } from "../../utils/helpers";
import { LightButton } from "../layout/LightButton";
import { HiOutlineDocumentAdd, HiPencil } from "react-icons/hi";
import { RequestChangesDialog } from "./RequestChangesDialog";
import { CheckIcon, ChevronDownIcon, EditIcon } from "@chakra-ui/icons";
import { CommentTemplateDialolg } from "./CommentTemplateDialog";
import { CommentTemplate } from "../../models/comment_template";
import { ReportType, reportTypeFromJSON } from "protobuffer-ts/dist/class_service/semester";
import { retrieveCommentPlaceholders } from "../../utils/comment_template";
import { CourseAPI } from "../../api/CourseAPI";
import { learningSkillValues } from "../../models/semester";
import FormSelect from "../forms/FormSelect";
import { BiBrain, FaChalkboardTeacher, FaClipboardList, FaTasks, FaUserCheck } from "react-icons/all";
import { UnpublishDialog } from "./UnpublishDialog";
import { AttendanceAPI } from "../../api/AttendanceAPI";
import { AttendanceColors, AttendanceCount } from "../../models/attendance";
import { utils } from "../../utils/date_utils";
import { PdfGenerator } from "../homeroom_pdf/PdfGenerator";
import { PdfGenerator as HighSchoolPdfGenerator } from "../high_school_pdf/PdfGenerator";
import { CommentPlaceholdersGroup } from "./CommentPlaceholdersGroup";
import { useToaster } from "../../hooks/useToaster";

interface Props {
	report_entry: ReportEntry,
	report_layout?: ReportLayout
	student?: Student
    comment_templates: CommentTemplate[]
    show_attendance_count?: boolean
    update_templates: () => void
}

export const StudentReportEntry : React.FC<Props> = React.memo(({ report_entry, report_layout, student, comment_templates, show_attendance_count = true, update_templates }) => {
    const { showErrorToast, showSuccessToast } = useToaster();

	const [report_entry_state, set_report_entry_state] = useState<ReportEntry>(report_entry);

    const student_id = useMemo(() => report_entry_state.student, [report_entry_state])
    const semester_report_layout = useMemo(() => report_entry_state.semester_report_layout, [report_entry_state])

	const [student_state, set_student_state] = useState<Student | undefined>(student);
    const [selected_comment_template, set_selected_comment_template] = useState<CommentTemplate>()
    const [report_layout_state, set_report_layout_state] = useState<ReportLayout | undefined>(report_layout)
    const [attendence_count, set_attendance_count] = useState<Record<string,number>>();

    const user = LocalStorageService.getInstance().getUser();
    const isAdmin = user?.has_role(UserRole.Report_Card_Admin);

    const canRequestChanges = isAdmin && !(report_entry_state.review_status === ReviewStatus.Published)
    const canUnpublish = isAdmin && (report_entry_state.review_status === ReviewStatus.Published)
    const canApprove = isAdmin && (report_entry_state.review_status === ReviewStatus.Filled)
    const canPublish = isAdmin && (report_entry_state.review_status === ReviewStatus.Approved)
    const isPublished = (report_entry_state.review_status === ReviewStatus.Published)

    const [isOpen_request_changes, setIsOpen_request_changes] = useState(false)
    const [isOpen_unpublish, setIsOpen_unpublish] = useState(false)
    const [isOpen_comment_template, setIsOpen_comment_template] = useState(false)

    const [isOpen_smart_comment_dialog, setIsOpen_smart_comment_dialog] = useState(false);
    const [smart_comment_prompt, set_smart_comment_prompt] = useState<string>("");
    const isPromptValid = smart_comment_prompt.length > 0;
    const [loading, setLoading] = useState(false);

    // Disable Publish Button if Course Belongs to Homeroom
    const [disable_publishing, set_disable_publishing] = useState<boolean>(false);

    const commentCharLimit = useMemo(() => 
        report_layout_state?.commentCharLimit || semester_report_layout?.commentCharLimit
    , [report_layout_state])

    const [update_loading, set_update_loading] = useState(false)
    const [approve_loading, set_approve_loading] = useState(false)
    const [publish_loading, set_publish_loading] = useState(false)
    
    const { register, handleSubmit, reset, control, setValue, watch, setFocus, formState: { errors } } = useForm({
        resolver: yupResolver(updateReportEntrySchema(commentCharLimit)),
    });

    useFieldArray({ control, name: "sections" });
    useFieldArray({ control, name: "checkboxes" });
    useFieldArray({ control, name: "learning_skills" });
    
    useEffect(() => {
        const fetchAllData = async () => {
            try {
                if (!student_state) {
                    const _student = await StudentAPI.get_student(student_id.$oid)

                    set_student_state(_student!);
                }

                if (report_entry_state.course) {
                    if (!report_layout) {
                        report_layout = await CourseAPI.get_report_layout(report_entry_state.course!)
                    }

                    set_report_layout_state(report_layout)

                    // loop over layout sections and ensure that they're filled
                    const filledCheckboxes = report_layout?.checkBoxes?.map((checkbox, index) => ({
                        check_box_id: to_oid(checkbox.id!.id),
                        state: report_entry.checkboxes?.find(e => e.check_box_id.$oid == checkbox.id!.id)?.state || false
                    })) || []

                    const filledSections =  report_layout?.sections?.map((section, index) => ({
                        layout_id: to_oid(section.id!.id),
                        mark: report_entry.sections?.find(e => e.layout_id.$oid == section.id!.id)?.mark || ""
                    })) || []

                    report_entry = {
                        ...report_entry,
                        checkboxes: filledCheckboxes,
                        sections: filledSections
                    }
                }

                let start_date = report_entry.semester.startDate!
                let end_date = report_entry.semester_report_layout.reportDates
                    .find(_rdate => _rdate.reportType === report_entry_state.report_type)!
                    .distributionDate!


                let payload : AttendanceCount = {
                    student_id,
                    start_date: utils.get_utc_date(new Date(start_date)).toISOString(),
                    end_date: utils.get_utc_date(new Date(end_date)).toISOString()
                }


                if (show_attendance_count) {
                    if (report_entry.course) {
                        payload.course_id = report_entry.course

                        try {
                            let res = await AttendanceAPI.get_student_course_attendance_count(payload)
                            
                            set_attendance_count(res)
                        } catch (error) {}
                    } else {
                        payload.homeroom_id = report_entry.homeroom!

                        try {
                            let res = await AttendanceAPI.get_student_homeroom_attendance_count(payload)
    
                            set_attendance_count(res)
                        } catch (error) {}
                    }
                }


                updateData(report_entry)
            } catch (e) {
                console.error('Error fetching data', e);
            }
        };
    
        fetchAllData();
    }, [report_entry])

    useEffect(() => {
        const fetchCourse = async () => {
            if (report_entry && report_entry.course) {
                try {
                    let course = await CourseAPI.get_course(report_entry.course);
                    if (course && course.homeroom) {
                        set_disable_publishing(true);
                    }
                } catch {

                }
            };
        };

        fetchCourse();
    }, [report_entry]);

    const updateReportEntry = async (data : ReportEntryUpdate) => {

        set_update_loading(true)
        ReportAPI.update_report_entry(data).then((res) => {
            updateData(res);

            showSuccessToast('Report entry updated successfully');
        }).catch(err => {
			showErrorToast("Failed to update report entry: " + err.response.data)
        }).finally(() => {
            set_update_loading(false)
        })
    };

    const approve = async () => {

        set_approve_loading(true)
        try {
            const res = await ReportAPI.approve({ report_id: report_entry_state._id });

            updateData(res);
    
			showSuccessToast('Report entry approved successfully');
        } catch (error) {
			showErrorToast("Failed to approve report entry")
        }

        set_approve_loading(false)
    };

    const publish = async () => {

        set_publish_loading(true)
        try {
            const res = await ReportAPI.publish({ report_id: report_entry_state._id });

            updateData(res);
    
			showSuccessToast('Report entry published successfully');
        } catch (error) {
            try {
                let message: string = (error as { response: { data: string } }).response.data;
                showErrorToast(message, "Failed to publish report entry")
            } catch {
                showErrorToast("Failed to publish report entry")
            }
        }

        set_publish_loading(false)
    };

    const onCloseRequestchanges = (data? : ReportEntry) => {
        if (data) updateData(data);

        setIsOpen_request_changes(false)
    }

    const onCloseUnpublish = (data? : ReportEntry) => {
        if (data) updateData(data);

        setIsOpen_unpublish(false)
    }

    const onCloseTemplateDialog = async () => {
        update_templates()

        setIsOpen_comment_template(false)
    }   
    
    const updateData = (data : ReportEntry) => {
        const reportEntry = new ReportEntry(data)

        set_report_entry_state(reportEntry)

        reset(reportEntry)
    }

    const onTemplateClicked = (comment: string) => {
        const updatedComment = retrieveCommentPlaceholders(comment, student_state!)

        setValue("comment", updatedComment)
    }

    const onNewTemplateClicked = () => {
        set_selected_comment_template(undefined)

        setIsOpen_comment_template(true)
    }

    const onTemplateItemEditClicked = (e : React.MouseEvent<HTMLButtonElement, MouseEvent>, template : CommentTemplate) => {
        e.stopPropagation()
        set_selected_comment_template(template)
        setIsOpen_comment_template(true)
    }

    const onPlaceholderClicked = (value: string) => {
        const currentComment = watch("comment") || '';

        const newComment = currentComment + ` {{${value}}} `;
        const updatedComment = retrieveCommentPlaceholders(newComment, student_state!)

        setValue("comment", updatedComment)

        // focus on the comment input
        setFocus('comment')
    }

    const generate_smart_comment = async () => {
        setLoading(true);
        try {
            const comment = await ReportAPI.generate_report_entry_smart_comment(report_entry!._id.$oid, smart_comment_prompt);
            setValue("comment", comment);

            showSuccessToast('Smart comment generated and added.');

            setIsOpen_smart_comment_dialog(false);
        } catch (error) {
			showErrorToast("Failed to generate smart comment")
        } finally {
            setLoading(false);
        }
    };

    const validateReportEntry = async (report_entry: ReportEntry, commentCharLimit: number) => {
        const schema = updateReportEntrySchema(commentCharLimit);
        return await schema.isValid(report_entry);
    };


    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        const validate = async () => {
            const result = await validateReportEntry(report_entry_state, commentCharLimit);

            setIsValid(result);
        };

        validate().then();

    }, [report_entry, commentCharLimit, report_entry_state]);

    return (
        <Stack gap={4}                 
            as="form"
            onSubmit={handleSubmit(updateReportEntry)}
            id={report_entry_state?.student.$oid}
        >
			<BlCard>
                <FlexWrap mb="6">
                    <Stack spacing="0">
                        <HStack gap="2">
                            <Text fontSize="lg" fontWeight="bold">
                                {student_state?.first_name} {student_state?.last_name}
                            </Text>
                            {report_entry_state?.review_status && (
                                <Badge fontSize="xs" colorScheme={ReviewStatusColor[report_entry_state.review_status]}>
                                    {convertCaseToNormal(report_entry_state.review_status)}
                                </Badge>
                            )}
                            {report_entry_state.signed_by_guardian_name ? (
                                <Badge fontSize="xs" colorScheme="purple">
                                    Signed by {report_entry_state.signed_by_guardian_name}
                                </Badge>
                            ) : (
                                <Badge fontSize="xs" colorScheme="red">
                                    Not Signed by Guardian
                                </Badge>
                            )}
                        </HStack>
 
                        <FormLabel textColor="gray.500">
                            <strong>{report_entry_state.class_name}</strong>
                        </FormLabel>
                    </Stack>

                    <Spacer /> 
                </FlexWrap>

            { report_entry_state?.requested_changes && 
                <Alert borderRadius="lg" bgColor="orange.100" gap="4" mb="8">
                    <FiAlertCircle color="orange" size="50" />

                    <Box>
                        <AlertTitle color="orange.600">Changes Requested!</AlertTitle>

                        <AlertDescription fontWeight="bold">
                            {report_entry_state.requested_changes}
                        </AlertDescription>
                    </Box>
                </Alert>
            }

            { attendence_count && Object.keys(attendence_count).length > 0 && 
                <BlCard
                    bg="gray.50"
                    border="1px dashed"
                    borderColor="gray.300"
                    boxShadow={"none"}
                >
                    <FlexWrap justifyContent="space-between">
                        <Text fontWeight="bold">Attendance</Text>

                        <HStack wrap="wrap" spacing={0} gap={2}>
                            { Object.entries(attendence_count!).map(([status, count]) => (
                                <Box
                                    p={2}
                                    borderWidth="1px"
                                    display="flex"
                                    gap={4}
                                    alignItems="center"
                                    borderRadius="md"
                                    bg={`${AttendanceColors[status]}.50`}
                                    borderColor={`${AttendanceColors[status]}.200`}
                                    borderStyle="dashed"
                                >
                                    <Text fontWeight="bold" textColor={`${AttendanceColors[status]}.600`}>
                                        {status}
                                    </Text>

                                    <Badge rounded={"sm"} colorScheme={AttendanceColors[status]}>
                                        {count}
                                    </Badge>
                                </Box>
                            ))}
                        </HStack>
                    </FlexWrap>
                </BlCard>
            }

				<SimpleGrid
					bg="gray.50"
                    border="1px dashed"
                    borderColor="gray.300"
					p="4"
					rounded="md"
					columns={{md: 2, lg: 4 }} gap="6"
				>
                    { report_layout_state && 
                    <>
                        <Stack gap="4">
                            <Text fontWeight="bold">Checkboxes</Text>

                            {report_layout_state.checkBoxes?.map((checkbox, index) => (
                                <HStack key={checkbox.id?.id}>
                                    <FormSwitch 
                                        name={`checkboxes.${index}.state`}
                                        control={control}
                                    />

                                    <FormLabel> {checkbox.name} </FormLabel>
                                </HStack>
                            ))}

                            { semester_report_layout.showCreditsEarnedEntry && 
                                <>
                                  <Text fontWeight="bold">Course Credit</Text>

                                  <HStack>
                                          <FormSwitch 
                                              name={`credit_earned`}
                                              control={control}
                                              />
      
                                      <FormLabel> Credit Earned </FormLabel>
                                  </HStack>
                                </>
                            }
                        </Stack>

                        <Stack gap="4">
                            <Text fontWeight="bold">Marks</Text>

						{ report_layout_state?.sections?.map((section, index) => 
                            reportTypeFromJSON(report_entry?.report_type) ===  ReportType.Progress? 
                                <FormSelect
                                    label={section.name}
                                    name={`sections.${index}.mark`}
                                    options={ElementaryMarksOptions}
                                    register={register(`sections.${index}.mark`)}
                                    error={errors?.sections?.[index]?.mark?.message}
                                />
                                : 
                                <FormInput
                                    label={section.name}
                                    name={`sections.${index}.mark`}
                                    register={register(`sections.${index}.mark`)}
                                    error={errors?.sections?.[index]?.mark?.message}
                                    placeholder="Enter Mark"
                                />
                        )}

                        </Stack>
                    </>
                    }

                    <Stack gap="4" as={GridItem}  gridColumn="span 2">
                        <Text fontWeight="bold">Learning Skills</Text>

                        {report_entry_state.learning_skills.length > 0 && (report_entry_state.semester_report_layout?.learningSkills?.map((learningSkill, index) => (
                            <HStack key={learningSkill.id?.id}>
                                <FormSelect
                                    label={learningSkill.title}
                                    name={`learning_skills.${index}.mark`}
                                    register={register(`learning_skills.${index}.mark`)}
                                    error={errors?.learning_skills?.[index]?.mark?.message}
                                    options={learningSkillValues}
                                    isRequired
                                />
                            </HStack>
                        )))}
                    </Stack>
				</SimpleGrid>

				<Box>
                    <FlexWrap mb="2">
                        <Text fontWeight="bold">Comment</Text>

                        <Spacer />

                        <HStack>
                        <Tooltip
                            label="Please fill marks and learning skills to use AI comments!"
                            isDisabled={isValid}
                            shouldWrapChildren
                        >
                            <Button
                                onClick={() => setIsOpen_smart_comment_dialog(true)}
                                colorScheme="pink"
                                rightIcon={<BiBrain />}
                                isDisabled={!isValid}
                            >
                                AI Comment
                            </Button>
                        </Tooltip>

                        <Modal isOpen={isOpen_smart_comment_dialog} onClose={() => setIsOpen_smart_comment_dialog(false)} size="lg" isCentered>
                            <ModalOverlay />
                            <ModalContent borderRadius="md" shadow="lg" py={4}>
                                <ModalHeader fontSize="2xl" textAlign="center">
                                    Generate AI Comment
                                </ModalHeader>
                                <ModalCloseButton />

                                <ModalBody px={6} py={4}>
                                    {loading ? (
                                        <Flex align="center" justify="center" height="200px" direction="column">
                                            <Spinner size="lg" color="blue.400" thickness="5px" speed="0.6s" />
                                            <Text mt={4} fontSize="md" fontWeight="semibold" color="gray.600">
                                                Generating your smart comment, please wait...
                                            </Text>
                                        </Flex>
                                    ) : (
                                        <>
                                            <Text fontWeight="bold" fontSize="xl" textAlign="center" mb={8}>
                                                This comment generator will utilize the following data:
                                            </Text>

                                            <Grid
                                                templateColumns="repeat(auto-fit, minmax(100px, 1fr))"
                                                gap={8}
                                                width="100%"
                                                px={0}
                                            >
                                                {[
                                                    { icon: FaUserCheck, label: "Attendance", color: "blue.500" },
                                                    { icon: FaChalkboardTeacher, label: "Google Classroom", color: "green.500" },
                                                    { icon: FaClipboardList, label: "Report Marks", color: "orange.500" },
                                                    { icon: FiBook, label: "Markbook", color: "red.500" },
                                                    { icon: FaTasks, label: "Learning Skills", color: "purple.500" },
                                                    { icon: FiFileText, label: "Prompt", color: "cyan.500" },
                                                ].map(({ icon, label, color }) => (
                                                    <Box
                                                        key={label}
                                                        bg="white"
                                                        borderRadius="lg"
                                                        boxShadow="lg"
                                                        p={6}
                                                        textAlign="center"
                                                        _hover={{ transform: "scale(1.05)", transition: "0.2s" }}
                                                    >
                                                        <Icon as={icon} w={9} h={9} color={color} />
                                                        <Text mt={4} fontSize="sm">
                                                            {label}
                                                        </Text>
                                                    </Box>
                                                ))}
                                            </Grid>

                                            <Text fontWeight="bold" fontSize="lg" mb={3} mt={6}>
                                                Tips & Tricks for Teachers:
                                            </Text>
                                            <List spacing={3} mb={6}>
                                                <ListItem>
                                                    <ListIcon as={CheckIcon} color="green.500" />
                                                    Make sure to check the spelling of the student’s name.
                                                </ListItem>
                                                <ListItem>
                                                    <ListIcon as={CheckIcon} color="green.500" />
                                                    Ensure gender pronouns are accurate.
                                                </ListItem>
                                                <ListItem>
                                                    <ListIcon as={CheckIcon} color="green.500" />
                                                    Verify that marks and skills are filled in.
                                                </ListItem>
                                                <ListItem>
                                                    <ListIcon as={CheckIcon} color="green.500" />
                                                    Personalize the generated comment before submitting.
                                                </ListItem>
                                            </List>

                                            <FormControl mb={4}>
                                                <FormLabel fontWeight="semibold">Smart Comment Prompt</FormLabel>
                                                <Input
                                                    placeholder="Example: Student assignments are very organized!"
                                                    value={smart_comment_prompt}
                                                    onChange={(e) => set_smart_comment_prompt(e.target.value)}
                                                    focusBorderColor="blue.400"
                                                    size="md"
                                                    isRequired
                                                />
                                            </FormControl>
                                        </>
                                    )}
                                </ModalBody>

                                <ModalFooter>
                                    <Button
                                        onClick={generate_smart_comment}
                                        colorScheme="blue"
                                        mr={3}
                                        isDisabled={!isPromptValid || loading}
                                        size="md"
                                    >
                                        Generate
                                    </Button>
                                    <Button
                                        variant="ghost"
                                        onClick={() => setIsOpen_smart_comment_dialog(false)}
                                        isDisabled={loading}
                                        size="md"
                                    >
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>

                        <Menu>
                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                Templates
                            </MenuButton>
                            <MenuList>
                            {comment_templates.map(template => (
                                <MenuItem key={template.title} onClick={() => onTemplateClicked(template.comment)} flex="1" me="4">
                                    <Flex justify="space-between" align="center" width="100%" >
                                        <Box>
                                            {template.title}
                                        </Box>
                            
                                        <IconButton
                                            icon={<EditIcon />}
                                            aria-label="Edit template"
                                            size="sm"
                                            textColor="blue.500"
                                            variant="ghost"
                                            onClick={e => onTemplateItemEditClicked(e, template)}
                                        />
                                    </Flex>
                              </MenuItem>
                            ))}

                                <MenuDivider />

                                <MenuItem 
                                    key={"sad"}
                                    onClick={onNewTemplateClicked}
                                >
                                    New Template
                                </MenuItem>

                            </MenuList>
                        </Menu>
                        </HStack>
                    </FlexWrap>

                    <Stack gap="1">
                       <CommentPlaceholdersGroup onPlaceholderClick={onPlaceholderClicked} />

                        <FormTextarea
                            rows={5}
                            name="comment"
                            register={register("comment")}
                            error={errors?.comment?.message}
                            placeholder="Enter Comment"
                        />

                        <Text fontSize="sm" textColor="gray.500"> {watch("comment")?.length} / {commentCharLimit} </Text>
                    </Stack>
                </Box>

                <FlexWrap>
                    <Spacer />
                    { canRequestChanges && 
                    <>
                        <LightButton 
                            leftIcon={<HiPencil />}
                            color="orange"
                            onClick={() => setIsOpen_request_changes(true)}
                        >
                            Request Changes
                        </LightButton>

                        <RequestChangesDialog
                            report_id={report_entry_state._id}
                            isOpen={isOpen_request_changes} 
                            onClose={(report_entry_state) => onCloseRequestchanges(report_entry_state)}
                        />
                    </>
                    }

                    { canUnpublish && 
                    <>
                        <LightButton 
                            leftIcon={<HiPencil />}
                            color="orange"
                            onClick={() => setIsOpen_unpublish(true)}
                        >
                            Unpublish
                        </LightButton>

                        <UnpublishDialog
                            report_id={report_entry_state._id}
                            isOpen={isOpen_unpublish} 
                            onClose={(report_entry_state) => onCloseUnpublish(report_entry_state)}
                        />
                    </>
                    }

                    { canApprove && 
                        <Button 
                            leftIcon={<CheckIcon/>}
                            colorScheme="blue"
                            onClick={approve}
                            isLoading={approve_loading}
                        >
                            Approve
                        </Button>
                    }
                    {canPublish && (
                        <Tooltip
                            label="Publishing is disabled because this course has a homeroom. Course can be published from homeroom only!"
                            isDisabled={!disable_publishing} // Only show Tooltip when the button is disabled
                            hasArrow
                        >
                            <span> {/* Wrapping with span to fix disabled button hover issue */}
                                <Button
                                    leftIcon={<CheckIcon />}
                                    colorScheme="purple"
                                    onClick={publish}
                                    isLoading={publish_loading}
                                    isDisabled={disable_publishing} // Use `isDisabled` instead of `disabled`
                                >
                                    Publish
                                </Button>
                            </span>
                        </Tooltip>
                    )}

                    { report_entry.homeroom && isPublished &&
                        <PdfGenerator 
                            student_id={student_id}
                            homeroom_id={report_entry.homeroom}
                            report_type={report_entry.report_type}
                        />
                    }

                    { report_entry.course && isPublished &&
                        <HighSchoolPdfGenerator 
                            student_id={student_id}
                            semester_id={to_oid(report_entry.semester.id!.id)}
                            report_type={report_entry.report_type}
                        />
                    }

                    <Button 
                        colorScheme="green" 
                        leftIcon={<HiOutlineDocumentAdd />}
                        isLoading={update_loading}
                        type="submit" 
                    >
                        Save
                    </Button>
                </FlexWrap>
			</BlCard>

            <CommentTemplateDialolg
                isOpen={isOpen_comment_template} 
                onClose={onCloseTemplateDialog}
                comment_template={selected_comment_template} 
        />
        </Stack>
    );
});
