import * as React from "react"
import { Heading, GridItem, VStack, Text, SimpleGrid, Container } from '@chakra-ui/react';
import { Property } from "../3rd_party/Property";
import { LabeledProperty } from "../3rd_party/LabeledProperty";
import LocalStorageService from "../../api/LocalStorageService";
import { FamiliesOverviewCard } from "./homepage/FamiliesOverviewCard";
import { BlCard } from "../layout/Card";
import { AttendanceOverviewCard } from "./homepage/AttendanceOverviewCard";


export const HomeParent: React.FC = () => {

    const important_links = (
        <BlCard>
            <Text fontSize="xl" fontWeight="bold">
                Important Links
            </Text>

            <LabeledProperty label="How To Fill The Forms" link="https://youtu.be/lSuTtM6BF2w" />
        </BlCard>
    )

    const announcements = (
        <BlCard>
            <Text fontSize="xl" fontWeight="bold">
                Announcements
            </Text>

            <Property date="Feb 28, 2022" value="Starting Wednesday March 2nd, parents will receive attendance notifications" />
            <Property date="Feb 25, 2022" value="Initial software's web based application release" />
        </BlCard>
    )

    return (
        <>
            <VStack mb={4}>
                <Heading size="sm" fontWeight="bold">
                    Hello {LocalStorageService.getInstance().getUser()?.full_name}!
                </Heading>

                <Text fontSize="xl">Date: {new Date().toDateString()}</Text>
            </VStack>


            <Container>
                <SimpleGrid columns={{ lg: 2 }} gap={6}>
                    <GridItem>
                        <FamiliesOverviewCard />
                    </GridItem>

                    <GridItem>
                        <AttendanceOverviewCard />
                    </GridItem>

                    <GridItem> {important_links} </GridItem>

                    <GridItem> {announcements} </GridItem>
                </SimpleGrid>
            </Container>
        </>
    );
}