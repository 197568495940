import React from "react";
import { Box, Stack, Skeleton, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { AttendanceTableData } from "../../models/attendance";
import { useColorModeValue } from '@chakra-ui/react';
import {MiscCell, SignInCell, SignOutCell, StatusCell} from "./AttendanceCells";

interface AttendanceDesktopTableProps {
    entry_data: Array<AttendanceTableData> | undefined,
    single_student_view: boolean
    update?: () => void;
    editable: boolean,
    show_periods: boolean
}

export const AttendanceDesktopTable = React.memo(({ entry_data, single_student_view, update,  editable=true, show_periods }: AttendanceDesktopTableProps) => {
    // Define background colors for different modes
    const bgLight = useColorModeValue('gray.50', 'gray.700');
    const bgDark = useColorModeValue('white', 'gray.800');

    return (
        <Box minW={"900px"}>
            {entry_data ?
                <Table variant="simple">
                    {single_student_view ? (
                        <>
                            <Thead>
                                <Tr>
                                    <Th>Date</Th>
                                    <Th>Status</Th>
                                    <Th>Sign In Time</Th>
                                    <Th>Sign Out Time</Th>
                                    <Th>Misc</Th>
                                    { show_periods ? (
                                        <Th>Period</Th>): (<></>)
                                    }
                                </Tr>
                            </Thead>
                            <Tbody>
                                {entry_data.map((row, index) => (
                                    <Tr key={row.date} bg={index % 2 === 0 ? bgLight : bgDark}>
                                        <Td>{row.date}</Td>
                                        <Td>
                                            <StatusCell attendance={row.attendance_entry} table_data={row} editable={editable} update={update} />
                                        </Td>
                                        <Td>
                                            <SignInCell table_data={row} editable={editable} />
                                        </Td>
                                        <Td>
                                            <SignOutCell table_data={row} editable={editable} />
                                        </Td>
                                        <Td>
                                            <MiscCell table_data={row} editable={editable} />
                                        </Td>
                                        { show_periods ? (
                                            <Td>{row.attendance_entry.period_number}</Td>): (<></>)
                                        }
                                    </Tr>
                                ))}
                            </Tbody>
                        </>
                    ) : (
                        <>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Status</Th>
                                    <Th>Sign In Time</Th>
                                    <Th>Sign Out Time</Th>
                                    <Th>Misc</Th>
                                    { show_periods ? (
                                        <Th>Period</Th> ): (<></>)
                                    }
                                </Tr>
                            </Thead>
                            <Tbody>
                                {entry_data.map((row, index) => (
                                    <Tr key={row.student.id} bg={index % 2 === 0 ? bgLight : bgDark}>
                                        <Td>{row.name}</Td>
                                        <Td>
                                            <StatusCell attendance={row.attendance_entry} table_data={row} editable={editable}  update={update} />
                                        </Td>
                                        <Td>
                                            <SignInCell table_data={row} editable={editable} />
                                        </Td>
                                        <Td>
                                            <SignOutCell table_data={row} editable={editable} />
                                        </Td>
                                        <Td>
                                            <MiscCell table_data={row} editable={editable} />
                                        </Td>
                                        { show_periods ? (
                                            <Td>{row.attendance_entry.period_number}</Td>): (<></>)
                                        }
                                    </Tr>
                                ))}
                            </Tbody>
                        </>
                    )}
                </Table>
                :
                <Stack>
                    <Skeleton height='100px' />
                    <Skeleton height='100px' />
                    <Skeleton height='100px' />
                    <Skeleton height='100px' />
                    <Skeleton height='100px' />
                </Stack>
            }
        </Box>
    );
});
