import {BassemLabsStaff, BassemLabsStaffCreate, BassemLabsStaffUpdate, StaffStatus} from "../models/bassem_labs_staff";
import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";
import {DateTime} from "../utils/DateTime";

export namespace BassemLabsStaffAPI {
    export const get_user_by_id = async (user_id: ObjectId) => {
        return HTTPApi.get(`/bassem_labs_staff/id/${user_id.$oid}`)
            .then((res) => {
                return res.data as BassemLabsStaff;
            });
    };

    export const create_user = async (
        payload: BassemLabsStaffCreate
    ) => {
        return HTTPApi.post(`/bassem_labs_staff/create`, payload).then((res) => {
            return res.data as BassemLabsStaff;
        });
    };

    export const get_user_by_email = async (email: string) => {
        return HTTPApi.get(`/bassem_labs_staff/email/${email}`)
            .then((res) => {
                return res.data as BassemLabsStaff;
            });
    };

    export const get_users = async () => {
        return HTTPApi.get(`/bassem_labs_staff/`)
            .then((res) => {
                console.log(res.data[0])
                return res.data as BassemLabsStaff[];
            });
    };

    export const update_profile = async (
        user_id: ObjectId,
        payload: BassemLabsStaffUpdate
    ) => {
        return HTTPApi.post(`/bassem_labs_staff/update_profile/${user_id.$oid}`, payload).then((res) => {
            return res.data as BassemLabsStaff;
        });
    };

    export const update_status = async (user_id: ObjectId, status: StaffStatus) => {
        return HTTPApi.post(`/bassem_labs_staff/update_status/${user_id.$oid}`, {
            status,
        }).then((res) => {
            return res.data as BassemLabsStaff;
        });
    };
}