import React, { useState, useEffect } from 'react';
import { Box, Input, Stack, Text, HStack, Button, IconButton } from '@chakra-ui/react';
import { AttachmentIcon, AddIcon, CloseIcon } from '@chakra-ui/icons';
import { ObjectId } from '../../utils/ObjectId';
import { CustomFieldEntry } from "protobuffer-ts/dist/user_service/custom_field_entry";
import { AWSFile } from "protobuffer-ts/dist/utils/aws_file";
import { CustomFieldEntryAPI } from '../../api/CustomFIeldEntryAPI';
import { useToaster } from '../../hooks/useToaster';

interface DocumentCustomFieldProps {
    userId: ObjectId;
    fieldId: ObjectId;
    field_entry?: CustomFieldEntry;
    onDocumentUpdated: (entry: CustomFieldEntry) => Promise<void>; // to notify parent component to update GroupApprovalStatus
}

const DocumentCustomField: React.FC<DocumentCustomFieldProps> = ({ userId, fieldId, field_entry, onDocumentUpdated }) => {
    const [files, setFiles] = useState<AWSFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const { showErrorToast, showSuccessToast } = useToaster();

    useEffect(() => {
        const parseFiles = () => {
            if (!field_entry?.value) return [];

            try {
                const parsedValue = JSON.parse(field_entry.value);
                return Array.isArray(parsedValue) ? parsedValue.map(file => AWSFile.fromJSON(file)) : [];
            } catch (e) {
                console.error('Error parsing documents:', e);
                return [];
            }
        };
        
        setFiles(parseFiles());
    }, [field_entry]);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
        if (selectedFiles.length === 0) return;

        setUploading(true);
        try {
            for (const file of selectedFiles) {
                await uploadFile(file);
            }
        } catch (error) {
            console.error('Upload failed:', error);
        } finally {
            setUploading(false);
        }
    };

    const uploadFile = async (file: File) => {
        try {
            const updatedEntry = await CustomFieldEntryAPI.upload_document(file, file.name, fieldId, userId);
            
            const parsedFiles = JSON.parse(updatedEntry.value).map((f: any) => AWSFile.fromJSON(f));
            setFiles(parsedFiles);

            await onDocumentUpdated(updatedEntry);
            showSuccessToast(`Field has been deleted successfully`)
        } catch (error: any) {
            const message = error.response?.data || `Failed to delete the`;
            showErrorToast(message)
        }
    };

    const handleRemoveDocument = async (index: number) => {
        try {
            const updatedEntry = await CustomFieldEntryAPI.remove_document(userId, fieldId, index);
            
            const parsedFiles = JSON.parse(updatedEntry.value).map((f: any) => AWSFile.fromJSON(f));
            setFiles(parsedFiles);
            
            await onDocumentUpdated(updatedEntry);
            showSuccessToast(`Field has been deleted successfully`)
        } catch (error: any) {
            const message = error.response?.data || `Failed to delete the`;
            showErrorToast(message)
        }
    };


    return (
        <Box>
            <Stack spacing={4}>
                <Box>
                    <Stack spacing={2}>
                        {files.map((file, index) => (
                            <Box
                                key={`${file.preSignedUrl}-${index}`}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={3}
                                borderRadius="md"
                                bg="orange.100"
                                cursor="pointer"
                                border="2px dashed orange"
                                _hover={{ borderColor: "orange.600", bg: "orange.200" }}
                                onClick={() => window.open(file.preSignedUrl, '_blank')}
                            >
                                <HStack>
                                    <AttachmentIcon color="orange.600" />
                                    <Text fontWeight="medium">{file.fileName}</Text>
                                </HStack>
                                <HStack spacing={2}>
                                    <IconButton
                                        aria-label="Remove document"
                                        icon={<CloseIcon />}
                                        size="xs"
                                        variant="ghost"
                                        colorScheme="red"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRemoveDocument(index);
                                        }}
                                    />
                                </HStack>
                            </Box>
                        ))}
                    </Stack>
                </Box>

                <Box position="relative" display="inline-block" width="full">
                    <Button
                        as="label"
                        leftIcon={<AddIcon />}
                        colorScheme="blue"
                        variant="solid"
                        isLoading={uploading}
                        loadingText="Uploading..."
                        cursor={uploading ? 'wait' : 'pointer'}
                    >
                        Upload Files
                        <Input
                            type="file"
                            multiple
                            onChange={handleFileChange}
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            opacity="0"
                            cursor="pointer"
                            pointerEvents={uploading ? 'none' : 'auto'}
                            disabled={uploading}
                        />
                    </Button>
                </Box>
            </Stack>
        </Box>
    );
};

export default DocumentCustomField;