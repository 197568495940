import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    Badge,
    FormLabel,
    Box,
    Text,
    Stack,
    Spacer,
    HStack,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    ButtonGroup,
    Button,
	useBreakpointValue,
} from '@chakra-ui/react';
import {
	ReportEntry,
    ReportTypeColor,
    reportTypeOptions,
    ReviewStatus,
    ReviewStatusColor,
    reviewStatusOptions,
} from "../../models/report_entry";
import { ObjectId } from "../../utils/ObjectId";
import { useChipCell } from "../../hooks/useChipCell"
import { TableContent } from "../table/TableContent";
import { ReportAPI } from "../../api/ReportAPI";
import { FlexWrap } from "../layout/FlexWrap";
import { Select } from "chakra-react-select";
import { ReportType, reportTypeFromJSON, reportTypeToJSON, Semester } from "protobuffer-ts/dist/class_service/semester";
import { StudentAPI } from "../../api/StudentAPI";
import { convertCaseToNormal } from "../../utils/helpers";
import { BlCard } from "../layout/Card";
import { SemesterAPI } from "../../api/SemesterAPI";
import { StudentReportEntry } from "./StudentReportEntry";
import { CommentTemplateAPI } from "../../api/CommentTemplateAPI";
import { CommentTemplate } from "../../models/comment_template";
import { BsGrid, BsTable } from "react-icons/bs";
import { CheckIcon } from "@chakra-ui/icons";
import { CourseAPI } from "../../api/CourseAPI";
import { to_oid } from "../../utils/ObjectId";
import { ReportLayout } from "protobuffer-ts/dist/class_service/report_layout";
import { Student } from "../../models/student";
import { useToaster } from "../../hooks/useToaster";
import { proto_utils } from "../../utils/proto_utils";

interface Props {
	teacher_id?: ObjectId
	student_id?: ObjectId
  }

export const ReportsQueue = React.memo(({ teacher_id, student_id }: Props) => {
    const [view, set_view] = useState<'table' | 'grid'>('table');
	const { showErrorToast, showSuccessToast } = useToaster()

	const [report_entries, set_report_entries] = useState<ReportEntry[]>([]);
    const [students, set_students] = useState<Record<string, Student>>({});
    const [semesters, set_semesters] = useState<Semester[]>([]);
    const [comment_templates, set_comment_templates] = useState<CommentTemplate[]>([]);
	const [report_layouts, set_report_layouts] = useState<Record<string, ReportLayout>>({});

	const [loading, set_loading] = useState<boolean>(true);
	const [approve_loading, set_approve_loading] = useState<boolean>(false);
	const [publish_loading, set_publish_loading] = useState<boolean>(false);

	const [selected_report, set_selected_report] = useState< ReportEntry>();
	const [selected_semester, set_selected_semester] = useState<Semester>();
	const [selected_status, set_selected_status] = useState<ReviewStatus[]>([ReviewStatus.Filled]);
	const [selected_report_type, set_selected_report_type] = useState<ReportType>();

	const isDesktop = useBreakpointValue({ base: false, md: true });

	const toggle_selected_status = (reviewStatus : ReviewStatus) => {
		if (selected_status.includes(reviewStatus)) 
			set_selected_status(selected_status.filter(status => status !== reviewStatus))

		else 
			set_selected_status([...selected_status, reviewStatus])
	}

	const toggle_selected_report_type = (reportType : ReportType) => {
		if (selected_report_type === reportType) set_selected_report_type(undefined)

		else set_selected_report_type(reportType)
	}

	// the filter contains only Approved reprots and there's a selected semester
	const canBulkPublish = useMemo(
		() =>
		  	selected_status.length === 1 &&
		  	selected_status[0] === ReviewStatus.Approved &&
		  	selected_semester,
		[selected_status, selected_semester]
	);

	// the filter contains only Filled reprots and there's a selected semester
	const canBulkApprove = useMemo(
		() =>
		  	selected_status.length === 1 &&
		  	selected_status[0] === ReviewStatus.Filled &&
		  	selected_semester,
		[selected_status, selected_semester]
	);

    const cancelRef = useRef<HTMLButtonElement>(null);

	const fetchSemesters = async () => {
		try {
			if (student_id)  {
				let data = await StudentAPI.get_semesters(student_id)
				set_semesters(data)
			} else {
				let data = await SemesterAPI.get_active_semesters();

				set_semesters(data!);
			} 
		} catch (error) {
			showErrorToast("Failed to fetch semesters");
		}
	};

	useEffect(() => {
		fetchAllData();
	}, [selected_semester, student_id]);


	const fetchAllData = async () => {
		set_loading(true)

		fetchSemesters()

		let semester_id
		if (selected_semester?.id) semester_id = proto_utils.from_proto_object_id(selected_semester.id)

		ReportAPI.get_report_entries_queue(teacher_id, student_id, semester_id)
			.then(async (res : ReportEntry[]) => {
				// map over report entries and add a link
				const entries = res.map(report => ({
					...report,
					run_func: () => onRowClick(report)
				}))

				set_report_entries(entries)

				// Extract unique course IDs
				const courseSet = new Set<string>();
				res.forEach(report => {
					if (report.course)  courseSet.add(report.course.$oid);
				});

				// Fetch course report layouts and create hash map
				const reportLayoutPromises = Array.from(courseSet).map(courseId =>
					CourseAPI.get_report_layout(to_oid(courseId)).then(layout => ({
						courseId,
						layout
					}))
				);

				let layouts = await Promise.all(reportLayoutPromises)

				const courseLayoutHash: Record<string, ReportLayout> = {};
				layouts.forEach(({ courseId, layout }) => {
					courseLayoutHash[courseId] = layout;
				});

				set_report_layouts(courseLayoutHash)


				// loop over entries students and get their names 
				const studentsIds = res.map(report => report.student)

				if (!studentsIds.length) set_students({})
				
				else {
					let studentsHash : Record<string, Student> = {}

					await StudentAPI.students_by_ids(studentsIds).then(res => {
						res.map(student => studentsHash[student._id.$oid] = student)
					})

					set_students(studentsHash)
				}
			}).catch((err) => {
				showErrorToast(err.response.data)
			}).finally(() => {
				set_loading(false)
			})
		
		updateTemplates()
	}

	const filtered_entries = useMemo(() => report_entries.filter((entry) => {
		const matches_review_status = selected_status.length ? selected_status.includes(entry.review_status) : true;
		const matches_report_type = selected_report_type ? entry.report_type === selected_report_type : true;

		return matches_review_status && matches_report_type;
	}), 
	[report_entries, selected_status, selected_report_type]);


	const columns = [
		{
			Header: 'Student',
			accessor: 'student',
			Cell: (data: ObjectId) => (
				<Text size="sm" me="1" fontWeight="semibold" key={data.$oid}>
					{Student.getName(students[data.$oid]) }
				</Text>
			)
		},

		{
			Header: 'Class Name',
			accessor: 'class_name',
			Cell: (data : string) => (
				<Text size="sm" me="1" fontWeight="semibold">
					{data}
				</Text>
			)
		},

		{
			Header: 'Report Type',
			accessor: 'report_type',
			Cell: (data: ReportType) => useChipCell(reportTypeToJSON(data), ReportTypeColor[reportTypeFromJSON(data)])
		},

		{
			Header: 'Review Status',
			accessor: 'review_status',
			Cell: (data: ReviewStatus) => useChipCell(convertCaseToNormal(data), ReviewStatusColor[data])
		},

		{
			Header: 'Requested Changes',
			accessor: 'requested_changes',
			Cell: (data: string) => data || <Badge fontSize='xs' colorScheme="gray"> No Request</Badge>
		},
	]

	const onRowClick = (report : ReportEntry) => {
		set_selected_report(report)
	}

	const updateTemplates = async () => {
        try {
            const res = await CommentTemplateAPI.list()
    
            set_comment_templates(res)
        } catch (error) {}
    }

    const bulkPublish = async () => {

		set_publish_loading(true);

		try {
			const publishPromises = filtered_entries.map(entry => 
				ReportAPI.publish({ report_id: entry._id })
			);
	
			// Wait for all publish operations to complete
			await Promise.all(publishPromises);
	
			showSuccessToast('Report entries published successfully');
		} catch (error) {
			showErrorToast("Failed to publish one or more report entries")
		}
	

        set_publish_loading(false)

		fetchAllData()
    };

	const bulkApprove = async () => {
		set_approve_loading(true);

		try {
			const approvePromises = filtered_entries.map(entry => 
				ReportAPI.approve({ report_id: entry._id })
			);
	
			// Wait for all publish operations to complete
			await Promise.all(approvePromises);
	
			showSuccessToast('Report entries approved successfully');
		} catch (error) {
			showErrorToast("Failed to approve one or more report entries")
		}

		set_approve_loading(false)

		fetchAllData()
	}

    return (
		<>

		<BlCard mb="4">
			<FlexWrap>
				<Stack spacing="0">
					<HStack spacing="0">
						<FormLabel fontSize="lg" fontWeight="bold" mb="0">
							List Of Reports
						</FormLabel>

						<Badge colorScheme="purple" rounded="md">
							{filtered_entries.length ? `${filtered_entries.length} Total Records` : 'No Records'}
						</Badge>
					</HStack>

					<FormLabel textColor="gray.500">
						You can filter by Report Type, Review Status 
					</FormLabel>
				</Stack>

				<Spacer />

				<FlexWrap flexWrap={"wrap"}>
					<Box minW={{ md: 250 }}>
						<FormLabel>Semester</FormLabel>

						<Select
							isClearable
							placeholder="Select Semester"
							options={semesters}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.name}
							onChange={s => set_selected_semester(s || undefined)}
						/>
					</Box>

					<Box>
						<FormLabel> Report Type </FormLabel>

						<ButtonGroup size='sm' isAttached variant="outline" width="full">
							{ reportTypeOptions(selected_semester).map(reportType => 							
								<Button 
									flex="1"
									key={reportType.value}
									isActive={reportType.label === selected_report_type}
									_active={{bgColor: `${ReportTypeColor[reportType.label]}.50`, textColor: `${ReportTypeColor[reportType.label]}.500`}} 
									onClick={() => toggle_selected_report_type(reportType.label)}
								>
									{reportType.label}
								</Button> 
							)}
						</ButtonGroup>
					</Box>

					<Box>
						<FormLabel> Review Status </FormLabel>

						<ButtonGroup
							size="sm"
							variant="outline"
							spacing={0}
							gap={isDesktop ? 0 : 2}
							flexWrap={isDesktop ? undefined : "wrap"}
							isAttached={isDesktop}
							width="full"
						>
							{ reviewStatusOptions.map(reviewStatus => 							
								<Button 
									key={reviewStatus.value}
									isActive={selected_status.includes(reviewStatus.value)}
									_active={{bgColor: `${ReviewStatusColor[reviewStatus.value]}.50`, textColor: `${ReviewStatusColor[reviewStatus.value]}.500`}} 
									onClick={() => toggle_selected_status(reviewStatus.value)}
								>
									{convertCaseToNormal(reviewStatus.label)}
								</Button> 
							)}
						</ButtonGroup>
					</Box>

					<Box>
						<FormLabel> View </FormLabel>

						<ButtonGroup size='sm' isAttached variant="outline">
							<Button 
								leftIcon={<BsGrid />} 
								isActive={view === 'grid'}
								_active={{bgColor: 'blue.50', textColor: 'blue.500'}} 
								onClick={() => set_view('grid')}
							>
								Grid
							</Button>

							<Button 
								leftIcon={<BsTable />} 
								isActive={view === 'table'}
								_active={{bgColor: 'blue.50', textColor: 'blue.500'}} 
								onClick={() => set_view('table')}
							>
								Table
							</Button>
						</ButtonGroup>
					</Box>
				</FlexWrap>
			</FlexWrap>
		</BlCard>

		<BlCard mb="4" bgColor="purple.50">
			<FlexWrap>
				<Stack spacing="0">
					<HStack spacing="0">
						<FormLabel fontSize="lg" fontWeight="bold" mb="0">
							Tips!
						</FormLabel>
					</HStack>

					<FormLabel textColor="gray.500">
						select a semester, filter ONLY by approved report cards, to be able to publish them at once! 
					</FormLabel>

					<FormLabel textColor="gray.500">
						select a semester, filter ONLY by filled report cards, to be able to approve them at once! 
					</FormLabel>
				</Stack>

				<Spacer />

				<Button 
					leftIcon={<CheckIcon />}
					colorScheme="green"
					isDisabled={!canBulkApprove}
					isLoading={approve_loading}
					onClick={bulkApprove}
				>
					Approve
				</Button>

				<Button 
					leftIcon={<CheckIcon />}
					colorScheme="purple"
					isDisabled={!canBulkPublish}
					isLoading={publish_loading}
					onClick={bulkPublish}
				>
					Publish
				</Button>
			</FlexWrap>
		</BlCard>

		{ view === 'table' ? (
			<>
			<TableContent columns={columns} data={filtered_entries} loading={loading} />

			<AlertDialog
				isOpen={!!selected_report}
				leastDestructiveRef={cancelRef}
				onClose={() => set_selected_report(undefined)}
			>
			<AlertDialogOverlay>
				<AlertDialogContent minW={{ md: 800, lg: 900}}>
					{ selected_report && 
						
					<StudentReportEntry
						report_entry={selected_report}
						comment_templates={comment_templates}
						update_templates={updateTemplates}
						report_layout={(selected_report?.course && report_layouts[selected_report.course.$oid]) || undefined}
						show_attendance_count={false}
						student={students[selected_report._id.$oid]}
						/>
					}
				</AlertDialogContent>
			</AlertDialogOverlay>
			</AlertDialog>
			</>
		) : (
			<Stack>
				
			{ filtered_entries.map((entry, index) => 
				<StudentReportEntry
					key={entry._id.$oid}
					report_entry={entry}
					comment_templates={comment_templates}
					update_templates={updateTemplates}
					report_layout={(entry?.course && report_layouts[entry.course.$oid]) || undefined}
					show_attendance_count={false}
					student={students[entry.student.$oid]}
				/>
			)}
		</Stack>
		)}
		</>
    );
});
