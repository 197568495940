import React from "react"
import { Stack, Box, useColorModeValue, Text, Button, HStack, Badge, Progress, Spacer, useToast, Tabs, TabList, TabPanels, Tab, TabPanel,
} from "@chakra-ui/react";
import { Migration, MigrationStatus } from "../../models/migration"
import { MigrationAPI } from "../../api/MigrationAPI"
import { useToaster } from "../../hooks/useToaster";

export const Migrations = () =>  {

    const [migrations, set_migrations] = React.useState<Migration[]>()

    React.useEffect(() => {
        MigrationAPI.migrations().then((res) => {
            set_migrations(res)
        })
    }, [])

    return (
        <>
        <Stack>
            <Tabs>
            <TabList>
                <Tab>Active</Tab>
                <Tab>Archived</Tab>
            </TabList>

            <TabPanels>
                <TabPanel>
                    <>
                    {migrations?.filter(e => e.status != MigrationStatus.Inactive).map((e) => {
                        return (
                            <>
                                <MigrationComp migration={e} set_migrations={set_migrations} migrations={migrations}/>
                                <br/>
                            </>
                        )
                        })
                    }
                    </>
                </TabPanel>
                <TabPanel>
                    <>
                    {migrations?.filter(e => e.status == MigrationStatus.Inactive).map((e) => {
                        return (
                            <>
                                <MigrationComp migration={e} set_migrations={set_migrations} migrations={migrations}/>
                                <br/>
                            </>
                        )
                        })
                    }
                    </>
                </TabPanel>
            </TabPanels>
            </Tabs>
        </Stack>
        </>
    )
}


interface MigrationCard {
    migration: Migration
    set_migrations: React.Dispatch<React.SetStateAction<Migration[] | undefined>>
    migrations: Migration[] | undefined
}
const MigrationComp = ({migration, set_migrations, migrations}: MigrationCard) => {
    let { showErrorToast } = useToaster();

    const [is_loading, set_is_loading] = React.useState<boolean>(migration.status == MigrationStatus.Running || migration.status == MigrationStatus.RollingBack)
    const [progress, set_progress] = React.useState<number>((migration.progress/migration.progress_total)*100)

    const update = (m: Migration) =>  {
        let idx = migrations!.findIndex((e) => e._id.$oid == m._id.$oid)
        let arr = [...migrations!]
        arr[idx] = m
        set_migrations(arr);
        set_is_loading(false)
    }

    const get_progress = () => {
        let interval = setInterval(() => {
            MigrationAPI.get_migration(migration._id).then((e) => {
                if(e.status != MigrationStatus.Running && e.status != MigrationStatus.RollingBack){
                    clearInterval(interval)
                }
                update(e)
            })
        }, 1000)
    }

    const run = () => {
        set_is_loading(true)
        get_progress()
        MigrationAPI.run(migration._id).then((e) => {
            update(e)
        }).catch((err) => {
            showErrorToast(err.response.data)
            set_is_loading(false)
            MigrationAPI.get_migration(migration._id).then(e => update(e))
        })
    }

    const roll_back = () => {
        set_is_loading(true)
        get_progress()
        MigrationAPI.roll_back(migration._id).then((e) => {
            update(e)
        }).catch((err) => {
            showErrorToast(err.response.data)
            set_is_loading(false)
            MigrationAPI.get_migration(migration._id).then(e => update(e))
        })
    }

    const archive = () => {
        set_is_loading(true)
        MigrationAPI.archive(migration._id).then((e) => {
            update(e)
        }).catch((err) => {
            showErrorToast(err.response.data)
            set_is_loading(false)
            MigrationAPI.get_migration(migration._id).then(e => update(e))
        })
    }

    React.useEffect(()=>{
        set_progress((migration.progress/migration.progress_total)*100)
        set_is_loading(migration.status == MigrationStatus.Running || migration.status == MigrationStatus.RollingBack)
    }, [migration])

    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Text fontSize={"xl"}>{migration.file_name}</Text>
                <HStack>
                    <Text>Status: </Text>
                    <Badge colorScheme={badgeEnum[migration.status]}>{migration.status}</Badge>
                </HStack>
                <br/>
                <HStack>
                    <Progress flex="1" hasStripe value={progress} colorScheme="green" />
                    <Text>{migration.progress}/{migration.progress_total}</Text>
                </HStack>
                <br/>
                {migration.error_reason &&
                    <Text>Error: {migration.error_reason}</Text>
                }
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <Spacer/>
                    <Button colorScheme={"green"} onClick={run} isLoading={is_loading} isDisabled={is_loading}>Run</Button>
                    <Button colorScheme={"orange"} onClick={roll_back} isLoading={is_loading} isDisabled={is_loading}>Rollback</Button>
                    <Button colorScheme={"red"} onClick={archive} isLoading={is_loading} isDisabled={is_loading}>Archive</Button>
                </Stack>
            </Stack>
        </Box>
    )
}

const badgeEnum: Record<string, string> = {
    "Active": "green",
    "Inactive": "orange",
    "Running": "blue",
    "Ran": "green",
    "RollingBack": "blue",
    "RolledBack": "green",
    "Error": "red",
}