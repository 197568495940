import { Badge, Flex, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import { FiAtSign, FiCheckCircle, FiUser } from "react-icons/fi";
import { BlCard } from "../layout/Card";
import { TeacherAPI } from "../../api/TeacherAPI";
import { useToaster } from "../../hooks/useToaster";
import { Teacher, TeacherProfile } from "../../models/teacher";
import { useState } from "react";
import { TeacherProfileForm } from "./ProfileForm";

interface TeacherProfileCardProps {
	teacher: Teacher;
	set_teacher: React.Dispatch<React.SetStateAction<Teacher>>
	| React.Dispatch<React.SetStateAction<Teacher | undefined>>
}

export const TeacherProfileCard: React.FC<TeacherProfileCardProps> = ({
	teacher,
	set_teacher,
}) => {
	const { showErrorToast, showSuccessToast } = useToaster();
	const [loading, setLoading] = useState<boolean>(false)

	const update_profile = async (data: TeacherProfile) => {
		setLoading(true)

		try {
			await TeacherAPI.update_profile(teacher._id, data);

			const newTeacher = await TeacherAPI.get_teacher(teacher._id);

			set_teacher(newTeacher);
			showSuccessToast("Profile updated successfully");
		} catch (err) {
			showErrorToast("Failed to update profile");
		}

		setLoading(false)
	};

	return (
		<SimpleGrid columns={{ lg: 4 }} gap={4}>
			{/* Read-Only Information Card */}
			<BlCard>
				<Flex gap="8" wrap="wrap">
					<Flex gap={2} align="center">
						<Icon as={FiCheckCircle} boxSize={6} color="blue.500" />
						<Text color="gray.600">
							Status:{" "}
							<Badge
								colorScheme={teacher.status === "Active" ? "green" : "red"}
								ml={2}
							>
								{teacher.status}
							</Badge>
						</Text>
					</Flex>

					<Flex gap={2} align="center">
						<Icon as={FiUser} boxSize={6} color="green.500" />
						<Text color="gray.600">
							Full Name:{" "}
							<strong>
								{teacher.first_name} {teacher.last_name}
							</strong>
						</Text>
					</Flex>

					<Flex gap={2} align="center">
						<Icon as={FiAtSign} boxSize={6} color="orange.500" />
						<Text color="gray.600">
							Username: <strong>{teacher.username}</strong>
						</Text>
					</Flex>
				</Flex>
			</BlCard>

			{/* Editing Form Card */}
			<BlCard gridColumn={{ lg: "span 3" }}>
				<Text fontSize="lg" fontWeight="bold" mb={4}>
					Teacher Information
				</Text>

				<TeacherProfileForm teacher={teacher} onSubmit={update_profile} loading={loading} />
			</BlCard>
		</SimpleGrid>
	);
};
