import * as yup from "yup";
import { ObjectId } from "../utils/ObjectId";  // Ensure you have the correct path for ObjectId
import { ObjectId as ProtoObjectId } from "protobuffer-ts/dist/utils/object_id"

export const updateReportLayoutSchema = yup.object().shape({
    _id: yup.mixed<ObjectId>().required("Report Layout ID is required"),
    comment_char_limit: yup.number().required("Comment Character Limit is required"),
    sections: yup.array().of(
        yup.object().shape({
            id: yup.mixed<ProtoObjectId>().required(),
            name: yup.string().required("Section name is required"),
        })
    ).required("At least one section is required"),
    check_boxes: yup.array().of(
        yup.object().shape({
            id: yup.mixed<ProtoObjectId>().required(),
            name: yup.string().required("Check Box name is required"),
        })
    ).required("At least one checkbox is required"),
    credit_weight: yup.number().required("Credit Weight is required"),
});

export const updateSemesterReportLayoutSchema = yup.object().shape({
    _id: yup.mixed<ObjectId>().label("Report Layout").required(),
    semester_id: yup.mixed<ObjectId>().label("Semester ID").required(),
    comment_char_limit: yup.number().label("Comment Character Limit").required(),

	include_progress_report_cards: yup.boolean().label('Include Progress Report Cards').required(),
	show_credits_earned_entry: yup.boolean().label('Show Credits Eearned Entry').required(),

    due_date: yup.date().label('Due Date').required(),
    distribution_date: yup.date().label('Distribution Date').required().min(yup.ref('due_date'), "Distribution Date can't be before Due date"),

    learning_skills: yup.array().of(
        yup.object().shape({
            _id: yup.mixed<ProtoObjectId>().label("Section ID").required(),
            title: yup.string().label("Learning Skill title").required(),
            description: yup.string().label("Learning Skill description").required(),
        })
    ).required("At least one Learning Skill is required"),
});