import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    FormLabel,
    HStack,
    Textarea,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { ActionType } from "../../../models/onboarding";
import DatePicker from "../../DatePicker";

interface OnboardingMessageDialogProps {
    isOpen: boolean;
    onClose: () => void;
    messageType: ActionType;
    initialMessage: string;
    onConfirm: (message: string, interviewDate?: Date) => void;
}

export const OnboardingMessageDialog = ({
    isOpen,
    onClose,
    messageType,
    initialMessage,
    onConfirm
}: OnboardingMessageDialogProps) => {
    const cancelRef = useRef<HTMLButtonElement>(null);
    const [message, setMessage] = useState(initialMessage);
    const [interviewDate, setInterviewDate] = useState<Date>();

    const getTitle = () => {
        switch (messageType) {
            case ActionType.Interview: return "Send Interview Invitation";
            case ActionType.Approve: return "Send Approval Notification";
            case ActionType.Reject: return "Send Rejection Notification";
            case ActionType.Withdraw: return "Send Withdraw Notification";
            case ActionType.RequireChanges: return "Require Changes From Parents";
            case ActionType.Enroll: return "Enroll Student";
        }
    };

    const handleConfirm = () => {
        if (messageType === ActionType.Interview) {            
            onConfirm(message, interviewDate!);
        } else {
            onConfirm(message);
        }
    };

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>{getTitle()}</AlertDialogHeader>
                    <AlertDialogBody>
                    {messageType === ActionType.Interview && (
                            <HStack mb={4}>
                                <FormLabel> Interview Date: </FormLabel>
                                <DatePicker
                                    placeholderText="Select interview date"
                                    selectedDate={interviewDate}
                                    onChange={(value) => {
                                        setInterviewDate(value as Date);
                                    }}
                                />
                            </HStack>
                        )}
                        <Textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder={`Enter ${messageType} message...`}
                            minHeight="200px"
                        />
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="blue"
                            ml={3}
                            onClick={handleConfirm}
                        >
                            Confirm
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};