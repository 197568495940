import {Lookup} from "../../models/lookup";
import { Homeroom } from "protobuffer-ts/dist/class_service/homeroom";
import React, { useRef, useState } from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Skeleton,
    Stack,
    Text,
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {ObjectId} from "../../utils/ObjectId";
import {HomeroomAPI} from "../../api/HomeroomAPI";
import {Grades} from "../../models/student";
import {Select as SearchSelect} from "chakra-react-select";
import {FiChevronDown} from "react-icons/fi";
import { useToaster } from '../../hooks/useToaster';


interface CreateHomeroomDialogProps {
    isOpen: boolean
    onClose: any
    teachers: Lookup[]
    semesters: Lookup[]
    clone_from?: Homeroom
}

export const CreateHomeroomDialog = React.memo(({ isOpen, onClose, clone_from, teachers, semesters }: CreateHomeroomDialogProps) => {
    const { showErrorToast } = useToaster();
    const navigate = useNavigate();
    const [loading, set_loading] = useState<boolean>(false);

    const [name, set_name] = useState<string>(clone_from ? clone_from.name : "");
    const [semester, set_semester] = useState<ObjectId>()
    const [teacher, set_teacher] = useState<ObjectId | undefined>(undefined);
    const [grade, set_grade] = useState<string | undefined>(clone_from ? clone_from.grade : undefined);

    const cancelRef = useRef<HTMLButtonElement>(null);

    const create = () => {
        if(name && teacher && grade && semester){
            set_loading(true)
            HomeroomAPI.create(name, semester, Grades.get(grade)!, [teacher]).then((res) => {
                set_loading(false)
                cancel()
                navigate("/class/homerooms/" + res.id!.id)
            })
        }else{
            showErrorToast('Please fill in all the required information')
        }
    };

    const cancel = () => {
        set_name(clone_from?.name || "");
        set_teacher(undefined);
        set_grade(clone_from?.grade);

        onClose();
    };

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {clone_from ? `Cloning Homeroom From ${clone_from.name}` : 'Create Homeroom'}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {loading ? (
                            <Stack>
                                <Text>Please Wait... You will be redirected soon</Text>
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                            </Stack>
                        ) : (
                            <>
                                <FormControl id="name" isRequired>
                                    <FormLabel>Homeroom Name</FormLabel>
                                    <Input
                                        isRequired
                                        value={name}
                                        onChange={(value) => set_name(value.target.value)}
                                    />
                                </FormControl>
                                <br></br>
                                <FormControl id="semester" isRequired>
                                    <FormLabel>Semester</FormLabel>
                                    <SearchSelect
                                        name="flavors"
                                        options={semesters}
                                        placeholder="Select a semester..."
                                        onChange={(e: any) => {set_semester(e.value as ObjectId)}}
                                    />
                                </FormControl>
                                <br />
                                <FormControl id="teacher" isRequired>
                                    <FormLabel>Teacher</FormLabel>
                                    <SearchSelect
                                        name="flavors"
                                        options={teachers}
                                        placeholder="Select a teacher..."
                                        onChange={(e: any) => {set_teacher(e.value as ObjectId)}}
                                    />
                                </FormControl>
                                <br />
                                <FormControl id="grade" isRequired>
                                    <FormLabel>Grade</FormLabel>
                                    <Select isRequired
                                            value={grade}
                                            placeholder="Please Select the Grade"
                                            icon={<FiChevronDown/>}
                                            onChange={(value) => {
                                                set_grade(value.target.value)
                                            }}
                                    >
                                        {
                                            Array.from(Grades).map(([key, value]) => (
                                                <option value={key}>{value}</option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <br />
                            </>
                        )}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        {loading ? null : (
                            <>
                                <Button ref={cancelRef} onClick={cancel}>
                                    Cancel
                                </Button>
                                <Button colorScheme='green' onClick={create} ml={3}>
                                    Create
                                </Button>
                            </>
                        )}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
});