import React, { useState } from 'react';
import { Text } from '@chakra-ui/react';
import { TableTeacher } from '../../models/teacher'
import { TableContent } from '../table/TableContent'
import { ObjectId } from '../../utils/ObjectId'
import { DeleteDialog } from '../dialog/DeleteDialog'
import { RemoveIconButton } from '../layout/RemoveIconButton';

interface TeachersTableProps {
	teachers?: TableTeacher[]
	onDelete: (_id: ObjectId) => void
}

const SimpleTeacherList: React.FC<TeachersTableProps> = ({ teachers = [], onDelete }) => {
	const [isDialogOpen, setDialogOpen] = useState(false)
	const [selectedTeacher, setSelectedTeacher] = useState<ObjectId | null>(null)

	const openDialog = (teacherId: ObjectId) => {
		setSelectedTeacher(teacherId)
		setDialogOpen(true)
	}

	const closeDialog = () => {
		setDialogOpen(false)
		setSelectedTeacher(null)
	}

	const confirmRemoveTeacher = async () => {
		if (!selectedTeacher) return

		await onDelete(selectedTeacher)

		closeDialog()
	}

	const columns = [
		{
			Header: 'Name',
			accessor: 'profile',
			Cell: function cell(value: any) {
				return (
					<>
						<Text fontWeight={600}>
							{value.first_name} {value.last_name}
						</Text>

						<Text textColor='gray.500' display={{ base: "none", md: "block" }}>{value.email}</Text>
					</>
				)
			}
		},
		{
			Header: '',
			accessor: '_id',
			Cell: (value : ObjectId) => (
				<div style={{ textAlign: 'right' }}>
					<RemoveIconButton
						onClick={() => openDialog(value)}
					/>
				</div>
			)
		}
	]

	return (
		<>
			<TableContent data={teachers} columns={columns} />

			<DeleteDialog
				item="teacher"
                isOpen={isDialogOpen}
                onClose={closeDialog}
                action={confirmRemoveTeacher}
            />
		</>
	)
}

export default SimpleTeacherList
