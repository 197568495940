import {OrganizationProfileSettings} from "protobuffer-ts/dist/organization_service/organization_profile_settings"
import { ObjectId } from "../utils/ObjectId";
import HTTPApi from "./ApiCommon";

export namespace OrganizationProfileSettingsAPI {
    export const get_organization_profile_settings = async () => {
        return HTTPApi.get("/organization_profile_settings/")
            .then((res) => {
                return res.data as OrganizationProfileSettings;
            });
    };

    export const add_parent_profile_section = async (new_profile_section: string) => {
        return HTTPApi.post("/organization_profile_settings/add_parent_profile_section", {
            new_profile_section,
        }).then((res) => {
            return res.data as OrganizationProfileSettings;
        });
    };

    export const remove_parent_profile_section = async (remove_profile_section: string) => {
        return HTTPApi.post("/organization_profile_settings/remove_parent_profile_section", {
            remove_profile_section,
        }).then((res) => {
            return res.data as OrganizationProfileSettings;
        });
    };

    export const add_teacher_profile_section = async (new_profile_section: string) => {
        return HTTPApi.post("/organization_profile_settings/add_teacher_profile_section", {
            new_profile_section,
        }).then((res) => {
            return res.data as OrganizationProfileSettings;
        });
    };

    export const remove_teacher_profile_section = async (remove_profile_section: string) => {
        return HTTPApi.post("/organization_profile_settings/remove_teacher_profile_section", {
            remove_profile_section,
        }).then((res) => {
            return res.data as OrganizationProfileSettings;
        });
    };

    export const add_student_profile_section = async (new_profile_section: string) => {
        return HTTPApi.post("/organization_profile_settings/add_student_profile_section", {
            new_profile_section,
        }).then((res) => {
            return res.data as OrganizationProfileSettings;
        });
    };

    export const remove_student_profile_section = async (remove_profile_section: string) => {
        return HTTPApi.post("/organization_profile_settings/remove_student_profile_section", {
            remove_profile_section,
        }).then((res) => {
            return res.data as OrganizationProfileSettings;
        });
    };

    export const set_student_primary_id_custom_field = async (student_primary_id: ObjectId) => {
        return HTTPApi.post("/organization_profile_settings/set_student_primary_id_custom_field", {
            student_primary_id,
        }).then((res) => {
            return res.data as OrganizationProfileSettings;
        });
    };
}