import React, { ReactNode } from 'react';
import { Heading, HeadingProps } from '@chakra-ui/react';

interface BlHeadingProps extends HeadingProps {
	children: ReactNode;
}

export const BlHeading: React.FC<BlHeadingProps> = ({ children, ...props }) => {
	return (
		<Heading size="sm" mb="6" fontWeight="bold" textAlign="center" {...props}>
			{children}
		</Heading>
	);
};
