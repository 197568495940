import { UserRole } from "../api/UserApi";
import { DateTime } from "../utils/DateTime";
import { ObjectId } from "../utils/ObjectId";


export enum StudentStatus {
    Active = "Active",
    Inactive = "Inactive",
}


export class Teacher {
    _id: ObjectId;
    roles: UserRole[]
    status: StudentStatus;
    username: string;
    first_name: string;
    last_name: string;
    gender: string;
    email: string;
    personal_email: string;
    date_of_birth: DateTime;
    phone_number: string;

    constructor(data: any){
        this._id = data._id
        this.username = data.username
        this.roles = data.roles

        this.status = data.status

        this.first_name = data.first_name; 
        this.last_name = data.last_name; 
        this.gender = data.gender; 
        this.email = data.email; 
        this.personal_email = data.personal_email; 
        this.date_of_birth = new DateTime(data.date_of_birth);
        this.phone_number = data.phone_number;
    }

    static getProfile = (teacher : Teacher) => ({
        first_name: teacher.first_name, 
        last_name: teacher.last_name, 
        gender: teacher.gender, 
        email: teacher.email, 
        personal_email: teacher.personal_email, 
        date_of_birth: teacher .date_of_birth.to_date(),
        phone_number: teacher.phone_number,
    })
}


export interface TeacherProfile {
    first_name: string,
    last_name: string,
    gender: string,
    personal_email: string
    date_of_birth: Date,
    phone_number: string,
    email?: string,
}
export interface TeacherList {
    teachers: Array<ListTeacher>,
    teachers_count: number
}
export interface ListTeacher {
    _id: ObjectId,
    first_name: string,
    last_name: string,
    gender: string,
    email: string,
    personal_email: string
    date_of_birth: DateTime,
    phone_number: string,
}
export interface TableTeacher {
    _id: ObjectId, 
    profile: {
        first_name: string,
        last_name: string,
        email: string
    }
}