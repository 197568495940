import { Box, Center, Heading, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react";
import { OnboardingSettings } from "protobuffer-ts/dist/organization_service/onboarding_settings";
import { useEffect, useState } from "react";
import { OnboardingSettingsAPI } from "../../../api/OnboardingSettingsAPI";
import { StudentAPI } from "../../../api/StudentAPI";
import { useToaster } from "../../../hooks/useToaster";
import { ApprovalStatus } from "../../../models/custom_fields_group";
import { StudentCounts } from "../../../models/onboarding";
import { StudentStatus } from "../../../models/student";
import { StudentsList } from "../StudentsList";
import { ApplicantStudents } from "./ApplicantStudents";
import { WaitlistStudents } from "./WaitlistStudents";



export const OnboardingPage = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [onboardingSettings, setOnboardingSettings] = useState<OnboardingSettings>();
    const [studentCounts, setStudentCounts] = useState<StudentCounts>();
    const { showErrorToast } = useToaster();

    const fetchOnboardingSettings = () => {
        setLoading(true);
        OnboardingSettingsAPI.get_onboarding_settings()
            .then((data) => {
                setOnboardingSettings(data);
                setLoading(false);
            })
            .catch(() => {
                showErrorToast("Failed to load settings");
                setLoading(false);
            });
    }

    const fetchStudentCounts = async (): Promise<void> => {
        try {
            const [waitlist, interview, applicants, rejected] = await Promise.all([
                StudentAPI.count_students({"statuses": [StudentStatus.Waitlist]}),
                StudentAPI.count_students({"statuses": [StudentStatus.Interview]}),
                StudentAPI.count_students({"statuses": [StudentStatus.Applicant]}),
                StudentAPI.count_students({"statuses": [StudentStatus.Rejected]}),
            ]);
    
            setStudentCounts({
                waitlist,
                interview,
                applicants,
                rejected
            });
        } catch (error) {
            showErrorToast("Failed to load student counts");
        }
    };

    useEffect(() => {
        fetchOnboardingSettings();
        fetchStudentCounts();
    }, []);

    const handleStudentCountUpdate = () => {
        fetchStudentCounts();
    };

    if (loading) {
        return (
            <Box maxW="800px" mx="auto" mt="10" p="5">
                <VStack spacing="5" align="stretch">
                    <Skeleton height="40px" />
                    <Skeleton height="25px" />
                    <Skeleton height="25px" />
                    <Skeleton height="70px" />
                    <Skeleton height="25px" />
                </VStack>
            </Box>
        );
    }

    if (!onboardingSettings) {
        return null;
    }

    return (
        <Box>
            <Tabs isLazy lazyBehavior="unmount">
                <TabList>
                    <Tab>Waitlist ({studentCounts?.waitlist})</Tab>
                    {onboardingSettings.hasInterviewForNewcomers && <Tab>Interview ({studentCounts?.interview})</Tab>}
                    <Tab>Applicants ({studentCounts?.applicants})</Tab>
                    <Tab>Rejected ({studentCounts?.rejected})</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <WaitlistStudents onboardingSettings={onboardingSettings} onStudentCountUpdate={handleStudentCountUpdate} />
                    </TabPanel>
                    {onboardingSettings.hasInterviewForNewcomers && (
                        <TabPanel>
                            <StudentsList status={StudentStatus.Interview}/>
                        </TabPanel>
                    )}
                    <TabPanel>
                        <ApplicantStudents onStudentCountUpdate={handleStudentCountUpdate} />
                    </TabPanel>
                    <TabPanel>
                        <StudentsList status={StudentStatus.Rejected}/>
                    </TabPanel>
            </TabPanels>
            </Tabs>
        </Box>
    )
};
