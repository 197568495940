import { Box, Flex, FlexProps, useColorModeValue, Link, Icon } from "@chakra-ui/react";
import * as React from "react";
import { MdFiberManualRecord } from "react-icons/md"; // bullet-like icon

interface Props extends FlexProps {
  label: string;
  link: string;
}

export const LabeledProperty = ({label, link, ...flexProps} : Props) => {
  return (
    <Flex mb={2} {...flexProps}>
        <Link href={link} textDecoration="none" _hover={{ color: "blue.500" }} target={"_blank"}>
          <Flex align="center">
            <Icon as={MdFiberManualRecord} boxSize={3} color="blue.500" mr={2} />

            {label}
          </Flex>
        </Link>
    </Flex>
  );
};
