import { Text } from "@chakra-ui/layout";
import { getLookupLabel, Lookup } from "../models/lookup";
import { ObjectId } from "../utils/ObjectId";

export const useTeacherCell = (
  data: ObjectId[] = [],
  teachers: Lookup[] = [],
  showAll?: boolean
) => {
  const visibleTeachers = showAll ? data : data.slice(0, 3);
  const teacherNames = visibleTeachers.map((teacherId: ObjectId) =>
    getLookupLabel(teachers, teacherId)
  );

  if (!showAll && data.length > 3) {
    teacherNames.push("and others...");
  }

  const teacherText = teacherNames.join(", ");
  return <Text fontWeight="semibold">{teacherText}</Text>;
};
