import React from 'react'
import { IconButton, IconButtonProps } from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'

interface CustomIconButtonProps extends Omit<IconButtonProps, 'aria-label'> {
	ariaLabel?: string
}

export const EditIconButton: React.FC<CustomIconButtonProps> = ({
	onClick,
	ariaLabel = 'Edit', 
	...props
}) => {
	return (
		<IconButton
			bgColor='blue.50'
			textColor='blue.600'
			_hover={{ bg: 'blue.100' }}
			aria-label={ariaLabel}
			icon={<EditIcon />}
			onClick={onClick}
			{...props}
		/>
	)
}
