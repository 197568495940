import {
    Box, Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from "@chakra-ui/react";
import React from "react";
import { SemesterList } from "../semesters/SemesterList";
import { NotificationList } from "../notifications/NotificationsList";
import { CustomFields } from "../custom_fields/CustomFields";
import OrganizationPage from "../organizations/OrganizationPage";
import { OnboardingSettingsPage } from "../onboarding_settings/OnboardingSettingsPage";
import { BlHeading } from "../layout/Heading";
import { ResourceAccessManagement } from "../resource_access_management/ResourceAccessManagement";

export const AdminPortal = React.memo(() =>  {

    return (<Box>
            <BlHeading> Admin Portal </BlHeading>

            <Tabs >
                <TabList>
                    <Tab>Organization Settings</Tab>
                    <Tab>Semesters</Tab>
                    <Tab>Notifications</Tab>
                    <Tab>Custom Fields</Tab>
                    <Tab>Resource Access Management</Tab>
                    <Tab>Onboarding Settings</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <OrganizationPage/>
                    </TabPanel>
                    <TabPanel>
                        <SemesterList />
                    </TabPanel>
                    <TabPanel>
                        <NotificationList/>
                    </TabPanel>
                    <TabPanel>
                        <CustomFields/>
                    </TabPanel>
                    <TabPanel>
                            <ResourceAccessManagement />
                    </TabPanel>
                    <TabPanel>
                        <OnboardingSettingsPage/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>

    );
});
