import React, { useState } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FiExternalLink, FiBook, FiCompass, FiAlertOctagon, FiBookOpen } from 'react-icons/fi';
import { CourseAPI } from '../../api/CourseAPI'; // Adjust the import based on your file structure
import { to_oid } from '../../utils/ObjectId';
import { HomeroomAPI } from '../../api/HomeroomAPI';
import { useToaster } from '../../hooks/useToaster';
import { openInNewTab } from '../../utils/route';

interface ExternalLinksProps {
    id: string;
    type: 'course' | 'homeroom'
}

const ExternalLinks: React.FC<ExternalLinksProps> = ({ id, type }) => {
    const [gclass_open_loading, set_gclass_open_loading] = useState(false); // Local state for loading
    const { showErrorToast } = useToaster();
    const getClassUrlFunction = type === 'course' ? CourseAPI.get_gclass_url : HomeroomAPI.get_gclass_url

    const open_gclass_url = () => {
        set_gclass_open_loading(true);
        getClassUrlFunction(to_oid(id))
            .then((url) => {
                openInNewTab(url);
                set_gclass_open_loading(false);
            })
            .catch(() => {
                showErrorToast('Failed to Open Google Classroom URL')
                
                set_gclass_open_loading(false);
            });
    };

    return (
        <Menu>
            <MenuButton
                as={Button} 
                bgColor="purple.100" 
                textColor="purple.800" 
                rightIcon={<ChevronDownIcon />}
            >
                Open
            </MenuButton>

            <MenuList>
                <MenuItem icon={<FiExternalLink />} onClick={open_gclass_url} isDisabled={gclass_open_loading}>
                    Google Classroom
                </MenuItem>
                <MenuItem icon={<FiBook />} onClick={() => openInNewTab(`/markbook/${type}s/${id}`)}>
                    Mark Book
                </MenuItem>
                <MenuItem icon={<FiCompass />} onClick={() => openInNewTab(`/attendance/${type}s/${id}`)}>
                    Attendance
                </MenuItem>
                <MenuItem icon={<FiAlertOctagon />} onClick={() => openInNewTab(`/violations/${type}s/${id}`)}>
                    Violations
                </MenuItem>
                <MenuItem icon={<FiBookOpen />} onClick={() => openInNewTab(`/reports/${type}s/${id}`)}>
                    Report Cards
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

export default ExternalLinks;
