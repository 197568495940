import React from "react";
import { Box, Button, FormControl, FormLabel, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import DatePicker from "../DatePicker";
import { AttendanceAPI } from "../../api/AttendanceAPI";
import { useToaster } from "../../hooks/useToaster";

export const AttendanceDownload =  React.memo(() =>  {
    const { showSuccessToast, showErrorToast} = useToaster()
    const [start_date, set_start_date] = React.useState<Date>()
    const [end_date, set_end_date] = React.useState<Date>()

    const download_csv = () => {
        if(start_date && end_date){
            AttendanceAPI.late_dismissal_csv_data(start_date, end_date).then(() => {
                showSuccessToast("Downloaded")
            })
        }
        else showErrorToast("Must Enter Start & End Date")
    }

    return (
        <>
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
            >
                <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                    <Text>Dismissal Time Export</Text>
                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <FormControl id="start_date" isRequired>
                            <FormLabel>Start Date</FormLabel>
                            <DatePicker 
                                selectedDate={start_date} 
                                onChange={(value) => {
                                    set_start_date(value as Date)
                                }}
                            />
                        </FormControl>
                        <FormControl id="end_date" isRequired>
                            <FormLabel>End Date</FormLabel>
                            <DatePicker 
                                selectedDate={end_date} 
                                onChange={(value) => {
                                    set_end_date(value as Date)
                                }}
                            />
                        </FormControl>
                    </Stack>
                    <Button colorScheme={"green"} onClick={download_csv}>Export Dismissal CSV</Button>
                </Stack>
                
        </Box>
        </>
    )
})