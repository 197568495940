import React, { useState, useEffect } from 'react';
import {
    SimpleGrid,
    Stack,
    List,
    ListItem,
    Flex,
    Text,
    FormControl,
    FormLabel,
    Box,
    useBreakpointValue,
    Switch,
} from '@chakra-ui/react';
import { Select } from "chakra-react-select";
import { ChevronRightIcon } from '@chakra-ui/icons';
import { HiOutlineBan, HiOutlineCalendar, HiOutlineClock } from 'react-icons/hi';
import { FaPercentage } from 'react-icons/fa';
import { InfoCard } from '../../layout/InfoCard';
import { Student } from '../../../models/student';
import { StudentAPI } from '../../../api/StudentAPI'; // Update path if needed
import { Course } from 'protobuffer-ts/dist/class_service/course';
import { BlCard } from '../../layout/Card';
import { Homeroom } from 'protobuffer-ts/dist/class_service/homeroom';
import { AttendanceAPI } from '../../../api/AttendanceAPI';
import { AttendanceMobileTable } from '../../attendance/AttendanceMobileTable';
import { AttendanceDesktopTable } from '../../attendance/AttendanceDesktopTable';
import SkeletonTable from '../../table/TableSkeleton';
import { AttendanceTableData, mapAttendanceEntries } from '../../../models/attendance';
import { FlexWrap } from '../../layout/FlexWrap';
import DatePicker from '../../DatePicker';

interface StudentAttendanceCardProps {
	student: Student;
}

export const StudentAttendanceCard: React.FC<StudentAttendanceCardProps> = React.memo(
	({ student }) => {
		const [loading, setLoading] = useState(false);
		const [homerooms, setHomerooms] = useState<Homeroom[]>([]);
		const [courses, setCourses] = useState<Course[]>([]);
		const [selectedItem, setSelectedItem] = useState<Course | Homeroom>();

		const [startDate, setStartDate] = useState<Date>();
		const [endDate, setEndDate] = useState<Date>();

		const [attendanceLoading, setAttendanceLoading] = useState(false);
		const [attendanceData, setAttendanceData] = useState<AttendanceTableData[]>([]);
		const [filteredData, setFilteredData] = useState<AttendanceTableData[]>([]);
		const [showPresent, setShowPresent] = useState<boolean>(false);

		// Example for toggling a mobile layout / drawer
		const isMobile = useBreakpointValue({ base: true, md: false });

		const onClose = () => {
			// For example, close a drawer if using Chakra's Drawer
		};

		useEffect(() => {
			setLoading(true);

			const fetchData = async () => {

				const [_courses, _homerooms,] = await Promise.all([
					StudentAPI.get_courses(student._id),
					StudentAPI.get_homerooms(student._id),
				]);

				const filteredCourses = _courses.filter((course: Course) => !course.homeroom);

				setCourses(filteredCourses);
				setHomerooms(_homerooms);

				// set selected item as first homeroom or first course
				setSelectedItem(_homerooms?.[0] || _courses?.[0])

				setLoading(false);
			};

			fetchData();

		}, [student._id]);

		/**
		 * Renders the sidebar list, combining homerooms and courses.
		 * Clicking an item sets it as selected in state.
		 */
		const sidebarList = (
			<List spacing={2}>
				{/* Show homerooms first */}
				{homerooms.map((item) => (
					<ListItem
						key={item.id?.id}
						cursor="pointer"
						p={2}
						borderRadius="md"
						fontWeight="semibold"
						bg={selectedItem?.id?.id === item.id?.id ? 'blue.50' : 'transparent'}
						color={selectedItem?.id?.id === item.id?.id ? 'blue.600' : 'inherit'}
						_hover={{ bg: 'gray.100', color: 'black' }}
						onClick={() => {
							setSelectedItem(item);
							if (isMobile) onClose();
						}}
					>
						<Flex align="center" justify="space-between">
							<Text as="span">{item.name}</Text>
							<ChevronRightIcon />
						</Flex>
					</ListItem>
				))}

				{/* Show courses next */}
				{courses.map((item) => (
					<ListItem
						key={item.id?.id}
						cursor="pointer"
						p={2}
						borderRadius="md"
						fontWeight="semibold"
						bg={selectedItem?.id?.id === item.id?.id ? 'blue.50' : 'transparent'}
						color={selectedItem?.id?.id === item.id?.id ? 'blue.600' : 'inherit'}
						_hover={{ bg: 'gray.100', color: 'black' }}
						onClick={() => {
							setSelectedItem(item);
							if (isMobile) onClose();
						}}
					>
						<Flex align="center" justify="space-between">
							<Text as="span">{item.name}</Text>
							<ChevronRightIcon />
						</Flex>
					</ListItem>
				))}
			</List>
		);

		// Fetch attendance data when a card, semester, or custom dates are selected
		useEffect(() => {
			if (!selectedItem || !startDate || !endDate) return;
			setAttendanceLoading(true);
			const id = selectedItem.id!.id;
			const isHomeroom = homerooms.some((h) => h.id?.id === id);
			let apiCall = isHomeroom
				? AttendanceAPI.get_student_homeroom_attendance_entries(id, student._id.$oid, startDate, endDate)
				: AttendanceAPI.get_student_course_attendance_entries(id, student._id.$oid, startDate, endDate);

			apiCall
				.then((data) => {
					const entries = mapAttendanceEntries(data!, student);
					setAttendanceData(entries);

					const filteredEntries = showPresent ? entries : entries.filter(item => item.attendance_entry.status !== 'Present');
					setFilteredData(filteredEntries);
				})
				.catch((err) => {
					console.error(err);

					setAttendanceData([]);
					setFilteredData([]);
				})
				.finally(() => {
					setAttendanceLoading(false);
				});
		}, [selectedItem, startDate, endDate, showPresent, homerooms, student]);

		// Compute totals for the InfoCards based on the attendanceData (not the filtered one)
		const totalDays = attendanceData.length;
		const totalPresent = attendanceData.filter(entry => entry.attendance_entry.status === 'Present').length;
		const totalLate = attendanceData.filter(entry => entry.attendance_entry.status === 'Late').length;
		const totalAbsent = attendanceData.filter(entry => entry.attendance_entry.status === 'Absent').length;
		const attendanceRate = totalDays > 0 ? Math.round((totalPresent / totalDays) * 100) : 0;

		return (
			<>
				{/* Top InfoCards row (attendance metrics) */}
				<SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={4} mb={8}>
					<InfoCard
						colorScheme="yellow"
						icon={HiOutlineClock}
						number={totalLate}
						text="Late Days"
					/>
					<InfoCard
						colorScheme="red"
						icon={HiOutlineBan}
						number={totalAbsent}
						text="Absent Days"
					/>
					<InfoCard
						colorScheme="blue"
						icon={HiOutlineCalendar}
						number={totalDays}
						text="Total Days"
					/>
					<InfoCard
						colorScheme="green"
						icon={FaPercentage}
						number={attendanceRate}
						text="Attendance Rate"
					/>
				</SimpleGrid>

				{/* Main layout: sidebar on the left, details on the right */}
				<SimpleGrid columns={{ lg: 4 }} gap={4}>
					{/* Left sidebar */}
					<Stack>
						<BlCard position="sticky" top="4" flex="0">
							{sidebarList}
						</BlCard>
					</Stack>

					{/* Right content area */}
					<Stack gap={4} gridColumn={{ lg: 'span 3' }}>
						{!loading && !selectedItem && (
							<Text>Please select a homeroom or course from the sidebar.</Text>
						)}

						{selectedItem && (
							<>
								<FlexWrap>
									<Box minW={250}>
										<FormControl>
											<FormLabel>Start Date</FormLabel>

											<DatePicker 
												placeholderText='Select Date...' 
												selectedDate={startDate} 
												onChange={(d) => setStartDate(d as Date)} 
											/>
										</FormControl>
									</Box>
									<Box minW={250}>
										<FormControl>
											<FormLabel>End Date</FormLabel>

											<DatePicker 
												placeholderText='Select Date...' 
												selectedDate={endDate} 
												onChange={(d) => setEndDate(d as Date)} 
											/>
										</FormControl>
									</Box>
									<FormControl display="flex" alignItems="center" pt={4}>
										<Switch colorScheme='blue' isChecked={showPresent} onChange={() => setShowPresent(!showPresent)}>
											Show Present
										</Switch>
									</FormControl>
								</FlexWrap>

								{attendanceLoading ? (
									<SkeletonTable />
								) : (
									<>
										{isMobile ? (
											<AttendanceMobileTable
												entry_data={filteredData}
												single_student_view={true}
												editable={true}
											/>
										) : (
											<AttendanceDesktopTable
												entry_data={filteredData}
												update={() => {}}
												editable={true}
												single_student_view={true}
												show_periods={false}
											/>
										)}
									</>
								)}
							</>
						)}
					</Stack>
				</SimpleGrid>
			</>
		);
	}
);
