import React, {useEffect} from "react"
import { useParams } from "react-router-dom";
import { ProfileCard } from "./Cards/ProfileCard";
import { Student } from "../../models/student";
import { StudentAPI } from '../../api/StudentAPI';
import { StudentFamilyCard } from './Cards/StudentFamilyCard';


export const PrintableStudent = () =>  {

    let { student_id } = useParams();

    const [student, set_student] = React.useState<Student>();

    useEffect(() => {
        StudentAPI.get_student(student_id!)
            .then((res) => {
                set_student(res!)
            })
    }, []);

    return (
        <>
  
                    
                {student &&
                <>
                    <ProfileCard student={student} set_student={set_student}/>
                    <StudentFamilyCard student={student}/>
                </>
                }

        
        </>
    )
}
