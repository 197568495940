import { UserRole, UserType } from "../api/UserApi";
import { ObjectId } from "../utils/ObjectId";

export interface AccessRule {
  permission_type: AccessPermissionType;
  principal?: principalType;
  wildcard?: WildcardAccess;
}

export interface ResourceAccessSettings {
  _id: ObjectId;
  organization: ObjectId;
  name: string;
  ownership_kind: OwnershipKind;
  user_type: UserType;
  access_rules: AccessRule[];
}

export interface CreateResourceAccessSettings {
  name: string;
  ownership_kind: OwnershipKind;
  user_type: UserType;
  access_rules: AccessRule[];
}

export enum OwnershipKind {
  Owned = "Owned",
  Unowned = "Unowned",
}

export enum AccessPermissionType {
  AllowRead = "AllowRead",
  DenyRead = "DenyRead",
  AllowWrite = "AllowWrite",
  DenyWrite = "DenyWrite",
}

export enum principalType {
  User = "User",
  Family = "Family",
}

export type WildcardAccess =
  | { Role: UserRole }
  | "AllPrincipals"
  | "AllStudentPrincipals"
  | "AllTeacherPrincipals"
  | "AllParentPrincipals";

export const resourceUserTypes = [UserType.Student, UserType.Teacher, UserType.Parent];

export const accessRuleToString = (rule: AccessRule): string => {
  if (rule.principal) {
    return rule.principal;
  }

  if (rule.wildcard) {
    if (typeof rule.wildcard === "object" && "Role" in rule.wildcard) {
      return `Role: ${rule.wildcard.Role}`;
    } else if (typeof rule.wildcard === "string") {
      switch (rule.wildcard) {
        case "AllPrincipals":
          return "All principals";
        case "AllStudentPrincipals":
          return "All students";
        case "AllTeacherPrincipals":
          return "All teachers";
        case "AllParentPrincipals":
          return "All parents";
        default:
          return "";
      }
    }
  }

  return "";
};


export interface AccessRuleFormValues {
  selection: FormSelection;
  roleValue?: UserRole;
}

export type FormSelection =
  | "user"
  | "family"
  | "role" // for selecting a specific role via second dropdown
  | "AllPrincipals"
  | "AllStudentPrincipals"
  | "AllTeacherPrincipals"
  | "AllParentPrincipals";

export const formSelectionOptions: FormSelection[] = ["user", "family", "role", "AllPrincipals", "AllStudentPrincipals", "AllTeacherPrincipals", "AllParentPrincipals"];
