import { Box, Stack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FamilyAPI } from "../../api/FamilyAPI";
import { Family } from "../../models/family";
import { to_oid } from "../../utils/ObjectId";
import { FamilyCard } from "../cards/family/FamilyCard";
import { BlHeading } from "../layout/Heading";

export const FamilyEdit: React.FC = () => {
    let { family_id } = useParams();
    const [family, set_family] = React.useState<Family>();

    const save_family = (family: Family) => {
        FamilyAPI.update(family).then((res) => {
            window.open("/parent/family", "_self")
        })
    }

    useEffect(()=> {
        FamilyAPI.get_family(to_oid(family_id!))
            .then((res) => {
                set_family(res)
            })
    }, [])

    return (
        <>
        <Box>
                <Box overflowX="auto" overflowY="hidden">
                    <BlHeading> Update Family </BlHeading>

                    { family &&
                        <Stack>
                            <FamilyCard family={family} save_family={save_family} save_on_edit={false}/>
                        </Stack>
                    }   
                </Box>
            </Box>
        </>
    )
}