import React, { useEffect, useState } from "react";
import { Button, FormControl, FormLabel, HStack, Input, Select, Spinner, Switch } from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";
import { StudentAPI } from "../../../api/StudentAPI";
import { useToaster } from "../../../hooks/useToaster";
import { Grades, Student } from "../../../models/student";
import { DateTime } from "../../../utils/DateTime";
import { ObjectId } from "../../../utils/ObjectId";
import DatePicker from "../../DatePicker";
import { OnboardingSettingsAPI } from "../../../api/OnboardingSettingsAPI";
import { AWSFile } from "protobuffer-ts/dist/utils/aws_file";
import { DocumentsDisplay } from "./DocumentsDisplay";
import { BlCard } from "../../layout/Card";


interface CreateStudentDialogProps {
    family_id: ObjectId;
    onStudentCreated: (newStudent: Student) => void;
}


export const CreateStudentDialog = React.memo(({ family_id, onStudentCreated }: CreateStudentDialogProps) => {
    const { showErrorToast } = useToaster()
    const [isCreated, setIsCreated] = React.useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [first_name, set_first_name] = useState<string>("");
    const [last_name, set_last_name] = useState<string>("");
    const [grade, set_grade] = useState<string>()
    const [gender, set_gender] = useState<string>()
    const [date_of_birth, set_date_of_birth] = useState<DateTime>();

    const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);

    const [schoolHandbook, setSchoolHandbook] = useState<AWSFile[]>();

    const fetchSchoolHandbook = async () => {
        try {
            setLoading(true);
            const handbook = await OnboardingSettingsAPI.get_school_handbook();
            setSchoolHandbook(handbook);
        } catch (err: any) {
            showErrorToast(err);
        } finally {
            setLoading(false);
        }
    }

    const create = () => {
        if (first_name && last_name && grade && gender && date_of_birth && isTermsChecked) {
            setLoading(true)
            StudentAPI.new_student({ first_name, last_name, grade, gender, family_id, date_of_birth })
                .then((new_student) => {
                    setLoading(false);
                    setIsCreated(true);
                    onStudentCreated(new_student);
                })
                .catch((err) => {
                    console.log(err);

                    showErrorToast('An Error Occurred')
                    setLoading(false)
                })
        } else showErrorToast('Please fill in all the required information')
    }

    const handleDateOfBirthChange = (date: any) => {
        if (date instanceof Date) {
            set_date_of_birth(DateTime.from_date(date));
        }
    }

    useEffect(() => {
        fetchSchoolHandbook();
    }, []);

    if (loading || !schoolHandbook) {
        return <Spinner />;
    }

    return (
        <BlCard>
            <FormControl id="first_name" isRequired>
                <FormLabel>First Name</FormLabel>
                <Input isRequired
                    value={first_name}
                    onChange={(value) => { set_first_name(value.target.value) }}
                />
            </FormControl>
            <FormControl id="last_name" isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input isRequired
                    value={last_name}
                    onChange={(value) => { set_last_name(value.target.value) }}
                />
            </FormControl>
            <FormControl id="grade" isRequired>
                <FormLabel>Incoming Grade</FormLabel>
                <Select isRequired
                    value={grade}
                    placeholder="Please Select the Grade"
                    icon={<FiChevronDown />}
                    onChange={(value) => {
                        set_grade(value.target.value)
                    }}
                >
                    {
                        Array.from(Grades).map(([key, value]) => (
                            <option value={key}>{value}</option>
                        ))
                    }
                </Select>
            </FormControl>
            <FormControl id="gender" isRequired>
                <FormLabel>Gender</FormLabel>
                <Select isRequired
                    value={gender}
                    placeholder="Please Select the Gender"
                    icon={<FiChevronDown />}
                    onChange={(value) => {
                        set_gender(value.target.value)
                    }}
                >

                    <option value="F">Female</option>
                    <option value="M">Male</option>

                </Select>
            </FormControl>
            <FormControl id="date_of_birth" isRequired>
                <FormLabel>Date of Birth</FormLabel>
                <DatePicker
                    selectedDate={date_of_birth?.to_date()}
                    onChange={(value) => { handleDateOfBirthChange(value) }}
                />
            </FormControl>

            <DocumentsDisplay title={"School Handbook"} files={schoolHandbook!} />

            <FormControl isRequired>
                <HStack>
                    <Switch
                        isChecked={isTermsChecked}
                        onChange={(e) => setIsTermsChecked(e.target.checked)}
                    />
                    <FormLabel htmlFor="termsSwitch" ml={2}>
                        I agree to the terms and conditions outlined in the school handbook
                    </FormLabel>
                </HStack>
            </FormControl>

            {!isCreated && (
                <Button colorScheme='green' onClick={create} ml={3}>
                    Create
                </Button>
            )}
        </BlCard>
    )

})
