import React from 'react';
import { View, Text as PdfText } from '@react-pdf/renderer';
import { Course } from 'protobuffer-ts/dist/class_service/course';
import { getReportMark, getReportMedianMark, getReportsByType, ReportEntry } from '../../models/report_entry';
import PdfStylesheet from './PdfStylesheet';
import { ReportType } from 'protobuffer-ts/dist/class_service/semester';

type CourseProps = {
  course: Course;
  reports: ReportEntry[];
  reportType: ReportType;
  isMiddleSchool: Boolean;
};

const styles = PdfStylesheet

export const CourseRowPdf: React.FC<CourseProps> = ({ course, reports, reportType, isMiddleSchool }) => {
  const sections = course.reportLayout?.sections || [];

  // Group reports by type
  const reportsByType = getReportsByType(reports);

  const ProgressReport = reportsByType[ReportType.Progress] || {};
  const MidtermReport = reportsByType[ReportType.Midterm] || {};
  const FinalReport = reportsByType[ReportType.Final] || {};

  let reportEntry =
    reportType === ReportType.Final ? FinalReport :
    reportType === ReportType.Midterm ? MidtermReport :
    ProgressReport;

  const isProgress = reportType === ReportType.Progress;

  // Define hidden cell
  const hiddenCell = <PdfText style={[styles.emptyCell]}>{course.name}</PdfText>;

  return (
    <View style={styles.gridContainer}>
      {/* Subject Name, sections and Checkboxes */}
      <View style={[styles.gridCell, styles.subjectCol, { borderRight: 'none', padding: 0 }]}>
        <PdfText style={styles.subjectText}>{course.name}</PdfText>

        {sections.map((section, i) => (
          <View key={i} style={[styles.gridCell, { border: 'none', marginBottom: 5 }]}>
            <PdfText style={styles.value}>{section.name}</PdfText>
          </View>
        ))}

        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          {course.reportLayout?.checkBoxes?.map(({ name, id }, i) => {
            const isChecked = reportEntry.checkboxes?.find(c => c.check_box_id.$oid === id?.id)?.state;
            return (
              <View key={i} style={{ marginLeft: 5, flexDirection: 'row', alignItems: 'center' }}>
                <View style={styles.checkbox}>
                  {isChecked && <PdfText style={styles.checkmark}>x</PdfText>}
                </View>
                <PdfText style={styles.value}>{` ${name}`}</PdfText>
              </View>
            );
          })}
        </View>
      </View>

      {isProgress ? (
        ['Progressing with Difficulty', 'Progressing Well', 'Progressing Very Well'].map((_checkbox, i) => (
          <View key={i} style={[styles.gridCell, styles.smallCol]}>
            <PdfText style={[styles.value, { textAlign: 'center' }]}>
              {reportEntry.sections?.some((section) => section.mark === _checkbox) ? '✔️' : ''}
            </PdfText>
          </View>
        ))
      ) : isMiddleSchool ? (
        <>
          <View style={[styles.gridCell, styles.xSmallCol, { borderRight: 'none' }]}>
            {hiddenCell}

            {sections.map((section, i) => (
              <PdfText key={i} style={[styles.markValue, { textAlign: 'center' }]}>
                {getReportMark(MidtermReport, section)}
              </PdfText>
            ))}
          </View>
          <View style={[styles.gridCell, styles.xSmallCol, { borderRight: 'none' }]}>
            {hiddenCell}

            {sections.map((section, i) => (
              <PdfText key={i} style={[styles.markValue, { textAlign: 'center' }]}>
                {getReportMedianMark(MidtermReport, section)}
              </PdfText>
            ))}
          </View>
          <View style={[styles.gridCell, styles.xSmallCol, { borderRight: 'none' }]}>
            {hiddenCell}

            {sections.map((section, i) => (
              <PdfText key={i} style={[styles.markValue, { textAlign: 'center' }]}>
                {getReportMark(FinalReport, section)}
              </PdfText>
            ))}
          </View>
          <View style={[styles.gridCell, styles.xSmallCol]}>
            {hiddenCell}

            {sections.map((section, i) => (
              <PdfText key={i} style={[styles.markValue, { textAlign: 'center' }]}>
                {getReportMedianMark(FinalReport, section)}
              </PdfText>
            ))}
          </View>
        </>
      ) : (
        <>
          <View style={[styles.gridCell, styles.MarkCol, { borderRight: 'none' }]}>
            {hiddenCell}

            {sections.map((section, i) => (
              <PdfText key={i} style={[styles.markValue, styles.value, { textAlign: 'center' }]}>
                {getReportMark(MidtermReport, section)}
              </PdfText>
            ))}
          </View>
          <View style={[styles.gridCell, styles.MarkCol]}>
            {hiddenCell}

            {sections.map((section, i) => (
              <PdfText key={i} style={[styles.markValue, styles.value, { textAlign: 'center' }]}>
                {getReportMark(FinalReport, section)}
              </PdfText>
            ))}
          </View>
        </>
      )}

      {/* Comments */}
      <View style={[styles.gridCell, styles.largeCol, { height: 125, fontSize: 7 }]}>
        <PdfText style={styles.smallValue}>{reportEntry.comment}</PdfText>
      </View>
    </View>
  );
};
