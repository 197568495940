import { useEffect, useState } from "react";
import { Box, Stack, Text, Spacer, HStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FamilyAPI } from "../../../api/FamilyAPI";
import { Family } from "../../../models/family";
import { BlCard } from "../../layout/Card";
import { EditIconButton } from "../../layout/EditIconButton";
import { FlexWrap } from "../../layout/FlexWrap";
import { LightButton } from "../../layout/LightButton";
import SkeletonTable from "../../table/TableSkeleton";

export const FamiliesOverviewCard = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [families, setFamilies] = useState<Family[]>([]);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchFamilies = async () => {
			setLoading(true);
			try {
				const data = await FamilyAPI.get_parent_families();

				setFamilies(data!);
			} catch (error) {
				console.error("Error fetching families", error);
			} finally {
				setLoading(false);
			}
		};
		fetchFamilies();
	}, []);

	return (
		<BlCard>
			<FlexWrap>
				<Text fontSize="lg" fontWeight="bold">
					Families Overview
				</Text>

				<Spacer />

				<LightButton
					color="blue"
					onClick={() => navigate("/parent/family")}
				>
					View All
				</LightButton>
			</FlexWrap>

			{ loading ? 
				<SkeletonTable height="50px" /> : 

				<Stack gap={2}>
					{families.map((family) => (
						<Box
							key={family._id.$oid}
							borderWidth="1px"
							borderStyle="dashed"
							borderRadius="md"
							background="gray.50"
							p={3}
						>
							<Stack direction="row" align="center" justify="space-between">
								<Box>
									<Text fontWeight="bold">{family.name}</Text>

									<Text fontSize="sm">
										Language: {family.information.language_spoken}
									</Text>

									<Text fontSize="sm">
										Emergency Contact: {family.information.emergency_contact.name}
									</Text>
								</Box>

								<EditIconButton
									aria-label="Edit Family"
									onClick={() =>
										navigate(`/parent/family/update/${family._id.$oid}`)
									}
								/>
							</Stack>
						</Box>
					))}
				</Stack>
			}

			<HStack justify="end">
			</HStack>
		</BlCard>
	);
};

