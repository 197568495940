import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Skeleton, Stack } from "@chakra-ui/react"
import React from "react"
import { useNavigate } from "react-router-dom"
import { TeacherAPI } from "../../api/TeacherAPI"
import { useToaster } from "../../hooks/useToaster"
import { Teacher, TeacherProfile } from "../../models/teacher"
import { DateTime } from "../../utils/DateTime"
import { to_oid } from "../../utils/ObjectId"
import { TeacherProfileForm } from "./ProfileForm"

interface CreateTeacherDialogProps {
	isOpen: boolean
	onClose: any
	cancelRef: any
}

export const CreateTeacherDialog = React.memo(({ isOpen, onClose, cancelRef }: CreateTeacherDialogProps) => {
	let navigate = useNavigate()
	const [loading, set_loading] = React.useState<boolean>(false);

	const create_teacher = (data: TeacherProfile) => {
		set_loading(true)

		TeacherAPI.create_teacher(data)
			.then((res) => {
				navigate("/teachers/" + res._id.$oid)

				onClose()
			}).finally(() => {
				set_loading(false)
			})
	}


	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
			size="xl"
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Create Teacher
					</AlertDialogHeader>

					<AlertDialogBody>
						<TeacherProfileForm onSubmit={create_teacher} loading={loading} />
					</AlertDialogBody>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
})