import { useEffect, useState } from "react";
import {
    Button,
    Stack,
    AlertDialogFooter,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialog,
    IconButton,
    Input,
    FormLabel,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { memo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomFieldsGroupAPI } from "../../api/CustomFieldsGroupAPI";
import { CreateCustomField, CustomField, CustomFieldType } from "../../models/custom_field";
import { ObjectId } from "../../utils/ObjectId";
import FormInput from "../forms/FormInput";
import FormSelect from "../forms/FormSelect";
import FormSwitch from "../forms/FormSwitch";
import FormTextarea from "../forms/FormTextarea";
import { createCustomFieldSchema } from "../../validations/custom_fields";
import RegexPatternBuilder from "./RegexPatternBuilder";
import { useToaster } from '../../hooks/useToaster';

interface CreateCustomFieldDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onFieldAdded?: (field: CustomField) => void;
    onFieldUpdated?: (field: CustomField) => void;
    groupId: ObjectId;
    fieldToEdit?: CustomField;
}

export const CreateCustomFieldDialog = memo(
    ({ isOpen, onClose, onFieldAdded, onFieldUpdated, groupId, fieldToEdit }: CreateCustomFieldDialogProps) => {
        const { showErrorToast, showSuccessToast } = useToaster();
        const [options, setOptions] = useState<string[]>([]);

        const { register, handleSubmit, reset, setValue, control, watch, formState: { errors, isSubmitting } } = useForm({
            defaultValues: {
                field_name: fieldToEdit?.name || "",
                field_type: fieldToEdit?.field_type || CustomFieldType.String,
                is_required: fieldToEdit?.is_required || false,
                description: fieldToEdit?.description || "",
                is_archived: fieldToEdit?.is_archived || false,
            },
            resolver: yupResolver(createCustomFieldSchema),
        });

        const fieldType = watch("field_type");

        const handleFieldTypeChange = (selectedValue: CustomFieldType) => {
            setValue("field_type", selectedValue);
        };

        const addOption = () => {
            setOptions([...options, ""]);
        };

        const updateOption = (index: number, value: string) => {
            const updatedOptions = [...options];
            updatedOptions[index] = value;
            setOptions(updatedOptions);
        };

        const removeOption = (index: number) => {
            const updatedOptions = options.filter((_, i) => i !== index);
            setOptions(updatedOptions);
        };

        const handlePatternChange = (newPattern: string) => {
            setValue("regex_pattern", newPattern);
        }

        const submitNewField = async (data: CreateCustomField) => {
            try {
                const finalData = { ...data, ...(fieldType === CustomFieldType.Select && { options }) };

                if (fieldToEdit) {
                    const updatedField = await CustomFieldsGroupAPI.update_custom_field(fieldToEdit!._id, finalData);
                    showSuccessToast('Field updated successfully');
                    onFieldUpdated?.(updatedField);
                } else {
                    const newField = await CustomFieldsGroupAPI.add_custom_field_to_group(groupId, finalData);
                    showSuccessToast('Field Created successfully');
                    onFieldAdded?.(newField);
                }

                reset();
                setOptions([]);
                onClose();
            } catch (error: any) {
                showErrorToast("Failed to create a field: " + error.response.data);
            }
        };

        const setInitialFieldOptions = () => {
            if (fieldToEdit?.field_type === CustomFieldType.Select) {
                setOptions(fieldToEdit.options!);
            }
        };

        useEffect(() => {
            setInitialFieldOptions();
        }, []);

        return (
            <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={undefined}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {fieldToEdit ? "Update" : "Create"} Custom Field
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <form onSubmit={handleSubmit(submitNewField)}>
                                <Stack gap="4">
                                    <FormInput
                                        name="field_name"
                                        label="Field Name"
                                        register={register("field_name", { required: true })}
                                        error={errors.field_name?.message}
                                        isRequired
                                    />

                                    <FormSelect
                                        name="field_type"
                                        label="Field Type"
                                        options={Object.values(CustomFieldType)}
                                        isDisabled={!!fieldToEdit}
                                        register={{
                                            ...register("field_type", {
                                                onChange: (event) => handleFieldTypeChange(event.target.value as CustomFieldType)
                                            }),
                                        }}
                                        error={errors.field_type?.message}
                                    />

                                    <Stack>
                                        <FormLabel>Is Required</FormLabel>
                                        <FormSwitch name="is_required" control={control} />
                                    </Stack>

                                    {fieldToEdit && (
                                        <Stack>
                                            <FormLabel>Is Archived</FormLabel>
                                            <FormSwitch name="is_archived" control={control} />
                                        </Stack>
                                    )}

                                    <FormTextarea
                                        name="description"
                                        label="Description"
                                        placeholder="Enter description for the field here"
                                        register={register("description")}
                                    />

                                    {fieldType === CustomFieldType.RegexValidated && (
                                        <RegexPatternBuilder onPatternChange={handlePatternChange} initialCustomPattern={fieldToEdit?.regex_pattern} />
                                    )}

                                    {fieldType === CustomFieldType.Select && (
                                        <Stack spacing={2}>
                                            <FormLabel>Options</FormLabel>
                                            {options.map((option, index) => (
                                                <Stack key={index} direction="row" align="center">
                                                    <Input
                                                        value={option}
                                                        placeholder={`Option ${index + 1}`}
                                                        onChange={(e) => updateOption(index, e.target.value)}
                                                    />
                                                    <IconButton
                                                        aria-label="Remove option"
                                                        icon={<DeleteIcon />}
                                                        onClick={() => removeOption(index)}
                                                    />
                                                </Stack>
                                            ))}
                                            <Button
                                                leftIcon={<AddIcon />}
                                                onClick={addOption}
                                                colorScheme="blue"
                                                size="sm"
                                            >
                                                Add New Option
                                            </Button>
                                        </Stack>
                                    )}
                                </Stack>

                                <AlertDialogFooter>
                                    <Button onClick={onClose} isDisabled={isSubmitting}>
                                        Cancel
                                    </Button>
                                    <Button colorScheme="green" type="submit" isLoading={isSubmitting} ml={3}>
                                        Save
                                    </Button>
                                </AlertDialogFooter>
                            </form>
                        </AlertDialogBody>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        );
    }
);