import React, { useState } from "react";
import { Box, Button } from '@chakra-ui/react';
import { TabRoutes } from "../layout/TabRoutes";
import { UserInvoices } from "./UserInvoices";
import { InvoicesListAdminView } from "./InvoicesListAdminView";
import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import LocalStorageService from "../../api/LocalStorageService";
import { UserRole } from "../../api/UserApi";
import { BlHeading } from "../layout/Heading";
import { BlBreadcrumbs } from "../layout/Breadcrumbs";

export const Invoices = React.memo(() => {
    const [defaultTabIndex, setDefaultTabIndex] = useState<number>(0);
    const user = LocalStorageService.getInstance().getUser();
	const canAccessInvoice = user?.has_role(UserRole.Can_Access_Invoice_Manager);

    const tabs = [
        {
            label: "Student View",
            path: "/invoices/student-view",
            element: (
                <UserInvoices /> 
            ),
        },
        {
            label: "Admin View",
            path: "/invoices/admin-view",
            element: (
                <InvoicesListAdminView /> 
            ),
        },

    ];

    return (
        <Box>
            <BlHeading> Invoices </BlHeading>

            <BlBreadcrumbs />

            <TabRoutes tabs={tabs} defaultTabIndex={defaultTabIndex}>
                { canAccessInvoice &&
                    <Button
                        as={Link}
                        to="/invoice/create-invoice"
                        colorScheme="green"
                        leftIcon={<FiPlus />}
                        ms={{md: '4'}}
                    >
                        Add Invoice
                    </Button>
                }
            </TabRoutes>
        </Box>
    );
});
