import { Box, Checkbox, Stack, useColorModeValue } from "@chakra-ui/react";
import React from "react"
import LocalStorageService from "../../api/LocalStorageService"
import { TeacherAPI } from "../../api/TeacherAPI"
import { UserRole } from "../../api/UserApi"
import { useToaster } from "../../hooks/useToaster"
import { Teacher } from "../../models/teacher"


interface Card {
    teacher: Teacher,
    set_teacher: React.Dispatch<React.SetStateAction<Teacher | undefined>> | React.Dispatch<React.SetStateAction<Teacher>>
}

export const TeacherRoles = ({teacher, set_teacher} : Card) =>  {
    const { showSuccessToast } = useToaster()

    const [roles_map, set_roles_map] = React.useState<{ [k: string]: boolean; }>()

    const update_role = (state: boolean, role: UserRole) => {
        TeacherAPI.update_role(teacher._id, role, !state).then(() => {
            let roles = [...teacher.roles]
            if(state) {
                roles = [...roles, role]
            }
            else{
                roles = roles.filter((e) => e != role)
            }
            set_teacher(
                (prevState: any) => (prevState ? {
                    ...prevState,
                    roles: roles
                } : undefined)
            );
    
            _get_roles(roles)

            showSuccessToast("Updated Role: " + role.toString().replaceAll("_", " "));
        })
    }

    const _get_roles = (roles: UserRole[]) => {
        let roles_arr = Object.values(UserRole).map((role) => {
            return { key: role, value: roles.includes(role) }
        })
    
        let _roles_map = Object.fromEntries(
            roles_arr.map(e => [e.key, e.value])
        )

        set_roles_map(_roles_map)
    }

    React.useEffect(()=>{
        if(teacher.roles){
            _get_roles(teacher.roles)
        }
    },[])

    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
            >
        { LocalStorageService.getInstance().getUser()?.has_role(UserRole.Super_Admin) && roles_map &&
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
            <Stack>
                {Object.values(UserRole).map((v) => {
                    return (
                        <Checkbox 
                            size='md' 
                            colorScheme='green' 
                            isChecked={roles_map[v]} 
                            onChange={(e) => update_role(e.target.checked, v)}>
                                {v.toString().replaceAll("_", " ")}
                        </Checkbox>
                    )
                })
                }
                
            </Stack>
            </Stack>
        }
        </Box>
    )
}