import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { ObjectId } from "../../utils/ObjectId";
import { TeacherAPI } from "../../api/TeacherAPI";
import {
    CheckIcon,
    DeleteIcon,
    EditIcon,
    AddIcon,
    CloseIcon,
    RepeatIcon,
} from "@chakra-ui/icons";
import {useToaster} from "../../hooks/useToaster";

interface SignaturePageProps {
    user_id: ObjectId;
}

export const SignaturePage = ({ user_id }: SignaturePageProps) => {
    const [signatureUrl, setSignatureUrl] = useState<string | undefined>(undefined);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const sigPadRef = useRef<SignatureCanvas | null>(null);
    const toast = useToaster();

    useEffect(() => {
        const fetchSignature = async () => {
            try {
                const url = await TeacherAPI.get_signature(user_id);
                setSignatureUrl(url);
            } catch (error) {
                console.error("Error fetching signature", error);
                toast.showErrorToast("Could not load signature", "Error");
            }
        };

        fetchSignature().then();
    }, [user_id, toast]);

    const handleSave = async () => {
        if (sigPadRef.current) {
            const dataUrl = sigPadRef.current
                .getTrimmedCanvas()
                .toDataURL("image/png");

            // Check file size (limit is 500KB)
            const maxSizeKB = 500;
            const base64Length =
                ((dataUrl.length - "data:image/png;base64,".length) * 3) /
                4 /
                1024;
            if (base64Length > maxSizeKB) {
                toast.showErrorToast("Signature too large", `Your signature is ${Math.round(base64Length)}KB. Please try again with a smaller signature.`);
                return;
            }

            try {
                // Convert Base64 Data URL to a Blob
                const blob = await fetch(dataUrl)
                    .then((res) => res.blob())
                    .then(
                        (blob) =>
                            new File([blob], `${user_id.$oid}_signature.png`, {
                                type: "image/png",
                            })
                    );
                const newUrl = await TeacherAPI.save_signature(user_id, blob);
                toast.showSuccessToast(signatureUrl ? "Signature updated" : "Signature added", "Success");
                setSignatureUrl(newUrl);
                setIsEditing(false);
            } catch (error) {
                console.error("Error saving signature", error);

                toast.showErrorToast("Could not save signature", "Error");
            }
        }
    };


    const handleClear = () => {
        sigPadRef.current?.clear();
    };

    const handleDelete = async () => {
        try {
            await TeacherAPI.delete_signature(user_id);
            toast.showSuccessToast("Signature deleted", "Success");
            setSignatureUrl(undefined);
        } catch (error) {
            console.error("Error deleting signature", error);
            toast.showErrorToast("Could not delete signature", "Error");
        }
    };

    return (
        <Box p={4}>
            <Text fontSize="2xl" mb={2}>
                Signature
            </Text>
            <Text fontSize="sm" mb={4} color="gray.600">
                This signature will appear in documents you sign, like report cards.
            </Text>

            {isEditing ? (
                <Box
                    border="4px"
                    borderColor="gray.200"
                    p={4}
                    borderRadius="md"
                    display="inline-block"
                >
                    <Box position="relative" width="400px" height="200px">
                        <SignatureCanvas
                            penColor="black"
                            canvasProps={{
                                width: 400,
                                height: 200,
                                style: { backgroundColor: "white", borderRadius: "4px" },
                            }}
                            ref={sigPadRef}
                        />
                        {/* Signature line overlay */}
                        <Box
                            position="absolute"
                            bottom="30px"
                            left="10px"
                            right="10px"
                            borderBottom="2px dashed"
                            borderColor="gray.300"
                            pointerEvents="none"
                        />
                    </Box>
                    <Flex mt={4}>
                        <Button
                            colorScheme="blue"
                            mr={2}
                            leftIcon={<CheckIcon />}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                        <Button
                            colorScheme="gray"
                            mr={2}
                            leftIcon={<RepeatIcon />}
                            onClick={handleClear}
                        >
                            Clear
                        </Button>
                        <Button
                            variant="outline"
                            leftIcon={<CloseIcon />}
                            onClick={() => setIsEditing(false)}
                        >
                            Cancel
                        </Button>
                    </Flex>
                </Box>
            ) : (
                <Box>
                    {signatureUrl ? (
                        <>
                            <Box
                                display="inline-block"
                                bg="white"
                                border="1px"
                                borderColor="gray.200"
                                borderRadius="md"
                                mb={4}
                                p="15px"
                            >
                                <Box position="relative" width="400px" height="200px">
                                    <Image
                                        src={signatureUrl}
                                        alt="User signature"
                                        objectFit="contain"
                                        width="400px"
                                        height="200px"
                                        borderRadius="md"
                                    />
                                    <Box
                                        position="absolute"
                                        bottom="30px"
                                        left="10px"
                                        right="10px"
                                        borderBottom="2px dashed"
                                        borderColor="gray.300"
                                        pointerEvents="none"
                                    />
                                </Box>
                            </Box>
                            <Flex>
                                <Button
                                    colorScheme="blue"
                                    mr={2}
                                    leftIcon={<EditIcon />}
                                    onClick={() => setIsEditing(true)}
                                >
                                    Update Signature
                                </Button>
                                <Button
                                    colorScheme="red"
                                    leftIcon={<DeleteIcon />}
                                    onClick={handleDelete}
                                >
                                    Delete Signature
                                </Button>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Text mb={4}>No signature found.</Text>
                            <Button
                                colorScheme="blue"
                                leftIcon={<AddIcon />}
                                onClick={() => setIsEditing(true)}
                            >
                                Add Signature
                            </Button>
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};