import React from "react";
import {
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useBreakpointValue,
    Flex
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Navigate, NavLink, Route, Routes, useLocation } from "react-router-dom";
import { FlexWrap } from "./FlexWrap";

export interface TabRoute {
    label: string;
    path: string;
    element: React.ReactNode;
}

interface TabRoutesProps {
    tabs: TabRoute[];
    defaultTabIndex?: number;
    children?: React.ReactNode;
}

export const TabRoutes: React.FC<TabRoutesProps> = ({ tabs, defaultTabIndex = 0, children }) => {
    const location = useLocation();

    // Determine the active tab based on the current path
    const activeIndex = tabs.findIndex(tab => location.pathname.includes(tab.path)) ?? defaultTabIndex;

    // Detect mobile view for base and sm breakpoints
    const isMobile = useBreakpointValue({ base: true, md: false });

    // Only on mobile, if there are more than 2 tabs, we set showMore true.
    const showMore = isMobile && tabs.length > 2;

    // minimalTabs: first 2 tabs if showMore is true, otherwise all tabs.
    const minimalTabs = showMore ? tabs.slice(0, 2) : tabs;

    // otherTabs: remaining tabs if showMore is true.
    const otherTabs = showMore ? tabs.slice(2) : [];

    return (
        <>
            <Tabs index={activeIndex} isLazy>
                <FlexWrap w="full">
                    <TabList
                        color="blue.600"
                        flexGrow={1}
                    >
                        {minimalTabs.map((tab, index) => (
                            <Tab key={index} as={NavLink} to={tab.path}>
                                {tab.label}
                            </Tab>
                        ))}
                        {showMore ? (
                            <Tab
                                color={activeIndex >= 2 ? "blue.600" : undefined}
                                borderBottom={activeIndex >= 2 ? "2px solid" : ""}
                                borderBottomColor={activeIndex >= 2 ? "blue.600" : ""}
                            >
                                <Menu>
                                    <MenuButton as={Tab} fontWeight="500">
                                        More <ChevronDownIcon ml={1} />
                                    </MenuButton>

                                    <MenuList>
                                        {otherTabs.map((tab, idx) => {
                                            const isActive = location.pathname.includes(tab.path);

                                            return (
                                                <MenuItem 
                                                    key={idx} 
                                                    as={NavLink}
                                                    to={tab.path} 
                                                    bg={isActive ? "blue.50" : undefined}
                                                    color={isActive ? "blue.600" : "gray.700"}
                                                >
                                                    {tab.label}
                                                </MenuItem>
                                            )
                                        })}
                                    </MenuList>
                                </Menu>
                            </Tab>

                        ) :
                            otherTabs.map((tab, index) => (
                                <Tab key={index} as={NavLink} to={tab.path}>
                                    {tab.label}
                                </Tab>
                            ))
                        }
                    </TabList>

                    {children}
                </FlexWrap>

                <TabPanels>
                    {tabs.map((tab, index) => (
                        <TabPanel key={index} paddingX={0}>
                            {tab.element}
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>

            <Routes>
                <Route path="/" element={<Navigate to={tabs[defaultTabIndex].path} />} />
            </Routes>
        </>
    );
};
