import { useEffect, useState, useCallback } from "react";
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    HStack,
    IconButton,
    Input,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    Stack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { DeleteIcon, QuestionIcon } from "@chakra-ui/icons";
import { new_oid, ObjectId } from "../../../utils/ObjectId";
import { MarkCategoriesAPI } from "../../../api/MarkCategoryAPI";
import { MarkCategory } from "../../../models/mark_category";
import { DeleteDialog } from "../../dialog/DeleteDialog";
import { useToaster } from '../../../hooks/useToaster';

interface Props {
    class_id: ObjectId;
}

export const MarkBookCategories = ({ class_id }: Props) => {
    const [markCategories, setMarkCategories] = useState<MarkCategory[]>([]);
    const [totalWeight, setTotalWeight] = useState<number>(0);
    const { showErrorToast, showSuccessToast } = useToaster();

    const fetchMarkCategories = useCallback(async () => {
        try {
            const categories = await MarkCategoriesAPI.get_mark_category_list_from_class(class_id);
            setMarkCategories(categories || []);
            const total = (categories || []).reduce((sum, cat) => sum + cat.weight, 0);
            setTotalWeight(total);
        } catch (error) {
            showErrorToast('Failed to fetch mark categories');
        }
    }, [class_id]);

    useEffect(() => {
        fetchMarkCategories();
    }, [class_id]);

    const createMarkCategory = async () => {
        const newCategory: MarkCategory = {
            _id: new_oid(),
            class_id,
            name: "",
            weight: 0,
        };

        try {
            await MarkCategoriesAPI.create_mark_category(newCategory);
            fetchMarkCategories();
            showSuccessToast('New mark category created');
        } catch (error) {
            showErrorToast('Failed to create new mark category');
        }
    };

    return (
        <Box p={4}>
            <Stack spacing={6}>
                {markCategories && (
                    <MarkCategoryTable
                        categories={markCategories}
                        totalWeight={totalWeight}
                        refreshCategories={fetchMarkCategories}
                    />
                )}
                <Button
                    leftIcon={<FiPlus />}
                    colorScheme="green"
                    onClick={createMarkCategory}
                    alignSelf="flex-start"
                >
                    Add Mark Category
                </Button>
            </Stack>
        </Box>
    );
};

interface MarkCategoryTableProps {
    categories: MarkCategory[];
    totalWeight: number;
    refreshCategories: () => void;
}

const MarkCategoryTable = ({
                               categories,
                               totalWeight,
                               refreshCategories,
                           }: MarkCategoryTableProps) => {
    const { showErrorToast, showSuccessToast } = useToaster();

    const handleValueChange = async (
        field: keyof MarkCategory,
        category: MarkCategory,
        value: string
    ) => {
        const updatedValue = field === "weight" ? Number(value) : value;
        if (category[field] === updatedValue) return;

        const updatedCategory = { ...category, [field]: updatedValue };

        try {
            await MarkCategoriesAPI.update_mark_category(updatedCategory);
            refreshCategories();
            showSuccessToast(`Updated ${field} successfully`);
        } catch (error) {
            showErrorToast(`Failed to update ${field}`);
        }
    };

    return (
        <Box>
            <HStack mb={4}>
                <Alert
                    status={totalWeight === 100 ? "success" : "warning"}
                    variant="left-accent"
                    flex="1"
                    borderRadius="md"
                >
                    <AlertIcon />
                    Total Weight: {totalWeight}%
                </Alert>
                <Popover placement="right">
                    <PopoverTrigger>
                        <IconButton aria-label="Info" icon={<QuestionIcon />} variant="ghost" />
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                            The total weight of all categories should sum up to 100%.
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </HStack>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Weight (%)</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {categories.length > 0 ? (
                        categories.map((category) => (
                            <Tr key={category._id.$oid}>
                                <Td>
                                    <Input
                                        defaultValue={category.name}
                                        onBlur={(e) =>
                                            handleValueChange("name", category, e.target.value)
                                        }
                                        variant="filled"
                                        placeholder="Category Name"
                                    />
                                </Td>
                                <Td>
                                    <Input
                                        type="number"
                                        defaultValue={category.weight}
                                        onBlur={(e) =>
                                            handleValueChange("weight", category, e.target.value)
                                        }
                                        variant="filled"
                                        placeholder="Weight"
                                    />
                                </Td>
                                <Td>
                                    <DeleteCategoryButton
                                        categoryId={category._id}
                                        refreshCategories={refreshCategories}
                                    />
                                </Td>
                            </Tr>
                        ))
                    ) : (
                        <Tr>
                            <Td colSpan={3} textAlign="center" py={4}>
                                No categories available.
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
        </Box>
    );
};

interface DeleteCategoryButtonProps {
    categoryId: ObjectId;
    refreshCategories: () => void;
}

const DeleteCategoryButton = ({
                                  categoryId,
                                  refreshCategories,
                              }: DeleteCategoryButtonProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { showErrorToast, showSuccessToast } = useToaster();

    const deleteCategory = async () => {
        try {
            await MarkCategoriesAPI.delete_mark_category(categoryId);
            refreshCategories();

            showSuccessToast('Mark category deleted');

            onClose();
        } catch (error) {
            showErrorToast('Failed to delete mark category');
        }
    };

    return (
        <>
            <IconButton
                aria-label="Delete"
                icon={<DeleteIcon />}
                colorScheme="red"
                variant="ghost"
                onClick={onOpen}
            />
            <DeleteDialog isOpen={isOpen} onClose={onClose} action={deleteCategory} />
        </>
    );
};