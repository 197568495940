import { ObjectId as ProtoObjectId } from "protobuffer-ts/dist/utils/object_id";
import {DateTime} from "../utils/DateTime";
import {ObjectId} from "../utils/ObjectId";

export enum StaffStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export interface BassemLabsStaff {
    id: ProtoObjectId;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    personalEmail: string;
    phoneNumber: string;
    roles: string[];
    status: StaffStatus;
    gender: string;
    dateOfBirth: string;
}


export interface BassemLabsStaffCreate {
    first_name: string,
    last_name: string,
    username: string,
    personal_email: string,
    phone_number: string,
    gender: string,
    date_of_birth: DateTime
}

export interface BassemLabsStaffUpdate {
    first_name: string | null,
    last_name: string | null,
    gender: string | null,
    personal_email: string | null,
    phone_number: string | null,
    date_of_birth: DateTime | null
}