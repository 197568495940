import { Button, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { ProfileSection } from 'protobuffer-ts/dist/organization_service/organization_profile_settings';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrganizationAPI } from '../../api/OrganizationAPI';
import { OrganizationProfileSettingsAPI } from '../../api/OrganizationProfileSettingsAPI';
import { UserType } from '../../api/UserApi';
import { ActionRequiredByParents, Student, StudentStatus } from '../../models/student';
import { ProfileSectionCustomFieldsGroups } from '../custom_fields/ProfileSectionCustomFieldsGroups';
import GClassStudentViewer from '../gclass/GClassStudentViewer'
import { BlCard } from '../layout/Card';
import { LightButton } from '../layout/LightButton';
import { TabRoutes } from '../layout/TabRoutes';
import { StudentAttendanceCard } from '../students/Cards/AttendanceCard';
import StudentOverview from '../students/Cards/OverviewCard';
import { StudentCourseList } from '../students/Cards/StudentCourseList';
import { StudentHomeroomList } from '../students/Cards/StudentHomeroomList';
import StudentCustomFieldsSection from '../students/CustomFieldsSection';
import { StudentPublishedReports } from './StudentPublishedReports';

interface Card {
	student: Student
	actionRequiredByParents: ActionRequiredByParents[]
}

export const ParentsStudentProfile = ({ student, actionRequiredByParents }: Card) => {
	const navigate = useNavigate();

	const [studentProfileSections, setStudentProfileSections] = useState<ProfileSection[]>([]);

	useEffect(() => {
		const fetchStudentProfileSections = async () => {
			let student_sections = (await OrganizationProfileSettingsAPI.get_organization_profile_settings()).studentProfileSections;
			setStudentProfileSections(student_sections.filter(section => section !== ProfileSection.WAITLIST && section !== ProfileSection.INTERVIEW))
		}

		fetchStudentProfileSections();
	}, []);



	const CompleteParentRequiredActions = () => {
		navigate(`/parent/student/${student!._id.$oid}/complete_required_actions`)
	}

	const allTabs = [
        {
            label: "Overview",
            path: `/parent/student/${student._id.$oid}/overview`,
            element: (
			<>
				<SimpleGrid columns={{ lg: 4 }} gap={4}>
					<Stack gap="4">
						<StudentOverview student={student} set_student={() => {}} />
							

						{actionRequiredByParents.length > 0 && (
							<BlCard backgroundColor="red.100" borderColor="red.500" mt={4}>
								<Text fontSize="lg" fontWeight="bold" mb={2}>
									Actions Required:
								</Text>
								<Stack spacing={2}>
									{actionRequiredByParents.map((action, index) => (
										<Text key={index}>
											- {action.title}
										</Text>
									))}
								</Stack>

								<LightButton        
									color='red'
									onClick={CompleteParentRequiredActions}
								>
									Complete Required Actions
								</LightButton>
							</BlCard>
						)}
					</Stack>

					<Stack gap={4} gridColumn={{ lg: "span 3" }}>
						<StudentHomeroomList student={student} />

						<StudentCourseList student={student} />
					</Stack>
				</SimpleGrid>

				{/* <LightButton color="purple" onClick={() => openInNewTab('/students/printable/' + student?._id.$oid)}>
					Printable Page
				</LightButton> */}
			</>
			), 
			condition: true,
        },
        {
            label: "Academic Tracker",
            path: `/parent/student/${student._id.$oid}/academic-tracker`,
            element: (
				<>
					<GClassStudentViewer type='homeroom' studentId={student._id} />

					<GClassStudentViewer type='course' studentId={student._id} />
				</>
            ),
			condition: student.status !== StudentStatus.Waitlist && student.status !== StudentStatus.Interview && student.status !== StudentStatus.Applicant,
        },
		{
            label: "Attendance",
            path: `/parent/student/${student._id.$oid}/attendance`,
            element: <StudentAttendanceCard student={student} />,
			condition: student.status !== StudentStatus.Waitlist && student.status !== StudentStatus.Interview && student.status !== StudentStatus.Applicant,
		},

		{
            label: "Published Reports",
            path: `/parent/student/${student._id.$oid}/published-reports`,
            element: 
				<>
					<StudentPublishedReports student={student} />
				</>,
			condition: student.status !== StudentStatus.Waitlist && student.status !== StudentStatus.Interview && student.status !== StudentStatus.Applicant,
        },
		
		{
            label: "Waitlist data",
            path: `/parent/student/${student._id.$oid}/waitlist-data`,
            element: 
				<>
					<ProfileSectionCustomFieldsGroups 
					userType={UserType.Student}
					profileSection={ProfileSection.WAITLIST}
					userId={student._id} 
					onGroupStatusUpdate={()=>{}}/>
				</>,
			condition: student.status === StudentStatus.Waitlist,
        },

		{
            label: "Student Information",
            path: `/parent/student/${student._id.$oid}/student-information`,
            element: 
				<>
					<StudentCustomFieldsSection student={student} profileSections={studentProfileSections} />
				</>,
			condition: student.status === StudentStatus.Applicant,
        },
    ];

	const filteredTabs = allTabs.filter(tab => tab.condition);
	return (
		<TabRoutes tabs={filteredTabs} />
	)
}
