import { Document, Image, Page, View, Text as PdfText, Font } from "@react-pdf/renderer";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { Homeroom } from "protobuffer-ts/dist/class_service/homeroom";
import { ReportType } from "protobuffer-ts/dist/class_service/semester";
import { PdfAttendanceCount } from "../../models/attendance";
import { getGradeForReport, getReportsByType, ReportEntry } from "../../models/report_entry";
import { getLearningSkillsMap } from "../../models/report_layout";
import { learningSkills } from "../../models/semester";
import { getGradeKey, getNextGrade, Grades, Student } from "../../models/student";
import { utils } from "../../utils/array_utils";
import { CourseRowPdf } from "./CourseRowPdf";
import PdfStylesheet from "./PdfStylesheet";
import { schoolInfo } from "../reports/ReportsPdfData";
import { LegendPage } from "./LegendPage";
import { StudentInfoSection } from "./StudentInfoSection";
import { FooterSection } from "./FooterSection";
import { CustomField } from "../../models/custom_field";
import { CustomFieldEntry } from "protobuffer-ts/dist/user_service/custom_field_entry";
import {SignatureProps, SignatureSection} from "../high_school_pdf/SignatureSection";

// Define styles for the PDF document
const styles = PdfStylesheet

Font.registerHyphenationCallback(word => [word]);

Font.registerEmojiSource({
	format: 'png',
	url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

// Register Roboto fonts
Font.register({
	family: 'Roboto',
	fonts: [
		{ src: '/fonts/Roboto-regular.woff', fontWeight: 'normal' },
		{ src: '/fonts/Roboto-700.woff', fontWeight: 'bold' },
	],
});

interface ReportCardProps {
	student: Student
	homeroom: Homeroom
	courses: Course[]
	teachers: string
	report: ReportEntry
	reports: ReportEntry[]
	coursesReports: ReportEntry[]
	days: PdfAttendanceCount
	primary_id_custom_field?: CustomField
	primary_id_entry?: CustomFieldEntry
	principalSignature?: SignatureProps
	teacherSignature?: SignatureProps
}

export const ReportCardPdf: React.FC<ReportCardProps> = ({ student, homeroom, courses, teachers, report, reports, coursesReports, days, primary_id_custom_field, primary_id_entry, principalSignature, teacherSignature }) => {
	// Convert the subjects to an array of 5 items for each, each 5 will be rendered in a separated page
	const coursesChunks = utils.chunkArray(courses, 4) as Course[][];
	const distributionDate = homeroom?.semester?.reportLayout?.reportDates.find(date => date.reportType === report.report_type)?.["distributionDate"]

	// Homeroom Learning Skills Data
	const learningSkillsMap = getLearningSkillsMap(homeroom.semester?.reportLayout)

	// Group reports by type
	const reportsByType = getReportsByType(reports)
	const grade = getGradeKey(student.grade) 

	const isMiddleSchool = (grade === "grade7"  || grade === "grade8")
	const isProgress = report.report_type === ReportType.Progress

	return (
		<Document>
			<Page style={styles.page} wrap>
				<View style={styles.headerContainer}>
					{/* Ontario Logo Column */}
					<View style={styles.headerColumn}>
						<Image style={styles.logo} src="/ontario-ministry-of-education-logo.jpg" />

					</View>
					{/* Title Column */}
					<View style={styles.headerColumn}>
						<PdfText style={styles.header}>
							Provincial { isProgress ? "Progress" : ""}Report Card
						</PdfText>

						<PdfText style={styles.subheader}>
							2024/2025 Academic Year
						</PdfText>
					</View>

					{/* AB Logo Column */}
					<View style={styles.headerColumn}>
						<Image style={[styles.logo, { marginLeft: 'auto' }]} src="/ab-logo.jpeg" />
					</View>
				</View>

				<PdfText>{distributionDate?.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}</PdfText>
				{/* Student Information Section Title */}
				<View style={styles.sectionTitleContainer}>
					<PdfText style={styles.sectionTitleText}>Student Information</PdfText>
				</View>

				{/* Student Information and School Information in a Specific Order */}
				<View style={styles.tableContainer}>
					{/* First Row */}
					<View style={[styles.cell, styles.cellQuarterWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Name: </PdfText>
							<PdfText style={styles.value}>{`${student.first_name} ${student.last_name}`}</PdfText>
						</PdfText>
					</View>

					<View style={[styles.cell, styles.cellQuarterWidth]}>
						{primary_id_custom_field && primary_id_entry &&
							<PdfText>
								<PdfText style={styles.label}>{primary_id_custom_field.name}:</PdfText>
								<PdfText style={styles.value}>{primary_id_entry.value}</PdfText>
							</PdfText>
						}
					</View>

					<View style={[styles.cell, styles.cellQuarterWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Days Absent: </PdfText>
							<PdfText style={styles.value}> {days.days_count['Absent'] ?? '0'} </PdfText>
						</PdfText>
					</View>
					<View style={[styles.cell, styles.cellQuarterWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Total Days Absent: </PdfText>
							<PdfText style={styles.value}>{days.total_days_count['Absent'] ?? '0'}</PdfText>
						</PdfText>
					</View>

					{/* Second Row */}
					<View style={[styles.cell, styles.cellQuarterWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Grade: </PdfText>
							<PdfText style={styles.value}>{Grades.get(grade)}</PdfText>
						</PdfText>
					</View>
					<View style={[styles.cell, styles.cellQuarterWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Teacher: </PdfText>
							<PdfText style={styles.value}>{teachers}</PdfText>
						</PdfText>
					</View>

					<View style={[styles.cell, styles.cellQuarterWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Times Late: </PdfText>
							<PdfText style={styles.value}>{days.days_count['Late'] ?? '0'}</PdfText>
						</PdfText>
					</View>
					<View style={[styles.cell, styles.cellQuarterWidth]}>
						<PdfText>
							<PdfText style={styles.label}>Total Times Late: </PdfText>
							<PdfText style={styles.value}>{days.total_days_count['Late'] ?? '0'}</PdfText>
						</PdfText>
					</View>

					{/* Third Row */}
					<View style={[styles.cell, styles.cellHalfWidth]}>
						<PdfText>
							<PdfText style={styles.label}>School: </PdfText>
							<PdfText style={styles.value}>{schoolInfo.School}</PdfText>
						</PdfText>
					</View>

					<View style={[styles.cell, styles.cellHalfWidth]}>
						<PdfText>
							<PdfText style={styles.label}>School Principal: </PdfText>
							<PdfText style={styles.value}>{principalSignature?.name}</PdfText>
						</PdfText>
					</View>

					{/* Fourth Row */}
					<View style={[styles.cell, styles.cellThreeQuarterWidth]}>
						<PdfText style={styles.label}>Address:</PdfText>
						<PdfText style={styles.value}>{schoolInfo.Address}</PdfText>
					</View>

					<View style={[styles.cell, styles.cellQuarterWidth]}>
						<PdfText style={styles.label}>Telephone: </PdfText>
						<PdfText style={styles.value}>{schoolInfo.Telephone}</PdfText>
					</View>
				</View>

				{/* Adding Spacing Between Sections */}
				<View style={styles.sectionSpacing} />

				{ report.report_type === ReportType.Final && 
					<>
					<View style={[styles.nextGradeCell]}>
						<PdfText style={styles.label}>
							<PdfText>Grade in September: </PdfText>
							<PdfText>{getNextGrade(grade)}</PdfText>
						</PdfText>
					</View>

					<View style={styles.sectionSpacing} />
					</>
				}
	
				{/* Learning Skills and Work Habits Section */}
				<View style={styles.skillsHeaderContainer}>
					<PdfText style={styles.skillsHeaderText}>Learning Skills and Work Habits</PdfText>
				</View>

				<View style={styles.skillsSubHeaderContainer}>
					<PdfText style={styles.skillsSubHeaderText}>E – Excellent G – Good S – Satisfactory N – Needs Improvement</PdfText>
				</View>

				{/* Learning Skills Section - Two Columns */}
				<View style={styles.skillsColumnContainer}>
					{learningSkills.map(({ skill, descriptions }, index) => (
						<View key={index} style={styles.skillItemContainer}>
							<View style={styles.skillItem}>
								<PdfText style={styles.skillTitle}>{skill}</PdfText>

								<View style={styles.GradesFlex}>
									<PdfText style={styles.skillGrade}>
										{getGradeForReport(skill, reportsByType[ReportType.Progress], learningSkillsMap)}
									</PdfText>

									<PdfText style={styles.skillGrade}>
										{getGradeForReport(skill, reportsByType[ReportType.Midterm], learningSkillsMap)}
									</PdfText>

									<PdfText style={styles.skillGrade}>
										{getGradeForReport(skill, reportsByType[ReportType.Final], learningSkillsMap)}
									</PdfText>
								</View>
							</View>
							<View style={styles.skillDescriptionContainer}>
								{descriptions.map((description, i) => (
									<PdfText key={i} style={styles.bulletPoint}>• {description}</PdfText>
								))}
							</View>
						</View>
					))}
				</View>

				{/* Comment Section */}
				<View style={styles.skillsHeaderContainer}>
					<PdfText style={styles.skillsHeaderText}>Comment</PdfText>
				</View>

				{/* New Comment Section with Border and Font Size 8 */}
				<View style={styles.commentSection}>
					<PdfText>
						{report.comment}
					</PdfText>
				</View>

				<FooterSection 
					isMiddleSchool={isMiddleSchool} 
					page={1} 
					totalPages={coursesChunks.length + 2} 
				/>
			</Page>

			{/* New Page for Subjects and Progress Grid */}
			{coursesChunks.map((chunk, pageIndex) => (
				<Page key={pageIndex} style={styles.page} wrap>
					<StudentInfoSection student={student} primary_id_entry={primary_id_entry} primary_id_custom_field={primary_id_custom_field} />

					{/* Adding Spacing Between Sections */}
					<View style={styles.sectionSpacing} />

					<View style={styles.tableContainer}>
						<View style={[styles.cell, styles.cellTwoThirdWidth, {backgroundColor: '#dbdbf2'}]}>
							<PdfText>
								<PdfText style={styles.label}>ESL/ELD - </PdfText>
								<PdfText style={styles.value}>
									Achievement is based on expectations modified from the curriculum expectations for the grade
									to support English language learning needs.
								</PdfText>
							</PdfText>
						</View>

						<View style={[styles.cell, styles.cellThirdWidth, {backgroundColor: '#dbdbf2'}]}>
							<PdfText>
								<PdfText style={styles.label}>IEP - </PdfText>
								<PdfText style={styles.value}>
									Individual Education Plan
								</PdfText>
							</PdfText>

							<PdfText>
								<PdfText style={styles.label}>NA - </PdfText>
								<PdfText style={styles.value}>
									No instruction for subject/strand
								</PdfText>
							</PdfText>
						</View>
					</View>

					{/* Report Card Section */}
					<View style={styles.skillsHeaderContainer}>
						<PdfText style={styles.skillsHeaderText}>Report Card</PdfText>
					</View>

					{/* Grid Header */}
					<View style={styles.gridContainer}>
						<View style={[styles.gridCell, styles.subjectCol, styles.flexCenter]}>
							<PdfText style={styles.label}>Subjects</PdfText>
						</View>

						{ isProgress ? (
							<>
								<View style={[styles.gridCell, styles.smallCol, { height: 80 }]}>
									<PdfText style={styles.verticalText}>Progressing With-Difficulty</PdfText>
								</View>
								<View style={[styles.gridCell, styles.smallCol]}>
									<PdfText style={styles.verticalText}>Progressing Well</PdfText>
								</View>
								<View style={[styles.gridCell, styles.smallCol]}>
									<PdfText style={styles.verticalText}>Progressing Very-Well</PdfText>
								</View>
							</>
						) : isMiddleSchool ? (
							<>
									<View style={[styles.MarkCol]}>
										<PdfText style={[styles.gridCell, styles.markCell, styles.label]}>
											Report 1
										</PdfText>

										<View style={[styles.tableContainer, { border: 'none'}]}>
											<View style={styles.cellHalfWidth}>
												<PdfText style={[styles.gridCell, styles.markCell, styles.xSmallValue]}>
													Mark
												</PdfText>
											</View>

											<View style={styles.cellHalfWidth}>
												<PdfText style={[styles.gridCell, styles.markCell, styles.xSmallValue]}>
													Median
												</PdfText>
											</View>
										</View>
									</View>

									<View style={[styles.MarkCol]}>
										<PdfText style={[styles.gridCell, styles.markCell, styles.label]}>
											Report 2
										</PdfText>

										<View style={[styles.tableContainer, { border: 'none'}]}>
											<View style={styles.cellHalfWidth}>
												<PdfText style={[styles.gridCell, styles.markCell, styles.xSmallValue]}>
													Mark
												</PdfText>
											</View>

											<View style={styles.cellHalfWidth}>
												<PdfText style={[styles.gridCell, styles.markCell, styles.xSmallValue]}>
													Median
												</PdfText>
											</View>
										</View>
									</View>
								</>
						) : (
							(
								<>
									<View style={[styles.MarkCol]}>
										<PdfText style={[styles.gridCell, styles.markCell, styles.label]}>
											Report 1
										</PdfText>

										<PdfText style={[styles.gridCell, styles.markCell]}>
											Mark
										</PdfText>
									</View>

									<View style={[styles.MarkCol]}>
										<PdfText style={[styles.gridCell, styles.markCell, styles.label]}>
											Report 2
										</PdfText>

										<PdfText style={[styles.gridCell, styles.markCell]}>
											Mark
										</PdfText>
									</View>
								</>
							)
						)}

						<View style={[styles.gridCell, styles.largeCol, styles.flexCenter]}>
							<PdfText style={styles.label}>Strengths/Next Steps for Improvement</PdfText>
						</View>
					</View>

					{chunk.map(course => (
						<CourseRowPdf 
							course={course} 
							reports={coursesReports.filter(r => r.course?.$oid === course.id?.id)!} 
							isMiddleSchool={isMiddleSchool}
							reportType={report.report_type}
						/>
					))}

					{ principalSignature && pageIndex === coursesChunks.length - 1 &&
						<SignatureSection 
							signatureProps={principalSignature} 
							secondarySignatureProps={teacherSignature}
						/>
					}

					<FooterSection 
						isMiddleSchool={isMiddleSchool} 
						page={pageIndex + 2} 
						totalPages={coursesChunks.length + 2} 
					/>
		
				</Page>
			))}

			<LegendPage isNumeric={isMiddleSchool}>
				<StudentInfoSection student={student} primary_id_entry={primary_id_entry} primary_id_custom_field={primary_id_custom_field} />

				<View style={styles.sectionSpacing} />

				<FooterSection 
					isMiddleSchool={isMiddleSchool} 
					page={coursesChunks.length + 2} 
					totalPages={coursesChunks.length + 2} 
				/>
		
			</LegendPage>
		</Document>
	);
};