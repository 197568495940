import { CustomFieldEntry } from "protobuffer-ts/dist/user_service/custom_field_entry";
import { ObjectId } from "../utils/ObjectId";
import HTTPApi from "./ApiCommon";

export namespace CustomFieldEntryAPI {

    export const get_user_entries_for_group = async (userId: ObjectId, groupId: ObjectId) => {
        return HTTPApi.get("/custom_fields_entries/get_group_entries_for_user/" + userId.$oid + "/" + groupId.$oid)
            .then((res) => {
                return res.data.map((element: any) => CustomFieldEntry.fromJSON(element)) 
            })
    }

    export const get_user_active_entries_for_group = async (userId: ObjectId, groupId: ObjectId) => {
        return HTTPApi.get("/custom_fields_entries/get_group_active_entries_for_user/" + userId.$oid + "/" + groupId.$oid)
            .then((res) => {
                return res.data.map((element: any) => CustomFieldEntry.fromJSON(element)) 
            })
    }

    export const upload_document = async (file: File, file_name: string, fieldId: ObjectId, userId: ObjectId) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', file_name);
        formData.append('custom_field_id', fieldId.$oid);
        formData.append('user_id', userId.$oid);

        return HTTPApi.post("/custom_fields_entries/upload_document", formData)
            .then((res) => {
                return CustomFieldEntry.fromJSON(res.data);
        })
    }

    export const remove_document = async (userId: ObjectId, fieldId: ObjectId, documentIndex: number) => {
        return HTTPApi.post("/custom_fields_entries/remove_document", {
            custom_field_id: fieldId, 
            document_index: documentIndex,
            user_id: userId,
        }).then((res) => {
            return CustomFieldEntry.fromJSON(res.data);
        })
    }
}