import { Button, Flex, SimpleGrid, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Teacher, TeacherProfile } from "../../models/teacher";
import { updateTeacherSchema } from "../../validations/teacher";
import FormDatePicker from "../forms/FormDatePicker";
import FormInput from "../forms/FormInput";
import FormSelectOptions from "../forms/FormSelectObjects";

interface TeacherProfileProps {
	teacher?: Teacher;
	loading: boolean;
	onSubmit: (data: TeacherProfile) => void;
}

export const TeacherProfileForm: React.FC<TeacherProfileProps> = ({ teacher, loading, onSubmit }) => {
	const editMode = Boolean(teacher)
	
	const { register, handleSubmit, control, reset, formState: { errors } } = useForm({
		resolver: yupResolver(updateTeacherSchema),
		defaultValues: teacher && { ...Teacher.getProfile(teacher) }
	});

	// In case teacher prop updates from parent, reset the form
	useEffect(() => {
		if (teacher) reset({ ...Teacher.getProfile(teacher) });
		else reset()
	}, [teacher, reset]);

	const onFormSubmit: SubmitHandler<TeacherProfile> = (data) => {
		onSubmit(data);
	};


	return (
		<Stack as="form" gap="4" onSubmit={handleSubmit(onFormSubmit)}>
			<SimpleGrid columns={{ sm: 1, md: 2, lg: editMode ? 3 : 2 }} gap={6}>
				<FormInput
					name="first_name"
					label="First Name"
					register={register('first_name')}
					error={errors.first_name?.message}
					isRequired
				/>

				<FormInput
					name="last_name"
					label="Last Name"
					register={register('last_name')}
					error={errors.last_name?.message}
					isRequired
				/>

				<FormSelectOptions
					name="gender"
					label="Gender"
					options={[
						{ label: "Female", value: "F" },
						{ label: "Male", value: "M" },
					]}
					control={control}
					error={errors.gender?.message}
					isRequired
				/>

				<FormInput
					name="phone_number"
					label="Phone Number"
					register={register("phone_number")}
					error={errors.phone_number?.message}
					leftAddon="+1"
					isRequired
				/>

				<FormDatePicker
					name="date_of_birth"
					label="Date of Birth"
					control={control}
					error={errors.date_of_birth?.message}
				/>

				<FormInput
					name="personal_email"
					label="Personal Email"
					register={register('personal_email')}
					error={errors.personal_email?.message}
					isRequired
				/>
			</SimpleGrid>

			<Flex justify="end">
				<Button colorScheme="green" type="submit" isLoading={loading}>
					Save
				</Button>
			</Flex>
		</Stack>
	)
}