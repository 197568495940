import * as React from "react";
import { Center, Heading } from '@chakra-ui/react';
import { useNavigate, useParams } from "react-router-dom";
import { to_oid } from "../../../utils/ObjectId";
import { CreateStudentDialog } from "./CreateStudentDialog";
import { Student } from "../../../models/student";
import { BlCard } from "../../layout/Card";

export const StudentRegistrationCards: React.FC = () => {
    const { family_id } = useParams<{ family_id: string }>();
    const navigate = useNavigate();

    const handleStudentCreated = (student: Student) => {
        navigate(`/parent/student/${student._id.$oid}/complete_required_actions`);
    };

    return (
        <Center flexDirection="column" gap={4} p={4}>
            <Heading size="sm">Add New Student</Heading>
                <CreateStudentDialog
                    family_id={to_oid(family_id!)}
                    onStudentCreated={handleStudentCreated}
                />
        </Center>
    );
}
