import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
	page: {
		padding: 10,
		fontSize: 10,
		lineHeight: 1.4,
		flexDirection: 'column',
		fontFamily: 'Roboto',
	},
	flexCenter: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	headerColumn: {
		width: '33%',
	},
	logo: {
		width: 60,
		height: 60,
		objectFit: "contain"
	},
	header: {
		fontSize: 16,
		textAlign: 'center',
		fontWeight: 'bold',
	},
	subheader: {
		marginTop: 4,
		fontSize: 12,
		textAlign: 'center',
	},
	sectionTitleContainer: {
		backgroundColor: '#dbdbf2',
		padding: 2,
		borderTop: '1px solid #000',
		borderLeft: '1px solid #000',
		borderRight: '1px solid #000',
	},
	sectionTitleText: {
		fontSize: 11,
		fontWeight: 'bold',
		color: '#000',
		textAlign: 'center',
	},
	tableContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		borderTop: '1px solid #000',
		borderLeft: '1px solid #000',
	},
	cell: {
		borderRight: '1px solid #000',
		borderBottom: '1px solid #000',
		padding: 5,
		backgroundColor: '#f7f7ff',
	},
	nextGradeCell: {
		border: '1px solid #000',
		backgroundColor: '#dbdbf2',
		width: '50%',
		padding: 5
	},

	cellHalfWidth: {
		width: '50%',
	},
	cellQuarterWidth: {
		width: '25%',
	},
	cellThreeQuarterWidth: {
		width: '75%',
	},
	cellFullWidth: {
		width: '100%',
	},
	cellThirdWidth: {
		width: '33.33%',
	},
	cellTwoThirdWidth: {
		width: '66.66%',
	},
	label: {
		fontWeight: 'bold',
		fontSize: 9,
	},
	value: {
		fontSize: 9,
	},
	smallValue: {
		fontSize: 8,
	},
	xSmallValue: {
		fontSize: 7,
	},
	markCell: {
		fontSize: 8,
		textAlign: 'center',
		padding: 3,
	},
	skillsHeaderContainer: {
		backgroundColor: '#dbdbf2',
		padding: 2,
		border: '1px solid #000',
	},
	skillsSubHeaderContainer: {
		backgroundColor: '#f7f7ff',
		padding: 2,
		borderLeft: '1px solid #000',
		borderRight: '1px solid #000',
		borderBottom: '1px solid #000',
	},
	skillsHeaderText: {
		fontSize: 11,
		fontWeight: 'bold',
		color: '#000',
		textAlign: 'center'
	},
	skillsSubHeaderText: {
		fontSize: 9,
		color: '#000',
		textAlign: 'center'
	},
	skillsColumnContainer: {
		borderLeft: '1px solid #000',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	skillItemContainer: {
		width: '50%',
		borderBottom: '1px solid #000',
		borderRight: '1px solid #000',
		backgroundColor: '#f7f7ff',
	},
	skillItem: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	GradesFlex: {
		display: 'flex',
		flexDirection: 'row',
	},
	skillTitle: {
		backgroundColor: '#dbdbf2',
		paddingHorizontal: 8,
		paddingVertical: 4,
		fontSize: 10,
		fontWeight: 'bold',
		borderBottom: '1px solid #000',
		borderRight: '1px solid #000',
	},
	skillGrade: {
		backgroundColor: '#ffffff',
		paddingHorizontal: 8,
		paddingVertical: 4,
		fontSize: 10,
		borderBottom: '1px solid #000',
		borderLeft: '1px solid #000',
	},
	skillDescriptionContainer: {
		fontSize: 8,
		lineHeight: 1.3,
		padding: 5,
		height: 100
	},
	bulletPoint: {
		marginBottom: 2,
	},
	sectionSpacing: {
		marginBottom: 10,
	},
	commentSection: {
		borderLeft: '1px solid #000',
		borderRight: '1px solid #000',
		borderBottom: '1px solid #000',
		padding: 10,
		fontSize: 9,
		lineHeight: 1.4,
		backgroundColor: '#f7f7ff',
		height: 100
	},
	footer: {
		position: 'absolute',
		bottom: 10,
		left: 20,
		right: 20,
		flexDirection: 'row',
		fontSize: 8,
		borderTop: '1px solid #000',
		paddingTop: 5,
	},
	footerColumnLeft: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		width: '33%',
	},
	footerColumnCenter: {
		flexDirection: 'column',
		alignItems: 'center',
		width: '33%',
	},
	footerColumnRight: {
		flexDirection: 'column',
		alignItems: 'flex-end',
		width: '33%',
	},
	verticalText: {
		textOverflow: 'ellipsis',
		transform: 'rotate(90deg)',
		textAlign: 'center',
		padding: 2,
		fontWeight: 'bold'
	},
	gridContainer: {
		backgroundColor: '#f7f7ff',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		borderLeft: '1px solid #000',
	},
	gridCell: {
		fontSize: 9,
		borderRight: '1px solid #000',
		borderBottom: '1px solid #000',
		padding: 5,
	},
	emptyCell: {
		fontSize: 9,
		padding: 5,
		color: '#f7f7ff',
	},

	markValue: {
		fontSize: 9,
		borderTop: '1px solid #000',
		borderBottom: '1px solid #000',
		borderLeft: '1px solid #000',
		padding: 5,
		backgroundColor: "#fff",
		marginBottom: 5,
	},
	subjectCol: {
		width: '20%',
	},
	xSmallCol: {
		width: '5%',
		padding: 0
	},
	smallCol: {
		width: '6.66%',
	},
	MarkCol: {
		width: '10%',
		padding: 0
	},
	largeCol: {
		width: '60%',
	},
	subjectText: {
		paddingHorizontal: 8,
		paddingVertical: 4,
		fontSize: 9,
		fontWeight: 'bold',
		backgroundColor: '#dbdbf2',
		borderBottom: '1px solid #000',
		borderRight: '1px solid #000',
		marginBottom: 5
	},
	checkbox: {
		width: 12,
		height: 12,
		border: '1px solid black',
		justifyContent: 'center',
		alignItems: 'center',
	},
	checkmark: {
		fontSize: 8,
		textAlign: 'center',
		lineHeight: 0,
	},
	legendMarkCol: {
		width: '20%',
	},
	legendDetailsCol: {
		width: '80%',
	},
	legendDetails: {
		fontSize: 9,
		height: 36
	},
	legendTitle: {
		fontSize: 10,
		fontWeight: 'bold',
		textAlign: 'center',
		padding: 10,
		backgroundColor: '#dbdbf2',
		height: 36
	},
});