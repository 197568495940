import { Page, View, Text as PdfText } from "@react-pdf/renderer";
import { Fragment } from "react/jsx-runtime";
import { LegendData } from "../reports/ReportsPdfData";
import PdfStylesheet from "./PdfStylesheet";

// Define styles for the PDF document
const styles = PdfStylesheet

interface LegendPageProps {
	isNumeric: Boolean
	children?: React.ReactNode;
}

export const LegendPage : React.FC<LegendPageProps> = ({ isNumeric, children  }) => {
	return (
	<Page style={styles.page}>
		{children}
		
		<View style={[styles.tableContainer]}>
			<PdfText style={[styles.cell, styles.legendMarkCol, styles.legendTitle]}>
				Percentage Mark 
			</PdfText>

			<PdfText style={[styles.cell, styles.legendDetailsCol, styles.legendTitle]}>
				Achievement of the Provincial Curriculum Expectations 
			</PdfText>

			{LegendData.map((row, index) => (
				<Fragment key={index}>
					<View style={styles.legendMarkCol}>
						<PdfText style={[styles.cell, styles.legendTitle]}>
							{isNumeric ? row.numeric : row.letter}
						</PdfText>
					</View>

					<View style={styles.legendDetailsCol}>
						<PdfText style={[styles.cell, styles.legendDetails]}>
							{row.description}
						</PdfText>
					</View>
			  </Fragment>
			))}

			<View style={styles.legendMarkCol}>
				<PdfText style={[styles.cell, styles.legendTitle]}>I</PdfText>
			</View>

			<View style={styles.legendDetailsCol}>
				<PdfText style={[styles.cell, styles.legendDetails]}>
					Insufficient evidence to assign a percentage mark (for Grade 9 and 10 courses only) 
				</PdfText>
			</View>

			<View style={styles.legendMarkCol}>
				<PdfText style={[styles.cell, styles.legendTitle]}>W</PdfText>
			</View>

			<View style={styles.legendDetailsCol}>
				<PdfText style={[styles.cell, styles.legendDetails]}>
					The student has withdrawn from the course.
				</PdfText>
			</View>

			<PdfText style={[styles.cell, styles.cellFullWidth]}>
				<PdfText style={styles.label}>ESL/ELD – </PdfText>

				<PdfText style={styles.value}>
					Achievement is based on expectations modified from the curriculum expectations for the course to support English
					language learning needs. 
				</PdfText>
			</PdfText>

			<PdfText style={[styles.cell, styles.cellFullWidth]}>
				<PdfText style={styles.label}>IEP – </PdfText>

				<PdfText style={styles.value}>
					Individual Education Plan
				</PdfText>
			</PdfText>

			<PdfText style={[styles.cell, styles.cellFullWidth]}>
				<PdfText style={styles.label}>FRENCH – </PdfText>

				<PdfText style={styles.value}>
					The student receives instruction in French for the course.
				</PdfText>
			</PdfText>

			<PdfText style={[styles.cell, styles.cellFullWidth]}>
				<PdfText style={styles.label}>SHSM – </PdfText>

				<PdfText style={styles.value}>
					Specialist High Skills Major (for Grade 11 and 12 courses only)
				</PdfText>
			</PdfText>

			{ isNumeric && 
			<PdfText style={[styles.cell, styles.cellFullWidth]}>
				<PdfText style={styles.label}>Course Median – </PdfText>

				<PdfText style={styles.value}>
					The median is the percentage mark at which 50 per cent of the students in the course have a higher
					percentage mark and 50 per cent of the students have a lower percentage mark
				</PdfText>
			</PdfText>
			}
		</View>
	</Page>
	)
}