import React, { useState } from "react";
import { Box } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';
import { StudentsList } from "./StudentsList";
import { StudentStatus } from "../../models/student";
import { BlHeading } from "../layout/Heading";

export const Students = React.memo(() => {
    const location = useLocation();
    const [heading, setHeading] = useState("Students");

    return (
        <Box >
            <BlHeading> {heading} </BlHeading>

            {location.pathname == "/students" ?
                <StudentsList status={StudentStatus.Enrolled} setHeading={setHeading} />
                :
                <Outlet context={{ setHeading }} />
            }
        </Box>
    );
});
