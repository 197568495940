import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import {
    HiOutlineCheck,
    HiOutlineClock,
    HiOutlineDocument,
    HiOutlineExclamationCircle,
} from 'react-icons/hi';
import {  SubmissionsWithGClassWork } from '../../models/gclass';
import { useSubmissionCounts } from '../../hooks/useSubmissionsCount';
import { InfoCard } from '../layout/InfoCard';

interface SubmissionStatsProps {
    submissions: SubmissionsWithGClassWork[];
}

const SubmissionStats: React.FC<SubmissionStatsProps> = ({ submissions }) => {
	const {
        notSubmittedCount,
        notSubmittedLateCount,
        submittedOnTimeCount,
        submittedLateCount
    } = useSubmissionCounts(submissions);

    return (
		<>
		<SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={4} mb={8}>
			<InfoCard
				colorScheme="blue"
				icon={HiOutlineDocument}
				number={notSubmittedCount}
				text="Not Submitted"
			/>

			<InfoCard
				colorScheme="red"
				icon={HiOutlineExclamationCircle}
				number={notSubmittedLateCount}
				text="Not Submitted & Late"
			/>

			<InfoCard
				colorScheme="green"
				icon={HiOutlineCheck}
				number={submittedOnTimeCount}
				text="Submitted On Time"
			/>

			<InfoCard
				colorScheme="yellow"
				icon={HiOutlineClock}
				number={submittedLateCount}
				text="Submitted Late"
			/>
		</SimpleGrid>
		</>
    );
};

export default SubmissionStats;
