import React, { useState, useEffect, useMemo } from 'react';
import DatePicker from '../DatePicker';
import { AttendanceDateMap, AttendanceDateMapVec } from '../../models/attendance';
import { AttendanceAPI } from '../../api/AttendanceAPI';
import { format, isWeekend } from 'date-fns';
import { ObjectId } from 'protobuffer-ts/dist/utils/object_id';

interface AttendanceDatePickerProps {
	homeroom?: ObjectId;
	course?: ObjectId;
	selectedDate: Date | undefined;
	onChange: (date: Date) => any;
}

export const AttendanceDatePicker: React.FC<AttendanceDatePickerProps> = ({
	homeroom,
	course,
	selectedDate,
	onChange,
}) => {
	// return if neither homeroom nor course is provided
	if (!homeroom && !course) throw Error('Either homeroom or course must be provided.')

	const [attendanceMapVec, setAttendanceMapVec] = useState<AttendanceDateMapVec>([]);

	// Use the selected date or default to today to initialize currentMonth.
	const initialMonth = selectedDate || new Date();
	const [currentMonth, setCurrentMonth] = useState<Date>(initialMonth);

	// Compute first and last day of the month based on currentMonth.
	const getMonthRange = (date: Date) => {
		const year = date.getFullYear();
		const month = date.getMonth();
		const startDate = new Date(year, month, 1);
		const endDate = new Date(year, month + 1, 0);
		return { startDate, endDate };
	};

	// Fetch attendance data whenever the current month or homeroom changes.
	useEffect(() => {
		const { startDate, endDate } = getMonthRange(currentMonth);

		// get the function based on the homeroom or course
		const getAttendanceMap = homeroom
			? AttendanceAPI.homeroom_attendance_date_map
			: AttendanceAPI.course_attendance_date_map;

		const id = homeroom || course!

		getAttendanceMap(id, startDate, endDate)
			.then((mapVec: AttendanceDateMapVec) => setAttendanceMapVec(mapVec))
			.catch((error) => {
				console.error('Error fetching attendance map:', error);
				setAttendanceMapVec([]);
		});

	}, [currentMonth, homeroom, course]);

	// When the month is changed in the date picker, update currentMonth.
	const handleMonthChange = (date: Date) => {
		setCurrentMonth(date);
	};

	// Combine the attendance maps into one.
	const combinedAttendanceMap = useMemo(() => {
		if (!attendanceMapVec || attendanceMapVec.length === 0) return undefined;
		return attendanceMapVec.reduce((acc, map) => ({ ...acc, ...map }), {} as AttendanceDateMap);
	}, [attendanceMapVec]);

	// Compute the day class name based on the combined attendance map.
	const dayClassName = (date: Date) => {
  		// if future date, attendanceMap is empty, or weekend (Saturday/Sunday): return empty string
		const isFuture = date > new Date();
		if (!combinedAttendanceMap || isFuture || isWeekend(date)) return '';

		const formattedDate = format(date, 'yyyy-MM-dd');
		const status = combinedAttendanceMap[formattedDate];
		return status ? `attendance-cell attendance-${status}` : '';
	};

	return (
		<DatePicker
			selectedDate={selectedDate}
			onChange={onChange}
			className="react-datapicker__input-text"
			dayClassName={dayClassName}
			onMonthChange={handleMonthChange}
			showYearDropdown={true}
		/>
	);
};