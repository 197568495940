import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import {ObjectId, to_oid} from '../../utils/ObjectId';
import { StudentAPI } from '../../api/StudentAPI';
import { ReportAPI } from '../../api/ReportAPI';
import { ReportCardPdf } from './ReportCardPdf';
import {ReportType, Semester} from 'protobuffer-ts/dist/class_service/semester';
import { ReportEntry, ReviewStatus } from '../../models/report_entry';
import { Student } from '../../models/student';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { SemesterAPI } from '../../api/SemesterAPI';
import { CourseAPI } from '../../api/CourseAPI';
import { TeacherAPI } from '../../api/TeacherAPI';
import { AttendanceAPI } from '../../api/AttendanceAPI';
import { PdfAttendanceCount } from '../../models/attendance';
import { OrganizationAPI } from '../../api/OrganizationAPI';
import LocalStorageService from '../../api/LocalStorageService';
import { CustomFieldsGroupAPI } from '../../api/CustomFieldsGroupAPI';
import { UserType } from '../../api/UserApi';
import { enIE } from 'date-fns/locale';
import { CustomFieldEntryAPI } from '../../api/CustomFIeldEntryAPI';
import { CustomFieldEntry } from "protobuffer-ts/dist/user_service/custom_field_entry";
import CourseList = CourseAPI.CourseList
import {SignatureProps, SignatureSectionProps} from "./SignatureSection";

interface PdfGeneratorProps {
  student_id: ObjectId;
  semester_id: ObjectId;
  report_type: ReportType;
}

export const PdfGenerator: React.FC<PdfGeneratorProps> = ({ student_id, semester_id, report_type }) => {
  const [loading, setLoading] = useState(false);

  const generatePdf = async () => {
    setLoading(true);
  
    try {    
      // Fetch student, semester, and courses
      const [student, semester, { courses }]: [Student | void, Semester, CourseList] = await Promise.all([
        StudentAPI.get_student(student_id.$oid),
        SemesterAPI.get_semester(semester_id),
        CourseAPI.course_list(null, null, undefined, undefined, undefined, true, semester_id),
      ]);

      // Fetch Organization & Primary ID
      let org = await OrganizationAPI.get_organization(LocalStorageService.getInstance().getUser()!.user_organization!);
      let custom_fields = await CustomFieldsGroupAPI.get_custom_fields_for_user_type(UserType.Student);
      let primary_id_custom_field = custom_fields.find((e) => org.organizationProfileSettings?.studentPrimaryIdCustomField?.id == e._id.$oid)
      let primary_id_entry: CustomFieldEntry | undefined = undefined

      if (primary_id_custom_field) {
        let entries = await CustomFieldEntryAPI.get_user_entries_for_group(student!._id, primary_id_custom_field.group_id)
        primary_id_entry = entries.find((e: CustomFieldEntry) => e.customFieldId!.id === primary_id_custom_field!._id.$oid)
      }

    
      // Fetch report entries for all courses
      const coursesReports = await Promise.all(
        courses.map((course) =>
          ReportAPI.get_student_report_entries_for_course(student_id, course._id)
        )
      );
    
      // Filter by published report entries
      const rawPublishedReports = coursesReports
        .flatMap((res: ReportEntry[]) => res.filter((r) => r.review_status === ReviewStatus.Published));

      const publishedReports = await ReportAPI.update_reports_with_medians(rawPublishedReports);
    
      // filter courses based on published report entries
      const publishedCourses = courses.filter((course) =>
        publishedReports.some((report) => report.course?.$oid === course._id.$oid)
      );

      // Fetch layouts, attendance, and teachers for only published courses
      const [reportLayouts, attendanceData, teachers] = await Promise.all([
        Promise.all(
            publishedCourses.map((course) => CourseAPI.get_report_layout(course._id))
        ),
        Promise.all(
            publishedCourses.map(async (course) => {
              const MidtermReportEndDate = semester.reportLayout!.reportDates.find(
                  (r) => r.reportType === ReportType.Midterm
              )!.distributionDate!.toISOString();

              const attendanceCountPayload = {
                course_id: course._id,
                student_id,
                start_date: semester.startDate!.toISOString(),
                end_date: MidtermReportEndDate,
              };

              const totalAttendanceCountPayload = {
                course_id: course._id,
                student_id,
                start_date: semester!.startDate!.toISOString(),
                end_date: semester!.endDate!.toISOString(),
              };

              const [total_days_count, days_count] = await Promise.all([
                AttendanceAPI.get_student_course_attendance_count(totalAttendanceCountPayload),
                AttendanceAPI.get_student_course_attendance_count(attendanceCountPayload),
              ]);


              return { courseId: course._id.$oid, days_count, total_days_count };
            })
        ),
        TeacherAPI.teachers_by_ids(
            Array.from(
                new Set(publishedCourses.flatMap((course) => course.teachers))
            )
        ).catch(() => undefined),
      ]);
    
      // Transform attendance data into a Record
      const attendanceCourses = attendanceData.reduce((acc, { courseId, days_count, total_days_count  }) => {
        acc[courseId] = { total_days_count, days_count };
        return acc;
      }, {} as Record<string, PdfAttendanceCount>);
    
      // Extract semester date
      const semesterDate = semester.reportLayout?.reportDates.find(
        (r) => r.reportType === report_type
      )?.distributionDate;

      let principalSignature: undefined | SignatureProps = undefined;

      try {
        const [principal, signature_url] = await Promise.all([
          TeacherAPI.get_teacher(to_oid(semester.principalId!)),
          TeacherAPI.get_signature(to_oid(semester.principalId!))
        ]);

        const principal_name = principal ? `${principal.first_name} ${principal.last_name}` : undefined;

        if (principal_name) {
          principalSignature = {
            title: "Principal",
            name: principal_name,
            signature_url: signature_url || undefined
          } as SignatureProps;
        }
      } catch (error) {
        principalSignature = undefined;
      }

      const document = (
        <ReportCardPdf
          student={student!}
          semester={semester}
          reportType={report_type}
          semesterDate={semesterDate}
          courses={publishedCourses}
          teachers={teachers}
          reportEntries={publishedReports}
          reportLayouts={reportLayouts}
          attendanceCourses={attendanceCourses}
          primary_id_entry={primary_id_entry}
          primary_id_custom_field={primary_id_custom_field}
          principalSignature={principalSignature}
        />
      );
    
      const asBlob = await pdf(document).toBlob();
      saveAs(asBlob, `${Student.getName(student!)} ${semester.name} Report.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  }
    
  
  return (
    <Button colorScheme="teal" onClick={generatePdf} isLoading={loading} leftIcon={<AiOutlineFilePdf />}>
      {loading ? 'Generating PDF...' : `Export PDF`}
    </Button>
  );
};
