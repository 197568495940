import { OnboardingSettings } from "protobuffer-ts/dist/organization_service/onboarding_settings";


export interface StudentCounts {
    waitlist?: number;
    interview?: number;
    applicants?: number;
    rejected?: number;
    reregistration?: number;
    withdrawn?: number,
}

export enum ActionType {
    RequireChanges = "require_changes",
    Enroll = "enroll",
    Reject = "reject",
    Withdraw = "withdraw",
    Interview = "interview",
    Approve = "Approve", 
}

export const getEmailTemplate = (actionType: ActionType, onboardingSettings: OnboardingSettings) => {
    switch (actionType) {
        case ActionType.RequireChanges: return onboardingSettings.fieldGroupRejectionEmailTemplate;
        case ActionType.Enroll: return onboardingSettings.approvalEmailTemplate;
        case ActionType.Reject: return onboardingSettings.rejectionEmailTemplate;
        case ActionType.Withdraw: return onboardingSettings.withdrawEmailTemplate;
        case ActionType.Interview: return onboardingSettings.interviewEmailTemplate;
        case ActionType.Approve: return onboardingSettings.approvalEmailTemplate;
    }
};