import { useState } from "react";
import {
    Button,
    Collapse,
    Flex,
    IconButton,
    Stack,
    Text,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Badge,
    HStack
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Student } from "../../../models/student";
import { OnboardingSettings } from "protobuffer-ts/dist/organization_service/onboarding_settings";
import { ProfileSection } from "protobuffer-ts/dist/organization_service/organization_profile_settings";
import { OnboardingMessageDialog } from "./OnboardingMessageDialog";
import { ProfileSectionCustomFieldsGroups } from "../../custom_fields/ProfileSectionCustomFieldsGroups";
import { UserType } from "../../../api/UserApi";
import { BlCard } from "../../layout/Card";
import { StudentAPI } from "../../../api/StudentAPI";
import { ObjectId } from "../../../utils/ObjectId";
import { useToaster } from "../../../hooks/useToaster";
import { LightButton } from "../../layout/LightButton";
import { ActionType, getEmailTemplate } from "../../../models/onboarding";
import { StudentInvoicesCard } from "../Cards/InvoicesCard";
import { FiExternalLink } from "react-icons/fi";
import { openInNewTab } from "../../../utils/route";

interface WaitlistStudentCardProps {
    student: Student;
    onboardingSettings: OnboardingSettings;
    onStudentAction: (studentId: ObjectId) => void;
}

export const WaitlistStudentCard = ({ student, onboardingSettings, onStudentAction }: WaitlistStudentCardProps) => {
    const { showSuccessToast, showErrorToast } = useToaster();
    const [isExpanded, setIsExpanded] = useState(false);
    const [actionType, setActionType] = useState<ActionType>();
    const [allInvoicesPaid, setAllInvoicesPaid] = useState<boolean>();
    const [isLoading, setIsLoading] = useState(false);

    const handleActionConfirmation = async (message: string, interviewDate?: Date) => {
        if (!actionType) return;

        setIsLoading(true);
        try {
            let updatedStudent: Student;
            switch (actionType) {
                case ActionType.Interview:
                    updatedStudent = await StudentAPI.to_interview(student._id, message, interviewDate!);
                    break;
                case ActionType.Approve:
                    updatedStudent = await StudentAPI.approve(student._id, message);
                    break;
                case ActionType.Reject:
                    updatedStudent = await StudentAPI.reject(student._id, message);
                    break;
                case ActionType.RequireChanges:
                    updatedStudent = await StudentAPI.requireFeildsChangesFromParents(student._id, message);
                    break;
            }

            onStudentAction(student._id);
            showSuccessToast(`Successfully set student status to ${updatedStudent!.status}`);
        } catch (error: any) {
            showErrorToast(error?.response?.data || 'Action Failed');
        } finally {
            setIsLoading(false);
            setActionType(undefined);
        }
    };

    return (
        <BlCard boxShadow="none" bgColor="gray.50" border="2px dashed" borderColor="gray.300">
            <Flex justify="space-between" align="center">
                <Stack spacing={0}>
                    <Text fontWeight="bold">{student.first_name} {student.last_name}</Text>
                    <Text fontSize="sm" color="gray.500">
                        {student.grade} • {student.gender}
                    </Text>
                </Stack>

                <HStack> 
                    {allInvoicesPaid !== undefined && (
                        <Badge colorScheme={allInvoicesPaid ? "green" : "red"} ml={2}>
                            {allInvoicesPaid ? "Paid" : "Not Paid"}
                        </Badge>
                    )}
                    <IconButton
                        aria-label={isExpanded ? "Collapse" : "Expand"}
                        icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        size="sm"
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                </HStack>
            </Flex>

            <Collapse in={isExpanded}>
                <LightButton
                    color="orange"
                    rightIcon={<FiExternalLink />}
                    onClick={() => { 
                        console.log(student);
                        
                        if (student.family_id && student.family_id.$oid) {
                        openInNewTab("/family/" + student.family_id.$oid);
                    } }}
                >
                    Family Manager
                </LightButton>
                <Tabs variant="enclosed" mt={4}>
                    <TabList>
                        <Tab>Information</Tab>
                        <Tab>Invoices</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Stack spacing={4}>
                                <ProfileSectionCustomFieldsGroups
                                    onGroupStatusUpdate={() => { }}
                                    userType={UserType.Student}
                                    profileSection={ProfileSection.WAITLIST}
                                    userId={student._id}
                                />
                                <Flex direction="row" justify="flex-end" gap={4} mt={4}>
                                    <LightButton color="gray" onClick={() => setActionType(ActionType.RequireChanges)} isLoading={isLoading}>
                                        Require changes
                                    </LightButton>
                                    {onboardingSettings.hasInterviewForNewcomers ? (
                                        <LightButton color="blue" onClick={() => setActionType(ActionType.Interview)} isLoading={isLoading}>
                                            Interview
                                        </LightButton>
                                    ) : (
                                        <LightButton color="green" onClick={() => setActionType(ActionType.Approve)} isLoading={isLoading}>
                                            Approve
                                        </LightButton>
                                    )}
                                    <LightButton color="red" onClick={() => setActionType(ActionType.Reject)} isLoading={isLoading}>
                                        Reject
                                    </LightButton>
                                </Flex>
                            </Stack>
                        </TabPanel>
                        <TabPanel>
                            <StudentInvoicesCard student={student} onInvoiceStatusChange={setAllInvoicesPaid} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Collapse>

            {actionType && (
                <OnboardingMessageDialog
                    isOpen={!!actionType}
                    onClose={() => setActionType(undefined)}
                    messageType={actionType}
                    initialMessage={getEmailTemplate(actionType, onboardingSettings)!}
                    onConfirm={handleActionConfirmation}
                />
            )}
        </BlCard>
    );
};
