import React, { useState } from "react"
import { Text, Badge, Flex } from "@chakra-ui/react";
import { Grades, Student, StudentStatus } from "../../../models/student"
import { Icon } from "@chakra-ui/icons"
import { FiBookOpen, FiCalendar, FiExternalLink, FiKey, FiUser } from "react-icons/fi"
import { BlCard } from "../../layout/Card";
import { LightButton } from "../../layout/LightButton";
import { EditProfileDialog } from "./EditProfileDialog";
import { openInNewTab } from "../../../utils/route";

interface StudentOverviewCardProps {
	student: Student
	set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
}

const StudentOverview: React.FC<StudentOverviewCardProps> = ({ student, set_student }) => {
	const [isOpen, setIsOpen] = useState(false)
	return (
		<BlCard flex="0">
			<Flex gap="2">
				<Text fontSize="lg" fontWeight="bold">{student.first_name} {student.last_name}</Text>

				<Badge rounded="lg" colorScheme={badgeEnum[student.status]}>{student.status}</Badge>
			</Flex>

			<Flex gap="8" wrap="wrap">
				{student.status !== StudentStatus.Interview && student.status !== StudentStatus.Waitlist &&
					<>
						<Flex gap={2} align="center">
							<Icon as={FiKey} boxSize={6} color="blue.500" />
							<Text color="gray.600">Student ID: <strong>{student.id}</strong></Text>
						</Flex>
						<Flex gap={2}>
							<Icon as={FiUser} boxSize={6} color="green.500" />
							<Text color="gray.600">username: <strong>{student.username}</strong></Text>
						</Flex>
					</>
				}

				{student.status === StudentStatus.Interview && 
					<Flex gap={2} align="center">
						<Icon as={FiCalendar} boxSize={6} color="blue.500" />
						<Text color="gray.600">Interview Date: <strong>{student.interview_date?.to_date().toDateString()}</strong></Text>
					</Flex>
				}

				<Flex gap={2} align="center">
					<Icon as={FiBookOpen} boxSize={6} color="orange.500" />

					<Text color="gray.600">Grade: <strong>{Grades.get(student.grade) || student.grade}</strong></Text>
				</Flex>

				<Flex gap={2} align="center">
					<Icon as={FiCalendar} boxSize={6} color="pink.500" />

					<Text color="gray.600">Date of Birth: <strong>{student.date_of_birth?.to_date().toDateString()}</strong></Text>
				</Flex>
			</Flex>

			<LightButton color="blue" onClick={() => setIsOpen(true)}>
				Edit Profile
			</LightButton>

			<LightButton
				color="orange"
				rightIcon={<FiExternalLink />}
				onClick={() => { openInNewTab("/family/" + student.family_id.$oid) }}
			>
				Family Manager
			</LightButton>


			<EditProfileDialog
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				student={student}
				set_student={set_student}
			/>
		</BlCard>
	)
}

export default StudentOverview

const badgeEnum: Record<string, string> = {
	Enrolled: "green",
	Withdrawn: "orange",
	Applicant: "yellow",
	Processed: "blue",
	Rejected: "red"
}
