import React from 'react';
import { Icon } from '@chakra-ui/react';
import { FiCopy } from 'react-icons/fi';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

interface CopyIconProps {
  text: string;
}

export const CopyIcon: React.FC<CopyIconProps> = ({ text }) => {
  const copy = useCopyToClipboard();

  return (
    <Icon
      as={FiCopy}
      boxSize={4}
      color="purple.600"
      cursor="pointer"
      onClick={() => copy(text)}
    />
  );
};