import { useState, useEffect } from 'react';
import { InvoiceFilter, InvoiceResponse, InvoiceStatus, invoiceStatusArray } from '../../models/invoice';
import { InvoiceAPI } from '../../api/InvoiceAPI';
import { InvoicesTable } from './InvoicesTable';
import { FlexWrap } from '../layout/FlexWrap';
import { Box, FormLabel, Input, InputGroup, InputLeftElement, usePrevious } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { BsSearch } from 'react-icons/bs';
import { TablePagination } from '../table/TablePagination';

export const InvoicesListAdminView = () => {
    const PER_PAGE = 15;

	const [page, setPage] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>(0);
	
    const [title, setTitle] = useState<string>();
    const [status, setStatus] = useState<InvoiceStatus>();
    const previousSearch = usePrevious(title);

	const [invoices, setInvoices] = useState<InvoiceResponse[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		const fetchInvoices = async () => {
			setLoading(true);

			if (previousSearch !== title) setPage(1);

			const payload : InvoiceFilter = {
				per_page: PER_PAGE,
				page,
				status,
				title: title || undefined
			}

			try {
				let response = await InvoiceAPI.invoices_list(payload);

				setTotalRecords(response.invoices_count);

				const list = response.invoices.map(i => ({
					...i,
					link: `/invoice/${i.invoice._id.$oid}`
				}))

				setInvoices(list);
			} catch (err) {
				console.error('Error fetching invoices:', err);
				setInvoices([]);
			} finally {
				setLoading(false);
			}
		};

		fetchInvoices();
	}, [title, page, status]);

	return (
		<>

			<FlexWrap>

			<Box minW={300}>
				<FormLabel> Invoice Title </FormLabel>
	
				<InputGroup>
					<InputLeftElement pointerEvents='none' color='gray.400'>
						<BsSearch />
					</InputLeftElement>

					<Input rounded='base' type='search' placeholder='Search by name...' onChange={e => setTitle(e.target.value)} />
				</InputGroup>
			</Box>

				<Box minW={300}>
					<FormLabel> Status </FormLabel>

					<Select
						isClearable
						options={invoiceStatusArray}
						placeholder="Enter Status"
						onChange={e => {setStatus(e ? e.value : undefined)}}
					/>
				</Box>
			</FlexWrap>

			<InvoicesTable invoices={invoices} loading={loading} showBillToColunm={true} />

			<TablePagination
				page={page}
				total={totalRecords}
				onPageChange={setPage}
			/>
		</>
	);
};
