import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import PdfStylesheet from "./PdfStylesheet";

export interface SignatureProps {
    title: string;
    name: string;
    signature_url?: string;
}

export interface SignatureSectionProps {
    signatureProps: SignatureProps;
    secondarySignatureProps?: SignatureProps;
}

// Define styles for the PDF document
const styles = PdfStylesheet;

export const SignatureSection: React.FC<SignatureSectionProps> = ({ signatureProps, secondarySignatureProps }) => {

    return (
        <View fixed style={styles.signatureContainer}>
            <View style={styles.signatureBlock}>
                <Text style={styles.signatureText}>
                    {signatureProps.title}: <Text style={styles.normalText}>{signatureProps.name}</Text>
                </Text>
                {signatureProps.signature_url && (
                    <>
                        <Text style={styles.separator}>|</Text>
                        <Image src={signatureProps.signature_url} style={styles.signatureImage} />
                    </>
                )}
            </View>

            {secondarySignatureProps && (
                <View style={styles.secondarySignatureBlock}>
                    <Text style={styles.signatureText}>
                        {secondarySignatureProps.title}: <Text style={styles.normalText}>{secondarySignatureProps.name}</Text>
                    </Text>
                    {secondarySignatureProps.signature_url && (
                        <>
                            <Text style={styles.separator}>|</Text>
                            <Image src={secondarySignatureProps.signature_url} style={styles.signatureImage} />
                        </>
                    )}
                </View>
            )}
        </View>
    );
};