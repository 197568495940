import { ObjectId } from "../utils/ObjectId";

export interface Family {
    _id: ObjectId,
    organization: ObjectId,
    name: string,
    guardians: ObjectId[], // Parent ObjectId
    guardians_to_not_contact: ObjectId[], // Parent ObjectId to not contact
    information: FamilyInformation,
}

export interface FamilyInformation {
    student_living: string,
    language_spoken: string,
    preferred_contact?: ObjectId, // Parent ObjectId
    emergency_contact: FamilyContact
}

export interface FamilyContact {
    name: string,
    phone: string,
    email: string,
}



export const livingWithValue: Record<string, string> = {
    'guardian1.guardian2': 'Guardian 1 & 2',
    guardian1: 'Guardian 1',
    guardian2: 'Guardian 2',
}