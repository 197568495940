import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Spinner, FormLabel, Button, HStack, Spacer } from '@chakra-ui/react';
import { InvoiceForm } from './InvoiceForm';
import { CreateInvoice, InvoiceResponse, InvoiceStatus } from '../../models/invoice';
import { InvoiceAPI } from '../../api/InvoiceAPI';
import { to_oid } from '../../utils/ObjectId';
import { BlCard } from '../layout/Card';
import { Transaction } from '../../models/transaction';
import { StudentAPI } from '../../api/StudentAPI';
import { FiArchive, FiPlus } from 'react-icons/fi';
import { FamilyAPI } from '../../api/FamilyAPI';
import { Student } from '../../models/student';
import { Family } from '../../models/family';
import { LightButton } from '../layout/LightButton';
import AddTransactionDialog from './AddTransactionDialog';
import { Payments } from '../payments/Payments';
import { TransactionsTable } from './TransactionsTable';
import { useToaster } from '../../hooks/useToaster';
import { BlHeading } from '../layout/Heading';
import { BlBreadcrumbs, useUpdateBreadcrumbs } from '../layout/Breadcrumbs';

const InvoiceDetails: React.FC = () => {
  useUpdateBreadcrumbs([
    { label: "Home", path: "/" },
    { label: "Invoices", path: "/invoices/" },
    { label: "Invoice Details" }
  ])


  const { invoice_id } = useParams<{ invoice_id: string }>();
  const navigate = useNavigate();
  const { showErrorToast, showSuccessToast } = useToaster();

  const [invoiceRes, setInvoiceRes] = useState<InvoiceResponse>();
  const [user, setUser] = useState<Student>();
  const [family, setFamily] = useState<Family>();
  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();

  const [isOpenAddTransaction, setIsOpenAddTransaction] = useState(false);

  const onCloseAddTransaction = (transaction? : Transaction) => {
    setIsOpenAddTransaction(false);

    if (transaction) fetchInvoice()
  }

  const fetchInvoice = async () => {
    if (!invoice_id) return;

  setLoading(true);

    try {
      const response = await InvoiceAPI.get(to_oid(invoice_id));
      setInvoiceRes(response);

      if(response.invoice.user) {
        await StudentAPI.get_student(response.invoice.user.$oid)
        .then(res => {
          if (res) 
            setUser(res);
        })
      } else if(response.invoice.family) {
        await FamilyAPI.get_family(response.invoice.family).then(res => setFamily(res))
      }

    } catch (err) {
      console.error('Error fetching invoice:', err);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchInvoice();
  }, [invoice_id]);

  const handleUpdate = async (data: CreateInvoice) => {
    setSubmitLoading(true)

    try {
      await InvoiceAPI.update(data);
      showSuccessToast('Invoice updated successfully.');
      navigate('/invoices')
    } catch (error) {
				showErrorToast('Failed to update invoice.')
    }

    setSubmitLoading(false)
  };

  const archiveInvoice = async () => {
    if (!invoiceRes?.invoice?._id) return

    setLoading(true)

		try {
			await InvoiceAPI.archive(invoiceRes.invoice._id)

      showSuccessToast('Invoice Archived Successfully');

      navigate('/invoices')

		} catch (error) { }
		
		setLoading(false)
	}

  if (loading) {
    return (
      <Box textAlign="center" mt="20">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <>
		  <BlHeading> Invoice Information </BlHeading>

      <BlBreadcrumbs />

    <InvoiceForm invoice={invoiceRes?.invoice} onSubmit={handleUpdate} user={user} family={family} loading={submitLoading}/>

	  <BlCard mt={6}>
      <HStack>
        <FormLabel fontSize={"lg"} fontWeight="bold">
          Transactions
        </FormLabel>

        <Spacer />

        {/* Show Pay Button if invoice is not paid yet */}
        { invoiceRes?.status !== InvoiceStatus.Paid &&
          <>
          <LightButton
              color="green"
              leftIcon={<FiPlus />} 
              onClick={() =>  setIsOpenAddTransaction(true)}
          >
              Add transaction
          </LightButton>

          { invoiceRes?.invoice && <Payments invoiceRes={invoiceRes} /> }
          </>
        }
        
        <AddTransactionDialog
            invoice_id={to_oid(invoice_id!)}
            isOpen={isOpenAddTransaction} 
            onClose={onCloseAddTransaction}
          />
    </HStack>
      <FormLabel fontSize={"lg"} fontWeight="bold">
      </FormLabel>

      <TransactionsTable data={invoiceRes?.transactions} />
    </BlCard>

    <HStack justify={"end"} mt={4}>
      <Button
        bgColor='yellow.50'
        textColor='yellow.600'
        _hover={{ bg: 'yellow.100' }}
        leftIcon={<FiArchive />}
        onClick={archiveInvoice}
      >
          Archive
      </Button>
    </HStack>
    </>
  );
};

export default InvoiceDetails;
