import React from 'react';
import { View, Text as PdfText } from '@react-pdf/renderer';
import PdfStylesheet from './PdfStylesheet';
import { CourseAPI } from '../../api/CourseAPI';
import {
    getGradeForReport,
    getReportMark,
    getReportMedianMark,
    getReportsByType,
    ReportEntry
} from '../../models/report_entry';
import { ReportLayout } from 'protobuffer-ts/dist/class_service/report_layout';
import { ReportType, Semester } from 'protobuffer-ts/dist/class_service/semester';
import { learningSkills } from '../../models/semester';
import { PdfAttendanceCount } from '../../models/attendance';
import { getLearningSkillsMap } from '../../models/report_layout';

type CourseProps = {
    semester: Semester;
    course: CourseAPI.ListCourse;
    teacher?: string;
    reportType: ReportType;
    reports: ReportEntry[];
    reportLayout: ReportLayout;
    attendanceCount: PdfAttendanceCount;
};

const styles = PdfStylesheet;

export const CourseRowPdf: React.FC<CourseProps> = ({
                                                        semester,
                                                        course,
                                                        teacher,
                                                        reportType,
                                                        reports,
                                                        reportLayout,
                                                        attendanceCount,
                                                    }) => {
    // Homeroom Learning Skills Data
    const learningSkillsMap = getLearningSkillsMap(semester.reportLayout);

    // Group reports by type
    const reportsByType = getReportsByType(reports);

    // Provide default {} so we don't break if a report is missing
    const MidtermReport = reportsByType[ReportType.Midterm] || undefined;
    const FinalReport = reportsByType[ReportType.Final] || undefined;

    // If the parent says to show Final but it's missing, we'll fall back to Midterm
    const selectedReport =
        reportType === ReportType.Final && FinalReport ? FinalReport : MidtermReport;

    // Find the section in the report layout that matches the course name
    const section = reportLayout.sections.find((s) => s.name === course.name);

    return (
        <View style={styles.gridContainer}>
            {/* Course Name */}
            <View style={[styles.gridCell, styles.subjectCol, { padding: 0 }]}>
                <PdfText style={styles.subjectText}>{course.name}</PdfText>

                <PdfText style={styles.courseRowValue}>Course Code: {course.course_code}</PdfText>
                <PdfText style={styles.courseRowValue}>Teacher: {teacher}</PdfText>

                {/* Checkboxes (if any) */}
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginVertical: 5 }}>
                    {reportLayout.checkBoxes?.map(({ name, id }, i) => {
                        // Safely access selectedReport?.checkboxes
                        const isChecked =
                            selectedReport?.checkboxes?.find((c) => c.check_box_id?.$oid === id?.id)?.state;

                        return (
                            <View key={i} style={{ marginLeft: 5, flexDirection: 'row', alignItems: 'center' }}>
                                <View style={styles.checkbox}>
                                    {isChecked && <PdfText style={styles.checkmark}>x</PdfText>}
                                </View>
                                <PdfText style={styles.courseRowValue}>{` ${name}`}</PdfText>
                            </View>
                        );
                    })}
                </View>
            </View>

            {/* Headers */}
            <View style={[styles.smallCol]}>
                <View style={[styles.gridCell, styles.flex1, { paddingBottom: 10 }]}>
                    <PdfText style={[styles.verticalText]}>First</PdfText>
                </View>
                <View style={[styles.gridCell, styles.flex1, { paddingBottom: 10 }]}>
                    <PdfText style={[styles.verticalText]}>Final</PdfText>
                </View>
            </View>

            {/* Marks */}
            <View style={[styles.smallCol]}>
                <View style={[styles.gridCell, styles.cellValue, styles.flex1]}>
                    {MidtermReport && (
                        <PdfText>{getReportMark(MidtermReport, section)}</PdfText>
                    )}
                </View>
                <View style={[styles.gridCell, styles.cellValue, styles.flex1]}>
                    {FinalReport && (
                        <PdfText>{getReportMark(FinalReport, section)}</PdfText>
                    )}
                </View>
            </View>

            {/* Median Mark */}
            <View style={[styles.smallCol]}>
                <View style={[styles.gridCell, styles.cellValue, styles.flex1]}>
                    {MidtermReport && (
                        <PdfText>{getReportMedianMark(MidtermReport, section)}</PdfText>
                    )}
                </View>
                <View style={[styles.gridCell, styles.cellValue, styles.flex1]}>
                    {FinalReport && (
                        <PdfText>{getReportMedianMark(FinalReport, section)}</PdfText>
                    )}
                </View>
            </View>

            {/* Credit Earned */}
            <View style={[styles.smallCol]}>
                <View style={[styles.gridCell, styles.cellValue, styles.flex1]}>
                    {MidtermReport && (
                        <PdfText>
                            {MidtermReport?.credit_earned
                                ? reportLayout.creditWeight.toFixed(1)
                                : '0.0'}
                        </PdfText>
                    )}
                </View>
                <View style={[styles.gridCell, styles.cellValue, styles.flex1]}>
                    {FinalReport && (
                        <PdfText>
                            {FinalReport?.credit_earned
                                ? reportLayout.creditWeight.toFixed(1)
                                : '0.0'}
                        </PdfText>
                    )}
                </View>
            </View>

            {/* Learning Skills */}
            {learningSkills.map(({ skill }) => (
                <View key={skill} style={[styles.smallCol]}>
                    <View
                        style={[
                            styles.gridCell,
                            styles.cellValue,
                            styles.flex1,
                            { textAlign: 'center' },
                        ]}
                    >
                        {reportsByType[ReportType.Midterm] && (
                            <PdfText>
                                {getGradeForReport(
                                    skill,
                                    reportsByType[ReportType.Midterm],
                                    learningSkillsMap
                                )}
                            </PdfText>
                        )}
                    </View>
                    <View
                        style={[
                            styles.gridCell,
                            styles.cellValue,
                            styles.flex1,
                            { textAlign: 'center' },
                        ]}
                    >
                        {reportsByType[ReportType.Final] && (
                            <PdfText>
                                {getGradeForReport(
                                    skill,
                                    reportsByType[ReportType.Final],
                                    learningSkillsMap
                                )}
                            </PdfText>
                        )}
                    </View>
                </View>
            ))}

            {/* Comments */}
            <View style={[styles.gridCell, styles.largeCol]}>
                <PdfText style={styles.courseRowValue}>
                    {selectedReport?.comment || ''}
                </PdfText>
            </View>

            {/* Attendance */}
            <View style={[styles.smallCol]}>
                <View style={[styles.gridCell, styles.cellValue, styles.flex1]}>
                    {MidtermReport && attendanceCount?.days_count && (
                        <PdfText style={{ textAlign: 'center' }}>
                            {attendanceCount.days_count['Absent'] ?? '0'}
                        </PdfText>
                    )}
                </View>
                <View style={[styles.gridCell, styles.cellValue, styles.flex1]}>
                    {FinalReport && attendanceCount?.total_days_count && (
                        <PdfText style={{ textAlign: 'center' }}>
                            {attendanceCount.total_days_count['Absent'] ?? '0'}
                        </PdfText>
                    )}
                </View>
            </View>

            <View style={[styles.smallCol]}>
                <View style={[styles.gridCell, styles.cellValue, styles.flex1]}>
                    {MidtermReport && attendanceCount?.days_count && (
                        <PdfText style={{ textAlign: 'center' }}>
                            {attendanceCount.days_count['Late'] ?? '0'}
                        </PdfText>
                    )}
                </View>
                <View style={[styles.gridCell, styles.cellValue, styles.flex1]}>
                    {FinalReport && attendanceCount?.total_days_count && (
                        <PdfText style={{ textAlign: 'center' }}>
                            {attendanceCount.total_days_count['Late'] ?? '0'}
                        </PdfText>
                    )}
                </View>
            </View>

            <View style={[styles.smallCol]}>
                <View style={[styles.gridCell, styles.cellValue, styles.flex1]}>
                    {MidtermReport && attendanceCount?.days_count && (
                        <PdfText style={{ textAlign: 'center' }}>
                            {(attendanceCount.days_count['Absent'] ?? 0) +
                                (attendanceCount.days_count['Late'] ?? 0) +
                                (attendanceCount.days_count['Present'] ?? 0)}
                        </PdfText>
                    )}
                </View>
                <View style={[styles.gridCell, styles.cellValue, styles.flex1]}>
                    {FinalReport && attendanceCount?.total_days_count && (
                        <PdfText style={{ textAlign: 'center' }}>
                            {(attendanceCount.total_days_count['Absent'] ?? 0) +
                                (attendanceCount.total_days_count['Late'] ?? 0) +
                                (attendanceCount.total_days_count['Present'] ?? 0)}
                        </PdfText>
                    )}
                </View>
            </View>
        </View>
    );
};