import { ManualTransaction, PaymentType, Transaction } from "../models/transaction";
import { ObjectId } from "../utils/ObjectId";
import HTTPApi from "./ApiCommon";

export namespace PaymentAPI {
    export const new_payment = async (invoice_id : ObjectId, payment_type: PaymentType, amount: number) => {
        return HTTPApi.post("payments/new", { invoice_id, payment_type, amount })
    }

    export const create_manual_transaction = async (body : ManualTransaction) : Promise<Transaction> => {
        return HTTPApi.post("payments/create_manual_transaction", body)
    }

    export const delete_manual_transaction = async (id: ObjectId) => {
        return HTTPApi.get("/payments/delete_manual_transaction/delete/" + id.$oid)
    }
}