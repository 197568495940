import React from 'react';
import { Heading, Stack } from '@chakra-ui/react';

interface WizardCardProps {
    title?: string;
    children: React.ReactNode;
    onValidityChange?: (isValid: boolean) => void;
}

export const WizardCard: React.FC<WizardCardProps> = ({ title, children, onValidityChange }) => {
    return (
        <Stack spacing={4} align="center">
            {title && <Heading size="sm" textAlign="center">{title}</Heading>}
            {React.Children.map(children, child =>
                React.cloneElement(child as React.ReactElement, { onValidityChange })
            )}
        </Stack>
    );
};