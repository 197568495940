import { date, object, string } from 'yup'

export const updateTeacherSchema =
	object().shape({
		first_name: string().label('first Name').required(),
		last_name: string().label('last Name').required(),
		gender: string().label("gender").required(),
		phone_number: string().label("phone number").required(),
		personal_email: string().label("personal email").email().required(),
		date_of_birth: date().label("Date of Birth").required(),
	})
