import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Heading,
    HStack,
    Text,
    SimpleGrid,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BlCard } from "../layout/Card";
import { RemoveIconButton } from "../layout/RemoveIconButton";
import FormInput from "../forms/FormInput";
import { useToaster } from "../../hooks/useToaster";
import { Grades, validGrades } from "../../models/student";
import { ItemizedFee, FeeItem } from "protobuffer-ts/dist/organization_service/onboarding_settings";
import { gradeFeesValidationSchema } from "../../validations/onboarding_settings";

interface GradeFeesEditorProps {
    title: string;
    feeType: string;
    initialData: any;
    onSaveApi: (data: any) => Promise<any>;
}

export const GradeFeesEditor = ({ title, feeType, initialData, onSaveApi }: GradeFeesEditorProps) => {
    const { watch, setValue, handleSubmit, register, formState: { errors } } = useForm({
        resolver: yupResolver(gradeFeesValidationSchema),
        defaultValues: { fees: initialData },
    });
    const { showErrorToast, showSuccessToast } = useToaster();
    const fees = watch("fees") || {};


    const getFirstErrorMessage = (errors: any, path: string[] = []): string | null => {
        const validGradeKeys = new Set(validGrades.map(([gradeKey]) => gradeKey));
        for (const key in errors) {
            if (errors[key]?.message) {
                const gradeKey = path.find(p => validGradeKeys.has(p));
                const gradeName = gradeKey ? Grades.get(gradeKey) : "";
                return `${errors[key].message}${gradeName ? ` in ${gradeName}` : ""}`;
            }
            if (typeof errors[key] === "object") {
                const nestedError = getFirstErrorMessage(errors[key], [...path, key]);
                if (nestedError) return nestedError;
            }
        }
        return null;
    };

    const onSubmit = async (data: any) => {
        try {
            await onSaveApi(data.fees);
            showSuccessToast(`${title} updated`);
        } catch {
            showErrorToast("Update failed");
        }
    };

    const onError = (errors: any) => {
        const firstError = getFirstErrorMessage(errors);
        showErrorToast(firstError || "Please fix the validation errors before submitting.");
    };

    const handleAddItem = (gradeKey: string, items: FeeItem[]) => {
        setValue(`fees.${gradeKey}.items`, [
            ...items,
            { title: "", description: "", price: 0, quantity: 1 },
        ]);
    };

    const handleRemoveItem = (gradeKey: string, index: number, items: FeeItem[]) => {
        setValue(
            `fees.${gradeKey}.items`,
            items.filter((_, i) => i !== index)
        );
    };

    return (
        <SimpleGrid columns={12} spacing={6}>
            {/* Main Form Section */}
            <Box gridColumn="span 12">
                <BlCard>
                    <HStack justify="space-between" mb={6}>
                        <Heading size="md">{title}</Heading>
                    </HStack>

                    <Accordion allowMultiple>
                        {validGrades.map(([gradeKey, gradeName]) => {
                            const feeData: ItemizedFee = fees[gradeKey] || { title: "", description: "", items: [] };
                            const items: FeeItem[] = feeData.items || [];

                            return (
                                <AccordionItem key={gradeKey} border="none" mb={2}>
                                    <AccordionButton>
                                        <HStack justify="space-between" w="full">
                                            <Text fontSize="sm">{gradeName}</Text>
                                            <Text fontSize="xs" color="gray.500">{items.length} items</Text>
                                        </HStack>
                                    </AccordionButton>
                                    <AccordionPanel>
                                        <BlCard boxShadow="none" bgColor="gray.50" border="2px dashed" borderColor="gray.300">
                                            <HStack spacing={4}>
                                                <Box flex="1">
                                                    <FormInput
                                                        name={`fees.${gradeKey}.title`}
                                                        label="Fee Title"
                                                        placeholder="Enter fee title"
                                                        register={register(`fees.${gradeKey}.title`)}
                                                        error={(errors.fees as Record<string, any>)?.[gradeKey]?.title?.message}
                                                    />
                                                </Box>
                                                <Box flex="2">
                                                    <FormInput
                                                        name={`fees.${gradeKey}.description`}
                                                        label="Fee Description"
                                                        placeholder="Enter fee description"
                                                        register={register(`fees.${gradeKey}.description`)}
                                                        error={(errors.fees as Record<string, any>)?.[gradeKey]?.description?.message}
                                                    />
                                                </Box>
                                            </HStack>

                                            <Box borderBottom="1px solid" borderColor="gray.200" pb={2} mb={4}>
                                                <HStack spacing={4} fontSize="md">
                                                    <Box flex="2" fontWeight="medium" className="required">
                                                        Title
                                                    </Box>
                                                    <Box flex="4" fontWeight="medium" className="required">
                                                        Description
                                                    </Box>
                                                    <Box flex="1" fontWeight="medium" className="required">
                                                        Price
                                                    </Box>
                                                    <Box flex="1" fontWeight="medium" className="required">
                                                        Quantity
                                                    </Box>
                                                    <Box flex="0.5" />
                                                </HStack>
                                            </Box>

                                            {items.map((_, index) => (
                                                <Box key={index} mb={4} borderBottom="1px solid" borderColor="gray.200" pb={4}>
                                                    <HStack spacing={4} fontSize="md">
                                                        <Box flex="2">
                                                            <FormInput
                                                                name={`fees.${gradeKey}.items.${index}.title`}
                                                                placeholder="Item title"
                                                                register={register(`fees.${gradeKey}.items.${index}.title`)}
                                                                error={(errors.fees as Record<string, any>)?.[gradeKey]?.items?.[index]?.title?.message}
                                                            />
                                                        </Box>
                                                        <Box flex="4">
                                                            <FormInput
                                                                name={`fees.${gradeKey}.items.${index}.description`}
                                                                placeholder="Item description"
                                                                register={register(`fees.${gradeKey}.items.${index}.description`)}
                                                                error={(errors.fees as Record<string, any>)?.[gradeKey]?.items?.[index]?.description?.message}
                                                            />
                                                        </Box>
                                                        <Box flex="1">
                                                            <FormInput
                                                                name={`fees.${gradeKey}.items.${index}.price`}
                                                                placeholder="Price"
                                                                register={register(`fees.${gradeKey}.items.${index}.price`)}
                                                                error={(errors.fees as Record<string, any>)?.[gradeKey]?.items?.[index]?.price?.message}
                                                            />
                                                        </Box>
                                                        <Box flex="1">
                                                            <FormInput
                                                                name={`fees.${gradeKey}.items.${index}.quantity`}
                                                                placeholder="Qty"
                                                                register={register(`fees.${gradeKey}.items.${index}.quantity`)}
                                                                error={(errors.fees as Record<string, any>)?.[gradeKey]?.items?.[index]?.quantity?.message}
                                                            />
                                                        </Box>
                                                        <Box flex="0.5" display="flex" justifyContent="flex-end">
                                                            <RemoveIconButton onClick={() => handleRemoveItem(gradeKey, index, items)} />
                                                        </Box>
                                                    </HStack>
                                                </Box>
                                            ))}

                                            <Button
                                                onClick={() => handleAddItem(gradeKey, items)}
                                                leftIcon={<AddIcon />}
                                                variant="outline"
                                                border="1px dashed"
                                                borderColor="gray.400"
                                                size="sm"
                                                mb={6}
                                            >
                                                Add Item
                                            </Button>
                                        </BlCard>
                                    </AccordionPanel>
                                </AccordionItem>
                            );
                        })}
                    </Accordion>

                    <Button mt={6} onClick={handleSubmit(onSubmit, onError)} colorScheme="blue" alignSelf="flex-end">
                        Save Changes
                    </Button>
                </BlCard>
            </Box>
        </SimpleGrid>
    );
};