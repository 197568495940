import {useEffect, useState} from "react";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    IconButton,
    Input,
    Radio,
    RadioGroup,
    Select,
    SimpleGrid,
    Skeleton,
    Table,
    Tag,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useToast,
    VStack,
} from "@chakra-ui/react";
import {ArrowBackIcon, CheckIcon, CloseIcon, EditIcon} from "@chakra-ui/icons";
import {OrganizationAPI} from "../../api/OrganizationAPI";
import {OrganizationProfileSettingsAPI} from "../../api/OrganizationProfileSettingsAPI";
import {ProfileSection, profileSectionFromJSON} from "protobuffer-ts/dist/organization_service/organization_profile_settings"
import {Organization} from "protobuffer-ts/dist/organization_service/organization"
import {ObjectId, to_oid} from "../../utils/ObjectId";
import LocalStorageService from "../../api/LocalStorageService";
import {useNavigate, useParams} from "react-router-dom";
import { CustomField } from "../../models/custom_field";
import { CustomFieldsGroupAPI } from "../../api/CustomFieldsGroupAPI";
import { UserType } from "../../api/UserApi";
import { useToaster } from "../../hooks/useToaster";

const OrganizationPage = () => {
    const { organization_id } = useParams()
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const toast = useToast()
    const { showSuccessToast, showErrorToast } = useToaster()

    // Editing state
    const [editMode, setEditMode] = useState(false);

    // Organization name editing
    const [tempOrgName, setTempOrgName] = useState("");

    // Add domain
    const [newDomain, setNewDomain] = useState("");

    // Dropdown for new sections
    const [newParentSection, setNewParentSection] = useState<ProfileSection | "">("");
    const [newTeacherSection, setNewTeacherSection] = useState<ProfileSection | "">("");
    const [newStudentSection, setNewStudentSection] = useState<ProfileSection | "">("");
    const [studentPrimaryID, setStudentPrimaryID] = useState<ObjectId>();
    const [studentCustomFields, setStudentCustomFields] = useState<CustomField[]>([])

    const navigate = useNavigate();


    // Fetch organization
    const fetchOrganization = async () => {
        setLoading(true);
        try {
            const organizationId: ObjectId | undefined = LocalStorageService.getInstance().getUser()!.user_organization;
            if (organizationId) {
                const org = await OrganizationAPI.get_organization(organizationId);
                setOrganization(org);
                setTempOrgName(org.name);
                if (org.organizationProfileSettings?.studentPrimaryIdCustomField) {
                    setStudentPrimaryID(to_oid(org.organizationProfileSettings?.studentPrimaryIdCustomField?.id!))
                }
            } else {
                const org = await OrganizationAPI.get_organization(to_oid(organization_id!));
                setOrganization(org);
                setTempOrgName(org.name);
                if (org.organizationProfileSettings?.studentPrimaryIdCustomField) {
                    setStudentPrimaryID(to_oid(org.organizationProfileSettings?.studentPrimaryIdCustomField?.id!))
                }
            }
        } catch (error: any) {
            showErrorToast(error?.message || String(error), "Error loading organization")
        } finally {
            setLoading(false);
        }
    };

    const fetchStudentCustomField = async () => {
        try {
            let custom_fields = await CustomFieldsGroupAPI.get_custom_fields_for_user_type(UserType.Student);
            setStudentCustomFields(custom_fields)
        } catch (error: any) {
            showErrorToast(error?.message || String(error), "Error loading custom fields")
        }
    }

    useEffect(() => {
        fetchOrganization().then();
        fetchStudentCustomField().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Toggle edit mode
    const toggleEditMode = () => {
        setEditMode(!editMode);
        if (organization) {
            setTempOrgName(organization.name);
        }
    };

    // Save new org name
    const handleSaveOrgName = async () => {
        if (!organization) return;
        try {
            await OrganizationAPI.rename_organization(to_oid(organization.id!.id), tempOrgName);
            toast({
                title: "Organization renamed",
                description: "The organization name has been updated successfully.",
                status: "success",
                isClosable: true,
            });
            fetchOrganization();
        } catch (error: any) {
            toast({
                title: "Error renaming organization",
                description: error?.message || String(error),
                status: "error",
                isClosable: true,
            });
        }
    };

    // Handle default domain changes
    const handleSetDefaultDomain = async (domain: string) => {
        if (!organization || !domain) return;
        try {
            await OrganizationAPI.update_default_domain(to_oid(organization.id!.id), domain);
            toast({
                title: "Default domain updated",
                description: "The default domain has been updated successfully.",
                status: "success",
                isClosable: true,
            });
            fetchOrganization();
        } catch (error: any) {
            toast({
                title: "Error updating default domain",
                description: error?.message || String(error),
                status: "error",
                isClosable: true,
            });
        }
    };

    // Add new domain
    const handleAddDomain = async () => {
        if (!organization || !newDomain.trim()) return;
        try {
            await OrganizationAPI.add_domain(to_oid(organization.id!.id), newDomain.trim());
            toast({
                title: "Domain added",
                description: "A new domain has been added successfully.",
                status: "success",
                isClosable: true,
            });
            setNewDomain("");
            fetchOrganization();
        } catch (error: any) {
            toast({
                title: "Error adding domain",
                description: error?.message || String(error),
                status: "error",
                isClosable: true,
            });
        }
    };

    // Remove domain
    const handleRemoveDomain = async (domain: string) => {
        if (!organization) return;
        if (domain === organization.defaultDomain) {
            toast({
                title: "Cannot remove default domain",
                description: "Please change the default domain before removing it.",
                status: "warning",
                isClosable: true,
            });
            return;
        }
        try {
            await OrganizationAPI.remove_domain(to_oid(organization.id!.id), domain);
            toast({
                title: "Domain removed",
                description: `Removed domain: ${domain}`,
                status: "success",
                isClosable: true,
            });
            fetchOrganization();
        } catch (error: any) {
            toast({
                title: "Error removing domain",
                description: error?.toString() || String(error),
                status: "error",
                isClosable: true,
            });
        }
    };

    // Profile Settings: add / remove parent
    const handleAddParentSection = async () => {
        if (!newParentSection) return;
        try {
            await OrganizationProfileSettingsAPI.add_parent_profile_section(newParentSection.toString());
            toast({
                title: "Parent section added",
                description: `Added section: ${newParentSection}`,
                status: "success",
                isClosable: true,
            });
            setNewParentSection("");
            fetchOrganization();
        } catch (error: any) {
            toast({
                title: "Error adding parent section",
                description: error?.message || String(error),
                status: "error",
                isClosable: true,
            });
        }
    };

    const handleRemoveParentSection = async (section: ProfileSection) => {
        try {
            await OrganizationProfileSettingsAPI.remove_parent_profile_section(section.toString());
            toast({
                title: "Parent section removed",
                description: `Removed section: ${section}`,
                status: "success",
                isClosable: true,
            });
            fetchOrganization();
        } catch (error: any) {
            toast({
                title: "Error removing parent section",
                description: error?.message || String(error),
                status: "error",
                isClosable: true,
            });
        }
    };

    // Profile Settings: add / remove teacher
    const handleAddTeacherSection = async () => {
        if (!newTeacherSection) return;
        try {
            await OrganizationProfileSettingsAPI.add_teacher_profile_section(newTeacherSection.toString());
            toast({
                title: "Teacher section added",
                description: `Added section: ${newTeacherSection}`,
                status: "success",
                isClosable: true,
            });
            setNewTeacherSection("");
            fetchOrganization();
        } catch (error: any) {
            toast({
                title: "Error adding teacher section",
                description: error?.message || String(error),
                status: "error",
                isClosable: true,
            });
        }
    };

    const handleRemoveTeacherSection = async (section: ProfileSection) => {
        try {
            await OrganizationProfileSettingsAPI.remove_teacher_profile_section(section.toString());
            toast({
                title: "Teacher section removed",
                description: `Removed section: ${section}`,
                status: "success",
                isClosable: true,
            });
            fetchOrganization();
        } catch (error: any) {
            toast({
                title: "Error removing teacher section",
                description: error?.message || String(error),
                status: "error",
                isClosable: true,
            });
        }
    };

    // Profile Settings: add / remove student
    const handleAddStudentProfileSection = async () => {
        if (!newStudentSection) return;
        try {
            console.log(newStudentSection)
            await OrganizationProfileSettingsAPI.add_student_profile_section(newStudentSection.toString());
            toast({
                title: "Student section added",
                description: `Added section: ${newStudentSection}`,
                status: "success",
                isClosable: true,
            });
            setNewStudentSection("");
            fetchOrganization();
        } catch (error: any) {
            toast({
                title: "Error adding student section",
                description: error?.message || String(error),
                status: "error",
                isClosable: true,
            });
        }
    };

    const handleRemoveStudentProfileSection = async (section: ProfileSection) => {
        try {
            await OrganizationProfileSettingsAPI.remove_student_profile_section(section.toString());
            toast({
                title: "Student section removed",
                description: `Removed section: ${section}`,
                status: "success",
                isClosable: true,
            });
            fetchOrganization();
        } catch (error: any) {
            let userFriendlyMessage = "An unexpected error occurred.";
            
            const errorData = error?.response?.data;
            if (typeof errorData === "string" && errorData.includes("waitlist profile section is mandatory")) {
                userFriendlyMessage = "The waitlist section cannot be removed as it is required for all organizations.";
            }
    
            toast({
                title: "Error removing student section",
                description: userFriendlyMessage,
                status: "error",
                isClosable: true,
            });
        }
    };

    const handleSetStudentPrimaryId = async () => {
        if (!studentPrimaryID) return;
        try {
            await OrganizationProfileSettingsAPI.set_student_primary_id_custom_field(studentPrimaryID);
            showSuccessToast("Student Primary ID Set")
            fetchOrganization();
        } catch (error: any) {
            showErrorToast(error?.message || String(error), "Error Setting Student Primary ID")
        }
    };

    const headerBg = useColorModeValue("blue.50", "blue.900");
    const cardBg = useColorModeValue("white", "gray.800");

    if (loading) {
        return (
            <Box maxW="800px" mx="auto" mt="10" p="5">
                <VStack spacing="5" align="stretch">
                    <Skeleton height="40px" />
                    <Skeleton height="25px" />
                    <Skeleton height="25px" />
                    <Skeleton height="70px" />
                    <Skeleton height="25px" />
                </VStack>
            </Box>
        );
    }

    if (!organization) {
        return (
            <Box textAlign="center" mt="10">
                <Heading size="lg" color="red.500">
                    Failed to load organization data
                </Heading>
            </Box>
        );
    }

    // Get array of possible profile sections from the enum
    const allProfileSections = Object.values(ProfileSection);

    return (
        <Box maxW="900px" mx="auto" mt="10" p="5">
            <Button
                leftIcon={<ArrowBackIcon />}
                colorScheme="blue"
                variant="outline"
                mb={4}
                onClick={() => navigate(-1)} // Navigates to the previous page
            >
                Back
            </Button>
            {/* Edit/Save Controls */}
            <Flex justifyContent="flex-end" mb={4}>
                {!editMode ? (
                    <Button leftIcon={<EditIcon />} colorScheme="blue" onClick={toggleEditMode}>
                        Edit
                    </Button>
                ) : (
                    <Button
                        leftIcon={<CheckIcon />}
                        colorScheme="green"
                        onClick={() => {
                            handleSaveOrgName();
                            toggleEditMode();
                        }}
                    >
                        Save
                    </Button>
                )}
            </Flex>

            {/* Organization Details Card */}
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" boxShadow="lg" mb={6}>
                <Box bg={headerBg} p={6}>
                    <Heading size="md">
                        {editMode ? (
                            <HStack>
                                <Input
                                    size="sm"
                                    value={tempOrgName}
                                    onChange={(e) => setTempOrgName(e.target.value)}
                                />
                            </HStack>
                        ) : (
                            organization.name
                        )}
                    </Heading>
                    <Text fontSize="md" mt={1} color="gray.500">
                        Organization Details
                    </Text>
                </Box>
                <Box p={6} bg={cardBg}>
                    <SimpleGrid columns={[1, 2]} spacing={5}>
                        <Box>
                            <Text fontSize="md" fontWeight="semibold">
                                Default Domain:
                            </Text>
                            {editMode ? (
                                <RadioGroup
                                    value={organization.defaultDomain}
                                    onChange={handleSetDefaultDomain}
                                >
                                    <VStack align="start">
                                        {organization.domains.map((domain, idx) => (
                                            <Radio key={idx} value={domain}>
                                                {domain}
                                            </Radio>
                                        ))}
                                    </VStack>
                                </RadioGroup>
                            ) : (
                                <Text fontSize="md">{organization.defaultDomain}</Text>
                            )}
                        </Box>
                        <Box>
                            <Text fontSize="md" fontWeight="semibold">
                                Domains:
                            </Text>
                            <Box mt={2}>
                                {organization.domains.map((domain, index) => (
                                    <Tag
                                        key={index}
                                        colorScheme="blue"
                                        mr={2}
                                        mb={2}
                                        display="inline-flex"
                                        alignItems="center"
                                    >
                                        <Text mr={editMode && domain !== organization.defaultDomain ? 2 : 0}>
                                            {domain}
                                        </Text>
                                        {editMode && domain !== organization.defaultDomain && (
                                            <IconButton
                                                size="xs"
                                                aria-label="Remove domain"
                                                icon={<CloseIcon boxSize={2} />}
                                                onClick={() => handleRemoveDomain(domain)}
                                                variant="ghost"
                                            />
                                        )}
                                    </Tag>
                                ))}
                            </Box>
                        </Box>
                    </SimpleGrid>

                    {editMode && (
                        <Box mt={4}>
                            <FormControl>
                                <FormLabel>Add Domain</FormLabel>
                                <HStack>
                                    <Input
                                        placeholder="New domain"
                                        value={newDomain}
                                        onChange={(e) => setNewDomain(e.target.value)}
                                    />
                                    <Button colorScheme="blue" onClick={handleAddDomain}>
                                        Add
                                    </Button>
                                </HStack>
                            </FormControl>
                        </Box>
                    )}
                </Box>
            </Box>

            {/* Profile Sections Card */}
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" boxShadow="lg">
                <Box bg={headerBg} p={6}>
                    <Heading size="md">Profile Sections</Heading>
                    <Text fontSize="sm" mt={1} color="gray.500">
                        {editMode ? "Edit profile sections" : "Configured profile sections"}
                    </Text>
                </Box>
                <Box p={6} bg={cardBg}>
                    <Table variant="striped" colorScheme="blue" size="md" mb={6}>
                        <Thead>
                            <Tr>
                                <Th>Role</Th>
                                <Th>Sections</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td fontWeight="semibold">Students</Td>
                                <Td>
                                    {organization.organizationProfileSettings?.studentProfileSections.map(
                                        (section, idx) => (
                                            <Tag
                                                key={idx}
                                                colorScheme="blue"
                                                mr={2}
                                                mb={2}
                                                display="inline-flex"
                                                alignItems="center"
                                            >
                                                <Text mr={editMode ? 2 : 0}>{section}</Text>
                                                {editMode && (
                                                    <IconButton
                                                        size="xs"
                                                        disabled={section==ProfileSection.PROFILE}
                                                        aria-label="Remove student section"
                                                        icon={<CloseIcon boxSize={2} />}
                                                        variant="ghost"
                                                        onClick={() => handleRemoveStudentProfileSection(section)}
                                                    />
                                                )}
                                            </Tag>
                                        )
                                    )}
                                </Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight="semibold">Parents</Td>
                                <Td>
                                    {organization.organizationProfileSettings?.parentProfileSections.map(
                                        (section, idx) => (
                                            <Tag
                                                key={idx}
                                                colorScheme="blue"
                                                mr={2}
                                                mb={2}
                                                display="inline-flex"
                                                alignItems="center"
                                            >
                                                <Text mr={editMode ? 2 : 0}>{section}</Text>
                                                {editMode && (
                                                    <IconButton
                                                        size="xs"
                                                        disabled={section==ProfileSection.PROFILE}
                                                        aria-label="Remove parent section"
                                                        icon={<CloseIcon boxSize={2} />}
                                                        variant="ghost"
                                                        onClick={() => handleRemoveParentSection(section)}
                                                    />
                                                )}
                                            </Tag>
                                        )
                                    )}
                                </Td>
                            </Tr>
                            <Tr>
                                <Td fontWeight="semibold">Teachers</Td>
                                <Td>
                                    {organization.organizationProfileSettings?.teacherProfileSections.map(
                                        (section, idx) => (
                                            <Tag
                                                key={idx}
                                                colorScheme="blue"
                                                mr={2}
                                                mb={2}
                                                display="inline-flex"
                                                alignItems="center"
                                            >
                                                <Text mr={editMode ? 2 : 0}>{section}</Text>
                                                {editMode && (
                                                    <IconButton
                                                        size="xs"
                                                        disabled={section==ProfileSection.PROFILE}
                                                        aria-label="Remove teacher section"
                                                        icon={<CloseIcon boxSize={2} />}
                                                        variant="ghost"
                                                        onClick={() => handleRemoveTeacherSection(section)}
                                                    />
                                                )}
                                            </Tag>
                                        )
                                    )}
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>

                    <VStack spacing={4} align="stretch">
                        <FormControl>
                            <FormLabel>Student Primary ID Custom Field</FormLabel>
                            <HStack>
                                <Select
                                    placeholder="Select custom field"
                                    value={studentPrimaryID?.$oid}
                                    onChange={(e) => setStudentPrimaryID(to_oid(e.target.value))}
                                >
                                    {studentCustomFields.map((section, idx) => (
                                        <option key={idx} value={section._id.$oid}>
                                            {section.name}
                                        </option>
                                    ))}
                                </Select>
                                <Button colorScheme="blue" onClick={handleSetStudentPrimaryId}>
                                    Set
                                </Button>
                            </HStack>
                        </FormControl>
                    </VStack>
                    <br/>

                    {editMode && (
                        <VStack spacing={4} align="stretch">
                            <FormControl>
                                <FormLabel>Add Parent Section</FormLabel>
                                <HStack>
                                    <Select
                                        placeholder="Select parent section"
                                        value={newParentSection}
                                        onChange={(e) => setNewParentSection(profileSectionFromJSON(e.target.value))}
                                    >
                                        {allProfileSections.map((section, idx) => (
                                            <option key={idx} value={section}>
                                                {section}
                                            </option>
                                        ))}
                                    </Select>
                                    <Button colorScheme="blue" onClick={handleAddParentSection}>
                                        Add
                                    </Button>
                                </HStack>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Add Teacher Section</FormLabel>
                                <HStack>
                                    <Select
                                        placeholder="Select teacher section"
                                        value={newTeacherSection}
                                        onChange={(e) => setNewTeacherSection(profileSectionFromJSON(e.target.value))}
                                    >
                                        {allProfileSections.map((section, idx) => (
                                            <option key={idx} value={section}>
                                                {section}
                                            </option>
                                        ))}
                                    </Select>
                                    <Button colorScheme="blue" onClick={handleAddTeacherSection}>
                                        Add
                                    </Button>
                                </HStack>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Add Student Section</FormLabel>
                                <HStack>
                                    <Select
                                        placeholder="Select student section"
                                        value={newStudentSection}
                                        onChange={(e) => setNewStudentSection(profileSectionFromJSON(e.target.value))}
                                    >
                                        {allProfileSections.map((section, idx) => (
                                            <option key={idx} value={section}>
                                                {section}
                                            </option>
                                        ))}
                                    </Select>
                                    <Button colorScheme="blue" onClick={handleAddStudentProfileSection}>
                                        Add
                                    </Button>
                                </HStack>
                            </FormControl>
                        </VStack>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default OrganizationPage;
