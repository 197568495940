import {Organization} from "protobuffer-ts/dist/organization_service/organization"
import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";

export namespace OrganizationAPI {
    export const get_organization = async (organization_id: ObjectId) => {
        return HTTPApi.get(`/organization/id/${organization_id.$oid}`)
            .then((res) => {
                return res.data as Organization;
            });
    };

    export const get_organizations = async (): Promise<Organization[]> => {
        return HTTPApi.get(`/organization/organizations`)
            .then((res) => {
                return res.data as Organization[];
            });
    };

    export const rename_organization = async (organization_id: ObjectId, new_name: string) => {
        return HTTPApi.post(`/organization/rename/${organization_id.$oid}`, {
            new_name,
        }).then((res) => {
            return res.data as Organization;
        });
    };

    export const update_default_domain = async (organization_id: ObjectId, new_default_domain: string) => {
        return HTTPApi.post(`/organization/update_default_domain/${organization_id.$oid}`, {
            new_default_domain,
        }).then((res) => {
            return res.data as Organization;
        });
    };

    export const add_domain = async (organization_id: ObjectId, new_domain: string) => {
        return HTTPApi.post(`/organization/add_domain/${organization_id.$oid}`, {
            new_domain,
        }).then((res) => {
            return res.data as Organization;
        });
    };

    export const remove_domain = async (organization_id: ObjectId, remove_domain: string) => {
        return HTTPApi.post(`/organization/remove_domain/${organization_id.$oid}`, {
            remove_domain,
        }).then((res) => {
            return res.data as Organization;
        });
    };
}