import { Flex, Stack, Text, IconButton, Collapse, Tabs, TabList, TabPanels, Tab, TabPanel, Badge, HStack } from "@chakra-ui/react";
import { useState } from "react";
import { Student, StudentStatus } from "../../../models/student";
import { OnboardingSettings } from "protobuffer-ts/dist/organization_service/onboarding_settings";
import { ProfileSection } from "protobuffer-ts/dist/organization_service/organization_profile_settings";
import { BlCard } from "../../layout/Card";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import StudentCustomFieldsSection from "../CustomFieldsSection";
import { StudentAPI } from "../../../api/StudentAPI";
import { ObjectId } from "../../../utils/ObjectId";
import { useToaster } from "../../../hooks/useToaster";
import { OnboardingMessageDialog } from "./OnboardingMessageDialog";
import { LightButton } from "../../layout/LightButton";
import { ActionType, getEmailTemplate } from "../../../models/onboarding";
import { StudentInvoicesCard } from "../Cards/InvoicesCard";

interface ApplicantStudentCardProps {
    student: Student;
    profileSections: ProfileSection[];
    onboardingSettings: OnboardingSettings;
    onStudentAction: (studentId: ObjectId) => void;
}

export const ApplicantStudentCard = ({
    student,
    profileSections,
    onStudentAction,
    onboardingSettings,
}: ApplicantStudentCardProps) => {
    const { showSuccessToast, showErrorToast } = useToaster();
    const [isExpanded, setIsExpanded] = useState(false);
    const [actionType, setActionType] = useState<ActionType>();
    const [isLoading, setIsLoading] = useState(false);
    const [allInvoicesPaid, setAllInvoicesPaid] = useState<boolean>();

    const handleActionConfirmation = async (message: string) => {
        if (!actionType) return;
        setIsLoading(true);
        try {
            let updatedStudent: Student;
            switch (actionType) {
                case ActionType.RequireChanges:
                    updatedStudent = await StudentAPI.requireFeildsChangesFromParents(student._id, message);
                    break;
                case ActionType.Enroll:
                    updatedStudent = await StudentAPI.enroll(student._id, message);
                    break;
                case ActionType.Reject:
                    updatedStudent = await StudentAPI.reject(student._id, message);
                    break;
                case ActionType.Withdraw:
                    updatedStudent = await StudentAPI.withdraw(student._id, message);
                    break;
            }
            onStudentAction(student._id);
            showSuccessToast(`Successfully set student status to ${updatedStudent!.status}`);
        } catch (error: any) {
            showErrorToast(error?.response?.data || "Action Failed");
        } finally {
            setIsLoading(false);
            setActionType(undefined);
        }
    };

    return (
        <BlCard boxShadow="none" bgColor="gray.50" border="2px dashed" borderColor="gray.300">
            <Flex justify="space-between" align="center">
                <Stack spacing={0}>
                    <Text fontWeight="bold">{student.first_name} {student.last_name}</Text>
                    <Text fontSize="sm" color="gray.500">
                        {student.grade} • {student.gender}
                    </Text>
                </Stack>

                <HStack>
                    {allInvoicesPaid !== undefined && (
                        <Badge colorScheme={allInvoicesPaid ? "green" : "red"} ml={2}>
                            {allInvoicesPaid ? "Paid" : "Not Paid"}
                        </Badge>
                    )}
                    <IconButton
                        aria-label={isExpanded ? "Collapse" : "Expand"}
                        icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        size="sm"
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                </HStack>
            </Flex>

            <Collapse in={isExpanded}>
                <Tabs variant="enclosed" mt={4}>
                    <TabList>
                        <Tab>Information</Tab>
                        <Tab>Invoices</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <StudentCustomFieldsSection student={student} profileSections={profileSections} />
                            <Flex direction="row" justify="flex-end" gap={4} mt={4}>
                                <LightButton color="gray" onClick={() => setActionType(ActionType.RequireChanges)} isLoading={isLoading}>
                                    Require changes
                                </LightButton>
                                <LightButton color="green" onClick={() => setActionType(ActionType.Enroll)} isLoading={isLoading}>
                                    Enroll
                                </LightButton>
                                {student.status === StudentStatus.Applicant ? (
                                    <LightButton color="red" onClick={() => setActionType(ActionType.Reject)} isLoading={isLoading}>
                                        Reject
                                    </LightButton>
                                ) : (
                                    <LightButton color="red" onClick={() => setActionType(ActionType.Withdraw)} isLoading={isLoading}>
                                        Withdraw
                                    </LightButton>
                                )}
                            </Flex>
                        </TabPanel>
                        <TabPanel>
                            <StudentInvoicesCard student={student} onInvoiceStatusChange={setAllInvoicesPaid} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Collapse>

            {actionType && (
                <OnboardingMessageDialog
                    isOpen={!!actionType}
                    onClose={() => setActionType(undefined)}
                    messageType={actionType}
                    initialMessage={getEmailTemplate(actionType, onboardingSettings)!}
                    onConfirm={handleActionConfirmation}
                />
            )}
        </BlCard>
    );
};
