import { Alert, AlertIcon, Badge, Box, Button, Divider, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, SimpleGrid, Skeleton, Stack, Switch, Text, Textarea } from '@chakra-ui/react';
import { CustomFieldEntry } from 'protobuffer-ts/dist/user_service/custom_field_entry';
import { ProfileSection } from "protobuffer-ts/dist/organization_service/organization_profile_settings";
import { useEffect, useState } from 'react';
import { CustomFieldEntryAPI } from '../../api/CustomFIeldEntryAPI';
import { CustomFieldsGroupAPI } from '../../api/CustomFieldsGroupAPI';
import { UserRole } from '../../api/UserApi';
import { useToaster } from '../../hooks/useToaster';
import { useUser } from '../../hooks/useUser';
import { CustomField, CustomFieldType } from '../../models/custom_field';
import { ApprovalStatus, CustomFieldsGroup, GroupApprovalStatus } from '../../models/custom_fields_group';
import { ObjectId, to_oid } from '../../utils/ObjectId';
import DatePicker from '../DatePicker';
import DocumentCustomField from './DocumentCustomField';


type EntryValue = string | string[] | boolean | Date;
interface CustomFieldsGroupDisplayProps {
    group: CustomFieldsGroup;
    userId: ObjectId;
    onGroupStatusUpdate: (groupId: ObjectId, status: ApprovalStatus) => void;
}


export const CustomFieldsGroupDisplay = ({group, userId, onGroupStatusUpdate}: CustomFieldsGroupDisplayProps) => {

    const [fields, setFields] = useState<CustomField[]>();
    const [entries, setEntries] = useState<CustomFieldEntry[]>();
    const [fieldValues, setFieldValues] = useState<Map<string, EntryValue>>(new Map());
    const [approvalStatus, setApprovalStatus] = useState<GroupApprovalStatus | null>();
    const [havePermissionToView, setHavePermissionToView] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    
    const { user, userIsTeacher } = useUser() 
    const canApproveAndReject = userIsTeacher && user!.has_role(UserRole.Super_Admin);

    const [showRejectionInput, setShowRejectionInput] = useState<boolean>(false);
    const [rejectionMessage, setRejectionMessage] = useState<string>('');

    const { showErrorToast, showSuccessToast } = useToaster()

    const fetchFields = async () => {
        setLoading(true);

        try {
            const fetchedActiveFields = await CustomFieldsGroupAPI.get_group_active_custom_fields(group._id);
            setFields(fetchedActiveFields);
        } catch (error: any) {
            const message = error.response?.data || 'Failed to load group data'
            showErrorToast(message)
        }
    }

    const fetchEntries = async () => {
        try {
            const fetchedEntries = await CustomFieldEntryAPI.get_user_active_entries_for_group(userId, group._id);
            setEntries(fetchedEntries);

            const newMap = new Map<string, EntryValue>();
            fetchedEntries.forEach((entry: CustomFieldEntry) => {
                const field = fields!.find((f) => f._id.$oid == entry.customFieldId!.id)!;
                const deserializedValue = handleValueDeserialization(entry.value, field!.field_type);
                newMap.set(entry.customFieldId!.id, deserializedValue);  
            })
            
            setFieldValues(newMap);
        } catch (error: any) {
            if (error.response?.status === 403) { 
                setHavePermissionToView(false);
            } else {
                const message = error.response?.data || 'Failed to load entries'
                showErrorToast(message)
            }
        } finally {
            setLoading(false);
        }
    }

    const fetchGroupApprovalStatus = async () => {
        try {
            const fetchedGroupStatus = await CustomFieldsGroupAPI.get_group_approval_status(group._id, userId);
            setApprovalStatus(fetchedGroupStatus);
            onGroupStatusUpdate(group._id, fetchedGroupStatus.status); // Notify parent
        } catch (error: any) {
            // Check if the error is due to the group approval system being disabled
            if (error.response?.data === "OtherError: Group approval system is disabled") {
                setApprovalStatus(null);
            } else {
                const message = error.response?.data || 'Failed to load group data';
                showErrorToast(message);
            }
        }
    }

    const submitEntries = async () => {
        setLoading(true);
        try {

            fields?.forEach((field) => {
                if (field.field_type === CustomFieldType.CheckBox && !fieldValues.has(field._id.$oid)) {
                    fieldValues.set(field._id.$oid, false);
                }
            });

            const payload = Array.from(fieldValues.entries())
                .filter(([fieldId, entryValue]) => {
                    const field = fields!.find((f) => f._id.$oid === fieldId)!;
                    // Exclude empty values and Document type fields
                    return entryValue !== "" && field.field_type !== CustomFieldType.Document;
                })
            .map(([fieldId, entryValue]) => {
                const field = fields!.find((f) => f._id.$oid === fieldId)!;
                return {
                    custom_field_id: to_oid(fieldId),
                    value: handleValueSerialization(entryValue, field.field_type),
                };
            });

            

            const new_entries = await CustomFieldsGroupAPI.assign_entries_to_custom_fields_group(userId, group._id, payload);

            const updatedFieldValues = new Map(fieldValues);

            new_entries.forEach((entry) => {
                const field = fields?.find((f) => f._id.$oid === entry.customFieldId!.id);
                const deserializedValue = handleValueDeserialization(entry.value, field!.field_type);
                updatedFieldValues.set(entry.customFieldId!.id, deserializedValue);
            });

            setFieldValues(updatedFieldValues);
            await fetchGroupApprovalStatus();
            showSuccessToast(`Entries saved successfully for group: ${group.group_name}`)
        } catch (error: any) {
            const message = error.response?.data || `Failed to save entries for group: ${group.group_name}`;

            showErrorToast(message)
        } finally {
            setLoading(false);
        }
    };

    const handleDocumentUpdate = async (newEntry: CustomFieldEntry) => {
        // update CustomFieldEntry of the document field
        setEntries(prev => {
            const existingIndex = prev!.findIndex(e => e.customFieldId!.id === newEntry.customFieldId!.id);
            
            const updated = [...prev!];
            updated[existingIndex] = newEntry;
            return updated;
        });
        
        await fetchGroupApprovalStatus();
    };

    const approveGroup = async () => {
        setLoading(true);
        try {

            let new_approval_status = await CustomFieldsGroupAPI.approve_group(group._id, userId);
            setApprovalStatus(new_approval_status);
            onGroupStatusUpdate(group._id, new_approval_status.status);
            showSuccessToast(`Group: ${group.group_name} has been approved successfully`)
        } catch (error: any) {
            const message = error.response?.data || `Failed to approve group: ${group.group_name}`;

            showErrorToast(message)

        } finally {
            setLoading(false);
        }
    };


    const rejectGroup = async () => {

        if(!showRejectionInput) {
            setShowRejectionInput(true);
            return; 
        }

        setLoading(true);
        try {
            
            let new_approval_status = await CustomFieldsGroupAPI.reject_group(group._id, userId, rejectionMessage);
            setApprovalStatus(new_approval_status);
            onGroupStatusUpdate(group._id, new_approval_status.status);
            
            showSuccessToast(`Group: ${group.group_name} has been rejected successfully`)
        } catch (error: any) {
            const message = error.response?.data || `Failed to reject group: ${group.group_name}`;
            showErrorToast(message)
        } finally {
            setLoading(false);
        }
    };

    const handleValueSerialization = (value: EntryValue, fieldType: CustomFieldType) => {
        if (fieldType === CustomFieldType.Select || fieldType === CustomFieldType.CheckBox) {
            value = JSON.stringify(value);
        }
        return value as string;
    };
    
    const handleValueDeserialization = (value: string, fieldType: CustomFieldType) => {
        if (fieldType === CustomFieldType.Date) {
            return new Date(value);
        } else if (fieldType === CustomFieldType.Select || fieldType === CustomFieldType.CheckBox) {
            return JSON.parse(value) as EntryValue;
        }
        return value as EntryValue;
    };

    useEffect(() => {
        fetchFields();
        fetchGroupApprovalStatus();
    }, []);

    useEffect(() => {
        if(fields)
            fetchEntries();
    }, [fields]);

    const handleFieldChange = (fieldId: ObjectId, newValue: EntryValue) => {
        setFieldValues((prevValues) => {
            const updatedValues = new Map(prevValues);
            updatedValues.set(fieldId.$oid, newValue);
            return updatedValues;
        });
    };

    const renderFieldInput = (field: CustomField) => {
        const value = fieldValues.get(field._id.$oid);

        const renderStringField = (value: string) => (
            <Textarea value={value} onChange={(e) => handleFieldChange(field._id, e.target.value)} />
        )

        const renderSelectField = (value: string[]) => {
            const selectedValue = value && value.length > 0 ? value[0] : '';

            return (
                <RadioGroup
                    onChange={(e) => {
                        const selectedArray = [e];
                        handleFieldChange(field._id, selectedArray);
                    }}
                    value={selectedValue}
                >
                    <Stack>
                        {field.options?.map((option, index) => (
                            <Radio key={index} value={option}>
                                {option}
                            </Radio>
                        ))}
                    </Stack>
                </RadioGroup>
            );
        };

        const renderAddressField = (value: string) => (
            <Input value={value} onChange={(e) => handleFieldChange(field._id, e.target.value)} />
        );

        const renderDateField = (value: Date) => (
            <DatePicker
                selectedDate={value}
                onChange={(date) => {
                    if (date instanceof Date) {
                        handleFieldChange(field._id, date);
                    } else {
                        console.error('Invalid date value:', date);
                    }
                }}
            />
        );

        const renderRegexField = (value: string) => (
            <Input value={value} onChange={(e) => handleFieldChange(field._id, e.target.value)} />
        );

        const renderCheckboxField = (value: boolean) => (
            <Switch
                isChecked={value}
                onChange={(e) => handleFieldChange(field._id, e.target.checked)}
            />
        );

        const renderDocumentField = (field: CustomField) => (
            <DocumentCustomField 
                userId={userId}
                fieldId={field._id}
                field_entry={entries?.find(entry => entry.customFieldId!.id === field._id.$oid)} 
                onDocumentUpdated={handleDocumentUpdate}/>
        )

        switch (field.field_type) {
            case CustomFieldType.String:
                return renderStringField(value as string);
            case CustomFieldType.Select:
                return renderSelectField(value as string[]);
            case CustomFieldType.Address:
                return renderAddressField(value as string);
            case CustomFieldType.Date:
                return renderDateField(value as Date);
            case CustomFieldType.RegexValidated:
                return renderRegexField(value as string);
            case CustomFieldType.CheckBox:
                return renderCheckboxField(value as boolean);
            case CustomFieldType.Document: 
                return renderDocumentField(field);
        }
    };
          
    // Do not display group if it has no fields
    if(!fields || fields.length == 0) {
        return null;
    }

    return (
        <Box
            as="form"
            bg={
                approvalStatus?.status === ApprovalStatus.Rejected
                    ? 'red.50'
                    : approvalStatus?.status === ApprovalStatus.PendingReview
                    ? 'rgba(255, 255, 250)'
                    : 'bg-surface'
            }
            borderColor={
                approvalStatus?.status === ApprovalStatus.Rejected
                    ? 'red.500'
                    : 'none'
            }
            flex="1"
            p={{ base: '4', md: '6' }}
        >
            
        <FormLabel fontSize="xl" fontWeight="bold" mb={4} display="flex" alignItems="center">
            {group.group_name}
            <Box ml={2} color={approvalStatus?.status === ApprovalStatus.Rejected ? 'red.500' : 'gray.500'}>
                {approvalStatus?.status === ApprovalStatus.Approved && (
                    <Badge colorScheme="green">Approved</Badge>
                )}
                {approvalStatus?.status === ApprovalStatus.PendingReview && (
                    <Badge colorScheme="orange">Review Pending</Badge>
                )}
                {approvalStatus?.status === ApprovalStatus.RequiredToFill && (
                    <Badge colorScheme="blue">Required to Fill {group.profile_section === ProfileSection.INTERVIEW ? "by interviewer" : "by parents"}</Badge>
                )}
                {approvalStatus?.status === ApprovalStatus.Rejected && (
                    <Badge colorScheme="red"
                    border="2px solid"
                    borderColor="red.500"
                    >Rejected</Badge>
                )}
            </Box>
        </FormLabel>
            
        {group.hints && group.hints.length > 0 && (
            <Box mb={6}>
                {group.hints.map((hint, index) => (
                    <Box mb={2} key={index}>
                        <Alert status="info" variant="subtle">
                            <AlertIcon />
                            <Text>{hint}</Text>
                        </Alert>
                    </Box>
                ))}
            </Box>
        )}
        
        {approvalStatus?.status === ApprovalStatus.Rejected && (
            <Alert status="error" variant="left-accent" mt={4} mb={2}>
                <AlertIcon />
                <Text fontSize="sm">
                    <strong>Rejection Reason:</strong> {approvalStatus.rejection_message || 'No message provided'}
                </Text>
            </Alert>
        )}

            {loading ? (
                <Stack gap="4">
                    <Skeleton height="100px" />
                    <Skeleton height="100px" />
                    <Skeleton height="100px" />
                </Stack>
            ) : (
                <SimpleGrid
                    columns={{base: 1, md: 2, lg: 3, xl: 4}}
                    gap={6}
                    mb={6}
                >
                    {fields.map((field) => 
                        <FormControl isRequired={field.is_required} key={field._id.$oid}>
                            <FormLabel> {field.name} </FormLabel>

                            {field.description && (
                                <Box mb={2} color="gray.500">
                                    {field.description}
                                </Box>
                            )}
                            {havePermissionToView ? renderFieldInput(field) : <Input isDisabled placeholder="******" opacity={0} />}
                        </FormControl>
                    )}
                </SimpleGrid>
            )}

            <Divider mt={6} mb={4} />
            {havePermissionToView ? (
                <Flex direction="row" justify="flex-end" gap={4} mt={4}>
                    
                    {showRejectionInput && approvalStatus?.status == ApprovalStatus.PendingReview && (
                        <Textarea
                            placeholder="Enter rejection reason"
                            value={rejectionMessage}
                            onChange={(e) => setRejectionMessage(e.target.value)}
                            size="md"
                            resize="vertical"
                            rows={3}
                        />
                    )}


                    {!(showRejectionInput && approvalStatus?.status === ApprovalStatus.PendingReview) && <Button colorScheme="green" onClick={submitEntries} isLoading={loading}>
                        Save
                    </Button>}

                    {canApproveAndReject && approvalStatus?.status === ApprovalStatus.PendingReview && (
                        <>
                            {!showRejectionInput && <Button colorScheme="blue" onClick={approveGroup} isLoading={loading}>
                                Approve
                            </Button>}
                            
                            <Button colorScheme="red" onClick={rejectGroup} isLoading={loading}>
                                Reject
                            </Button>
                        </>
                    )}
                </Flex>
            ) : (
                <Text fontSize="lg" color="red.500" textAlign="center" mt={4}>
                    You do not have the necessary permissions to view the values of the fields in the group "
                    {group.group_name}". Please contact your administrator if you believe this is a mistake.
                </Text>
            )}

        </Box>
    );
};