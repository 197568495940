import React from "react";
import { Box } from '@chakra-ui/react';
import { BlHeading } from "../layout/Heading";
// import { AttendanceHomeroom } from "./AttendanceHomeroom";
// import { AttendanceCourse } from "./AttendanceCourse";


export const Violations = React.memo((props) =>  {
    return (
        // <Grid h='full' p={3}>
                <Box>
                    <Box overflowX="auto" overflowY="hidden">
                        <BlHeading> Violations </BlHeading>
                        {/* SHOULD BE REFACTORED */}
                        {/* <ClassList attendance={true}/> */}
                    </Box>
                </Box>
        // {/* </Grid> */}
    );
} );
