import { ObjectId } from "../utils/ObjectId"
import { ObjectId as ProtoObjectId } from "protobuffer-ts/dist/utils/object_id";

export interface Lookup {
    value: ObjectId
    label: string
}

export const getLookupLabel = (list : Lookup[], value: ObjectId | ProtoObjectId) => {
    const id = "$oid" in value ? value.$oid : value.id;
    return list.find((item) => item.value.$oid === id)?.label;
}