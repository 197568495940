import {
    Stack
} from "@chakra-ui/react";
import React from "react";
import { Teacher } from "../../models/teacher";
import { TeacherRoles } from "./TeacherRoles";
import { SignaturePage } from "../signauture/SignaturePage";
import { TabRoutes } from "../layout/TabRoutes";
import { TeacherProfileCard } from "./ProfileCard";
import { BlBreadcrumbs, useUpdateBreadcrumbs } from "../layout/Breadcrumbs";



interface Card {
    teacher: Teacher,
    set_teacher: React.Dispatch<React.SetStateAction<Teacher | undefined>> | React.Dispatch<React.SetStateAction<Teacher>>
    show_save?: boolean
    show_overview?: boolean
}

export const TeacherProfile = ({ teacher, set_teacher }: Card) => {
    useUpdateBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Teachers", path: "/teachers/" },
        { label: "Teacher Profile" },
    ])

    const tabs = [
        {
            label: "Profile",
            path: `/teachers/${teacher._id.$oid}/profile`,
            element: <TeacherProfileCard teacher={teacher} set_teacher={set_teacher} />,
        },
        {
            label: "Signature",
            path: `/teachers/${teacher._id.$oid}/signature`,
            element: <SignaturePage user_id={teacher._id} />
        },
        {
            label: "Roles",
            path: `/teachers/${teacher._id.$oid}/roles`,
            element: <TeacherRoles teacher={teacher} set_teacher={set_teacher} />
        }
    ]

    return (
        <Stack>
            <BlBreadcrumbs />

            <TabRoutes tabs={tabs} />
        </Stack>
    )

}