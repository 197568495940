import { Box, Button, Heading, SimpleGrid, Stack, Text, HStack, Code } from "@chakra-ui/react";
import { BlCard } from "../layout/Card";
import { useForm } from "react-hook-form";
import FormTextarea from "../forms/FormTextarea";
import { OnboardingSettingsAPI } from "../../api/OnboardingSettingsAPI";
import { useToaster } from "../../hooks/useToaster";
import { EmailTemplatesData } from "../../models/onboarding_settings";


interface EmailTemplatesEditorProps {
    hasInterview: boolean;
    initialData: EmailTemplatesData;
}

export const EmailTemplatesEditor = ({ hasInterview, initialData }: EmailTemplatesEditorProps) => {
    const { showErrorToast, showSuccessToast } = useToaster();
    const { register, handleSubmit } = useForm({
        defaultValues: initialData,
    });

    const onSubmit = async (data: EmailTemplatesData) => {
        try {
            await OnboardingSettingsAPI.update_email_templates(data);
            showSuccessToast("Email templates updated");
        } catch {
            showErrorToast("Update failed");
        }
    };

    return (
        <BlCard>
            <Heading size="md" mb={6}>
                Email Templates
            </Heading>

            <Box bg="gray.50" p={4} borderRadius="md" mb={6}>
                <Text fontSize="sm" color="gray.600" mb={2}>
                    You can use these placeholders in your templates:
                </Text>
                <SimpleGrid columns={2} spacing={2}>
                    <HStack spacing={2}>
                        <Code fontSize="xs" bg="gray.100" px={2} py={1} borderRadius="sm">
                            {`{student_name}`}
                        </Code>
                        <Text fontSize="sm" color="gray.600">
                            Student's full name
                        </Text>
                    </HStack>
                    <HStack spacing={2}>
                        <Code fontSize="xs" bg="gray.100" px={2} py={1} borderRadius="sm">
                            {`{parent_name}`}
                        </Code>
                        <Text fontSize="sm" color="gray.600">
                            Parent's name
                        </Text>
                    </HStack>
                    <HStack spacing={2}>
                        <Code fontSize="xs" bg="gray.100" px={2} py={1} borderRadius="sm">
                            {`{grade}`}
                        </Code>
                        <Text fontSize="sm" color="gray.600">
                            Student's grade level
                        </Text>
                    </HStack>
                </SimpleGrid>
                <Text fontSize="sm" color="gray.600" mt={4}>
                    These will be automatically replaced with actual values when emails are sent.
                </Text>
            </Box>

            <Stack spacing={6}>
                <FormTextarea
                    name="interviewEmailTemplate"
                    label="Interview Invitation Template"
                    placeholder="Hi {parent_name}, please schedule an interview..."
                    register={register("interview_email_template")}
                />
                <FormTextarea
                    name="postInterviewEmailTemplate"
                    label="Post-Interview Followup Template"
                    placeholder="Thank you for interviewing with us..."
                    register={register("post_interview_email_template")}
                />
                <FormTextarea
                    name="rejectionEmailTemplate"
                    label="Rejection Email Template"
                    placeholder="We regret to inform you that..."
                    register={register("rejection_email_template")}
                />
                <FormTextarea
                    name="approvalEmailTemplate"
                    label="Approval Email Template"
                    placeholder="Congratulations! We are pleased to inform you that..."
                    register={register("approval_email_template")}
                />

                <FormTextarea
                    name="fieldGroupRejectionEmailTemplate"
                    label="Field Group Rejection Email Template"
                    placeholder="request parents to refill fields"
                    register={register("field_group_rejection_email_template")}
                />
                
                <FormTextarea
                    name="withdrawEmailTemplate"
                    label="Withdraw Email Template"
                    placeholder="Inform parents about the student's withdrawal"
                    register={register("withdraw_email_template")}
                />

                <Button onClick={handleSubmit(onSubmit)} colorScheme="blue" alignSelf="flex-end">
                    Save Templates
                </Button>
            </Stack>
        </BlCard>
    );
};
