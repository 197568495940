import { startOfDay, endOfDay, subDays, subMonths } from "date-fns";
export namespace utils {
    export const get_utc_date = (date: Date) => {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    }
}


export type DateFilterOption = "today" | "yesterday" | "lastMonth" | "custom";

export const dateFilterOptions: { value: DateFilterOption; label: string } [] = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "lastMonth", label: "Last Month" },
    { value: "custom", label: "Custom" },
];

export const getAttendanceDateRange = (
    dateFilter: DateFilterOption,
    customRange: { start?: Date; end?: Date }
): { start: Date; end: Date } => {
    const today = new Date();
    let start: Date;
    let end: Date;
    switch (dateFilter) {
        case "today":
            start = startOfDay(today);
            end = endOfDay(today);
            break;
        case "yesterday":
            const yesterday = subDays(today, 1);
            start = startOfDay(yesterday);
            end = endOfDay(yesterday);
            break;
        case "lastMonth":
            const lastMonthDate = subMonths(today, 1);
            start = startOfDay(lastMonthDate);
            end = endOfDay(today);
            break;
        case "custom":
            start = customRange.start ? startOfDay(customRange.start) : startOfDay(today);
            end = customRange.end ? endOfDay(customRange.end) : endOfDay(today);
            break;
        default:
            start = startOfDay(today);
            end = endOfDay(today);
            break;
    }
    return { start, end };
};


