import { OnboardingSettings, ItemizedFee } from "protobuffer-ts/dist/organization_service/onboarding_settings";
import { AWSFile } from "protobuffer-ts/dist/utils/aws_file";
import { EmailTemplatesData, EnrollmentConfigData, WaitlistFeeData } from "../models/onboarding_settings";
import HTTPApi from "./ApiCommon";

export namespace OnboardingSettingsAPI {
    export const get_onboarding_settings = async (): Promise<OnboardingSettings> => {
        return HTTPApi.get("/onboarding_settings")
            .then((res) => {
                return OnboardingSettings.fromJSON(res.data);
            });
    };

    export const get_school_handbook = async (): Promise<AWSFile[]> => {
        const res = await HTTPApi.get("/onboarding_settings/school_handbook"); 
        return res.data;
    };
    
    // Update the enrollment configuration (interview & approval system).
    export const update_enrollment_configuration = async (data: EnrollmentConfigData): Promise<OnboardingSettings> => {
        return HTTPApi.post("/onboarding_settings/enrollment-configuration", data).then((res) => {
                return OnboardingSettings.fromJSON(res.data);
        });
    };


    export const update_waitlist_fee = async (data: WaitlistFeeData): Promise<OnboardingSettings> => {
        return HTTPApi.post("/onboarding_settings/waitlist-fee", data)
            .then((res) => {
                return OnboardingSettings.fromJSON(res.data);
            });
    };

    export const update_email_templates = async (data: EmailTemplatesData): Promise<OnboardingSettings> => {
        return HTTPApi.post("/onboarding_settings/email-templates", data).then((res) => {
                return OnboardingSettings.fromJSON(res.data);
        });
    };

    // itemized fee for each grade
    export const update_registration_fee = async (registration_fees: Record<string, ItemizedFee>): Promise<OnboardingSettings> => {
        return HTTPApi.post("/onboarding_settings/registration-fee", { registration_fees })
            .then((res) => {
                return OnboardingSettings.fromJSON(res.data);
            });
    };

    export const update_reregistration_fee = async (reregistration_fees: Record<string, ItemizedFee>): Promise<OnboardingSettings> => {
        return HTTPApi.post("/onboarding_settings/reregistration-fee", { reregistration_fees })
            .then((res) => {
                return OnboardingSettings.fromJSON(res.data);
            });
    };


    export const upload_school_handbook = async (file: File, file_name: string): Promise<AWSFile[]> => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', file_name);

        return HTTPApi.post("/onboarding_settings/upload_school_handbook", formData)
            .then((res) => {
                return res.data.schoolHandbook.map((file: any) => AWSFile.fromJSON(file));
        })
    }

    export const remove_school_handbook = async (file_index: number): Promise<AWSFile[]> => {
        return HTTPApi.post("/onboarding_settings/remove_school_handbook", {
            file_index,
        }).then((res) => {
            return (res.data.schoolHandbook ?? []).map((file: any) => AWSFile.fromJSON(file));
        })
    }
}