import { Box, Stack, SimpleGrid, Text, VStack, Skeleton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { OnboardingSettings } from "protobuffer-ts/dist/organization_service/onboarding_settings";
import { OnboardingSettingsAPI } from "../../api/OnboardingSettingsAPI";
import { EnrollmentConfigSection } from "./EnrollmentConfigSection";
import { WaitlistFeeSection } from "./WaitlistFeeSection";
import { GradeFeesEditor } from "./GradeFeesEditor";
import { EmailTemplatesEditor } from "./EmailTemplatesEditor";
import { DocumentUploadSection } from "./DocumentUploadSection";
import { useToaster } from "../../hooks/useToaster";

export const OnboardingSettingsPage = () => {
    const { showErrorToast } = useToaster();
    const [settings, setSettings] = useState<OnboardingSettings>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true); 
        OnboardingSettingsAPI.get_onboarding_settings()
            .then((data) => {
                setSettings(data);
                setLoading(false);
            })
            .catch(() => {
                showErrorToast("Failed to load settings");
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box maxW="800px" mx="auto" mt="10" p="5">
                <VStack spacing="5" align="stretch">
                    <Skeleton height="40px" />
                    <Skeleton height="25px" />
                    <Skeleton height="25px" />
                    <Skeleton height="70px" />
                    <Skeleton height="25px" />
                </VStack>
            </Box>
        );
    }

    if (!settings) {
        return null; 
    }

    return (
        <Box p={6}>
            <SimpleGrid columns={12} spacing={6}>
                <Box gridColumn="span 3">
                    <Stack spacing={6}>
                        <EnrollmentConfigSection
                            initialData={{
                                has_interview_for_newcomers: settings.hasInterviewForNewcomers,
                                enable_group_approval_system: settings.enableGroupApprovalSystem,
                            }}
                        />

                        <DocumentUploadSection
                            title="School Handbook"
                            initialFiles={settings.schoolHandbook || []}
                            onUpload={OnboardingSettingsAPI.upload_school_handbook}
                            onDelete={OnboardingSettingsAPI.remove_school_handbook}
                        />

                        <WaitlistFeeSection initialData={{ waitlist_fee: settings.waitlistFee }} />
                    </Stack>
                </Box>

                <Box gridColumn="span 9">
                    <Stack spacing={6}>
                        <EmailTemplatesEditor
                            hasInterview={settings.hasInterviewForNewcomers}
                            initialData={{
                                interview_email_template: settings.interviewEmailTemplate,
                                post_interview_email_template: settings.postInterviewEmailTemplate,
                                rejection_email_template: settings.rejectionEmailTemplate,
                                approval_email_template: settings.approvalEmailTemplate,
                                field_group_rejection_email_template: settings.fieldGroupRejectionEmailTemplate,
                                withdraw_email_template: settings.withdrawEmailTemplate,
                            }}
                        />

                        <GradeFeesEditor
                            title="Registration Fees"
                            feeType="registrationFees"
                            initialData={settings.registrationFees}
                            onSaveApi={(data) => OnboardingSettingsAPI.update_registration_fee(data)}
                        />

                        <GradeFeesEditor
                            title="Reregistration Fees"
                            feeType="reregistrationFees"
                            initialData={settings.reregistrationFees}
                            onSaveApi={(data) => OnboardingSettingsAPI.update_reregistration_fee(data)}
                        />
                    </Stack>
                </Box>
            </SimpleGrid>
        </Box>
    );
};
