import {Timestamp} from "bson";

interface LongDate{
    $numberLong: string
}
export interface BsonDate{
    $date: LongDate
}

export class DateTime {
    $date: LongDate;
    constructor(date: BsonDate){
        this.$date = date.$date
    }

    static from_date(date: Date) : DateTime{
        return new DateTime({
            $date : {
                $numberLong: (date.getTime()).toString()
            }
        })
    }

    static from_iso_8601(date: string): DateTime {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            throw new Error("Invalid ISO 8601 date string");
        }
        return new DateTime({
            $date: {
                $numberLong: parsedDate.getTime().toString(),
            },
        });
    }

    to_date(): Date{
        return new Date(parseInt(this.$date.$numberLong))
    }

    to_utc_string_date(): string {
        return this.to_date().toLocaleDateString('en-GB', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            timeZone: 'UTC',
        });
    }
}