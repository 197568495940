import React, { useState, useEffect } from 'react';
import { InvoiceResponse, InvoiceStatus } from '../../../models/invoice';
import { InvoiceAPI } from '../../../api/InvoiceAPI';
import { InvoicesTable } from '../../invoice/InvoicesTable';
import { Student } from '../../../models/student';
import { BlCard } from '../../layout/Card';
import { FormLabel } from '@chakra-ui/react';
import LocalStorageService from '../../../api/LocalStorageService';
import { UserType } from '../../../api/UserApi';

interface StudentInvoicesCardProps {
	student: Student,
	onInvoiceStatusChange?: (allPaid: boolean) => void,
	wrapInCard?: boolean;
}
export const StudentInvoicesCard: React.FC<StudentInvoicesCardProps> = React.memo(({ student, onInvoiceStatusChange, wrapInCard }) => {

	const [invoices, setInvoices] = useState<InvoiceResponse[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const user = LocalStorageService.getInstance().getUser();


	useEffect(() => {
		const fetchInvoices = async () => {
			setLoading(true);

			try {
				let response = await InvoiceAPI.user_invoices(student._id);

				const basePath = user?.user_type === UserType.Parent ? '/parent/invoices/' : '/invoice/';
				response = response.map(i => ({
					...i,
					link: `${basePath}${i.invoice._id.$oid}`
				}));

				const allPaid = response.every(i => i.status === InvoiceStatus.Paid);
                if (onInvoiceStatusChange) {
                    onInvoiceStatusChange(allPaid);
                }
				setInvoices(response);
			} catch (err) {
				console.error('Error fetching invoices:', err);
				setInvoices([]);
			} finally {
				setLoading(false);
			}
		};

		fetchInvoices();
	}, [student]);

	return (
		wrapInCard === true
			? 
			<InvoicesTable invoices={invoices} loading={loading} />
			: 
			<BlCard>
				<FormLabel fontSize={"lg"} fontWeight="bold">
					Student Invoices
				</FormLabel>

				<InvoicesTable invoices={invoices} loading={loading} />
			</BlCard>
	);
});
