import {
    Stack, Button,
    Spacer,
    Center,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    SimpleGrid
} from '@chakra-ui/react';
import React, { RefObject, useEffect } from "react";
import { Student, StudentStatus } from "../../models/student";
import { StudentAPI } from '../../api/StudentAPI';
import { FiAlertTriangle, FiEdit } from 'react-icons/fi';
import { ObjectId } from '../../utils/ObjectId';
import { FocusableElement } from "@chakra-ui/utils";
import { UserRole } from '../../api/UserApi';
import { IncidentCard } from './Cards/IncidentCard';
import { StudentCourseList } from './Cards/StudentCourseList';
import { StudentHomeroomList } from './Cards/StudentHomeroomList';
import GClassStudentViewer from '../gclass/GClassStudentViewer';
import StudentOverview from './Cards/OverviewCard';
import { LightButton } from '../layout/LightButton';
import { useUser } from '../../hooks/useUser';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import { useToaster } from "../../hooks/useToaster";
import { openInNewTab } from '../../utils/route';
import { StudentCustomFieldsSection } from './CustomFieldsSection';
import { StudentInvoicesCard } from './Cards/InvoicesCard';
import { ReportsQueue } from '../reports/ReportsQueue';
import { ProfileSection } from 'protobuffer-ts/dist/organization_service/organization_profile_settings';
import { OrganizationProfileSettingsAPI } from '../../api/OrganizationProfileSettingsAPI';
import { StudentAttendanceCard } from './Cards/AttendanceCard';
import { FlexWrap } from '../layout/FlexWrap';
import SkeletonTable from '../table/TableSkeleton';
import { TabRoutes } from '../layout/TabRoutes';
import { FamilyOverviewCard } from './Cards/FamilyOverviewCard';
import { Family } from '../../models/family';
import { FamilyAPI } from '../../api/FamilyAPI';
import { BlBreadcrumbs, useUpdateBreadcrumbs } from '../layout/Breadcrumbs';

interface OutletContext {
    setHeading: (heading: string) => void;
}

export const StudentProfile = () => {
    const location = useLocation();
    let { student_id } = useParams();
    
    const [student, set_student] = React.useState<Student>();
    const [family, set_family] = React.useState<Family>();
    const [profileSections, setProfileSections] = React.useState<ProfileSection[]>([]);

    const { user, userIsTeacher } = useUser();
    const canAccessIncident = userIsTeacher && user!.has_role(UserRole.Can_Access_Student_Incident);

    useUpdateBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Students", path: "/students/" },
        { label: "Student Profile" }
    ])

    useEffect(() => {
        OrganizationProfileSettingsAPI.get_organization_profile_settings()
            .then(res => {
                const filteredSections = res.studentProfileSections.filter(
                    (section: ProfileSection) => section !== ProfileSection.WAITLIST && section !== ProfileSection.INTERVIEW
                );
                setProfileSections(filteredSections);
            });
    }, []);

    const { setHeading } = useOutletContext<OutletContext>();

    useEffect(() => {
        StudentAPI.get_student(student_id!)
            .then((res) => {
                set_student(res!);
                setHeading(`${res!.first_name} ${res!.last_name}`);

                FamilyAPI.get_family(res!.family_id).then(family => set_family(family))
            });
    }, [student_id, setHeading]);

    // Show a loader if student data is not available yet
    if (!student || !family) {
        return <SkeletonTable />
    }

    const tabs = [
        {
            label: "Overview",
            path: `/students/${student._id.$oid}/overview`,
            element: (
                <SimpleGrid columns={{ lg: 4 }} gap={4}>
                    <Stack gap="4">
                        <StudentOverview student={student} set_student={() => set_student(student)} />
                    </Stack>
                    <Stack gap={4} gridColumn={{ lg: "span 3" }}>
                        <StudentHomeroomList student={student} />
                        <StudentCourseList student={student} link={`${location.pathname}/course/`} />
                    </Stack>
                </SimpleGrid>
            ),
        },
        {
            label: "Family",
            path: `/students/${student._id.$oid}/family`,
            element: <FamilyOverviewCard family={family} />,
        },
        {
            label: "Invoices",
            path: `/students/${student._id.$oid}/invoices`,
            element: <StudentInvoicesCard student={student} />,
        },
        {
            label: "Reports",
            path: `/students/${student._id.$oid}/reports`,
            element: <ReportsQueue student_id={student._id} />,
        },
        {
            label: "Attendance",
            path: `/students/${student._id.$oid}/attendance`,
            element: <StudentAttendanceCard student={student} />,
        },
        {
            label: "Student Information",
            path: `/students/${student._id.$oid}/info`,
            element: <StudentCustomFieldsSection student={student} profileSections={profileSections} />,
        },
        ...(canAccessIncident
            ? [
                {
                    label: "Incidents",
                    path: `/students/${student._id.$oid}/incidents`,
                    element: <IncidentCard student={student} set_student={set_student} />,
                },
            ]
            : []),
        {
            label: "Academic Tracker",
            path: `/students/${student._id.$oid}/academic-tracker`,
            element: (
                <>
                    <GClassStudentViewer type="homeroom" studentId={student._id} />
                    <GClassStudentViewer type="course" studentId={student._id} />
                </>
            ),
        },
    ];


    return (
        <Stack>
            <BlBreadcrumbs />

            <TabRoutes tabs={tabs} />

            <FlexWrap>
                <Spacer />

                <LightButton color="purple" onClick={() => openInNewTab('/students/printable/' + student._id.$oid)}>
                    Printable Page
                </LightButton>

                {student && student.status === StudentStatus.Enrolled && userIsTeacher &&
                    <ChangePasswordPopover student_id={student._id} />
                }
                {student && (student.status === StudentStatus.Enrolled || student.status === StudentStatus.Applicant || StudentStatus.ReRegistration) && userIsTeacher &&
                    <WithdrawPopOver student={student} />
                }
            </FlexWrap>
        </Stack>
    );
};


interface ChangePasswordPopoverCard{
    student_id: ObjectId
}
const ChangePasswordPopover = ({student_id}: ChangePasswordPopoverCard) => {
    const { showErrorToast, showSuccessToast } = useToaster()

    const { isOpen, onToggle, onClose } = useDisclosure()

    const [password, set_password] = React.useState<string>("");
    const [change_password_at_next_login, set_change_password_at_next_login] = React.useState<boolean>(true);

    const change_password = () => {
        StudentAPI.change_password(student_id, password, change_password_at_next_login)
        .then((res) => {
            onToggle()
            set_password("")
            showSuccessToast('Password Change');
        })
        .catch((err) => {
            set_password("")

            showErrorToast("Failed to change password")
        })
    }


    return (

    <Popover
    isOpen={isOpen}
    onClose={onClose}
    >
        <PopoverTrigger >
            <LightButton color="orange" rightIcon={<FiEdit/>} onClick={onToggle}>Change Password</LightButton>
        </PopoverTrigger>
        <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Change Password</PopoverHeader>
            <PopoverBody>
                <>
                <FormControl isRequired>
                    <FormLabel>Password</FormLabel>
                    <Input placeholder='Password' type="password" value={password} onChange={(value) => {set_password(value.target.value)}}/>
                </FormControl>
                <br/>
                <FormControl isRequired>
                    <Checkbox 
                        colorScheme="green" 
                        isChecked={change_password_at_next_login}
                        onChange={(e) => set_change_password_at_next_login(e.target.checked)}
                        >Change Password at Next Login</Checkbox>                
                </FormControl>
                <br/>
                <Center>
                    <Button colorScheme="green" onClick={change_password}>Submit</Button>
                </Center>
                </>
            </PopoverBody>
        </PopoverContent>
    </Popover>

    )
}


interface WithdrawPopoverCard{
    student: Student
}
const WithdrawPopOver = ({student}: WithdrawPopoverCard) => {
    const { showErrorToast, showSuccessToast } = useToaster()


    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef() as RefObject<FocusableElement>

    const [loading, set_loading] = React.useState<boolean>(false)
    

    const withdraw = () => {
        set_loading(true)
        if(student.status === StudentStatus.Enrolled || StudentStatus.ReRegistration){
            StudentAPI.withdraw(student._id, "")
            .then((res) => {
                onClose()
            showSuccessToast('SemeStudent Withdrawn');
                window.location.reload();
            })
            .catch((err) => {
                set_loading(false)

                showErrorToast("Failed to withdraw student")
            })
        }
        else{
            StudentAPI.reject(student._id, "")
            .then((res) => {
                onClose()
            showSuccessToast('SemeStudent Rejected');
                window.location.reload();
            })
            .catch((err) => {
                set_loading(false)

                showErrorToast("Failed to reject student")
            })
        }
    }

  return (
    <>
      <LightButton color='red' rightIcon={<FiAlertTriangle/>} onClick={onOpen}>
        {student.status === StudentStatus.Enrolled ? "Withdraw" : "Reject"}
      </LightButton>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                {student.status === StudentStatus.Enrolled ? "Withdraw" : "Reject"} Student?
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef as React.LegacyRef<HTMLButtonElement>} onClick={onClose}>
                Cancel
              </Button>
                <Button colorScheme='red' onClick={withdraw} ml={3} isLoading={loading}>
                    {student.status === StudentStatus.Enrolled ? "Withdraw"
                        : student.status === StudentStatus.ReRegistration ? "Re-Withdraw"
                            : "Reject"}
                </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
