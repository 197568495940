import { useEffect, useRef, useState } from "react";
import { Stack, HStack, Spacer, Text } from '@chakra-ui/react';
import { useLocation } from "react-router-dom";
import { TableContent } from "../table/TableContent";
import { TeacherAPI } from "../../api/TeacherAPI";
import { TablePagination } from "../table/TablePagination";
import SearchInput from "../forms/SearchInput";
import { TeacherList as TeacherListModel, TeacherProfile } from "../../models/teacher";
import LocalStorageService from "../../api/LocalStorageService";
import { UserRole } from "../../api/UserApi";
import AddButton from "../layout/AddButton";
import { CreateTeacherDialog } from "./CreateTeacherDialog";
import { DateTime } from "../../utils/DateTime";
import SkeletonTable from "../table/TableSkeleton";
import { CopyIcon } from "../layout/CopyIcon";
import { BlHeading } from "../layout/Heading";
import { BlBreadcrumbs } from "../layout/Breadcrumbs";

export const TeacherList = () => {
    const PER_PAGE = 15
    const location = useLocation();
    const [teachers_data, set_teachers_data] = useState<Array<TeacherObj>>();
    const [teachers_page, set_teachers_page] = useState<number>(1);
    const [total_teachers, set_total_teachers] = useState<number>();
    const [teachers_name_search, set_teachers_name_search] = useState<string>();

    const [isOpen, setIsOpen] = useState(false)
    const onClose = () => setIsOpen(false)
    const cancelRef = useRef()

    const canCreateTeacher = LocalStorageService.getInstance().getUser()?.has_role(UserRole.Can_Create_Teacher)


    const columns = [
        {
            Header: "Name",
            accessor: "name",
			Cell: (name: string) => <Text fontWeight="medium">{name}</Text>
        },

        {
            Header: "Email",
            accessor: "email",
			Cell: (email: string) => (
                <HStack>
                    <Text color="gray.500">{email}</Text>

                    <CopyIcon text={email} />
                </HStack>
            )
        },
    ]


    const _set_teacher_list = (res: TeacherListModel) => {
        let teachers = res.teachers.map((teacher) => {
            return {
                ...teacher,
                date_of_birth: new DateTime(teacher.date_of_birth).to_date(),
                name: teacher.first_name + " " + teacher.last_name,
                link: location.pathname + "/" + teacher._id.$oid
            }
        })
        set_teachers_data(teachers)
        set_total_teachers(res.teachers_count)
    }

    const set_course_list = (_page: number, name_search: string | undefined) => {
        TeacherAPI.teachers_list(name_search, PER_PAGE, _page)
            .then((res) => {
                _set_teacher_list(res!)
            })
    }

    useEffect(() => {
        set_course_list(teachers_page, teachers_name_search)
    }, [teachers_page]);

    useEffect(() => {
        set_teachers_page(1)
        set_course_list(1, teachers_name_search)
    }, [teachers_name_search]);


    return teachers_data ?
        (
            <Stack>
    			<BlHeading> Teacher List </BlHeading>

                <BlBreadcrumbs />

                <HStack>
                    <HStack>
                        <SearchInput onChange={set_teachers_name_search} />
                    </HStack>

                    {canCreateTeacher &&
                        <>
                            <Spacer />

                            <AddButton
                                onClick={() => setIsOpen(true)}
                            >
                                Add Teacher
                            </AddButton>

                            <CreateTeacherDialog isOpen={isOpen} onClose={onClose} cancelRef={cancelRef} />
                        </>
                    }
                </HStack>


                <TableContent data={teachers_data} columns={columns} />

                <TablePagination
                    page={teachers_page}
                    onPageChange={set_teachers_page}
                    total={total_teachers!}
                />
            </Stack>

        )
        : <SkeletonTable />


}


type TeacherObj = TeacherProfile & {
    name: string,
    link?: string
}
