// src/components/staff/StaffDashboard.tsx

import React from 'react';
import {
    Box,
    Flex,
    Grid,
    Heading,
    Icon,
    SimpleGrid,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    Text,
    useColorModeValue,
    VStack,
} from '@chakra-ui/react';
import {
    FaUsers,
    FaChartLine,
    FaBuilding,
    FaClock,
    FaUserPlus,
    FaChartPie,
    FaDollarSign,
    FaHeartbeat,
    FaHeadset,
    FaServer,
    FaTachometerAlt,
    FaCogs,
} from 'react-icons/fa';
import { IconType } from 'react-icons';

/**
 * Interface for StatCard component props
 */
interface StatCardProps {
    title: string;
    value: number | string;
    icon: IconType;
    color: string;
}

/**
 * Interface for ActivityCard component props
 */
interface ActivityCardProps {
    title: string;
    activity: string;
    icon: IconType;
    color: string;
}

/**
 * Interface for ChartCard component props
 */
interface ChartCardProps {
    title: string;
    children?: React.ReactNode;
}

/**
 * Interface for DashboardData
 */
interface DashboardData {
    totalUsers: number;
    activeUsers: number;
    totalOrganizations: number;
    recentActivity: string;
    newSignups: number;
    churnRate: string;
    totalRevenue: string;
    mrr: string;
    arpu: string;
    userEngagement: string;
    supportTickets: number;
    systemUptime: string;
    averageResponseTime: string;
    featureUsage: string;
    topFeatures: string;
    geographicDistribution: string;
    deviceBrowserBreakdown: string;
    conversionRates: string;
}

/**
 * Reusable StatCard Component
 */
const StatCard: React.FC<StatCardProps> = ({ title, value, icon, color }) => {
    return (
        <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            bg={useColorModeValue('white', 'gray.800')}
        >
            <Flex align="center">
                <Icon as={icon} w={8} h={8} color={color} mr={4} />
                <Box>
                    <Stat>
                        <StatLabel>{title}</StatLabel>
                        <StatNumber>{value}</StatNumber>
                    </Stat>
                </Box>
            </Flex>
        </Box>
    );
};

/**
 * Reusable ActivityCard Component
 */
const ActivityCard: React.FC<ActivityCardProps> = ({ title, activity, icon, color }) => {
    return (
        <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            bg={useColorModeValue('white', 'gray.800')}
            display="flex"
            alignItems="center"
        >
            <Icon as={icon} w={8} h={8} color={color} mr={4} />
            <Box>
                <Stat>
                    <StatLabel>{title}</StatLabel>
                    <StatHelpText>{activity}</StatHelpText>
                </Stat>
            </Box>
        </Box>
    );
};

/**
 * Reusable ChartCard Component
 */
const ChartCard: React.FC<ChartCardProps> = ({ title, children }) => {
    return (
        <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            bg={'white'}
            minH="200px"
        >
            <Text fontSize="lg" mb={4} fontWeight="bold">
                {title}
            </Text>
            {/* Placeholder for chart */}
            {children || (
                <Box h="150px" bg={'gray.100'} borderRadius="md" />
            )}
        </Box>
    );
};

/**
 * Main StaffDashboard Component
 */
const StaffDashboard: React.FC = () => {
    // Fake data for demonstration
    const data: DashboardData = {
        totalUsers: 1500,
        activeUsers: 1200,
        totalOrganizations: 75,
        recentActivity: 'User JohnDoe signed up.',
        newSignups: 50,
        churnRate: '5%',
        totalRevenue: '$50,000',
        mrr: '$4,000',
        arpu: '$33.33',
        userEngagement: 'High',
        supportTickets: 20,
        systemUptime: '99.9%',
        averageResponseTime: '200ms',
        featureUsage: 'Feature A: 70%, Feature B: 30%',
        topFeatures: 'Feature A, Feature C',
        geographicDistribution: 'North America, Europe, Asia',
        deviceBrowserBreakdown: 'Desktop: 60%, Mobile: 30%, Tablet: 10%',
        conversionRates: 'Signup: 10%, Upgrade: 5%',
    };

    return (
        <Box p={6} bg={useColorModeValue('gray.100', 'gray.700')} minH="100vh">
            <Heading as="h1" size="xl" mb={6}>
                Staff Dashboard
            </Heading>

            <VStack align="start" spacing={6}>
                {/* Overview Section */}
                <Box w="100%">
                    <Heading as="h3" size="md" mb={4}>
                        Overview
                    </Heading>
                    <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={6}>
                        <StatCard
                            title="Total Users"
                            value={data.totalUsers}
                            icon={FaUsers}
                            color="blue.500"
                        />
                        <StatCard
                            title="Active Users"
                            value={data.activeUsers}
                            icon={FaChartLine}
                            color="green.500"
                        />
                        <StatCard
                            title="Organizations"
                            value={data.totalOrganizations}
                            icon={FaBuilding}
                            color="purple.500"
                        />
                        <ActivityCard
                            title="Recent Activity"
                            activity={data.recentActivity}
                            icon={FaClock}
                            color="orange.500"
                        />
                    </SimpleGrid>
                </Box>

                {/* User Acquisition Section */}
                <Box w="100%">
                    <Heading as="h3" size="md" mb={4}>
                        User Acquisition
                    </Heading>
                    <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={6}>
                        <StatCard
                            title="New Signups"
                            value={data.newSignups}
                            icon={FaUserPlus}
                            color="teal.500"
                        />
                        <StatCard
                            title="Churn Rate"
                            value={data.churnRate}
                            icon={FaHeartbeat}
                            color="red.500"
                        />
                        <StatCard
                            title="Conversion Rates"
                            value={data.conversionRates}
                            icon={FaChartPie}
                            color="yellow.500"
                        />
                        {/* Placeholder for future metrics */}
                        <Box />
                    </SimpleGrid>
                </Box>

                {/* Revenue Metrics Section */}
                <Box w="100%">
                    <Heading as="h3" size="md" mb={4}>
                        Revenue Metrics
                    </Heading>
                    <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={6}>
                        <StatCard
                            title="Total Revenue"
                            value={data.totalRevenue}
                            icon={FaDollarSign}
                            color="green.500"
                        />
                        <StatCard
                            title="Monthly Recurring Revenue (MRR)"
                            value={data.mrr}
                            icon={FaDollarSign}
                            color="green.300"
                        />
                        <StatCard
                            title="Average Revenue per User (ARPU)"
                            value={data.arpu}
                            icon={FaDollarSign}
                            color="green.700"
                        />
                        {/* Placeholder for future metrics */}
                        <Box />
                    </SimpleGrid>
                </Box>

                {/* User Engagement Section */}
                <Box w="100%">
                    <Heading as="h3" size="md" mb={4}>
                        User Engagement
                    </Heading>
                    <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={6}>
                        <StatCard
                            title="User Engagement"
                            value={data.userEngagement}
                            icon={FaHeartbeat}
                            color="pink.500"
                        />
                        <ChartCard title="Feature Usage">
                            {/* Placeholder: Replace with actual chart when implemented */}
                        </ChartCard>
                        <ChartCard title="Top Performing Features">
                            {/* Placeholder: Replace with actual chart when implemented */}
                        </ChartCard>
                        {/* Placeholder for future metrics */}
                        <Box />
                    </SimpleGrid>
                </Box>

                {/* Support and Operations Section */}
                <Box w="100%">
                    <Heading as="h3" size="md" mb={4}>
                        Support and Operations
                    </Heading>
                    <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={6}>
                        <StatCard
                            title="Support Tickets"
                            value={data.supportTickets}
                            icon={FaHeadset}
                            color="cyan.500"
                        />
                        <StatCard
                            title="System Uptime"
                            value={data.systemUptime}
                            icon={FaServer}
                            color="gray.500"
                        />
                        <StatCard
                            title="Avg. Response Time"
                            value={data.averageResponseTime}
                            icon={FaTachometerAlt}
                            color="blue.300"
                        />
                        <StatCard
                            title="System Health"
                            value="Good"
                            icon={FaCogs}
                            color="yellow.500"
                        />
                    </SimpleGrid>
                </Box>

                {/* Demographics Section */}
                <Box w="100%">
                    <Heading as="h3" size="md" mb={4}>
                        Demographics
                    </Heading>
                    <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={6}>
                        <ChartCard title="Geographic Distribution">
                            {/* Placeholder: Replace with actual chart when implemented */}
                        </ChartCard>
                        <ChartCard title="Device Breakdown">
                            {/* Placeholder: Replace with actual chart when implemented */}
                        </ChartCard>
                        <ChartCard title="Browser Usage">
                            {/* Placeholder: Replace with actual chart when implemented */}
                        </ChartCard>
                        <ChartCard title="Platform Usage">
                            {/* Placeholder: Replace with actual chart when implemented */}
                        </ChartCard>
                    </SimpleGrid>
                </Box>
            </VStack>
        </Box>
    );
};

export default StaffDashboard;