import * as React from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom";
import LocalStorageService from "../api/LocalStorageService";
import { useUser } from "../hooks/useUser";

export const RequireAuth: React.FC = () => {
    const location = useLocation();

    const userIsLogged = (): boolean => {
        return LocalStorageService.getInstance().getUser() != null;
    }

    if (!userIsLogged()) {
        // Save the current location in the state before redirecting to the login page
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Outlet />;
}

export const RequireAuthTeacher = () => {
    const location = useLocation();


    const { userIsTeacher } = useUser()

    if (!userIsTeacher) {
        return <Navigate to="/" state={{ from: location }} />;
    }

    return <Outlet />;
}

export const RequireAuthStaff = () => {
    const location = useLocation();


    const { userIsStaff } = useUser()

    if (!userIsStaff) {
        return <Navigate to="/staff" state={{ from: location }} />;
    }

    return <Outlet />;
}
  