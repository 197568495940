import * as React from 'react'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useToast,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form'
import { StudentAPI } from '../../../api/StudentAPI'
import { GradesArray, Student, StudentProfileData } from '../../../models/student';
import FormInput from '../../forms/FormInput'
import FormSelect from '../../forms/FormSelect'
import FormDatePicker from '../../forms/FormDatePicker'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateStudentProfileSchema } from '../../../validations/student'

interface EditProfileDialogProps {
  isOpen: boolean
  onClose: () => void
  student: Student
  set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
}

export const EditProfileDialog = React.memo(
  ({ isOpen, onClose, student, set_student }: EditProfileDialogProps) => {
    const toast = useToast()
    const cancelRef = React.useRef<HTMLButtonElement>(null)

    const { register, handleSubmit, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(updateStudentProfileSchema),
        defaultValues: { 
          ...student,
          date_of_birth: student.date_of_birth.to_date()
        },
    })

    React.useEffect(() => {
      reset({
        ...student,
        date_of_birth: student.date_of_birth.to_date()
      })
    }, [student, isOpen])

    const onSubmit = async (data: StudentProfileData) => {
      try {
        await StudentAPI.update_profile(student._id, data)

        const res = await StudentAPI.get_student(student._id.$oid)

        set_student(res!)

        toast({
          title: 'Success',
          description: 'Profile updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        onClose()
      } catch (err: any) {
        let errorMessage = err.response?.data || 'An error occurred'
    
        // Check for the specific permission error and update the message accordingly.
        if (errorMessage.includes('InvalidPermissionsDbModel')) {
          errorMessage = "You can only update student profile data if the student is on the waitlist"
        }
    
        toast({
          title: 'Error',
          description: errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }

    return (
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size="xl"
      >
        <AlertDialogOverlay>
          <AlertDialogContent as="form" onSubmit={handleSubmit(onSubmit)}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Edit Profile
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack gap={4}>
                <SimpleGrid columns={2} gap={4}>
                  <FormInput
                    name="first_name"
                    label="First Name"
                    register={register('first_name')}
                    error={errors.first_name?.message}
                    placeholder="Enter First Name"
                    isRequired
                  />

                  <FormInput
                    name="last_name"
                    label="Last Name"
                    register={register('last_name')}
                    error={errors.last_name?.message}
                    placeholder="Enter Last Name"
                    isRequired
                  />

                  <FormSelect
                    name="gender"
                    label="Gender"
                    register={register('gender')}
                    error={errors.gender?.message}
                    isRequired 
                    options={['M', 'F']}                  
                  />

                  <FormSelect
                    name="grade"
                    label="Grade"
                    options={GradesArray}
                    register={register('grade')}
                    error={errors.grade?.message}
                    isRequired
                  />
                </SimpleGrid>

                <FormDatePicker
                  name="date_of_birth"
                  label="Date of Birth"
                  control={control}
                  error={errors.date_of_birth?.message}
                  isDisabled={false}
                  isRequired
                />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>

              <Button colorScheme="green" ml={3} type="submit">
                Save
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    )
  }
)
