import { View, Text as PdfText } from "@react-pdf/renderer";

import PdfStylesheet from "./PdfStylesheet";

const styles = PdfStylesheet

interface FooterSectionProps {
	page: number
	totalPages: number
}

export const FooterSection: React.FC<FooterSectionProps> = ({ page, totalPages }) => {
	return (
		<View style={styles.footer} fixed>
			<View style={styles.footerColumnLeft}>
				<PdfText>ABA R01</PdfText>
			</View>
			<View style={styles.footerColumnCenter}>
				<PdfText>app.bassemlabs.com</PdfText>
				<PdfText>highschool@alfajrulbassem.com</PdfText>
			</View>
			<View style={styles.footerColumnRight}>
				<PdfText>Tel: +1 (905) 514-9995</PdfText>
				<PdfText>Page {page} of {totalPages}</PdfText>
			</View>
		</View>
	)
}