import { useEffect, useState } from "react";
import {
    Box,
    HStack, useBreakpointValue, Divider,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Badge, FormControl,
    FormLabel, Wrap,
    Button,
    Spacer,
    Select,
    Switch
} from '@chakra-ui/react';
import { AttendanceAPI } from "../../api/AttendanceAPI";
import { useParams } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from "../DatePicker";
import { Student } from "../../models/student";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { AttendanceDesktopTable } from "./AttendanceDesktopTable";
import { AttendanceMobileTable } from "./AttendanceMobileTable";
import { DateTime } from "../../utils/DateTime";
import { SemesterAPI } from "../../api/SemesterAPI";
import { Semester } from "protobuffer-ts/dist/class_service/semester";
import ExcuseStudent from "./ExcuseStudent";
import { AttendanceTableData, mapAttendanceEntries } from "../../models/attendance";
import { AttendanceDatePicker } from "./AttendanceDatePicker";
import { BlHeading } from "../layout/Heading";
import { FlexWrap } from "../layout/FlexWrap";
import { BlBreadcrumbs, useUpdateBreadcrumbs } from "../layout/Breadcrumbs";

export const AttendanceCourse = (() =>  {
    let { course_id } = useParams();

    useUpdateBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Attendance", path: "/attendance" },
        { label: "Courses", path: "/attendance/courses" },
        { label: "Attendance Details" }
    ])

    const [date, set_date] = useState<Date>(new Date());
    const [period_number, set_period_number] = useState<number>(1);
    const [entry_data, set_entry_data] = useState<Array<AttendanceTableData>>([]);
    const [student_attendance_data, set_student_attendance_data] = useState<Array<AttendanceTableData>>([]);
    const [course, set_course] = useState<Course>();
    const [selectedStudent, setSelectedStudent] = useState<Student>();
    const [startDate, setStartDate] = useState<Date>(new Date('2024-09-01T00:00:00'));
    const [endDate, setEndDate] = useState<Date>(new Date('2025-07-01T00:00:00'));
    const [semesters, setSemesters] = useState<Semester[]>([]);
    const [showAll, setShowAll] = useState<boolean>(false);
    const [totalDays, setTotalDays] = useState(0);
    const [totalPresent, setTotalPresent] = useState(0);
    const [totalLate, setTotalLate] = useState(0);
    const [totalAbsent, setTotalAbsent] = useState(0);
    const [isExcuseDialogOpen, setIsExcuseDialogOpen] = useState(false);

    const handleOpenDialog = () => setIsExcuseDialogOpen(true);
    const handleCloseDialog = () => {
        if (selectedStudent) {
            get_student_attendance_entries(selectedStudent, startDate, endDate)
        }
        setIsExcuseDialogOpen(false);
    }

    const on_data_change = (date: any) => {
        set_date(date);
        get_entries(date);
    }

    const get_semesters = async (): Promise<void> => {
        const res = await SemesterAPI.get_active_semesters();
        setSemesters(res);

    };

    const on_semester_change = (semesterId: string) => {
        const selectedSemester = semesters.find(sem => sem.id!.id === semesterId);
        if (selectedSemester && selectedStudent) {
            setStartDate(selectedSemester.startDate!);
            setEndDate(selectedSemester.endDate!);
            get_student_attendance_entries(selectedStudent, selectedSemester.startDate!, selectedSemester.endDate!);
        }
    };

    const get_entries = (date: Date) => {
        AttendanceAPI.get_course_attendance_entries(course_id!, date, period_number).then((res) => {
            let entry: Array<AttendanceTableData> = [];
            let data = res!;
            for(let student in data.students){
                entry.push({
                    date: data.attendance_entries[student].date ? new DateTime(data.attendance_entries[student].date as any).to_date().toDateString() : "No Date",
                    student: data.students[student],
                    attendance_entry: data.attendance_entries[student],
                    first_name: data.students[student].first_name,
                    last_name: data.students[student].last_name,
                    name: data.students[student].first_name + " " + data.students[student].last_name
                });
            }
            // Order by alpha of first_name
            entry.sort((a,b) => a.first_name.localeCompare(b.first_name));

            set_entry_data(entry);
            set_course(data.course);
        });
    }

    const get_student_attendance_entries = (student: Student, startDate: Date, endDate: Date) => {
        AttendanceAPI.get_student_course_attendance_entries(course_id!, student._id.$oid, startDate, endDate)
            .then((res) => {
                if (res) {
                    const entries = mapAttendanceEntries(res, student);

                    const filteredEntries = showAll
                        ? entries
                        : entries.filter(entry => entry.attendance_entry.status === "Late" || entry.attendance_entry.status === "Absent");

                    filteredEntries.sort((a, b) => {
                        const dateA = new Date(parseInt(a.attendance_entry.date.$date.$numberLong));
                        const dateB = new Date(parseInt(b.attendance_entry.date.$date.$numberLong));

                        return dateA.getTime() - dateB.getTime();
                    });

                    setTotalDays(entries.length);
                    setTotalPresent(entries.filter(entry => entry.attendance_entry.status === 'Present').length);
                    setTotalLate(entries.filter(entry => entry.attendance_entry.status === 'Late').length);
                    setTotalAbsent(entries.filter(entry => entry.attendance_entry.status === 'Absent').length);

                    set_student_attendance_data([]);
                    set_student_attendance_data(filteredEntries);
                }
            })
    };

    const on_student_date_change = (start: any, end: any) => {
        setStartDate(start);
        setEndDate(end);
    }

    useEffect(() => {
        get_entries(date);
        get_semesters().then();
        return
    }, [date,period_number]);
    const update_entries = () => {
        get_entries(date);
        return
    }

    useEffect(() => {
        if (selectedStudent && startDate && endDate) {
            get_student_attendance_entries(selectedStudent, startDate, endDate);
        }
    }, [selectedStudent, startDate, endDate, showAll]);
    const update_student_entries = () => {
        if (selectedStudent) {
            get_student_attendance_entries(selectedStudent, startDate, endDate);
        }
    }
    const isMobile = useBreakpointValue({ base: true, md: false });

    // Handlers for incrementing and decrementing
    const handle_next_period = () => {
        if (period_number < 3) {
            set_period_number(period_number + 1);
        }
    };

    const handle_previous_period = () => {
        if (period_number > 1) {
            set_period_number(period_number - 1);
        }
    };


    return (
        <>
            <>
                <BlHeading>Course: {course?.name}</BlHeading>

                <BlBreadcrumbs />

                <Tabs w="full">
                    <TabList>
                        <Tab>Attendance</Tab>
                        <Tab>Single Student View</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <>
                                <Box mb={4}>
                                    <HStack>
                                        { course && 
                                            <FormControl>
                                                <FormLabel>Date</FormLabel>
                                            
                                                <AttendanceDatePicker
                                                    course={course.id!}
                                                    selectedDate={date} 
                                                    onChange={(d) => on_data_change(d)} 
                                                />
                                            </FormControl>
                                        }
                                        <FormControl>
                                            <FormLabel>Period Number</FormLabel>

                                            {/* Control buttons for Previous and Next */}
                                            <Box mt={4}>
                                                <Button
                                                    onClick={handle_previous_period}
                                                    isDisabled={period_number === 1} // Disable if at minimum
                                                    mr={2}
                                                >
                                                    Previous Period
                                                </Button>

                                                <Button
                                                    onClick={handle_next_period}
                                                    isDisabled={period_number === 3} // Disable if at maximum
                                                >
                                                    Next Period
                                                </Button>
                                            </Box>
                                        </FormControl>

                                    </HStack>
                                </Box>
                                <Divider />
                                <>
                                    {isMobile ? (
                                        <AttendanceMobileTable entry_data={entry_data} single_student_view={false} editable={true} />
                                    ) : (
                                        <AttendanceDesktopTable entry_data={entry_data} single_student_view={false} update={update_entries} editable={true} show_periods={true}/>
                                    )}
                                </>
                            </>
                        </TabPanel>
                        <TabPanel>
                            <>
                                <FormLabel>Select Student</FormLabel>
                                <Select bgColor="white" placeholder="Select student" onChange={(e) => {
                                    const selectedStudentId = e.target.value;
                                    const selectedStudent = entry_data!.find(student => student.student._id.$oid === selectedStudentId);
                                    setSelectedStudent(selectedStudent?.student);
                                }}>
                                    {entry_data && entry_data.map(student => (
                                        <option key={student.student._id.$oid.toString()} value={student.student._id.$oid}>
                                            {student.name}
                                        </option>
                                    ))}
                                </Select>
                                {selectedStudent && (
                                    <>
                                        <FlexWrap mt={4} gap={4} align={{md: "flex-end"}}>
                                            <FormControl >
                                                <FormLabel>Select Semester</FormLabel>
                                                <Select bgColor="white" placeholder="Select semester" onChange={(e) => on_semester_change(e.target.value)}>
                                                    {semesters.map((semester) => (
                                                        <option key={semester.id!.id.toString()} value={semester.id!.id.toString()}>
                                                            {semester.name} ({semester.startDate!.toLocaleDateString()} - {semester.endDate!.toLocaleDateString()})
                                                        </option>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <FormControl>
                                                <FormLabel>Start Date</FormLabel>
                                                   <DatePicker selectedDate={startDate} onChange={(d) => on_student_date_change(d, endDate)} />
                                            </FormControl>

                                            <FormControl id="end-date">
                                                <FormLabel>End Date</FormLabel>
                                                <DatePicker selectedDate={endDate} onChange={(d) => on_student_date_change(startDate, d)} />
                                            </FormControl>

                                            <Switch whiteSpace={"nowrap"} colorScheme='green' isChecked={showAll} mb="2" onChange={() => setShowAll(!showAll)}>
                                                Show Present
                                            </Switch>

                                            <FormControl>
                                                <Button colorScheme="blue" onClick={handleOpenDialog}>
                                                    Excuse Student
                                                </Button>
                                            </FormControl>

                                            {selectedStudent && course && (
                                                <ExcuseStudent
                                                    isOpen={isExcuseDialogOpen}
                                                    onClose={handleCloseDialog}
                                                    course={course}
                                                    student={selectedStudent}
                                                />
                                            )}
                                        </FlexWrap>
                                        {student_attendance_data && (
                                            <>
                                                <Wrap align="start" spacing={2} mt={4}>
                                                    <Badge colorScheme="blue">Total days: {totalDays}</Badge>
                                                    <Badge colorScheme="green">Total days present: {totalPresent}</Badge>
                                                    <Badge colorScheme="yellow">Total days late: {totalLate}</Badge>
                                                    <Badge colorScheme="red">Total days absent: {totalAbsent}</Badge>
                                                </Wrap>
                                                <Spacer height="10px" />
                                                {isMobile ? (
                                                    <AttendanceMobileTable entry_data={student_attendance_data} single_student_view={true} editable={true} />
                                                ) : (
                                                    <AttendanceDesktopTable entry_data={student_attendance_data} single_student_view={true} update={update_student_entries} editable={true} show_periods={true}/>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </>
        </>
    );
});