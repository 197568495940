import React, { useState, useCallback } from "react";
import { Box, Heading, Center } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';
import { OnboardingPage } from "./OnboardingPage";

export const Onboarding = React.memo(() => {
    const location = useLocation();
    const [heading, setHeading] = useState("Onboarding");

    const memoizedSetHeading = useCallback((newHeading: string) => {
        setHeading(newHeading);
    }, []);

    return (
        <Box>
            <Center>
                <Heading size="sm" fontWeight={"bold"} mb="6">
                    {heading}
                </Heading>
            </Center>

            {location.pathname === "/onboarding" ? (
                <OnboardingPage />
            ) : (
                <Outlet context={{ setHeading: memoizedSetHeading }} />
            )}
        </Box>
    );
});