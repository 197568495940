import { Box, Button, Flex, FormLabel, Grid, GridItem, Select, SimpleGrid, Skeleton, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ResourceAccessManagementAPI } from "../../api/ResourceAccessManagementAPI";
import { UserType } from "../../api/UserApi";
import { useToaster } from "../../hooks/useToaster";
import { ResourceAccessSettings, OwnershipKind, resourceUserTypes } from "../../models/resource_access_management";
import { FlexWrap } from "../layout/FlexWrap";
import { CreateResourceAccessSettingsDialog } from "./CreateResourceAccessSettingsDialog";
import { ResourceAccessSettingsCard } from "./ResourceAccessSettingsCard";

export const ResourceAccessManagement = () => {
    const { showErrorToast } = useToaster();

    const [userType, setUserType] = useState<UserType>(UserType.Student);
    const [ownershipKind, setOwnershipKind] = useState<OwnershipKind>(OwnershipKind.Owned);
    const [allResourceAccessSettings, setAllResourceAccessSettings] = useState<ResourceAccessSettings[]>([]);
    const [filteredResourceAccessSettings, setFilteredResourceAccessSettings] = useState<ResourceAccessSettings[]>([]);

    const [isAddingResourceAccessSettings, setIsAddingResourceAccessSettings] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleOnAddAccessSettingsClose = (new_access_settings?: ResourceAccessSettings) => {
        if (new_access_settings) {
            setAllResourceAccessSettings([
                ...allResourceAccessSettings,
                new_access_settings]
            );
        }
        setIsAddingResourceAccessSettings(false);
    }

    useEffect(() => {
        try {
            setLoading(true);
            ResourceAccessManagementAPI.get_resource_access_settings().then(settings => setAllResourceAccessSettings(settings));
        } catch (err: any) {
            showErrorToast("Failed to fetch resource access settings");
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
         setFilteredResourceAccessSettings(allResourceAccessSettings.filter((settings) => settings.user_type === userType && settings.ownership_kind === ownershipKind))
    }, [userType, ownershipKind, allResourceAccessSettings])

    if (loading) {
        return (
            <VStack spacing="5" align="stretch">
                <Skeleton height="80px" />
                <Skeleton height="80px" />
                <Skeleton height="80px" />
                <Skeleton height="80px" />
            </VStack>
        );
    }


    return (
        <>
            <FlexWrap mb={4}>
                <Box minW={{ md: 350 }}>
                    <FormLabel>Select User Type</FormLabel>
                    <Select
                        bg="white"
                        onChange={e => setUserType(e.target.value as UserType)}
                    >
                        {resourceUserTypes.map((userType) => (
                            <option key={userType} value={userType}>
                                {userType}
                            </option>
                        ))}
                    </Select>
                </Box>

                <Box minW={{ md: 350 }}>
                    <FormLabel>Select Resource Type</FormLabel>
                    <Select
                        bg="white"
                        onChange={e => setOwnershipKind(e.target.value as OwnershipKind)}
                    >
                        {Object.values(OwnershipKind).map((ownership_kind) => (
                            <option key={ownership_kind} value={ownership_kind}>
                                {ownership_kind}
                            </option>
                        ))}
                    </Select>
                </Box>
            </FlexWrap>

            <SimpleGrid columns={{ lg: 2 }} gap={6}>
                {filteredResourceAccessSettings.map(resourceSettings => (
                    <ResourceAccessSettingsCard resourceAccessSettings={resourceSettings} />
                ))}
            </SimpleGrid>


            <Flex mt={4}>
                <Button colorScheme="blue" onClick={() => setIsAddingResourceAccessSettings(true)}>
                    Add Resource Access Settings
                </Button>
            </Flex>

            <CreateResourceAccessSettingsDialog
                ownership_kind={ownershipKind}
                userType={userType}
                isOpen={isAddingResourceAccessSettings}
                onClose={handleOnAddAccessSettingsClose}
            />
        </>
    );
}