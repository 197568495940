import { Button, Checkbox, Select, Stack, Switch, Text, FormLabel, HStack } from "@chakra-ui/react";
import { ProfileSection } from "protobuffer-ts/dist/organization_service/organization_profile_settings";
import { StudentAPI } from "../../../api/StudentAPI";
import { StudentStatus, validGrades } from "../../../models/student";
import { BlCard } from "../../layout/Card";
import { useState } from "react";

interface StudentFilterProps {
    filter: StudentAPI.StudentsFilterParams;
    setFilter: React.Dispatch<React.SetStateAction<StudentAPI.StudentsFilterParams>>;
    forRegistration: boolean;
    forReRegistration: boolean;
    requiredProfileSections?: ProfileSection[];
}

export const StudentFilter = ({ filter, setFilter, forRegistration, forReRegistration, requiredProfileSections }: StudentFilterProps) => {
    const [isProfileSectionFilterActive, setProfileSectionFilterActive] = useState(true);

    return (
        <BlCard>
            <Text fontWeight="bold" mb={4}>
                Student Filter
            </Text>
            <Stack spacing={4}>
                <Stack>
                    <FormLabel htmlFor="grade-select">Desired Grade</FormLabel>
                    <Select
                        id="grade-select"
                        placeholder="Select Grade"
                        value={filter.grade ?? ""}
                        onChange={(e) =>
                            setFilter((prev) => ({
                                ...prev,
                                grade: e.target.value ? e.target.value : undefined,
                            }))
                        }
                    >
                        {validGrades.map(([gradeKey, gradeName]) => (
                            <option key={gradeKey} value={gradeName}>
                                {gradeName}
                            </option>
                        ))}
                    </Select>
                </Stack>

                <Stack>
                    <FormLabel htmlFor="gender-select">Gender</FormLabel>
                    <Select
                        id="gender-select"
                        placeholder="Select Gender"
                        value={filter.gender ?? ""}
                        onChange={(e) =>
                            setFilter((prev) => ({
                                ...prev,
                                gender: e.target.value ? e.target.value : undefined,
                            }))
                        }
                    >
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                    </Select>
                </Stack>

                {forRegistration && (
                    <HStack spacing={3} alignItems="center">
                        <Switch
                            isChecked={filter.no_nonpaid_registration}
                            onChange={(e) =>
                                setFilter((prev) => ({
                                    ...prev,
                                    no_nonpaid_registration: e.target.checked,
                                }))
                            }
                        />
                        <Text fontSize="sm" flex="1">
                            Exclude non‑paid registration invoices
                        </Text>
                    </HStack>
                )}

                {forReRegistration && (
                    <HStack spacing={3} alignItems="center">
                        <Switch
                            isChecked={filter.no_nonpaid_reregistration}
                            onChange={(e) =>
                                setFilter((prev) => ({
                                    ...prev,
                                    no_nonpaid_reregistration: e.target.checked,
                                }))
                            }
                        />
                        <Text fontSize="sm" flex="1">
                            Exclude non‑paid reregistration invoices
                        </Text>
                    </HStack>
                )}

                {(!!requiredProfileSections && requiredProfileSections.length > 0) && (
                    <HStack spacing={3} alignItems="center">
                        <Switch
                            isChecked={isProfileSectionFilterActive}
                            onChange={(e) => {
                                const isChecked = e.target.checked;
                                setProfileSectionFilterActive(isChecked);

                                setFilter((prev) => ({
                                    ...prev,
                                    filled_profile_sections: isChecked
                                        ? requiredProfileSections
                                        : undefined,
                                }));
                            }}
                        />
                        <Text fontSize="sm" flex="1">
                            Exclude non-completed fields
                        </Text>
                    </HStack>
                )}

                <HStack spacing={3} alignItems="center">
                    <Switch
                        isChecked={filter.sort_enrolled_family_first}
                        onChange={(e) =>
                            setFilter((prev) => ({
                                ...prev,
                                sort_enrolled_family_first: e.target.checked,
                            }))
                        }
                    />
                    <Text fontSize="sm" flex="1">
                        Sort enrolled family first
                    </Text>
                </HStack>
            </Stack>
        </BlCard>
    );
};
