import React from 'react';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import { Select as SearchSelect } from "chakra-react-select";
import { Controller, Control } from 'react-hook-form';
import './styles/form-input.css';
import { ObjectId } from "../../utils/ObjectId";

interface FormSelectProps {
    name: string;
    label: string;
    options: { value: ObjectId | string; label: string }[];
    control: Control<any>;
    error?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
}

const FormSelectOptions: React.FC<FormSelectProps> = ({
    name,
    label,
    options = [],
    control,
    error,
    isDisabled = false,
    isRequired = false
}) => {
    return (
        <FormControl isInvalid={!!error}>
            <FormLabel htmlFor={name} className={isRequired ? 'required' : ''}>{label}</FormLabel>

            <Controller
                name={name}
                control={control}
                rules={{ required: isRequired }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                    <SearchSelect
                        id={name}
                        isDisabled={isDisabled}
                        options={options}
                        ref={ref}
                        onChange={(selectedOption: any) => {
                            onChange(selectedOption?.value || null);
                        }}
                        onBlur={onBlur}
                        value={options.find(option => {
                            if (typeof option === 'string') return option === value
                            else {
                                const _value = option.value
                                return _value?.toString() === value?.toString()
                            }
                        })}
                    />
                )}
            />

            <FormErrorMessage>
                {error}
            </FormErrorMessage>
        </FormControl>
    );
};

export default FormSelectOptions;