
// src/components/OrganizationList.tsx

import React, { useEffect, useState } from "react";
import {
    Box,
    Heading,
    Text,
    VStack,
    Spinner,
    useToast, SimpleGrid, Badge, useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { OrganizationAPI } from "../../api/OrganizationAPI";
import {Organization} from "protobuffer-ts/dist/organization_service/organization"

const OrganizationList: React.FC = () => {
    // We'll store each org with fake stats
    const [organizations, setOrganizations] = useState<
        (Organization & {
            totalParents: number;
            totalTeachers: number;
            totalStudents: number;
        })[]
    >([]);
    const [loading, setLoading] = useState<boolean>(true);
    const toast = useToast();
    const navigate = useNavigate();

    const fetchOrganizations = async () => {
        try {
            setLoading(true);
            const orgs = await OrganizationAPI.get_organizations();

            // Add fake stats
            const extendedOrgs = orgs.map((org) => {
                return {
                    ...org,
                    totalParents: Math.floor(334),
                    totalTeachers: Math.floor(78),
                    totalStudents: Math.floor(276),
                };
            });

            setOrganizations(extendedOrgs);
        } catch (error: any) {
            toast({
                title: "Error fetching organizations",
                description: error.message || String(error),
                status: "error",
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrganizations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOrgClick = (
        org: Organization & {
            totalParents: number;
            totalTeachers: number;
            totalStudents: number;
        }
    ) => {
        // Navigate to the organization page
        navigate(`/staff/organizations/${org.id?.id}`);
    };

    if (loading) {
        return (
            <VStack spacing={4} py={8}>
                <Spinner size="xl" />
                <Text>Loading organizations...</Text>
            </VStack>
        );
    }

    if (organizations.length === 0) {
        return (
            <Box py={8} textAlign="center">
                <Heading size="md">No organizations found.</Heading>
            </Box>
        );
    }

    return (
        <Box py={8}>
            <Heading size="lg" mb={4}>
                Organizations
            </Heading>
            {/* Instead of a table, we render row components in a vertical list */}
            {organizations.map((org) => (
                <OrganizationRow
                    key={org.id?.id}
                    org={org}
                    onClick={handleOrgClick}
                />
            ))}
        </Box>
    );
};

// Extend the Organization interface to include stats
interface ExtendedOrganization extends Organization {
    totalParents: number;
    totalTeachers: number;
    totalStudents: number;
}

// Props for our row component
interface OrganizationRowProps {
    org: ExtendedOrganization;
    onClick: (org: ExtendedOrganization) => void;
}

const OrganizationRow: React.FC<OrganizationRowProps> = ({ org, onClick }) => {

    return (
        <Box
            borderWidth="1px"
            borderRadius="lg"
            bg={"white"}
            p={4}
            mb={4}
            _hover={{ cursor: "pointer" }}
            onClick={() => onClick(org)}
        >
            {/* Top-Level: Name + Default Domain */}
            <Heading size="md" mb={1}>
                {org.name}
            </Heading>
            <Text color="gray.600">{org.defaultDomain || "N/A"}</Text>

            {/* Middle-Level: Domains in Badges */}
            <Box mt={3}>
                {org.domains && org.domains.length > 0 ? (
                    org.domains.map((domain) => (
                        <Badge key={domain} colorScheme="blue" mr={2} mb={2}>
                            {domain}
                        </Badge>
                    ))
                ) : (
                    <Badge colorScheme="red">No Domains</Badge>
                )}
            </Box>

            {/* Bottom-Level: Stats */}
            <SimpleGrid columns={[1, 3]} spacing={4} mt={4}>
                <Box textAlign="center">
                    <Text fontSize="sm" color="gray.500">
                        Total Parents
                    </Text>
                    <Text fontSize="lg" fontWeight="bold">
                        {org.totalParents}
                    </Text>
                </Box>

                <Box textAlign="center">
                    <Text fontSize="sm" color="gray.500">
                        Total Teachers
                    </Text>
                    <Text fontSize="lg" fontWeight="bold">
                        {org.totalTeachers}
                    </Text>
                </Box>

                <Box textAlign="center">
                    <Text fontSize="sm" color="gray.500">
                        Total Students
                    </Text>
                    <Text fontSize="lg" fontWeight="bold">
                        {org.totalStudents}
                    </Text>
                </Box>
            </SimpleGrid>
        </Box>
    );
};

export default OrganizationList;