import * as yup from "yup";
import { Grades } from "../models/student";

const feeItemSchema = yup.object({
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
    price: yup.number().required("Price is required").min(0, "Price cannot be negative"),
    quantity: yup.number().required("Quantity is required").min(1, "Quantity must be at least 1"),
});

const feeSchema = yup.object({
    title: yup.string().required("Fee title is required"),
    description: yup.string().required("Fee description is required"),
    items: yup.array().of(feeItemSchema).min(1, "At least one item is required"),
});

export const gradeFeesValidationSchema = yup.object().shape({
    fees: yup.object().shape(
      Object.fromEntries(
        Array.from(Grades.entries())
          .filter(([_, gradeName]) => gradeName !== "") 
          .map(([gradeKey]) => [gradeKey, feeSchema])
      )
    ),
  });