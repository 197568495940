import { Box, SimpleGrid, Stack, Text, VStack, Skeleton } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { StudentAPI } from "../../../api/StudentAPI";
import { Student, StudentStatus } from "../../../models/student";
import { OnboardingSettings } from "protobuffer-ts/dist/organization_service/onboarding_settings";
import { TablePagination } from "../../table/TablePagination";
import { BlCard } from "../../layout/Card";
import { ObjectId } from "../../../utils/ObjectId";
import { WaitlistStudentCard } from "./WaitlistStudentCard";
import { StudentFilter } from "./StudentFilter";
import { ProfileSection } from "protobuffer-ts/dist/organization_service/organization_profile_settings";
import EmptyPlaceholder from "../../layout/EmptyPlaceholder";

interface WaitlistStudentsProps {
    onboardingSettings: OnboardingSettings;
    onStudentCountUpdate: () => void;
}

export const WaitlistStudents = ({ onboardingSettings, onStudentCountUpdate }: WaitlistStudentsProps) => {
    const PER_PAGE = 15;
    const [students, setStudents] = useState<Student[]>([]);
    const [page, setPage] = useState(1);
    const [totalStudents, setTotalStudents] = useState(0);
    const [loading, setLoading] = useState(true);

    const [filter, setFilter] = useState<StudentAPI.StudentsFilterParams>({
        per_page: PER_PAGE,
        page: 1,
        statuses: [StudentStatus.Waitlist],
        filled_profile_sections: [ProfileSection.WAITLIST],
        no_nonpaid_registration: true,
        no_nonpaid_reregistration: false,
        sort_enrolled_family_first: true,
    });

    const handleStudentAction = (studentId: ObjectId) => {
        setStudents(prevStudents => prevStudents.filter(student => student._id.$oid !== studentId.$oid));
        setTotalStudents(prevTotal => prevTotal - 1);
        onStudentCountUpdate();
    };

    const fetchStudents = () => {
        setLoading(true);

        StudentAPI.filtered_students_list({ ...filter, page: page })
            .then((res) => {
                if (res) {
                    setStudents(res.students);
                    setTotalStudents(res.students_count);
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false); 
                console.error(e);
            });
    };

    useEffect(() => {
        fetchStudents();
    }, [page, filter]);

    return (
        <SimpleGrid columns={12} spacing={6}>
            <Box gridColumn="span 3">
                <StudentFilter
                    filter={filter}
                    setFilter={(newFilter) => setFilter(newFilter)}
                    forRegistration={true}
                    forReRegistration={false}
                    requiredProfileSections={[ProfileSection.WAITLIST]} />
            </Box>

            <BlCard gridColumn="span 9">
                <Stack spacing={4} height="100%">
                    {loading ? (
                        <VStack spacing={4} align="stretch">
                            {[...Array(PER_PAGE)].map((_, i) => (
                                <Box key={i} borderWidth={1} borderRadius="md" p={4}>
                                    <Stack>
                                        <Skeleton height="20px" width="40%" />
                                        <Skeleton height="16px" width="60%" />
                                        <Skeleton height="16px" width="30%" mt={4} />
                                        <Skeleton height="40px" mt={4} />
                                    </Stack>
                                </Box>
                            ))}
                        </VStack>
                    ) : students.length === 0 ? (
                        <EmptyPlaceholder message="No items to show" />
                    ) :
                        (
                            <>
                                <VStack spacing={4} align="stretch" flex={1}>
                                    {students.map((student) => (
                                        <WaitlistStudentCard
                                            key={student._id.$oid}
                                            student={student}
                                            onboardingSettings={onboardingSettings}
                                            onStudentAction={handleStudentAction} />
                                    ))}
                                </VStack>

                                <Box mt="auto">
                                    <TablePagination
                                        page={page}
                                        total={totalStudents}
                                        onPageChange={setPage}
                                    />
                                </Box>
                            </>
                        )}
                </Stack>
            </BlCard>
        </SimpleGrid>
    );
};
