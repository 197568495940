import { UserType } from "../api/UserApi"
import { ObjectId } from "../utils/ObjectId"

export interface CustomField {
    _id: ObjectId, 
    organization: ObjectId, 
    group_id: ObjectId,
    name: string, 
    field_type: CustomFieldType, 
    is_required: boolean, 
    description: string,
    regex_pattern?: string,
    user_type: UserType,
    is_archived: boolean,
    options?: string[]
}

export interface CreateCustomField {
    field_name: string, 
    field_type: CustomFieldType, 
    is_required: boolean,
    description: string,
    is_archived: boolean,
    regex_pattern?: string, 
    options?: string[]
}

export enum CustomFieldType {
    String = "String", 
    Select = "Select", 
    Address = "Address", 
    Date = "Date", 
    RegexValidated = "RegexValidated",
    CheckBox = "CheckBox", 
    Document = "Document",
}


export const customFieldTypeColor: Record<CustomFieldType, string> = {
    [CustomFieldType.Select]: 'red',
    [CustomFieldType.Address]: 'blue',
    [CustomFieldType.Date]: 'yellow',
    [CustomFieldType.RegexValidated]: 'green',
    [CustomFieldType.CheckBox]: 'purple',
    [CustomFieldType.String]: 'orange', 
    [CustomFieldType.Document]: 'teal',
}