import React from 'react';
import { Button, IconButton, useBreakpointValue, ButtonProps } from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';

interface AddButtonProps extends ButtonProps {
	onClick: () => void;
	colorScheme?: string;
	children: React.ReactNode;
}

export const AddButton: React.FC<AddButtonProps> = ({
	onClick,
	colorScheme = 'green',
	children,
	...rest
}) => {
	// Determine if the screen is small
	const isSmallScreen = useBreakpointValue({ base: true, sm: false });

	if (isSmallScreen) {
		// Render an IconButton on small screens
		return (
			<IconButton
				colorScheme={colorScheme}
				icon={<FiPlus />}
				onClick={onClick}
				aria-label={typeof children === 'string' ? children : 'action button'}
				{...rest}
			/>
		);
	}

	return (
		<Button
			colorScheme={colorScheme}
			leftIcon={<FiPlus />}
			onClick={onClick}
			{...rest}
		>
			{children}
		</Button>
	);
};

export default AddButton;
