import {
    Box, Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from "@chakra-ui/react";
import React from "react";
import { Migrations } from "../migrations/Migrations";
import { AiInteractionRecordList } from "../ai_interaction_record/AiInteractionRecordsList";
import { BlHeading } from "../layout/Heading";

export const DeveloperPortal = React.memo(() =>  {

    return (<Box>
            <BlHeading> Developer Portal </BlHeading>

            <Tabs >
                <TabList>
                    <Tab>Migrations</Tab>
                    <Tab>Ai Interaction Records</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Migrations/>
                    </TabPanel>
                    <TabPanel>
                        <AiInteractionRecordList/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>

    );
});
