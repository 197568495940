import React from 'react';
import { Box, Button, Flex, Heading, Stack, Badge } from '@chakra-ui/react';
import { FlexWrap } from '../../layout/FlexWrap';


export interface CardStep {
    id: string;
    title: string;
    component: React.ReactElement;
    enableNext: boolean;
}

export interface CardWizardProps {
    step: CardStep;
    remainingSteps: number;
    onCompleteStep: () => void;
    nextButtonText?: string;
}

export const CardWizard: React.FC<CardWizardProps> = ({ step, remainingSteps, onCompleteStep, nextButtonText = 'Next' }) => {
    return (
        <Box p={4} maxW="800px" margin="0 auto">
            <Stack spacing={6}>
                <Flex justify="space-between" align="center" flexWrap="wrap">
                    <Heading size="md" color="gray.600" mb={[2, 0]}>
                        {step.title}
                    </Heading>
                    <Badge colorScheme="red">
                        {remainingSteps} action{remainingSteps > 1 ? "s" : ""} remaining
                    </Badge>
                </Flex>

                {step.component}

                <FlexWrap justifyContent="flex-end">
                    <Button onClick={onCompleteStep} colorScheme="blue" isDisabled={!step.enableNext}>
                        {remainingSteps > 1 ? nextButtonText : "Finish"}
                    </Button>
                </FlexWrap>
            </Stack>
        </Box>
    );
};
