import { useToast } from '@chakra-ui/react';

export const useToaster = () => {
  const toast = useToast();

  const showErrorToast = (message: string, title?: string) => {
    toast({
      title: title || 'Error',
      description: message,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const showSuccessToast = (message: string, title?: string) => {
    toast({
      title: title || 'Success',
      description: message,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  return { showErrorToast, showSuccessToast };
};
