import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, FormControl, Stack, Text, Badge, HStack, CloseButton, Wrap, WrapItem } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ResourceAccessManagementAPI } from "../../api/ResourceAccessManagementAPI";
import { UserType } from "../../api/UserApi";
import { useToaster } from "../../hooks/useToaster";
import { AccessPermissionType, AccessRule, accessRuleToString, ResourceAccessSettings, OwnershipKind } from "../../models/resource_access_management";
import FormInput from "../forms/FormInput";
import { AddAccessRuleDialog } from "./AddAccessRuleDialog";

interface CreateResourceAccessSettingsDialogProps {
    ownership_kind: OwnershipKind;
    userType: UserType;
    isOpen: boolean;
    onClose: (settings?: ResourceAccessSettings) => void;
}

interface FormValues {
    name: string;
}

export const CreateResourceAccessSettingsDialog: React.FC<CreateResourceAccessSettingsDialogProps> = ({ isOpen, onClose, userType, ownership_kind }) => {
    const cancelRef = useRef<HTMLButtonElement>(null);
    const { showSuccessToast, showErrorToast } = useToaster();

    const { register, handleSubmit } = useForm<FormValues>();

    const [allowedReadRules, setAllowedReadRules] = useState<AccessRule[]>([]);
    const [deniedReadRules, setDeniedReadRules] = useState<AccessRule[]>([]);
    const [allowedWriteRules, setAllowedWriteRules] = useState<AccessRule[]>([]);
    const [deniedWriteRules, setDeniedWriteRules] = useState<AccessRule[]>([]);

    const [isAddRuleDialogOpen, setIsAddRuleDialogOpen] = useState(false);
    const [currentPermissionType, setCurrentPermissionType] = useState<AccessPermissionType | null>(null);

    const [loading, setLoading] = useState(false);

    const openAddRuleDialog = (permissionType: AccessPermissionType) => {
        setCurrentPermissionType(permissionType);
        setIsAddRuleDialogOpen(true);
    };

    const handleAddRule = (rule?: AccessRule) => {
        setIsAddRuleDialogOpen(false);
        if (!rule) return;

        switch (currentPermissionType) {
            case AccessPermissionType.AllowRead:
                setAllowedReadRules([...allowedReadRules, rule]);
                break;
            case AccessPermissionType.DenyRead:
                setDeniedReadRules([...deniedReadRules, rule]);
                break;
            case AccessPermissionType.AllowWrite:
                setAllowedWriteRules([...allowedWriteRules, rule]);
                break;
            case AccessPermissionType.DenyWrite:
                setDeniedWriteRules([...deniedWriteRules, rule]);
                break;
            default:
                break;
        }
        setCurrentPermissionType(null);
    };

    const removeRule = (category: AccessPermissionType, index: number) => {
        switch (category) {
            case AccessPermissionType.AllowRead:
                setAllowedReadRules(allowedReadRules.filter((_, i) => i !== index));
                break;
            case AccessPermissionType.DenyRead:
                setDeniedReadRules(deniedReadRules.filter((_, i) => i !== index));
                break;
            case AccessPermissionType.AllowWrite:
                setAllowedWriteRules(allowedWriteRules.filter((_, i) => i !== index));
                break;
            case AccessPermissionType.DenyWrite:
                setDeniedWriteRules(deniedWriteRules.filter((_, i) => i !== index));
                break;
        }
    };

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        const allRules = [
            ...allowedReadRules,
            ...deniedReadRules,
            ...allowedWriteRules,
            ...deniedWriteRules,
        ];

        try {
            setLoading(true);
            const new_resource_access_settings = await ResourceAccessManagementAPI.create_resource_access_settings({
                name: data.name,
                ownership_kind,
                user_type: userType,
                access_rules: allRules,
            });
            showSuccessToast("Resource Access Settings has been successfully created");
            onClose(new_resource_access_settings);
        } catch (err: any) {
            console.error(err);
            showErrorToast("Failed to create a new resource access settings");
            onClose();
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={() => onClose()}>
                <AlertDialogOverlay>
                    <AlertDialogContent as="form" onSubmit={handleSubmit(onSubmit)} minW="500px">
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Create Resource Access Settings
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Stack spacing="4">
                                <FormControl>
                                    <FormInput
                                        name="name"
                                        label="Name"
                                        register={register('name', { required: true })}
                                        isRequired
                                    />
                                </FormControl>

                                {/* "Who can read?" Section */}
                                <Text fontWeight="semibold" mt={4}>
                                    Who can read?
                                </Text>
                                <Stack direction="row" spacing={2}>
                                    <Button size="sm" onClick={() => openAddRuleDialog(AccessPermissionType.AllowRead)}>
                                        Add Allow Read
                                    </Button>
                                    <Button size="sm" onClick={() => openAddRuleDialog(AccessPermissionType.DenyRead)}>
                                        Add Deny Read
                                    </Button>
                                </Stack>
                                <Wrap spacing="2">
                                    {allowedReadRules.map((rule, index) => (
                                        <WrapItem key={`ar-${index}`}>
                                            <Badge colorScheme="green" px={2} py={1} borderRadius="md">
                                                <HStack spacing={1}>
                                                    <Text fontSize="sm">Allow: {accessRuleToString(rule)}</Text>
                                                    <CloseButton onClick={() => removeRule(AccessPermissionType.AllowRead, index)} size="sm" />
                                                </HStack>
                                            </Badge>
                                        </WrapItem>
                                    ))}
                                    {deniedReadRules.map((rule, index) => (
                                        <WrapItem key={`dr-${index}`}>
                                            <Badge colorScheme="red" px={2} py={1} borderRadius="md">
                                                <HStack spacing={1}>
                                                    <Text fontSize="sm">Deny: {accessRuleToString(rule)}</Text>
                                                    <CloseButton onClick={() => removeRule(AccessPermissionType.DenyRead, index)} size="sm" />
                                                </HStack>
                                            </Badge>
                                        </WrapItem>
                                    ))}
                                </Wrap>

                                {/* "Who can update?" Section */}
                                <Text fontWeight="semibold" mt={4}>
                                    Who can update?
                                </Text>
                                <Stack direction="row" spacing={2}>
                                    <Button size="sm" onClick={() => openAddRuleDialog(AccessPermissionType.AllowWrite)}>
                                        Add Allow Update
                                    </Button>
                                    <Button size="sm" onClick={() => openAddRuleDialog(AccessPermissionType.DenyWrite)}>
                                        Add Deny Update
                                    </Button>
                                </Stack>
                                <Wrap spacing="2">
                                    {allowedWriteRules.map((rule, index) => (
                                        <WrapItem key={`aw-${index}`}>
                                            <Badge colorScheme="green" px={2} py={1} borderRadius="md">
                                                <HStack spacing={1}>
                                                    <Text fontSize="sm">Allow: {accessRuleToString(rule)}</Text>
                                                    <CloseButton onClick={() => removeRule(AccessPermissionType.AllowWrite, index)} size="sm" />
                                                </HStack>
                                            </Badge>
                                        </WrapItem>
                                    ))}
                                    {deniedWriteRules.map((rule, index) => (
                                        <WrapItem key={`dw-${index}`}>
                                            <Badge colorScheme="red" px={2} py={1} borderRadius="md">
                                                <HStack spacing={1}>
                                                    <Text fontSize="sm">Deny: {accessRuleToString(rule)}</Text>
                                                    <CloseButton onClick={() => removeRule(AccessPermissionType.DenyWrite, index)} size="sm" />
                                                </HStack>
                                            </Badge>
                                        </WrapItem>
                                    ))}
                                </Wrap>
                            </Stack>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => onClose()} isLoading={loading}>
                                Cancel
                            </Button>
                            <Button colorScheme="green" ml={3} type="submit" isLoading={loading}>
                                Create
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {isAddRuleDialogOpen && (
                <AddAccessRuleDialog
                    isOpen={isAddRuleDialogOpen}
                    onClose={handleAddRule}
                    permissionType={currentPermissionType!}
                />
            )}
        </>
    );
};
