import React, { useEffect, useMemo, useState } from "react";
import {
    Text,
    Stack,
    HStack,
    SimpleGrid,
    Box,
    Badge,
    Spacer,
    GridItem, Checkbox, VStack, Button,
} from "@chakra-ui/react";
import { ReportEntry } from "../../models/report_entry";
import { ObjectId, to_oid } from "../../utils/ObjectId";
import { BlCard } from "../layout/Card";
import { ReportLayout } from "protobuffer-ts/dist/class_service/report_layout";
import { FlexWrap } from "../layout/FlexWrap";
import { CourseAPI } from "../../api/CourseAPI";
import { PdfGenerator } from "../homeroom_pdf/PdfGenerator";
import { PdfGenerator as HighSchoolPdfGenerator } from "../high_school_pdf/PdfGenerator";
import { getLearningSkillColor } from "../../models/semester";
import {ReportAPI} from "../../api/ReportAPI";
import {useToaster} from "../../hooks/useToaster";
import {FaSignature} from "react-icons/all";

interface Props {
    report_entry: ReportEntry;
    report_layout?: ReportLayout;
}

const LearningSkillsSection: React.FC<{ report_entry: ReportEntry }> = ({ report_entry }) => (
    <Stack gap="2" as={GridItem} gridColumn="span 2">
        <Text fontWeight="semibold">Learning Skills</Text>
        {report_entry.learning_skills.map((learningSkill) => {
            const skillId = learningSkill.learning_skill_id.$oid;
            const skillData = report_entry.semester_report_layout.learningSkills.find(
                (s) => s.id?.id === skillId
            );
            const skillTitle = skillData?.title || "Unknown Skill";
            return (
                <FlexWrap key={skillId} gap={2}>
                    <Text>{skillTitle}:</Text>

                    <Badge colorScheme={getLearningSkillColor(learningSkill.mark)}>
                        {learningSkill.mark}
                    </Badge>
                </FlexWrap>
            );
        })}
    </Stack>
);

const CheckboxesSection: React.FC<Props> = ({ report_entry, report_layout }) => {
    if (report_entry.checkboxes.length === 0) return null;

    return (
        <>
            <Text fontWeight="semibold">Checkboxes</Text>

            {report_entry.checkboxes.map((checkbox) => {
                const checkboxData = report_layout?.checkBoxes.find(
                    (c) => c.id?.id === checkbox.check_box_id.$oid
                );
                const checkboxName = checkboxData?.name || "Unknown";

                return (
                    <HStack key={checkbox.check_box_id.$oid}>
                        <Badge colorScheme={checkbox.state ? "green" : "red"}>
                            {checkbox.state ? "✓" : "✗"}
                        </Badge>

                        <Text>{checkboxName}</Text>
                    </HStack>
                );
            })}
        </>
    );
};

const CreditsSection: React.FC<Props> = ({ report_entry, report_layout }) => {
    if (!report_entry.semester_report_layout.showCreditsEarnedEntry) return null;
    return (
        <>
            <Text fontWeight="semibold">Course Credit</Text>
            <FlexWrap>
                <Text>Credit Earned: </Text>
                <Text fontWeight="bold">
                    {report_entry.credit_earned ? report_layout?.creditWeight : 0}
                </Text>
            </FlexWrap>
        </>
    );
};

const MarksSection: React.FC<Props> = ({ report_entry, report_layout }) => {
    if (report_entry.sections.length === 0) return null;

    return (
        <Stack gap="4">
            <Text fontWeight="semibold">Marks</Text>
            {report_entry.sections.map((section) => {
                const sectionData = report_layout?.sections.find(
                    (s) => s.id?.id === section.layout_id.$oid
                );
                return (
                    <FlexWrap key={section.layout_id.$oid}>
                        <Text>
                            {sectionData?.name || "Unknown Section"}:
                        </Text>
                        <Text fontWeight="bold">{section.mark}</Text>
                    </FlexWrap>
                );
            })}
        </Stack>
    );
};


const GuardianSignedSection: React.FC<Props> = ({ report_entry }) => {

    return (
        <>
            {report_entry.signed_by_guardian_name ? (
                <Badge colorScheme="purple">Signed by {report_entry.signed_by_guardian_name}</Badge>
            ) : (
                <Badge colorScheme="red">Not Signed by Guardian</Badge>
            )}
        </>
    );
};


const PdfSection: React.FC<{ report_entry: ReportEntry; student_id: ObjectId, is_homeroom_course: boolean }> = ({ report_entry, student_id, is_homeroom_course }) => (
    <>
        {report_entry.homeroom && (
            <HStack>
                <GuardianSignedSection report_entry={report_entry}/>
                <Spacer />
                <PdfGenerator
                    student_id={student_id}
                    homeroom_id={report_entry.homeroom}
                    report_type={report_entry.report_type}
                />
            </HStack>
        )}
        {report_entry.course && !is_homeroom_course && (
            <HStack>
                <GuardianSignedSection report_entry={report_entry}/>
                <Spacer />
                <HighSchoolPdfGenerator
                    student_id={student_id}
                    semester_id={to_oid(report_entry.semester.id!.id)}
                    report_type={report_entry.report_type}
                />
            </HStack>
        )}
    </>
);

export const StudentReportEntryView: React.FC<Props> = React.memo(
    ({ report_entry, report_layout }) => {
        const [reportEntryState, setReportEntryState] = useState<ReportEntry>(report_entry);
        const [reportLayoutState, setReportLayoutState] = useState<ReportLayout | undefined>(report_layout);
        const [isHomeroomCourse, setIsHomeroomCourse] = useState<boolean>(false);
        
        const student_id = useMemo(() => reportEntryState.student, [reportEntryState]);

        useEffect(() => {
            const fetchAllData = async () => {
                try {
                    if (reportEntryState.course) {
                        let layout = report_layout;
                        if (!layout) {
                            layout = await CourseAPI.get_report_layout(reportEntryState.course!);
                            const course = await CourseAPI.get_course(reportEntryState.course!);
                            setReportLayoutState(layout);
                            if (course.homeroom) {
                                setIsHomeroomCourse(true);
                            }
                        }
                        const filledCheckboxes =
                            layout?.checkBoxes?.map((checkbox) => ({
                                check_box_id: to_oid(checkbox.id!.id),
                                state:
                                    report_entry.checkboxes?.find(
                                        (e) => e.check_box_id.$oid === checkbox.id!.id
                                    )?.state || false,
                            })) || [];

                        const filledSections =
                            layout?.sections?.map((section) => ({
                                layout_id: to_oid(section.id!.id),
                                mark:
                                    report_entry.sections?.find(
                                        (e) => e.layout_id.$oid === section.id!.id
                                    )?.mark || "",
                            })) || [];

                        const updatedEntry = {
                            ...report_entry,
                            checkboxes: filledCheckboxes,
                            sections: filledSections,
                        };

                        setReportEntryState(new ReportEntry(updatedEntry));
                    }
                } catch (e) {
                    console.error("Error fetching data", e);
                }
            };

            fetchAllData();
        }, [report_entry, student_id]);

        return (
            <Stack gap={4} id={reportEntryState?.student.$oid}>
                <BlCard>
                    <Text fontSize="lg" fontWeight="bold">
                        {reportEntryState.class_name}
                    </Text>
                    <SimpleGrid columns={{ md: 2, lg: 4 }} gap="6">
                        {!isHomeroomCourse && <LearningSkillsSection report_entry={reportEntryState} /> }

                        {(reportEntryState.checkboxes.length !== 0 ||
                            reportEntryState.semester_report_layout.showCreditsEarnedEntry) && (
                                <Stack gap="4">
                                    <CheckboxesSection
                                        report_entry={reportEntryState}
                                        report_layout={reportLayoutState}
                                    />

                                    <CreditsSection
                                        report_entry={reportEntryState}
                                        report_layout={reportLayoutState}
                                    />
                                </Stack>
                            )}

                        <MarksSection
                            report_entry={reportEntryState}
                            report_layout={reportLayoutState}
                        />
                    </SimpleGrid>

                    <Box bg="gray.50" border="1px dashed" borderColor="gray.300" p="4" rounded="md">
                        <Text fontWeight="semibold">Comment</Text>

                        <Text>{reportEntryState.comment}</Text>
                    </Box>
                    <PdfSection report_entry={report_entry} student_id={student_id} is_homeroom_course={isHomeroomCourse} />
                </BlCard>
            </Stack>
        );
    }
);
