import { Text } from '@chakra-ui/react';
import { TableContent } from '../table/TableContent';
import { useChipCell } from '../../hooks/useChipCell';
import { PaymentStatus, PaymentStatusColor, PaymentType, PaymentTypeColors, Transaction } from '../../models/transaction';
import { DateTime } from '../../utils/DateTime';

interface TransactionsTableProps {
	data?: Transaction[]
}

export const TransactionsTable = ({data = []}: TransactionsTableProps) => {
	
	const transactionsColumns = [
		{
			Header: "Date",
			accessor: "date",
			Cell: (data: DateTime) => <>{new DateTime(data).to_utc_string_date()}</>
		},
		{
			Header: "Status",
			accessor: "status",
			Cell: (data: PaymentStatus) => useChipCell(data, PaymentStatusColor[data])
		},
    {
      Header: "Payment Type",
      accessor: "payment_type",
      Cell: (data: PaymentType) => useChipCell(data, PaymentTypeColors[data])
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: (amount: number, row : Transaction) => <Text fontWeight={'bold'}>{amount} {row.currency}</Text>,
    },
	];

	return (
		<TableContent data={data} columns={transactionsColumns} />
	);
};
