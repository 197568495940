import { View, Text as PdfText } from "@react-pdf/renderer";
import { Semester } from "protobuffer-ts/dist/class_service/semester";
import { CustomField } from "../../models/custom_field";
import { CustomFieldEntry } from "protobuffer-ts/dist/user_service/custom_field_entry";
import { Grades, Student } from "../../models/student"
import { schoolInfo } from "../reports/ReportsPdfData";

import PdfStylesheet from "./PdfStylesheet";
import { SignatureProps } from "./SignatureSection";

const styles = PdfStylesheet

interface StudentInfoSectionProps {
	student: Student
	semester: Semester
	principal_name: string
	primary_id_custom_field?: CustomField
	primary_id_entry?: CustomFieldEntry
}

export const StudentInfoSection: React.FC<StudentInfoSectionProps> = ({ student, semester, principal_name, primary_id_custom_field, primary_id_entry }) => {
	return (
		<>
		{/* Student Information Section Title */}
		<View style={styles.sectionTitleContainer}>
			<PdfText style={styles.sectionTitleText}>Student Information</PdfText>
		</View>

		{/* Student Information and School Information */}
		<View style={styles.tableContainer}>
			{/* First Row */}
			<View style={[styles.cell, styles.cellQuarterWidth]}>
				<PdfText style={styles.label}>Name: </PdfText>
				<PdfText style={styles.value}>
					{`${student.first_name} ${student.last_name}`}
				</PdfText>
			</View>
							
			<View style={[styles.cell, styles.cellQuarterWidth]}>
					{primary_id_custom_field && primary_id_entry &&
					<>
						<PdfText style={styles.label}>{primary_id_custom_field.name}:</PdfText>
						<PdfText style={styles.value}>{primary_id_entry.value}</PdfText>
					</>			
					}
			
			</View>
		
			<View style={[styles.cell, styles.cellQuarterWidth]}>
				<PdfText style={styles.label}>Grade: </PdfText>
				<PdfText style={styles.value}>{Grades.get(student.grade)}</PdfText>
			</View>

			<View style={[styles.cell, styles.cellQuarterWidth]}>
				<PdfText style={styles.label}>Supervisor: </PdfText>
				<PdfText style={styles.value}>
					{
						semester.name.toLowerCase().includes("boys") ? schoolInfo.BoysSupervisor : 
						semester.name.toLowerCase().includes("girls") ? schoolInfo.girlsSupervisor : ""
					}
				</PdfText>
			</View>

			<View style={[styles.cell, styles.cellQuarterWidth]}>
				<PdfText style={styles.label}>School: </PdfText>
				<PdfText style={styles.value}>{schoolInfo.School}</PdfText>
			</View>

			<View style={[styles.cell, styles.cellQuarterWidth]}>
				<PdfText style={styles.label}>Principal: </PdfText>
				<PdfText style={styles.value}>{principal_name}</PdfText>
			</View>

			<View style={[styles.cell, styles.cellQuarterWidth]}>
				<PdfText style={styles.label}>School Address:</PdfText>
				<PdfText style={styles.value}>{schoolInfo.Address}</PdfText>
			</View>

			<View style={[styles.cell, styles.cellQuarterWidth]}>
				<PdfText style={styles.label}>Telephone: </PdfText>
				<PdfText style={styles.value}>{schoolInfo.Telephone}</PdfText>
			</View>
		</View>
		</>
	)
}