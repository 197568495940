import { Box, Button, Flex, SimpleGrid, Skeleton, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { OnboardingSettings } from "protobuffer-ts/dist/organization_service/onboarding_settings";
import { ProfileSection } from "protobuffer-ts/dist/organization_service/organization_profile_settings";
import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { OnboardingSettingsAPI } from "../../../api/OnboardingSettingsAPI";
import { StudentAPI } from "../../../api/StudentAPI";
import { useToaster } from "../../../hooks/useToaster";
import { ActionType, getEmailTemplate } from "../../../models/onboarding";
import { Student, StudentStatus } from "../../../models/student";
import { LightButton } from "../../layout/LightButton";
import { StudentInvoicesCard } from "../Cards/InvoicesCard";
import StudentOverview from "../Cards/OverviewCard";
import StudentCustomFieldsSection from "../CustomFieldsSection";
import { OnboardingMessageDialog } from "./OnboardingMessageDialog";

export const InterviewStudent = () => {
    const { student_id } = useParams<{ student_id: string }>();
    const [student, setStudent] = useState<Student>();
    const [onboardingSettings, setOnboardingSettings] = useState<OnboardingSettings>();
    const [actionType, setActionType] = useState<ActionType>();
    const [actionLoading, setActionLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const { showErrorToast, showSuccessToast } = useToaster();
    const { setHeading } = useOutletContext<{ setHeading: (heading: string) => void }>();


    const fetchStudentAndOnboardingSettings = async () => {
        setLoading(true);
        try {

            const [student, onboardingSettings] = await Promise.all([
                StudentAPI.get_student(student_id!), 
                OnboardingSettingsAPI.get_onboarding_settings(),
            ])

            setStudent(student!);
            setHeading(`Interviewing ${student!.first_name} ${student!.last_name}`);

            setOnboardingSettings(onboardingSettings);
        } catch (error) {
            showErrorToast("Failed to load student");
        } finally {
            setLoading(false);
        }
    };

    const handleActionConfirmation = async (message: string) => {
        if (!actionType) return;

        setActionLoading(true);
        try {
            let updatedStudent: Student;
            switch (actionType) {
                case ActionType.Approve:
                    updatedStudent = await StudentAPI.approve(student!._id, message);
                    break;
                case ActionType.Reject:
                    updatedStudent = await StudentAPI.reject(student!._id, message);
                    break;
                case ActionType.RequireChanges:
                    updatedStudent = await StudentAPI.requireFeildsChangesFromParents(student!._id, message);
                    break;
            }

            showSuccessToast(`Successfully set student status to ${updatedStudent!.status}`); // this must return an error, cause this component can do only handled aciton types.
        } catch (error: any) {
            showErrorToast(error?.response?.data || 'Action Failed');
        } finally {
            setActionLoading(false);
            setActionType(undefined);
            fetchStudentAndOnboardingSettings();
        }
    };

    useEffect(() => {
        fetchStudentAndOnboardingSettings();
    }, [student_id]);

    if (loading) {
        return <Skeleton height="400px" />;
    }

    if (!student) {
        return null;
    }
    return (
        <Stack>
            <Tabs>
                <TabList mb='1em' color={"blue.600"}>
                    <Tab>Profile</Tab>
                    <Tab>Invoices</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Stack gap="4">
                            <StudentOverview
                                student={student}
                                set_student={() => setStudent(student)}
                            />
                            <StudentCustomFieldsSection
                                student={student}
                                profileSections={[
                                    ProfileSection.WAITLIST,
                                    ProfileSection.INTERVIEW,
                                ]}
                            />
                        </Stack>
                    </TabPanel>

                    <TabPanel>
                        <StudentInvoicesCard student={student} />
                    </TabPanel>
                </TabPanels>
            </Tabs>

            {student.status === StudentStatus.Interview &&
                <Flex direction="row" justify="flex-end" gap={4} mt={4}>
                    <LightButton color="gray" onClick={() => setActionType(ActionType.RequireChanges)} isLoading={actionLoading}>
                        Require changes
                    </LightButton>
                    <LightButton color="green" onClick={() => setActionType(ActionType.Approve)} isLoading={actionLoading}>
                        Approve
                    </LightButton>
                    <LightButton color="red" onClick={() => setActionType(ActionType.Reject)} isLoading={actionLoading}>
                        Reject
                    </LightButton>
                </Flex>}

                {actionType && (
                <OnboardingMessageDialog
                    isOpen={!!actionType}
                    onClose={() => setActionType(undefined)}
                    messageType={actionType}
                    initialMessage={getEmailTemplate(actionType, onboardingSettings!)!}
                    onConfirm={handleActionConfirmation}
                />
            )}
        </Stack>
    );
};