import React from "react";
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    FormLabel,
    HStack,
    IconButton, SimpleGrid,
    Stack,
    useBreakpointValue,
    useDisclosure
} from "@chakra-ui/react";
import { BlCard } from "../layout/Card";
import { AiOutlineMenuFold } from "react-icons/ai";

interface ResponsiveSidebarWrapperProps {
    sidebar: (onClose: () => void) => React.ReactNode;
    children: React.ReactNode;
}

export const ResponsiveSidebarWrapper: React.FC<ResponsiveSidebarWrapperProps> = ({ sidebar, children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isMobile = useBreakpointValue({ base: true, lg: false });

    return (
        <>
            {isMobile ? (
                <>
                    <HStack mb={4}>
                        <IconButton
                            aria-label="Show Sections"
                            icon={<AiOutlineMenuFold />}
                            onClick={onOpen}
                            variant="ghost"
                        />

                        <FormLabel fontSize="lg" fontWeight="bold"> 
                            Student Information 
                        </FormLabel>
                    </HStack>
                    <Stack>
                        {children}
                    </Stack>
                    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader>Sections</DrawerHeader>
                            <DrawerBody>
                                <BlCard>{sidebar(onClose)}</BlCard>
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                </>
            ) : (
                <SimpleGrid columns={{ lg: 5 }} gap={4}>
                    <BlCard position="sticky" top="0" overflowY="auto" flex='0'>
                        {sidebar(onClose)}
                    </BlCard>
                    <Stack gridColumn={{ lg: "span 4" }}>
                        {children}
                    </Stack>
                </SimpleGrid>
            )}
        </>
    );
};
